'use strict';

var validator = require('./../../../../app_capitollighting_core/cartridge/js/validator');
var util = require('./../../../../app_capitollighting_core/cartridge/js/util');

/**
 * @private
 * @function
 * @description Function to validate forms
 * @todo Implement form validation logic after the submission
 */
function formValidation() {
    if ($('.js-trade-professionals-form').length) {

        validator.init();
        util.customRequiredMsgValidator();

        $(".js-trade-professionals-form input[name$='emailaddress']").on('input', function () {
            $('.js-error-account-existing').addClass('ms-hide');
            $('.js-error-account-pending').addClass('ms-hide');
        });

        $('.js-trade-professionals-form').on('submit', function(e) {
            e.preventDefault();

            var $this = $(this);
            if (!$this.valid()) {
                return false;
            }
            var firstName = $this.find("input[name$='firstname']").val();
            var lastName  = $(".js-trade-professionals-form input[name$='lastname']").val();
            var email     = $(".js-trade-professionals-form input[name$='emailaddress']").val();

            if (firstName == '' || lastName == '' || email == '') {
                return false;
            }

            /* -- Start Recaptcha Integration changes -- */
            if (SitePreferences.RECAPTCHA_ENABLED && !grecaptcha.getResponse().length) {
                $.ajax({
                    url: Urls.validateRecaptcha,
                    data: {
                        token: $('.g-recaptcha-response').val()
                    }
                }).done(function (response) {
                    if (!response.success || response['error-codes'].length > 1) {
                        let msg = '';

                        $.each(response['error-codes'], function(index, item) {
                            msg += Resources[item.split('-').join('_').toUpperCase()] + '<br>';
                        });

                        $('.js-error-grecaptcha').html(msg).removeClass('ms-hide');
                    }
                });

                return false;
            }
            /* -- End Recaptcha Integration changes -- */

            var url = Urls.checkExistingEmail;
            jQuery.ajax({
                type : 'POST',
                url  : url,
                data : {
                    email : email
                }
            }).done(function(result) {
                switch (true) {
                    case !result.isAccount && !result.isStatusPending:
                        e.currentTarget.submit();
                        break;
                    case result.isAccount && result.isStatusPending:
                    case !result.isAccount && result.isStatusPending:
                        $('.js-error-account-pending').toggleClass('ms-hide', !result.isStatusPending);
                        break;
                    case result.isAccount && !result.isStatusPending:
                        $('.js-error-account-existing').toggleClass('ms-hide', !result.isAccount);
                        break;
                    default:
                        return false;
                }
            });
        });
    }

    $(document).on('click', '.closeproaccountmessage', function(e) {
        e.preventDefault();
        var url = Urls.deleteSession;
        jQuery.ajax({
            type : 'POST',
            url  : url
        });
    });
}

exports.init = function () {
    formValidation();
};
