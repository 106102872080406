var stickynav = {
    didScroll: false,
    scrollLock: false,
    init: function () {
        var $window = $(window);

        // Triggers sticky nav on first scroll or removes when user goes back to the top
        $window.on("scroll", function () {
            if (!stickynav.didScroll || $window.scrollTop() == 0) {
                stickynav.didScroll = true;
                stickynav.hasScrolled();
            }
        });

        document.body.addEventListener("touchmove", function (event) {
            stickynav.updateVerticalHeight();
            stickynav.lockScroll(event, stickynav.scrollLock);
        }, false);

        stickynav.updateVerticalHeight();
        if ($(".js-menu-toggle").length) {
            stickynav.watchMenu();
        }
    },
    lockScroll: function (event, scrollLock) {
        if (scrollLock) {
            event.preventDefault();
        }
    },
    updateVerticalHeight: function () {
        var vh=window.innerHeight*.01;
        document.documentElement.style.setProperty("--vh", "".concat(vh, "px"));
    },
    watchMenu: function () {
        var $target=$(".js-menu-toggle");
        if ($target.length) {
            var targetNode=$target[0];
            var config={
                attributes:true
            };
            var observer=new MutationObserver(
                function () {
                    if ($target.hasClass("active")) {
                        stickynav.scrollLock=true;
                        this.updateVerticalHeight();
                    } else {
                        stickynav.scrollLock=false;
                    }
                });
            observer.observe(targetNode, config);
        }
    },
    hasScrolled: function () {
        var st = $(window).scrollTop();
        var $wrapper = $("#wrapper");
        if (!$wrapper.length) {
            $wrapper = $("body");
        }
        if (st > 0) {
            $wrapper.addClass("cl-header--sticky");
        } else {
            $wrapper.removeClass("cl-header--sticky");
            stickynav.didScroll = false;
        }
    }
};

module.exports = stickynav;
