//OPC Checkout JS derived from app.js

// include necessary modules
var util = require("../util");
var ajax = require("../ajax");
var validator = require("../validator");
var progress = require("../progress");
var tooltips = require("../tooltip");
var dialog = require("../dialog");
var page = require("../page");
var giftcard = require("../giftcard");
var giftCertApplied = false;
var giftCertCode = "";
var inputmask = require("../inputmask");
var addressSuggestions = require('../addressSuggestions');

var opc = opc || {};

//regex expressions
var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/,
    regex = {
        phone : {
            us : naPhone,
            ca : naPhone
        },
        postal : {
            us : /^\d{5}(-\d{4})?$/,
            ca : /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
            gb : /^GIR?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y]))|[0-9][A-HJKS-UW])?[0-9][ABD-HJLNP-UW-Z]{2}$/
        },
        email : /^[\w.%+\-]+@[\w.\-]+\.[\w]{2,6}$/
    },
    settings = {
        // global form validator settings
        errorClass : "error",
        errorElement : "span",
        onkeyup : false,
        onfocusout : function (element) {
            if (!this.checkable(element)) {
                this.element(element);
            }
        }
    },
    $cache, $inputs, $allFormElemets,
    isShipping,
    shippingMethods;

opc.checkout = {

    init: function () {
        $cache = {},
        isShipping = false,
        shippingMethods = null;

        opc.checkout.initializeCache();
        opc.checkout.initializeDom();
        opc.checkout.initializeEvents();
        opc.checkout.checkoutAccordion();

        // cybersource customization
        if($cache.checkoutForm.length && $cache.ccContainer.length){
            $cache.checkoutForm.find('input[name$="_selectedCardID"]').val('');
            $cache.checkoutForm.find('input[name*="_number"]').val('');

            $cache.ccContainer.find('input[name*="_number"]').on('change',function(e){
                $cache.checkoutForm.find('input[name$="_selectedCardID"]').val('');
            });
            $cache.ccContainer.find('input[name$="_owner"]').on('change',function(e){
                $cache.checkoutForm.find('input[name$="_selectedCardID"]').val('');
            });
            $cache.ccContainer.find('select[name$="creditCard_type"]').on('change',function(e){
                $cache.checkoutForm.find('input[name$="_selectedCardID"]').val('');
            });

            $cache.ccContainer.find('select[name*="expiration"]').on('change',function(e){
                $cache.checkoutForm.find('input[name$="_selectedCardID"]').val('');
                var selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]:checked').val();
                var cardNumber = $cache.checkoutForm.find('input[name*="_number"]').val();
                if(cardNumber.indexOf('****') != -1 && selectedPaymentMethodID == 'SA_SILENTPOST'){
                    $cache.checkoutForm.find('input[name*="_number"]').val('');
                }
            });

            // default payment method to 'CREDIT_CARD'
            var selectedPaymentMethod = $('input[name$="_selectedPaymentMethodID"]:checked').val();
            opc.checkout.updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');
            var $selectPaymentMethod = $(".cl-payment-method-options");
            $selectPaymentMethod.on('click', 'input[type="radio"]', function () {
                opc.checkout.updatePaymentMethod($(this).val());
            });
        }
        // end of cybersource customization
    },
    /**
         * Helper method which constructs a URL for an AJAX request using the
         * entered address information as URL request parameters.
         */
    getShippingMethodURL: function (url, extraParams) {
        var params = {
            address1: $cache.address1.val(),
            countryCode: $cache.countryCode.val(),
            stateCode: $cache.stateCode.val(),
            postalCode: $cache.postalCode.val(),
            city: $cache.city.val(),
            format: "ajax"
        };
        return util.appendParamsToUrl(url, $.extend(params, extraParams));
    },

    /**
         * OnePageCheckout accordion for richUI design
         */
    checkoutAccordion: function (activeIndex) {
        var $accordion = $(".accordion");
        var index;
        var isAccordionInitialized = $accordion.hasClass("ui-accordion");
        var section = window.location.queryString && window.location.queryString.section ? window.location.queryString.section : util.getParameterByName("section");

        if (isAccordionInitialized) {
            var fieldsets = $cache.checkoutForm.find("fieldset:has(> legend)"),
                invalidFieldset;
            fieldsets.each(function (index, fieldset) {
                if (!$(fieldset).hasClass("valid")) {
                    invalidFieldset = fieldset;
                    return false;
                }
            });
            if (invalidFieldset) {
                index = fieldsets.index(invalidFieldset);
                if (activeIndex && activeIndex < index) {
                    index = $accordion.accordion("option", "active");
                }
            } else {
                index = $accordion.accordion("option", "active");
            }
            //var index = $('.accordion').accordion("option", "active");
        } else {
            $accordion.accordion();
            index = 0;
        }

        if (section == "payment") {
            index = 3;
            window.history.pushState(null, null, util.removeParamFromURL(window.location.href, "section"));
        }

        $accordion.accordion("destroy").accordion({
            clearStyle: true,
            collapsible: false,
            heightStyle: "content",
            autoHeight: false,
            active: index,
            header: "legend",
            activate: function (event, ui) {
                if (ui.newPanel.hasClass("billing-coupon-code") && $("#noPaymentNeeded:visible").length) {
                    $cache.submitBtn.attr("disabled", !$cache.checkoutForm.validate().checkForm());
                    $cache.submitDuplicateBtn.attr("disabled", !$cache.checkoutForm.validate().checkForm());
                }

                opc.checkout.viewSummaryInformation();

                var activeTabIndex = $accordion.accordion("option", "active");
                if (activeTabIndex >= 1) {
                    $(".order-shipping, .order-shipping-discount, .order-sales-tax, .order-total").removeClass("hide");
                }

                ui.newPanel.parent().find(".ui-accordion-header").removeClass("completed-step-check-mark--active");
                ui.newPanel.parent().find(".js-checkout-step-edit-btn").removeClass("checkout-step-edit-btn--active");
                if (ui.newPanel.parent().attr("id") === "checkout-payment-methods")  {
                    $cache.submitBtnWrapper.removeClass("ms-hide");
                } else {
                    $cache.submitBtnWrapper.addClass("ms-hide");
                }
            },
            beforeActivate: function (event, ui) {
                if (ui.newHeader.length > 0) {
                    var clickedPrevPanel = (ui.oldPanel[0].id > ui.newPanel[0].id);
                    var valid = clickedPrevPanel || $cache.checkoutForm.valid();

                    if (!valid) {
                        var validate = $cache.checkoutForm.validate();
                        $cache.checkoutForm.find(validate.errorList[0].element).focus();
                        ui.oldPanel.parent().removeClass("validate");
                        // Forbid to leave not valid form
                        event.stopImmediatePropagation();
                        event.preventDefault();
                    } else {
                        ui.oldPanel.parent().addClass("validate");
                        if (ui.oldPanel.parent().hasClass("validate")) {
                            ui.oldPanel.parent().find(".ui-accordion-header").addClass("completed-step-check-mark--active");
                        }
                        ui.oldPanel.parent().find(".js-checkout-step-edit-btn").addClass("checkout-step-edit-btn--active");
                        // remove step check-mark in case field of credit card were not filled
                        if (ui.oldPanel.parent().attr("id") === "checkout-payment-methods") {
                            if (opc.checkout.isCreditCardChosen()) {
                                if (!opc.checkout.isCreditCardFilled()) {
                                    ui.oldPanel.parent().find(".ui-accordion-header").removeClass("completed-step-check-mark--active");
                                }
                            }
                        }
                        // remove step check-mark in case billing fields are not the same as shipping and not filled
                        if (ui.oldPanel.parent().attr("id") === "checkout-billing-address") {
                            if (!opc.checkout.isBillingSameAsShipping()) {
                                if (!opc.checkout.isBillingAddressFilled()) {
                                    ui.oldPanel.parent().find(".ui-accordion-header").removeClass("completed-step-check-mark--active");
                                }
                            }
                        }

                        if (ui.oldPanel.parent().attr("id") === "checkout-billing-address") {
                            opc.checkout.removeErrorsBillingAddress();
                        }

                        var fieldsets = $cache.checkoutForm.find("fieldset");
                        var newIndex = fieldsets.index(ui.newPanel.parent());
                        var allowChange = true;
                        fieldsets.each(function (index, fieldset) {
                            if (index < newIndex && !$(fieldset).hasClass("validate")) {
                                allowChange = false;
                            }
                        });
                        if (allowChange) {
                            ui.newPanel.parent().removeClass("validate");
                        } else {
                            // Forbid to hop over not validated fieldset(s)
                            event.stopImmediatePropagation();
                            event.preventDefault();
                        }
                    }
                } else {
                    // Forbid the accordion to collapse
                    event.stopImmediatePropagation();
                    event.preventDefault();
                }
            }
        });

        if (section === "payment" && util.getParameterByName("redirect") === "COBilling-Start") {
            opc.checkout.setCompletedStep("payment");
        }
    },

    //updates the order summary based on a possibly recalculated
    //basket after a shipping promotion has been applied

    updateSummary: function () {

        var url = Urls.summaryRefreshURL;
        var url2 = Urls.onePageCheckoutSummaryRefreshURL;
        var summary = $("#secondary.summary");
        var activeTabIndex = $(".accordion").accordion("option", "active");

        // indicate progress
        progress.show(summary);

        // load the updated summary area
        summary.load(url, function () {
            // hide edit shipping method link
            summary.fadeIn("fast");
            summary.find(".checkout-mini-cart .minishipment .header a").hide();
            summary.find(".order-totals-table .order-shipping .order-shipping-discount .label a").hide();
            progress.hide();
            if (activeTabIndex >= 1) {
                $(".order-shipping, .order-shipping-discount, .order-sales-tax, .order-total").removeClass("hide");
            }
        });

        var summary2 = $("#total.place-order-totals");
        // indicate progress
        progress.show(summary2);

        // load the updated summary area
        summary2.load(url, function () {
            // hide edit shipping method link
            summary2.fadeIn("fast");
            summary2.find(".checkout-mini-cart .minishipment .header a").hide();
            summary2.find(".order-totals-table .order-shipping .order-shipping-discount .label a").hide();
            progress.hide();

            if (activeTabIndex >= 1) {
                $(".order-shipping, .order-shipping-discount, .order-sales-tax, .order-total").removeClass("hide");
            }
        });

        opc.checkout.checkoutAccordion(activeTabIndex);
        opc.checkout.skipBillingStep(true, activeTabIndex);
    },

    // updates the affirm data-amount attribute according to the current total gross price
    // and refresh the cost value
    updateAffirm: function () {
        var totalGrossPrice = $(document).find(".applePayOrderTotal") ? $(document).find(".applePayOrderTotal").val() : 0;
        var dataAmountStr   = (totalGrossPrice * 100).toString();
        $(document).find("span.affirm-as-low-as").attr("data-amount", dataAmountStr);
        window.affirm.ui.refresh();
    },

    selectShippingMethods: function (shippingMethodID, shipmentID) {

        var url = util.appendParamsToUrl(Urls.selectMultiShippingMethods,
            {
                shippingMethodID: shippingMethodID,
                shipmentID: shipmentID
            },
            true);

        ajax.getJson({
            url: url,
            callback: function (data) {
                opc.checkout.updateSummary();
                if (!data || !data.shippingMethodID) {
                    window.alert("Couldn't select shipping method.");
                    return false;
                }
                $(document).trigger('shippingMethod:selected', {response: data});
                // display promotion in UI and update the summary section,
                // if some promotions were applied
                $(".shippingpromotions").empty();
                if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
                    var i, len = data.shippingPriceAdjustments.length;
                    for (i = 0; i < len; i++) {
                        var spa = data.shippingPriceAdjustments[i];
                    }
                }
            }
        });
    },
    //selects a shipping method for the default shipment
    //and updates the summary section on the right hand side
    selectShippingMethod: function (shippingMethodID) {
        // nothing entered
        if (!shippingMethodID) {
            return;
        }
        // attempt to set shipping method
        var url = util.appendParamsToUrl(Urls.selectShippingMethodsList,
            {
                countryCode: $cache.countryCode.val(),
                stateCode: $cache.stateCode.val(),
                postalCode: $cache.postalCode.val(),
                city: $cache.city.val(),
                shippingMethodID: shippingMethodID
            },
            true);

        $cache.shippingMethodList.addClass("loading");

        ajax.getJson({
            url: url,
            callback: function (data) {
                opc.checkout.updateSummary();
                opc.checkout.updateAffirm();
                if (!data || !data.shippingMethodID) {
                    window.alert("Couldn't select shipping method.");
                    return false;
                }
                // display promotion in UI and update the summary section,
                // if some promotions were applied
                $(".shippingpromotions").empty();
                if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
                    var i, len = data.shippingPriceAdjustments.length;
                    for (i = 0; i < len; i++) {
                        var spa = data.shippingPriceAdjustments[i];
                    }
                }
                // Release the Continue button
                var buttonStep = $(".shipping-button");
                buttonStep.css("pointer-events", "auto");
                buttonStep.removeAttr("disabled");
                $cache.shippingMethodList.removeClass("loading");
            }
        });
    },

    /**
        * @function
        * @description Removes errors from billing address in case user go to another panel or checkbox billing as shipping is checked and user visits this panel and goes to next panel
        */
    removeErrorsBillingAddress: function () {
        var checkoutBillingAddress = $("#checkout-billing-address");
        checkoutBillingAddress.find("span.error").each(function () {
            $(this).remove();
        });
        checkoutBillingAddress.find(".required.error").each(function () {
            $(this).removeClass("error");
        });
    },

    /**
        * @function
        * @description sets completed state for previous or current accordion panel in case redirect with back-end error
        * @param {string} accordionPanel - accordion panel
        */
    setCompletedStep: function (accordionPanel) {
        if (accordionPanel === "payment") {
            $cache.checkoutForm.find("#checkout-shipping-address, #checkout-billing-address, #checkout-shipping-methods").each(function () {
                $(this).addClass("validate");
                $(this).find(".ui-accordion-header").addClass("completed-step-check-mark--active");
                $(this).find(".js-checkout-step-edit-btn").addClass("checkout-step-edit-btn--active");
            });
        } else if (accordionPanel === "shippingMethod") {
            var $checkoutShippingMethods = $("#checkout-shipping-methods");
            $checkoutShippingMethods.addClass("validate");
            $checkoutShippingMethods.find(".ui-accordion-header").addClass("completed-step-check-mark--active");
            $checkoutShippingMethods.find(".js-checkout-step-edit-btn").addClass("checkout-step-edit-btn--active");
        }
    },

    /**
         * @private
         * @function
         * @description Make AJAX call to retrieve correct Shipping Address format and show warning
         * @param {boolean} hide - if is true - immediately hide the warning message
         */
    suggestCorrectShippingAddress: function (hide = false) {
        // Immediately hide the warning message
        if (hide) {
            $(".js-shipping-warning").fadeOut("fast");
            return;
        }

        // Cache reusable elements and configurations
        let label = Resources.OPC_SHIPPING_SUGGEST,
            $warningContainer = $(".js-shipping-warning"),
            linkConfig = {
                href: "#",
                class: "cl-link",
                title: label
            },
            $link = $("<a>", linkConfig);

        // Event listener for dynamically created link
        $link.off("click").on("click", function (e) {
            e.preventDefault();

            var data = $(this).data("address");
            if (!data) {
                return;
            }

            // Automatically update address fields based on data-address attribute
            var p;
            for (p in data) {
                if ($cache[p] && data[p]) {
                    $cache[p].val(data[p].replace("^", "'"));

                    // special handling for countrycode => stateCode combo
                    if ($cache[p] === $cache.countryCode) {
                        opc.checkout.updateStateOptions($cache[p]);
                        $cache.stateCode.val(data.stateCode);
                    }
                }
            }

            opc.checkout.updateShippingMethodList();
        });

        // Run address validation only if all required fields are completed
        if (SitePreferences.ENABLE_ADDRESS_VALIDATION && $cache.city.val() && $cache.stateCode.val() && $cache.countryCode.val() && $cache.postalCode.val() && $cache.address1.val()) {
            var url = util.appendParamsToUrl(Urls.validateAddress,
                {
                    address1: $cache.address1.val(),
                    address2: $cache.address2.val(),
                    city: $cache.city.val(),
                    stateCode: $cache.stateCode.val(),
                    postalCode: $cache.postalCode.val(),
                    countryCode: $cache.countryCode.val()
                },
                true);

            ajax.getJson({
                url: url,
                callback: function (data) {
                    if (data.success) {
                        $warningContainer.fadeOut("fast");
                    } else {
                        $warningContainer.fadeIn("fast");
                        $(".js-shipping-suggested").empty();

                        // Insert autocomplete link only if server returns address suggestion
                        if (data.address) {
                            // Insert JSON into data-address attribute in order to automatically update address fields by clicking on the link
                            $link.attr("data-address", JSON.stringify(data.address));
                            $link.text(`${data.address.address1} ${data.address.city} ${data.address.stateCode} ${data.address.postalCode}`);
                            $(".js-shipping-suggested").append(`<span>${label} </span>`, $link);
                        }
                    }
                }
            });
        }
    },

    /**
         * Make an AJAX request to the server to retrieve the list of applicable shipping methods
         * based on the merchandise in the cart and the currently entered shipping address
         * (the address may be only partially entered).  If the list of applicable shipping methods
         * has changed because new address information has been entered, then issue another AJAX
         * request which updates the currently selected shipping method (if needed) and also updates
         * the UI.
         */
    updateShippingMethodList: function (params) {

        if (!$cache.shippingMethodList || $cache.shippingMethodList.length === 0) { return; }
        var url = opc.checkout.getShippingMethodURL(Urls.shippingMethodsJSON, params);

        // Clear address suggestion message
        opc.checkout.suggestCorrectShippingAddress(true);

        // Disabled button to avoid continue without refreshing the shipping methods list
        var buttonStep = $(".shipping-button");
        buttonStep.css("pointer-events", "none");
        buttonStep.attr("disabled", "disabled");

        $cache.shippingMethodList.addClass("loading");

        ajax.getJson({
            url: url,
            callback: function (data) {
                if (!data) {
                    window.alert("Couldn't get list of applicable shipping methods.");
                    return false;
                }

                // We need to update the UI.  The list has changed.
                // Cache the array of returned shipping methods.
                shippingMethods = data;

                var smlUrl = opc.checkout.getShippingMethodURL(Urls.shippingMethodsList);

                // load the shipping method form
                $cache.shippingMethodList.load(smlUrl, function () {
                    $cache.shippingMethodList.removeClass("loading");
                    $cache.shippingMethodList.fadeIn("fast");
                    // rebind the radio buttons onclick function to a handler.
                    $cache.shippingMethodList.find("[name$='_shippingMethodID']").click(function () {
                        opc.checkout.selectShippingMethod($(this).val());
                    });

                    // update the summary
                    // the 'selectShippingMethod' function above calls this update also, so this one is not needed
                    // opc.checkout.updateSummary();
                    // recreate accordion
                    opc.checkout.checkoutAccordion();
                    tooltips.init();

                    if (util.getParameterByName("redirect") === "COBilling-Start") {
                        opc.checkout.setCompletedStep("shippingMethod");
                    }

                    var activeTabIndex = $(".accordion").accordion("option", "active");
                    // set completed state for shipping methods in case AJAX call
                    if (activeTabIndex === 2) {
                        opc.checkout.setCompletedStep("shippingMethod");
                    }

                    if (data.length > 0) {
                        $("#apShippingContinue").removeAttr("disabled");
                    }

                    var selectShippingMethod = $("[name$='_shippingMethodID']");
                    // Trigger the shipping selection if no default method returned
                    if (!selectShippingMethod.is(":checked")) {
                        selectShippingMethod.eq(0).trigger("click");
                    } else {
                        selectShippingMethod.filter(":checked").trigger("click");
                    }

                    // In-Store Modifications
                    function proceed(bol) {
                        if (bol) {
                            buttonStep.css("pointer-events", "auto");
                            buttonStep.removeAttr("disabled");
                        } else {
                            buttonStep.css("pointer-events", "none");
                            buttonStep.attr("disabled", "disabled");
                        }
                    }

                    function callController(storeId) {
                        $.ajax({
                            type : "POST",
                            url  : $storeSelector.attr("data-link"),
                            data : {
                                store : storeId
                            }
                        }).done(function (data) {
                            proceed($storeSelector.val());
                        });
                    }

                    if (!selectShippingMethod.is(":checked")) {
                        proceed(false);
                    }

                    var $storeSelector = $(".storeSelector");

                    $("[name$='_shippingMethodID']").change(function () {
                        var method = $(this).val();

                        if ($storeSelector.length) {
                            proceed(method != "005" || (method == "005" && $storeSelector.val()));

                            if (method == "005") {
                                $storeSelector.removeClass("ms-hide");
                                if ($storeSelector.val()) {
                                    callController($storeSelector.val());
                                }
                            } else {
                                proceed(true);
                                $storeSelector.addClass("ms-hide");
                            }
                        } else {
                            proceed(true);
                        }

                    });

                    if ($storeSelector.length) {
                        $storeSelector.change(function () {
                            callController($storeSelector.val());
                        });
                    }
                    // In-Store Modification
                });
            }
        });

        // Call address suggestion warning
        opc.checkout.suggestCorrectShippingAddress();
    },


    //shipping page logic
    //checkout gift message counter
    initGiftMessageBox: function () {
        // show gift message box, if shipment is gift
        try {
            $cache.giftMessage.toggle($cache.checkoutForm.find("#is-gift-yes")[0].checked);
        }
        catch (exception) {
        }

    },

    /*
         * Loading events for shipping page
         */
    shippingLoad: function () {
        $cache.checkoutForm.on("click", "#is-gift-yes, #is-gift-no", function (e) {
            $cache.checkoutForm.find(".gift-message-text").toggle($cache.checkoutForm.find("#is-gift-yes")[0].checked);
        })
            .on("change blur",
                "select[name$='_shippingAddress_addressFields_states_state'], select[name$='_shippingAddress_addressFields_country'], input[name$='_shippingAddress_addressFields_city'], input[name$='_shippingAddress_addressFields_postal']",
                function (e, forced) {
                    if (!forced) {
                        opc.checkout.updateShippingMethodList();
                    }
                }
            );

        // gift message character limitation
        opc.checkout.initGiftMessageBox();
        opc.checkout.updateShippingMethodList();
        return null;
    },

    /*
         * This function updates state options depending on selected country
         */
    updateStateOptions : function (countrySelect) {
        var country = $(countrySelect);
        if (country.length===0 || !Countries[country.val()]) {
            return;
        }
        var form = country.closest("form");
        var stateField = country.data("stateField") ? country.data("stateField") : form.find("select[name$='_shippingAddress_addressFields_states_state']");
        if (stateField.length===0) {
            return;
        }

        var form = country.closest("form"),
            c = Countries[country.val()],
            arrHtml = [],
            labelSpan = form.find("label[for='"+stateField[0].id+"'] span").not(".required-indicator");

        // set the label text
        labelSpan.html(c.label);

        var s;
        for (s in c.regions) {
            arrHtml.push("<option value=\""+s+"\">"+c.regions[s]+"</option>");
        }
        // clone the empty option item and add to stateSelect
        var o1 = stateField.children().first().clone();
        stateField.html(arrHtml.join("")).removeAttr("disabled").children().first().before(o1);
        stateField[0].selectedIndex=0;
    },

    /*
         * Initializes events for shipping, billing selection of saved address and changing country code events
         */
    addressLoad: function () {

        // select address from list
        $cache.shippingAddressList2.on("change", function () {
            var data = $(this).data("address");
            if (!data) { return; }
            var p;
            for (p in data) {
                if ($cache[p] && data[p]) {
                    $cache[p].val(data[p].replace("^", "'"));

                    // special handling for countrycode => stateCode combo
                    if ($cache[p] === $cache.countryCode) {
                        opc.checkout.updateStateOptions($cache[p]);
                        $cache.stateCode.val(data.stateCode);
                    }

                    // retrigger postal code
                    if ($cache[p] === $cache.postalCode) {
                        $cache.postalCode.trigger("change");
                    }
                }
            }

            opc.checkout.updateShippingMethodList();

            if (($cache.billingAddressList.prop("selectedIndex") == 0) && ($cache.useForBilling.is(":checked") === false)) {
                opc.checkout.cleanBillingForm();
            }

            // re-validate the form
            //  $cache.checkoutForm.validate().form();
        });

        $cache.billingAddressList2.on("change", function () {
            var data = $(this).data("address");
            if (!data) { return; }
            var p;
            for (p in data) {
                if ($cache[p + "Billing"] && data[p]) {
                    $cache[p + "Billing"].val(data[p].replace("^", "'"));

                    // special handling for countrycode => stateCode combo
                    if ($cache[p + "Billing"] === $cache.countryCodeBilling) {
                        opc.checkout.updateBillingStateOptions($cache[p + "Billing"]);
                        $cache.stateCodeBilling.val(data.stateCode);
                        $cache.stateCodeBilling.trigger("change", true);
                    }

                    // retrigger postal code
                    if ($cache[p + "Billing"] === $cache.postalCodeBilling) {
                        $cache.postalCodeBilling.trigger("change");
                    }
                }
            }

            opc.checkout.checkoutAccordion();
        });

        // update state options in case the country changes
        $cache.countryCode.on("change", function () {
            opc.checkout.updateStateOptions(this);
            if ($cache.useForBilling.is(":checked")) {
                opc.checkout.updateBillingStateOptions(this);
            }
        });

        $cache.countryCodeBilling.on("change", function () {
            // update states just if useForBilling is unchecked
            opc.checkout.updateBillingStateOptions(this);
        });

    },

    //changes the payment method form
    changePaymentMethod: function (paymentMethodID) {
        $cache.paymentMethods.removeClass("payment-method-expanded");
        var pmc = $cache.paymentMethods.filter("[data-method=\"" + paymentMethodID +"\"]");
        if (pmc.length === 0) {
            pmc = $("[data-method=\"Custom\"]");
        }
        pmc.addClass("payment-method-expanded");

        // ensure checkbox of payment method is checked
        //$("#is-" + paymentMethodID)[0].checked = true;

        var bmlForm = $cache.checkoutForm.find("#PaymentMethod_BML");
        bmlForm.find("select[name$='_year']").removeClass("required");
        bmlForm.find("select[name$='_month']").removeClass("required");
        bmlForm.find("select[name$='_day']").removeClass("required");
        bmlForm.find("input[name$='_ssn']").removeClass("required");

        if (paymentMethodID === "BML") {
            var yr = bmlForm.find("select[name$='_year']");
            bmlForm.find("select[name$='_year']").addClass("required");
            bmlForm.find("select[name$='_month']").addClass("required");
            bmlForm.find("select[name$='_day']").addClass("required");
            bmlForm.find("input[name$='_ssn']").addClass("required");
        }

        if (paymentMethodID === "CREDIT_CARD") {
            $cache.submitBtnText.text(Resources.OPC_REVIEW_ORDER);
            $cache.submitBtn.attr("disabled", true);
            $cache.submitBtn.css("pointer-events", "none");
            $cache.submitDuplicateBtn.attr("disabled", true).css("pointer-events", "none");
        }

        if (paymentMethodID === "DW_APPLE_PAY") {
            $cache.submitBtn.attr("disabled", false);
            $cache.submitBtnText.text(Resources.OPC_CONTINUE);
            $cache.submitBtn.css("pointer-events", "auto");
            $cache.submitDuplicateBtn.attr("disabled", false).css("pointer-events", "auto");
        }

        if (paymentMethodID === "PayPalCredit") {
            $cache.submitBtn.attr("disabled", false);
            $cache.submitBtnText.text(Resources.OPC_CONTINUE_PAYPAL);
            $cache.submitBtn.css("pointer-events", "auto");
            $cache.submitDuplicateBtn.attr("disabled", false).css("pointer-events", "auto");
        }

        if (paymentMethodID === "Check") {
            $cache.submitBtnText.text(Resources.OPC_REVIEW_ORDER);
            $cache.submitBtn.attr("disabled", false);
            $cache.submitBtn.css("pointer-events", "auto");
            $cache.submitDuplicateBtn.attr("disabled", false).css("pointer-events", "auto");
        }

        if (paymentMethodID === "Wire_Transfer") {
            $cache.submitBtnText.text(Resources.OPC_REVIEW_ORDER);
            $cache.submitBtn.attr("disabled", false);
            $cache.submitBtn.css("pointer-events", "auto");
            $cache.submitDuplicateBtn.attr("disabled", false).css("pointer-events", "auto");
        }

        if (paymentMethodID === "Foreign_CC") {
            $cache.submitBtnText.text(Resources.OPC_REVIEW_ORDER);
            $cache.submitBtn.attr("disabled", false);
            $cache.submitBtn.css("pointer-events", "auto");
            $cache.submitDuplicateBtn.attr("disabled", false).css("pointer-events", "auto");
        }

        if (paymentMethodID === "AFFIRM") {
            $cache.submitBtnText.text(Resources.OPC_CONTINUE_AFFIRM);
            $cache.submitBtn.css("pointer-events", "none");
            $cache.submitBtn.attr("disabled", true);
            $cache.submitDuplicateBtn.attr("disabled", true).css("pointer-events", "none");

            var affimCheckout = function (e) {
                e.preventDefault();

                $.ajax({
                    type: "POST",
                    url: Urls.saveAffirmData,
                    data: $("#next").serialize()
                }).done(function (data) {
                    if (data.success) {
                        affirm.checkout.open();
                    }
                });
            };

            opc.checkout.refreshAffirm(function () {
                $cache.submitBtn.off("click").on("click", affimCheckout);
                $cache.submitBtn.attr("disabled", false);
                $cache.submitBtn.css("pointer-events", "auto");
                $cache.submitDuplicateBtn.off("click").on("click", affimCheckout);
                $cache.submitDuplicateBtn.attr("disabled", false).css("pointer-events", "auto");
            });
        } else {
            $cache.submitBtn.off("click");
            $cache.submitDuplicateBtn.off("click");
        }

        validator.init();
    },

    refreshAffirm: function (callbackFn) {
        callbackFn = callbackFn || function () {};

        ajax.getJson({
            url: Urls.refreshAffirmData,
            callback: function (data) {
                if (!data) {
                    window.alert(Resources.CC_LOAD_ERROR);
                    return false;
                }

                var checkout = data,
                    csrf     = $("input[name='csrf_token']").val();

                //incorporate CSRF token
                checkout.merchant.user_confirmation_url += "?csrf_token=" + csrf;

                var shipping = {
                    "name":{
                        "first" : $("input[name$='shippingAddress_addressFields_firstName']").val(),
                        "last"  : $("input[name$='shippingAddress_addressFields_lastName']").val()
                    },
                    "address":{
                        "line1"   : $("input[name$='shippingAddress_addressFields_address1']").val(),
                        "city"    : $("input[name$='shippingAddress_addressFields_city']").val(),
                        "state"   : $("select[name$='shippingAddress_addressFields_states_state']").val(),
                        "zipcode" : $("input[name$='shippingAddress_addressFields_postal']").val(),
                        "country" : $("select[name$='shippingAddress_addressFields_country']").val()
                    },
                    "phone_number" : $("input[name$='shippingAddress_addressFields_phone']").val(),
                    "email"        : $("input[name$='billingAddress_email_emailAddress']").val()
                };

                var billing = {
                    "name":{
                        "first" : $("input[name$='billingAddress_addressFields_firstName']").val(),
                        "last"  : $("input[name$='billingAddress_addressFields_lastName']").val()
                    },
                    "address":{
                        "line1"   : $("input[name$='billingAddress_addressFields_address1']").val(),
                        "city"    : $("input[name$='billingAddress_addressFields_city']").val(),
                        "state"   : $("select[name$='billingAddress_addressFields_states_state']").val(),
                        "zipcode" : $("input[name$='billingAddress_addressFields_postal']").val(),
                        "country" : $("select[name$='billingAddress_addressFields_country']").val()
                    },
                    "phone_number" : $("input[name$='billingAddress_addressFields_phone']").val(),
                    "email"        : $("input[name$='billingAddress_email_emailAddress']").val()
                };

                checkout.shipping = shipping;
                checkout.billing  = billing;

                //init checkout
                affirm.checkout(checkout);
                callbackFn();
            }
        });
    },
    encodeRequestFieldValue: function(fieldValue){
        return fieldValue.replace(/</g, "&lt;").replace(/>/g, "&gt;")
    },
    setCCFields: function (data) {
        // Show and check Save this card checbox because is considering that data is changed
        $cache.checkoutForm.off("change").on("change",
            "input[name$='_creditCard_owner'], select[name$='_creditCard_type'], input[name*='_creditCard_number'], [name$='_creditCard_expiration_month'], [name$='_creditCard_expiration_year']",
            function (e) {
                $cache.saveCreditCardCheckbox.prop("checked", true);
                $cache.saveCreditCardWrapper.fadeIn("fast");
            }
        );
        var $creditCard = $('[data-method="CREDIT_CARD"]');
        // fill the form / clear the former cvn input
        $("input[name$='_creditCard_owner']").val(data.holder);
        $("select[name$='_creditCard_type']").val(data.type).change();
        $("input[name*='_creditCard_number']").val(data.maskedNumber);
        $("[name$='_creditCard_expiration_month']").val(data.expirationMonth);
        $("[name$='_creditCard_expiration_year']").val(data.expirationYear);
        $creditCard.find('input[name$="_cvn"]').val('').trigger('change');

        // cybersource customization
        var selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]:checked').val();
        if(selectedPaymentMethodID == 'SA_SILENTPOST'){
            $creditCard.find('[name$="_month"]').val(data.expirationMonth);
            $creditCard.find('[name$="_year"]').val(data.expirationYear);
        }else{
            $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
            $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
        }
        $creditCard.find('[name$="creditCard_selectedCardID"]').val(data.selectedCardID).trigger('change');
        $creditCard.find('input[name$="_cvn"]').val('')
        // end of cybersource customization

        // remove error messages
        $("[data-method=\"CREDIT_CARD\"]").find(".error").toggleClass("error").filter("span").remove();

        // Uncheck and hide Save this card checbox because was used already saved card
        $cache.saveCreditCardCheckbox.prop("checked", false);
        $cache.saveCreditCardWrapper.hide();
    },

    //updates the credit card form with the attributes of a given card
    populateCreditCardForm: function (cardID, selectedPaymentMethod) {
        // load card details
        var url = util.appendParamToURL(Urls.billingSelectCC, "creditCardUUID", cardID);
        ajax.getJson({
            url: url,
            callback: function (data) {
                if (!data) {
                    window.alert(Resources.CC_LOAD_ERROR);
                    return false;
                }
                $cache.ccList.data(cardID, data);

                // cybersource customization
                switch (selectedPaymentMethod) {
                    case "SA_REDIRECT":
                        $('.payment-method-expanded .saCCToken .field-wrapper').val(data.selectedCardID);
                        $("#dwfrm_billing_paymentMethods_creditCard_selectedCardID").val(data.selectedCardID);
                    break;
                    case "SA_IFRAME":
                        $('.payment-method-expanded .saIframeCCToken .field-wrapper').val(data.selectedCardID);
                        $("#dwfrm_billing_paymentMethods_creditCard_selectedCardID").val(data.selectedCardID);
                    break;
                    case "CREDIT_CARD":
                        opc.checkout.setCCFields(data);
                    break;
                    default:
                        opc.checkout.setCCFields(data);
                    break;
                }
            }
        });
    },

    //loads billing address, Gift Certificates, Coupon and Payment methods
    billingLoad: function () {
        if (!$cache.paymentMethodId) return;

        var creditCardList = $cache.checkoutForm.find(".input-radio[id$='creditCardList']");

        if (creditCardList.length > 0) {
            var ccUUID;

            creditCardList.each(function () {
                if ($(this).attr("checked")) {
                    ccUUID = $(this).val();
                }
            });

            //creditCardList.children(":selected").first().val() || creditCardList.children()[1].value;
            if (ccUUID && util.getParameterByName("redirect") != "COBilling-Start" && util.getParameterByName("section") != "payment") {
                opc.checkout.populateCreditCardForm(ccUUID);
            }
        }

        $cache.paymentMethodId.on("click", function () {
            opc.checkout.changePaymentMethod($(this).val());
        });

        // get selected payment method from payment method form
        var paymentMethodId = $cache.paymentMethodId.filter(":checked");
        opc.checkout.changePaymentMethod(paymentMethodId.length === 0 ? "CREDIT_CARD" : paymentMethodId.val());

        // select credit card from list
        $(document).on("click", ".input-radio[id$='creditCardList']", function () {
            var cardUUID = $(this).val();
            if (!cardUUID) {$cache.checkoutForm.find('input[name$="_selectedCardID"]').val(''); return;}
            var $selectPaymentMethod = $('.cl-payment-method-options');
            var selectedPaymentMethodID = $selectPaymentMethod.find(':checked').val();
            opc.checkout.populateCreditCardForm(cardUUID, selectedPaymentMethodID);

            var ccdata = $cache.ccList.data(cardUUID);
            if (ccdata && ccdata.holder) {
                opc.checkout.setCCFields(ccdata);
                return;
            }
            opc.checkout.populateCreditCardForm(cardUUID);
        });

        // cybersource customization
        $(document).on("click", ".input-radio[id$='creditCardList']", function () {
            var cardUUID = $(this).val();
            if (!cardUUID) {return;}
            var $selectPaymentMethod = $('.cl-payment-method-options');
            var selectedPaymentMethodID = $selectPaymentMethod.find(':checked').val();
            opc.checkout.populateCreditCardForm(cardUUID, selectedPaymentMethodID);
            // remove server side error
            $('.required.error').removeClass('error');
            $('.error-message').remove();
        });
        // end of cybersource customization

        // handle whole form submit (bind click to continue checkout button)
        // append form fields of current payment form to this submit
        // in order to validate the payment method form inputs too

        $cache.save.on("click", function (e) {
            // determine if the order total was paid using gift cert or a promotion
            if ($("#noPaymentNeeded").length > 0 && $(".giftcertpi").length > 0) {
                // as a safety precaution, uncheck any existing payment methods
                $cache.paymentMethodId.filter(":checked").removeAttr("checked");
                // add selected radio button with gift card payment method
                $("<input/>").attr({
                    name: $cache.paymentMethodId.first().attr("name"),
                    type: "radio",
                    checked: "checked",
                    value: Constants.PI_METHOD_GIFT_CERTIFICATE
                })
                    .appendTo($cache.checkoutForm);
            }

            var tc = $cache.checkoutForm.find("input[name$='bml_termsandconditions']");
            if ($cache.paymentMethodId.filter(":checked").val() === "BML" && !$cache.checkoutForm.find("input[name$='bml_termsandconditions']")[0].checked) {
                alert(Resources.BML_AGREE_TO_TERMS);
                return false;
            }

        });

        // cybersource custmoization
        $('.secureacceptance').on('click', function (e) {
            var $selectPaymentMethod = $('.cl-payment-method-options');
            var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();
            if ('SA_SILENTPOST' == selectedPaymentMethod) {
                var $checkoutForm = $('.checkout-billing');
                var ccnumber = $checkoutForm.find('input[name$="_creditCard_number"]').val();
                var cvn = $checkoutForm.find('input[name$="_creditCard_cvn"]').val();
                var month = $('.payment-method-expanded .month select').val();
                var expyear = $('.payment-method-expanded .year select').val();
                var dwcctype = $('.payment-method-expanded .cctype select').val();
                var savecc = $checkoutForm.find('input[name$="_creditCard_saveCard"]').is(':checked');
                var customerEmail = $("#dwfrm_billing_billingAddress_email_emailAddress").val();
                var cardmap= {'Visa': '001','Amex': '003','MasterCard': '002','Discover': '004','Maestro':'042'};
                if(month.length == 1) {
                    month = "0"+month;
                }
                var cctype = cardmap[dwcctype];
                var firstname = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_firstName"]').val());
                var lastname = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_lastName"]').val());
                var address1 = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_address1"]').val());
                var address2 = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_address2"]').val());
                var city = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_city"]').val());
                var zipcode = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_postal"]').val());
                var country = opc.checkout.encodeRequestFieldValue($checkoutForm.find('select[name$="_addressFields_country"]').val());
                var state = $checkoutForm.find('select[name$="_addressFields_states_state"]').val();
                if (state===undefined) {
                    state = $checkoutForm.find('input[name$="_addressFields_states_state"]').val();
                }
                state = opc.checkout.encodeRequestFieldValue(state);
                var phoneno = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_phone"]').val());
                var cctoken = opc.checkout.encodeRequestFieldValue($('[data-method="CREDIT_CARD"]').find('[name$="creditCard_selectedCardID"]').val());
                var validCardType = dwcctype.toLowerCase();
                var validCardNumber = $.payment.validateCardNumber(ccnumber);
                var validCardCvv= $.payment.validateCardCVC(cvn,validCardType);
                var validCardExp = $.payment.validateCardExpiry(month, expyear);
                if(cctoken) {
                    validCardNumber = true;
                }
                $checkoutForm.find('input[name$="_creditCard_number"]').val("");
                $checkoutForm.find('input[name$="_creditCard_cvn"]').val("");
                $checkoutForm.find('input[name$="_creditCard_expiration_month"]').val("");
                $checkoutForm.find('input[name$="_creditCard_expiration_year"]').val("");
                $checkoutForm.find('input[name$="_creditCard_type"]').val("");
                if(validCardCvv && validCardExp && validCardNumber) {
                    var data = {
                            custemail : customerEmail,
                            savecc : savecc,
                            firstname : firstname,
                            lastname : lastname,
                            address1 : address1,
                            address2 : address2,
                            city : city,
                            zipcode : zipcode,
                            country : country,
                            state : state,
                            phone : phoneno,
                            cctoken : cctoken,
                            format : 'ajax'
                    };
                    $.ajax({
                        url: Urls.silentpost,
                        type: "POST",
                        data: data,
                        success: function(xhr,data) {
                            if(xhr) {
                                if(xhr.error == true) {
                                    $("#saspCardError").html(xhr.errorMsg);
                                    $("#saspCardError").addClass('error');
                                }else{
                                    $("#secureAcceptancePost").html(xhr);
                                    $("#card_expiry_date").val(month +'-'+expyear);
                                    $("#card_type").val(cctype);
                                    $("#card_cvn").val(cvn);
                                    if(cctoken == null || cctoken == ''){
                                        $('#silentPostFetchToken').append('<input type="hidden" id="card_number" name="card_number" />');
                                        $("#card_number").val(ccnumber);
                                    }
                                    $("#silentPostFetchToken").submit();
                                }
                            }else{
                                $("#saspCardError").html(Resources.INVALID_SERVICE);
                                $("#saspCardError").addClass('error');
                            }
                        },
                        error: function(){
                            $("#saspCardError").html(Resources.INVALID_SERVICE).addClass('error');
                        }
                    });
                }else{
                    $("#saspCardError").html(Resources.INVALID_CREDITCARD);
                    $("#saspCardError").addClass('error');
                    return false;
                }
            }else{
                $('.secureacceptance').prop("type", "submit").submit();
                return false;
            }
        });
        // end of cybersource custmoization
        $cache.gcCheckBalance.on("click", function (e) {
            e.preventDefault();
            $cache.gcCode = $cache.gcCode || $cache.checkoutForm.find("input[name$='_giftCertCode']");
            $cache.balance = $cache.balance || $cache.checkoutForm.find("div.balance");
            if ($cache.gcCode.length === 0 || $cache.gcCode.val().length === 0) {
                var error = $cache.balance.find("span.error");
                if (error.length === 0) {
                    error = $("<span>").addClass("error").appendTo($cache.balance);
                }
                $cache.balance.css({display:"inline-block"});
                var giftError = $(".checkout-billing").find(".giftcert-error");
                if (giftError.length) giftError.hide();
                error.html(Resources.GIFT_CERT_MISSING);
                return;
            }

            giftcard.checkBalance($cache.gcCode.val(), function (data) {
                if (!data || !data.giftCertificate) {
                    // error
                    var error = $cache.balance.find("span.error");
                    if (error.length === 0) {
                        error = $("<span>").addClass("error").appendTo($cache.balance);
                    }
                    error.html(Resources.GIFT_CERT_INVALID);
                    return;
                }
                // display details in UI
                $cache.balance.find("span.error").remove();
                var balance = data.giftCertificate.balance;
                $cache.balance.html(Resources.GIFT_CERT_BALANCE + " " + balance);
            });
        });

        //off click ensures we are not duplicating events on billing reload
        $cache.addCoupon.off("click").on("click", function (e) {
            e.preventDefault();
            $cache.couponCode = $cache.couponCode || $cache.checkoutForm.find("input[name$='_couponCode']");
            $cache.redemptionCoupon = $cache.redemptionCoupon || $cache.checkoutForm.find("div.redemption.coupon");
            var error = $(".coupon-error");
            var couponRejection = " " + Resources.OPC_COUPON_REJECTION;

            var val = (typeof $cache.couponCode == "string")? $cache.couponCode : $cache.couponCode.val();
            if (val.length === 0) {

                if (error.length === 0) {
                    $cache.couponApplied = true;
                    couponCode = $cache.checkoutForm.find("input[name$='_couponCode']");
                    error = $("<span>").addClass("error").appendTo($cache.redemptionCoupon);
                }
                error.html(Resources.COUPON_CODE_MISSING);
                return;
            }

            var url = util.appendParamsToUrl(Urls.addCoupon, { couponCode: val, format: "ajax" });
            $.getJSON(url, function (data) {
                var fail = false;
                $(".coupon-error").html("");

                if (data.refresh) {
                    page.refresh();
                    return;
                }

                var msg = "";
                if (!data) {
                    msg = Resources.BAD_RESPONSE + couponRejection;
                    fail = true;
                }
                else if (data.message == "cart.undefined") {
                    msg = Resources.COUPON_CODE_ERROR + couponRejection;
                    fail = true;
                }
                else if (!data.success || data.status == "COUPON_CODE_UNKNOWN" || data.status == "NO_ACTIVE_PROMOTION" || data.status == "COUPON_CODE_MISSING" || data.status == "NO_APPLICABLE_PROMOTION" || data.status == "COUPON_DISABLED") {
                    msg = data.message + couponRejection;
                    fail = true;
                }

                if (fail) {
                    var error = $(".coupon-error");
                    //var error = $cache.redemption.find("span.error");
                    if (error.length === 0) {
                        $("<span>").addClass("error").appendTo($cache.redemptionCoupon);
                    }
                    error.html(msg);
                    $cache.couponApplied = false;
                    return;
                } else {
                    var couponCodeForm = $(".cl-coupon-code-form"),
                        urlButton      = Urls.couponButtonRefreshURL;
                    if ((data.status == "APPLIED" && data.success == true) || $(".appliedCoupon").length > 0) {
                        couponCodeForm.addClass("ms-hide");
                        $.ajax({
                            url: urlButton,
                            data: data,
                            success: function (data) {
                                $(".remove-coupon").html(data);
                            },
                            error: function (ajaxContext) {
                                $(".coupon-error").html(axContext.responseText);
                            }
                        });
                        if (typeof dynamicYield !== 'undefined') dynamicYield.callEvent('Promo Code Entered', {code: val, ajax: true});
                    } else {
                        couponCodeForm.removeClass("ms-hide");
                    }

                    $cache.couponApplied = true;
                }

                $cache.redemptionCoupon.html(data.message);
                opc.checkout.updateSummary();
            });
        });

        $(".js-remove-coupon.remove").on("click", function () {
            $(".js-cart-coupone-checkout").removeClass("ms-hide");
            $(".cl-coupon-code-form__button").removeClass("ms-hide");
        });

        $cache.addGiftCert.on("click", function (e) {
            e.preventDefault();
            var code = $("input[name$=\"_giftCertCode\"]").val(),
                $error = $(".checkout-billing").find(".giftcert-error");
            if (code.length === 0) {
                $cache.balance = $cache.balance || $cache.checkoutForm.find("div.balance");
                var errorBalance = $cache.balance.find("span.error");

                if (errorBalance.length) {
                    errorBalance.remove();
                    errorBalance.hide();
                }
                $error.css({display:"inline-block"});
                $error.html(Resources.GIFT_CERT_MISSING);
                return;
            }

            var url = util.appendParamsToUrl(Urls.onePageCheckoutApplyGiftCert, {giftCertCode: code, format: "ajax"});
            $.getJSON(url, function (data) {
                var fail = false;
                var msg = "";
                if (!data) {
                    msg = Resources.BAD_RESPONSE;
                    fail = true;
                } else if (!data.success) {
                    msg = data.message;
                    fail = true;
                }
                if (fail) {
                    $error.html(msg);
                } else {
                    giftCertApplied = true;
                    giftCertCode = code;
                    $cache.document.trigger("giftCertificateAdded", data);
                }
            });

        });
    },

    initializeAddressForm: function (form) {
        var form = $("#edit-address-form");

        form.find("input[name='format']").remove();
        tooltips.init();
        //$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);

        form.on("click", ".apply-button", function (e) {
            e.preventDefault();
            var addressId = form.find("input[name$='_addressid']");
            addressId.val(addressId.val().replace(/[^\w+-]/g, "-"));
            if (!form.valid()) {
                return false;
            }
            var url = util.appendParamsToUrl(form.attr("action"), {format:"ajax"});
            var applyName = form.find(".apply-button").attr("name");
            var options = {
                url: url,
                data: form.serialize()+"&"+applyName+"=x",
                type: "POST"
            };
            $.ajax(options).done(function (data) {
                if (typeof(data)!=="string") {
                    if (data.success) {
                        dialog.close();
                        page.refresh();
                    } else {
                        alert(data.message);
                        return false;
                    }
                } else {
                    $("#dialog-container").html(data);
                    account.init();
                    tooltips.init();
                }
            });
        })
            .on("click", ".cancel-button, .close-button", function (e) {
                e.preventDefault();
                dialog.close();
            })
            .on("click", ".delete-button", function (e) {
                e.preventDefault();
                if (confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
                    var url = util.appendParamsToUrl(Urls.deleteAddress, {AddressID:form.find("#addressid").val(), format:"ajax"});
                    $.ajax({
                        url: url,
                        method: "POST",
                        dataType:"json"
                    }).done(function (data) {
                        if (data.status.toLowerCase()==="ok") {
                            dialog.close();
                            page.refresh();
                        }
                        else if (data.message.length>0) {
                            alert(data.message);
                            return false;
                        }
                        else {
                            dialog.close();
                            page.refresh();
                        }
                    });
                }
            });

        $cache.countrySelect = form.find("select[id$='shippingAddress_addressFields_country']");
        $cache.countrySelect.on("change", function () {
            opc.checkout.updateStateOptions(this);
            if ($cache.useForBilling.is(":checked")) {
                opc.checkout.updateBillingStateOptions(this);
            }
        });

        $cache.countryBillingSelect = form.find("select[id$='billingAddress_addressFields_country']");
        $cache.countryBillingSelect.on("change", function () {
            opc.checkout.updateBillingStateOptions(this);
        });

        validator.init();
    },

    initializeDom: function () {
        isShipping = $(".checkout-shipping").length > 0;

        $(".js-navigation").add(".js-header-search .cl-header-search").add(".cl-header__search-cart-user").hide();
    },

    /*
            This function is used to update billing form with shipping information
        */
    updateBillingFormWithShippingData : function () {
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_firstName']").val($cache.checkoutForm.find("input[name$='shippingAddress_addressFields_firstName']").val());
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_lastName']").val($cache.checkoutForm.find("input[name$='shippingAddress_addressFields_lastName']").val());
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_company']").val($cache.checkoutForm.find("input[name$='shippingAddress_addressFields_company']").val());
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_address1']").val($cache.checkoutForm.find("input[name$='shippingAddress_addressFields_address1']").val());
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_address2']").val($cache.checkoutForm.find("input[name$='shippingAddress_addressFields_address2']").val());
        $cache.checkoutForm.find("select[name$='billingAddress_addressFields_country']").val($cache.checkoutForm.find("select[name$='shippingAddress_addressFields_country']").val());
        $cache.checkoutForm.find("select[name$='billingAddress_addressFields_states_state']").val($cache.checkoutForm.find("select[name$='shippingAddress_addressFields_states_state']").val());
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_city']").val($cache.checkoutForm.find("input[name$='shippingAddress_addressFields_city']").val());
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_postal']").val($cache.checkoutForm.find("input[name$='shippingAddress_addressFields_postal']").val());
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_phone']").val($cache.checkoutForm.find("input[name$='shippingAddress_addressFields_phone']").val());
        $cache.checkoutForm.find("input[name$='_billingAddress_addressFields_states_province']").val($cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_states_province']").val());
    },

    /*
            This function is used to clean the billing form
         */
    cleanBillingForm: function () {
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_firstName']").val("");
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_lastName']").val("");
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_company']").val("");
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_address1']").val("");
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_address2']").val("");
        $cache.checkoutForm.find("select[name$='billingAddress_addressFields_country']").prop("selectedIndex", 0);
        $cache.checkoutForm.find("select[name$='billingAddress_addressFields_states_state']").prop("selectedIndex", 0);
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_city']").val("");
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_postal']").val("");
        $cache.checkoutForm.find("input[name$='billingAddress_addressFields_phone']").val("");
    },

    /*
             This function is for saving shipping and billing addresses to cart
         */
    saveAddresses: function () {
        var subscribedToNewsletter = $cache.checkoutForm.find("input[name$=_shippingAddress_subscribeToNewsletter]").is(':checked');
        $.ajax({
            url      : Urls.onePageCheckoutSaveAddresses,
            method   : "POST",
            dataType : "json",
            data     : {
                shipping_savedAddressID  : $cache.checkoutForm.find("[name$=_savedAddressID]").val(),
                shipping_firstName       : $cache.checkoutForm.find("[name$=shippingAddress_addressFields_firstName]").val(),
                shipping_lastName        : $cache.checkoutForm.find("[name$=shippingAddress_addressFields_lastName]").val(),
                shipping_company         : $cache.checkoutForm.find("[name$=shippingAddress_addressFields_company]").val(),
                shipping_address1        : $cache.checkoutForm.find("[name$=shippingAddress_addressFields_address1]").val(),
                shipping_address2        : $cache.checkoutForm.find("[name$=shippingAddress_addressFields_address2]").val(),
                shipping_shippingComment : $cache.checkoutForm.find("[name$=shippingAddress_addressFields_shippingComment]").val(),
                shipping_city            : $cache.checkoutForm.find("[name$=shippingAddress_addressFields_city]").val(),
                shipping_state           : $cache.checkoutForm.find("[name$=shippingAddress_addressFields_states_state]").val(),
                shipping_country         : $cache.checkoutForm.find("[name$=shippingAddress_addressFields_country]").val(),
                shipping_postal          : $cache.checkoutForm.find("[name$=shippingAddress_addressFields_postal]").val(),
                shipping_email           : $cache.checkoutForm.find("[name$=billingAddress_email_emailAddress]").val(),
                shipping_phone           : $cache.checkoutForm.find("[name$=shippingAddress_addressFields_phone]").val(),
                billing_firstName        : $cache.checkoutForm.find("input[name$='billingAddress_addressFields_firstName']").val(),
                billing_lastName         : $cache.checkoutForm.find("input[name$='billingAddress_addressFields_lastName']").val(),
                billing_company          : $cache.checkoutForm.find("input[name$='billingAddress_addressFields_company']").val(),
                billing_address1         : $cache.checkoutForm.find("input[name$='billingAddress_addressFields_address1']").val(),
                billing_address2         : $cache.checkoutForm.find("input[name$='billingAddress_addressFields_address2']").val(),
                billing_country          : $cache.checkoutForm.find("select[name$='billingAddress_addressFields_country']").val(),
                billing_state            : $cache.checkoutForm.find("select[name$='billingAddress_addressFields_states_state']").val(),
                billing_city             : $cache.checkoutForm.find("input[name$='billingAddress_addressFields_city']").val(),
                billing_postal           : $cache.checkoutForm.find("input[name$='billingAddress_addressFields_postal']").val(),
                billing_phone            : $cache.checkoutForm.find("input[name$='billingAddress_addressFields_phone']").val()
            }
        }).done(function (data) {

            if (subscribedToNewsletter) {
                $('body').trigger('custom:emailsignup', [{type: 'form', formOfSignup: 'checkout shipping address'}]);
            }
            //add address suggestion check here
            //mark address form as accepted if suggestion was selected or ignored to prevent additional address check
            //need to check accepted address form if it was not modified after form accepting
            if (data.length) {
                data.Costs.forEach(function (el) {
                    $("#shipping-cost-" + el.MethodID)[0].innerText = el.basePrice;
                });
            }
            opc.checkout.updateSummary();
            opc.checkout.updateAffirm();
        });
    },

    /*
         * Initializes all checkout fields
         */
    initializeCache: function () {
        //adding validators
        $.validator.addMethod("postalCode", opc.checkout.validateZip, Resources.INVALID_POSTAL);

        $.validator.addMethod("billingPostalCode", opc.checkout.validateBillingZip, Resources.INVALID_POSTAL);
        $cache.checkoutForm = $("form.address");
        $cache.shippingFormSection = $cache.checkoutForm.find("#checkout-shipping-address");
        $cache.billingFormSection = $cache.checkoutForm.find("#checkout-billing-address");
        $cache.shippingMethodSection = $cache.checkoutForm.find("#checkout-shipping-methods");
        $cache.submitBtn = $("#submit-opc");
        $cache.submitDuplicateBtn = $(".js-btn-step-final-duplicate");
        $cache.submitBtnWrapper = $cache.checkoutForm.find(".js-submit-opc-wrapper");
        $cache.submitBtnText = $cache.submitBtn.find(".submit-btn-msg");
        $cache.document = $(document);
        $cache.redemption = $(".redemption.giftcert");
        $cache.redemptionCoupon = $(".redemption.coupon");
        $cache.addressList = $cache.checkoutForm.find(".select-address select[id$='_addressList']");
        $cache.payFields = $cache.checkoutForm.find(".payment-method-expanded :input.required");
        $cache.dropdowns = $cache.checkoutForm.find(".input-select[id$='singleshipping_addressList']").add(".input-select[id$='creditCardList']");
        $cache.selectAdress = $(".input-select[id$='singleshipping_addressList']");

        $cache.shippingAddressList = $cache.checkoutForm.find("select[id$='singleshipping_addressList']");
        $cache.shippingAddressList2 = $cache.checkoutForm.find("input[name$='_singleshipping_addressList']");
        $cache.billingAddressList = $cache.checkoutForm.find(".select-address select[id$='billing_addressList']");
        $cache.billingAddressList2 = $cache.checkoutForm.find("input[name$='billing_addressList']");

        $cache.firstName = $cache.checkoutForm.find("input[name$='shippingAddress_addressFields_firstName']");
        $cache.lastName = $cache.checkoutForm.find("input[name$='shippingAddress_addressFields_lastName']");
        $cache.company = $cache.checkoutForm.find("input[name$='shippingAddress_addressFields_company']");
        $cache.address1 = $cache.checkoutForm.find("input[name$='shippingAddress_addressFields_address1']");
        $cache.address2 = $cache.checkoutForm.find("input[name$='shippingAddress_addressFields_address2']");
        $cache.shippingComment = $cache.checkoutForm.find("input[name$='shippingAddress_addressFields_shippingComment']");
        $cache.city = $cache.checkoutForm.find("input[name$='shippingAddress_addressFields_city']");
        $cache.postalCode = $cache.checkoutForm.find("input[name$='shippingAddress_addressFields_postal']");
        $cache.phone = $cache.checkoutForm.find("input[name$='shippingAddress_addressFields_phone']");

        $cache.countryCode = $cache.checkoutForm.find("select[id$='shippingAddress_addressFields_country']");
        $cache.country = $cache.checkoutForm.find("select[id$='shippingAddress_addressFields_country']>option:selected").text(),

        $cache.stateCode = $cache.checkoutForm.find("select[id$='shippingAddress_addressFields_states_state']");

        $cache.savedAddressID = $cache.checkoutForm.find("input[name$='_savedAddressID']");
        $cache.stateCodeBilling = $cache.checkoutForm.find("select[id$='billingAddress_addressFields_states_state']");
        $cache.addToAddressBook = $cache.checkoutForm.find("input[name$='_addToAddressBook']");

        $cache.firstNameBilling = $cache.checkoutForm.find("input[name$='billingAddress_addressFields_firstName']");
        $cache.lastNameBilling = $cache.checkoutForm.find("input[name$='billingAddress_addressFields_lastName']");
        $cache.companyBilling = $cache.checkoutForm.find("input[name$='billingAddress_addressFields_company']");
        $cache.address1Billing = $cache.checkoutForm.find("input[name$='billingAddress_addressFields_address1']");
        $cache.address2Billing = $cache.checkoutForm.find("input[name$='billingAddress_addressFields_address2']");
        $cache.cityBilling = $cache.checkoutForm.find("input[name$='billingAddress_addressFields_city']");
        $cache.postalCodeBilling = $cache.checkoutForm.find("input[name$='billingAddress_addressFields_postal']");
        $cache.phoneBilling = $cache.checkoutForm.find("input[name$='billingAddress_addressFields_phone']");
        $cache.countryCodeBilling = $cache.checkoutForm.find("select[id$='billingAddress_addressFields_country']");
        $cache.countryBilling = $cache.checkoutForm.find("select[id$='billingAddress_addressFields_country']>option:selected").text();
        $cache.save = $cache.checkoutForm.find("button[name$='_billing_next']");

        $cache.changeBillingLabel = $cache.checkoutForm.find(".change-billing-button");
        $cache.billingAddressSelectList = $cache.checkoutForm.find("[name$='_billing_addressList']");
        $cache.useForBilling = $cache.checkoutForm.find("input[name$='_useAsBillingAddress']");
        $cache.sameAsShippingLabel = $cache.checkoutForm.find(".same-as-shipping");
        $cache.billingWrapper = $cache.checkoutForm.find("#billingWrapper");

        $cache.saveCreditCardCheckbox = $cache.checkoutForm.find("input[name$='paymentMethods_creditCard_saveCard']");
        $cache.saveCreditCardWrapper = $cache.checkoutForm.find(".js-save-credit-card");

        $cache.useInfoFromShipping = $cache.checkoutForm.find(".js-use-shipping-address");
        $cache.couponApplied = $(".redemption.coupon>span.success").length > 0;
        $cache.couponCode = "";

        // Payment method
        $cache.creditCardRadioBtn = $("#is-CREDIT_CARD");
        $cache.creditCardOwner = $cache.checkoutForm.find("#dwfrm_billing_paymentMethods_creditCard_owner");
        $cache.creditCardNumber = $cache.checkoutForm.find("#dwfrm_billing_paymentMethods_creditCard_number_d0eoazzuijpd");
        $cache.creditCardExpMonth = $cache.checkoutForm.find("#dwfrm_billing_paymentMethods_creditCard_expiration_month");
        $cache.creditCardExpYear = $cache.checkoutForm.find("#dwfrm_billing_paymentMethods_creditCard_expiration_year");
        $cache.creditCardSecurityCode = $cache.checkoutForm.find("#dwfrm_billing_paymentMethods_creditCard_cvn_d0crkmoiuipd");
        // cybersource customization
        $cache.ccvCode = $cache.checkoutForm.find(".js-cvn-field");
        $cache.cardType = $cache.checkoutForm.find(".js-payment-card-type");
        // end of cybersource customization
        $("input.appliedCoupon").each(function (i, e) {
            $cache.couponCode += e.value + " ";
        });

        //detect mobile (add/remove Apple Pay from Checkout Page)
        if ($(".is-DW_APPLE_PAY").length) {
            $(".is-DW_APPLE_PAY").addClass("ms-hide");

            if (navigator.userAgent.match(/(Mac|iPod|iPhone|iPad)/)) {
                $(".is-DW_APPLE_PAY").removeClass("ms-hide");
            }
        }

        $cache.couponCode = $cache.couponCode.trim();

        if ($cache.checkoutForm.hasClass("checkout-shipping") || $cache.checkoutForm.hasClass("checkout-multi-shipping")) {
            // shipping only
            $cache.useForBilling = $cache.checkoutForm.find("input[name$='_useAsBillingAddress']");
            $cache.giftMessage = $cache.checkoutForm.find(".gift-message-text");
            $cache.shippingMethodList = $("#shipping-method-list");
            $cache.multiShipments = $("#multipleShipmentsWrapper");
        }

        if ($cache.checkoutForm.hasClass("checkout-billing")) {
            // billing only
            $cache.email = $cache.checkoutForm.find("input[name$='_emailAddress']");
            $cache.save = $cache.checkoutForm.find("button[name$='_billing_next']");
            $cache.paymentMethods = $cache.checkoutForm.find("div.payment-method");
            $cache.paymentMethodId = $cache.checkoutForm.find("input[name$='_selectedPaymentMethodID']");
            $cache.ccContainer = $("#PaymentMethod_CREDIT_CARD");
            $cache.ccList = $(".input-radio[id$='creditCardList']");
            $cache.ccOwner = $cache.ccContainer.find("input[name$='creditCard_owner']");
            $cache.ccType = $cache.ccContainer.find("select[name$='_type']");
            $cache.ccNum = $cache.ccContainer.find("input[name$='_number']");
            $cache.ccMonth = $cache.ccContainer.find("[name$='_month']");
            $cache.ccYear = $cache.ccContainer.find("[name$='_year']");
            $cache.ccCcv = $cache.ccContainer.find("input[name$='_cvn']");
            $cache.BMLContainer = $("#PaymentMethod_BML");
            $cache.gcCheckBalance = $("#check-giftcert");
            $cache.addGiftCert = $("#add-giftcert");
            $cache.addCoupon = $("#add-coupon");
        }
        $cache.useForBilling.on("click", function (e) {
            // keep it in sync with the checkbox from the billing form
            $cache.useInfoFromShipping.prop("checked", $cache.useForBilling.prop("checked"));

            if ($cache.useForBilling.prop("checked") == true) {
                opc.checkout.updateBillingStateOptions($cache.countryCode);
                opc.checkout.updateBillingFormWithShippingData();
                // update billing fields right after changing of shipping information if useForBilling flag = true
                $inputs = $cache.checkoutForm.find(":input[name*=\"_singleshipping\"]");
                $inputs.on("change", function (e) {
                    if ($cache.useForBilling.is(":checked")) {
                        opc.checkout.updateBillingFormWithShippingData();
                        $cache.checkoutForm.validate().element($cache.stateCodeBilling);
                    }
                });
            } else {
                opc.checkout.cleanBillingForm();
            }
        });

        opc.checkout.updateBillingStateOptions($cache.countryCodeBilling);
        if ($cache.useForBilling.is(":checked")) {
            opc.checkout.updateBillingFormWithShippingData();
            $inputs = $cache.checkoutForm.find(":input[name*=\"_singleshipping\"]");
            $inputs.on("change", function (e) {
                if ($cache.useForBilling.is(":checked")) {
                    opc.checkout.updateBillingFormWithShippingData();
                }
            });

            $(document).on("keydown", ".js-cart-coupone-checkout", function (e) {
                if (e.keyCode == 13) {
                    e.preventDefault();
                    $("#add-coupon").click();
                }
            });
        }

        $cache.dropdowns.each(function (addressList) {
            $($cache.dropdowns[addressList]).selectmenu({
                create: function (event, ui) {
                    $(this).next().find(".ui-selectmenu-icon").html("<div class=\"cl-dd-arrow-down\"></div>");
                },
                change: function (event, ui) {
                    $(this).trigger("change", ui);
                }
            }).selectmenu("menuWidget").addClass("cl-dropdown");

            $(this).find(".ui-menu-item").on("click", function () {
                $($cache.dropdowns[addressList]).selectmenu({}).selectmenu("menuWidget").removeClass("cl-dropdown");
            });
        });

        if ($($cache.selectAdress).find("option").length > 7) {
            $(".select-address")
                .find($cache.selectAdress)
                .siblings(".ui-selectmenu-menu")
                .children()
                .addClass("custom-select-scroll");
        }
    },

    updateBillingStateOptions: function (countryBillingSelect) {
        var country = $(countryBillingSelect);
        if (country.length===0 || !Countries[country.val()]) {
            return;
        }
        var form = country.closest("form");
        var stateField = form.find("select[name$='billingAddress_addressFields_states_state']");
        if (stateField.length===0) {
            return;
        }

        var form = country.closest("form"),
            c = Countries[country.val()],
            arrHtml = [],
            labelSpan = form.find("label[for='"+stateField[0].id+"'] span").not(".required-indicator");

        // set the label text
        labelSpan.html(c.label);

        var s;
        for (s in c.regions) {
            arrHtml.push("<option value=\""+s+"\">"+c.regions[s]+"</option>");
        }
        // clone the empty option item and add to stateSelect
        var o1 = stateField.children().first().clone();
        stateField.html(arrHtml.join("")).removeAttr("disabled").children().first().before(o1);
        stateField[0].selectedIndex=0;
    },

    /*
         * Dynamic zip code validation for shipping address
         */
    validateZip: function (value, el)  {
        var country = $(el).closest("form").find(".country");
        if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()]) {
            return true;
        }
        var rgx = regex.postal[country.val().toLowerCase()];
        var isOptional = this.optional(el);
        var isValid = rgx.test($.trim(value));
        return isOptional || isValid;
    },

    /*
         * Dynamic zip code validation for billing address
         */
    validateBillingZip: function (value, el)  {
        var country = $(el).closest("form").find(".billingCountry");
        if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()]) {
            return true;
        }
        var rgx = regex.postal[country.val().toLowerCase()];
        var isOptional = this.optional(el);
        var isValid = rgx.test($.trim(value));
        return isOptional || isValid;
    },

    /*
         * Removing errors on the storefront
         */
    removeErrorFrom: function (formElement) {
        if ($(formElement).hasClass("valid")) {
            $(formElement).next("span").css("display", "none");
            $(formElement).parent("div").removeClass("error");
            $(formElement).find(".cl-form-input").removeClass("error");
        }
    },

    /*
         * Used for payment updating avter the Credit card section re-rendered
         * */
    updatePaymentMethod: function (paymentMethodID) {
        var $paymentMethods = $(".payment-method");
        $paymentMethods.removeClass("payment-method-expanded");

        // cybersource customization
        var dataMethod = paymentMethodID;
        if (paymentMethodID == 'SA_SILENTPOST' || paymentMethodID == 'SA_FLEX') {
            dataMethod = 'CREDIT_CARD';
        }

        var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + dataMethod + '"]')

        if ($selectedPaymentMethod.length === 0) {
            $selectedPaymentMethod = $("[data-method=\"Custom\"]");
        }
        if (paymentMethodID == "VISA_CHECKOUT") {
            $(".continue-place-order").hide();
            $(".visacheckoutbutton").show();
        }
        else if (paymentMethodID == "PAYPAL" || paymentMethodID == "PAYPAL_CREDIT") {
            $("#billingAgreementCheckbox").attr('checked',false);
            $(".continue-place-order").hide();
        } else {
            $(".continue-place-order").show();
            $(".visacheckoutbutton").hide();
        }

        if (paymentMethodID == "CREDIT_CARD" || paymentMethodID == "SA_SILENTPOST") {
            $(".spsavecard").show();
            $(".saflexshow").addClass('hide');
            $(".saflexhide").removeClass('hide');
        } else if ((paymentMethodID == "SA_REDIRECT" || paymentMethodID == "SA_IFRAME") && SitePreferences.TOKENIZATION_ENABLED) {
            $(".spsavecard").show();
        } else if(paymentMethodID == "SA_FLEX"){
            $(".saflexshow").removeClass('hide');
            $(".saflexhide").addClass('hide');
            $(".spsavecard").show();
        } else {
            $(".spsavecard").hide();
        }
        var isBicRequired = $selectedPaymentMethod.data('bicrequired');
        if (isBicRequired){
            $(".bic-section").show();
        } else {
            $(".bic-section").hide();
        }
        // end of cybersource customization

        $selectedPaymentMethod.addClass("payment-method-expanded");

        // ensure checkbox of payment method is checked
        $("input[name$=\"_selectedPaymentMethodID\"]").removeAttr("checked");
        $("input[value=" + paymentMethodID + "]").prop("checked", "checked");

        // cybersource customization
        opc.checkout.handleSecureAcceptance(paymentMethodID);
        // end of cybersource customization
    },

    // cybersource customization
    /**
     * Check if credit card form is for cybersource secure acceptance
     */
    handleSecureAcceptance: function(method) {
        if ('SA_SILENTPOST' === method) {
            var form = $('#dwfrm_billing');

            if (form.length > 0) {
                form.on('submit', function (e) {
                    e.preventDefault();
                    opc.checkout.secureAcceptanceDataHandler($(this));
                });
            }
        }
    },
    secureAcceptanceDataHandler: function(form){
        var method = $('.cl-payment-method-options').find(':checked').val();

        progress.show($('.payment-method.payment-method-expanded'));

        if ('SA_SILENTPOST' === method) {
            var $checkoutForm = $('.checkout-billing');
            var ccnumber = $checkoutForm.find('input[name*="_creditCard_number"]').val().replace(/\s/g, "");
            var cvn = $checkoutForm.find('input[name*="_creditCard_cvn"]').val();
            var month = $('.payment-method-expanded .month select').val();
            var expyear = $('.payment-method-expanded .year select').val();
            var dwcctype = $('.payment-method-expanded .cc--type select').val();
            var savecc = $checkoutForm.find('input[name$="_creditCard_saveCard"]').is(':checked');
            var customerEmail = $("#dwfrm_billing_billingAddress_email_emailAddress").val();
            var cardmap = { 'Visa': '001', 'Amex': '003', 'Master': '002', 'Discover': '004', 'Maestro': '042' };

            if (month.length === 1) {
                month = "0" + month;
            }

            var cctype = cardmap[dwcctype];
            var firstname = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_firstName"]').val());
            var lastname = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_lastName"]').val());
            var address1 = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_address1"]').val());
            var address2 = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_address2"]').val());
            var city = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_city"]').val());
            var zipcode = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_postal"]').val());
            var country = opc.checkout.encodeRequestFieldValue($checkoutForm.find('select[name$="_addressFields_country"]').val());
            var state = $checkoutForm.find('select[name$="_addressFields_states_state"]').val();
            var csrf = $checkoutForm.find('[data-billing-csrf]').val();

            if (state === undefined) {
                state = $checkoutForm.find('input[name$="_addressFields_states_state"]').val();
            }
            state = opc.checkout.encodeRequestFieldValue(state);

            var phoneno = opc.checkout.encodeRequestFieldValue($checkoutForm.find('input[name$="_addressFields_phone"]').val());
            var cctoken = opc.checkout.encodeRequestFieldValue($('div[data-method="CREDIT_CARD"]').find('[name$="creditCard_selectedCardID"]').val());

            var validCardType = dwcctype.toLowerCase();
            var validCardNumber = $.payment.validateCardNumber(ccnumber);
            var validCardCvv = $.payment.validateCardCVC(cvn, validCardType);
            var validCardExp = $.payment.validateCardExpiry(month, expyear);

            if (cctoken) {
                validCardNumber = true;
            }

            $checkoutForm.find('input[name$="_creditCard_number"]').val("");
            $checkoutForm.find('input[name$="_creditCard_cvn"]').val("");
            $checkoutForm.find('input[name$="_creditCard_expiration_month"]').val("");
            $checkoutForm.find('input[name$="_creditCard_expiration_year"]').val("");
            $checkoutForm.find('input[name$="_creditCard_type"]').val("");

            if (validCardCvv && validCardExp && validCardNumber) {
                var data = {
                    custemail: customerEmail,
                    savecc: savecc,
                    firstname: firstname,
                    lastname: lastname,
                    address1: address1,
                    address2: address2,
                    city: city,
                    zipcode: zipcode,
                    country: country,
                    state: state,
                    phone: phoneno,
                    cctoken: cctoken,
                    ccnumber : ccnumber,
                    cvn : cvn,
                    month : month,
                    expyear : expyear,
                    cctype : cctype,
                    csrf_token: csrf,
                    format: 'ajax'
                };

                $.ajax({
                    url: Urls.silentpost,
                    type: "POST",
                    data: data,
                    success: function (xhr, data) {
                        progress.hide();

                        if (xhr) {
                            if (xhr.error === true) {
                                $("#saspCardError").html(xhr.errorMsg);
                                $("#saspCardError").addClass('error');
                            } else {
                                $("#secureAcceptancePost").html(xhr);
                                $("#card_expiry_date").val(month + '-' + expyear);
                                $("#card_type").val(cctype);
                                $("#card_cvn").val(cvn);

                                if (cctoken === null || cctoken === '') {
                                    $('#silentPostFetchToken').append('<input type="hidden" id="card_number" name="card_number" />');
                                    $("#card_number").val(ccnumber);
                                }

                                /*
                                var subscribeFlag = form.find('input#dwfrm_billing_billingAddress_addToEmailList');
                                if (subscribeFlag.length > 0) {
                                    $.ajax({
                                        url: form.data('billing-url'),
                                        type: "POST",
                                        data: {
                                            addToEmailList: form.find('input#dwfrm_billing_billingAddress_addToEmailList').val(),
                                            addToAddressBook: form.find('input#dwfrm_billing_billingAddress_addToAddressBook').val()
                                        },
                                        success: function () {
                                            $("#silentPostFetchToken").submit();
                                        }
                                    });
                                }
                                */
                            }
                        } else {
                            $("#saspCardError").html(Resources.INVALID_SERVICE);
                            $("#saspCardError").addClass('error');
                        }
                    },
                    error: function () {
                        progress.hide();
                        $("#saspCardError").html(Resources.INVALID_SERVICE).addClass('error');
                    }
                });
            } else {
                progress.hide();
                $("#saspCardError").html(Resources.INVALID_CREDITCARD);
                $("#saspCardError").addClass('error').show();
                return false;
            }
        } else {
            $('.secureacceptance').prop("type", "submit").submit();
            return true;
        }
    },
    // end of cybersource customization
    /**
        * @function
        * @description compares cvn and card type
        * @return {boolean} true if cvn length matches card type
        */
    isMatchCVNAndCardType: function () {
        var cardLength = $cache.ccvCode.val().length,
            cardType = $cache.cardType.val();

        if (cardLength < 3) {
            return false;
        }

        if (cardType !== "Amex" && cardLength === 3) {
            return true;
        }

        if (cardType === "Amex" && cardLength === 4) {
            return true;
        }

        return false;
    },

    initializeEvents: function () {
        opc.checkout.addressLoad();
        opc.checkout.shippingLoad();
        opc.checkout.billingLoad();
        opc.checkout.initAddressSuggestionDialogEvents();

        if (SitePreferences.ENABLE_BACKBUTTON_OPC_ACCORDION && SitePreferences.IS_RICH_DESIGN) {
            history.pushState(null, null, "OnePageCheckout-Start");
            window.addEventListener("popstate", function (event) {
                var activeTabIndex = $(".accordion").accordion("option", "active");
                if (activeTabIndex == 0) {
                    window.location = (document.referrer != window.location.href ? document.referrer : Urls.cartURL);
                } else {
                    $(".accordion").accordion("option", "active", activeTabIndex-1);
                    history.pushState(null, null, "OnePageCheckout-Start");
                }
            });
        }

        // in case redirect with card validation error, show "continue to review" button
        if (util.getParameterByName("redirect") === "COBilling-Start" && $(".error-form").length) {
            $cache.submitBtnWrapper.removeClass("ms-hide");
        }

        var $paymentElements = $("[data-method=\"CREDIT_CARD\"]").find("input[name$='_creditCard_owner'], input[name*='_creditCard_number'], [name$='_creditCard_expiration_month'], [name$='_creditCard_expiration_year'], .cl-cvn-wrapper__field input");

        $paymentElements.on("change, keyup", function () {
            $paymentElements.each(function (index, element) {
                if ($(this).val().length > 0 && opc.checkout.isMatchCVNAndCardType()) {
                    $cache.submitBtn.attr("disabled", false);
                    $cache.submitBtn.css("pointer-events", "auto");
                    $cache.submitDuplicateBtn.attr("disabled", false).css("pointer-events", "auto");
                } else {
                    $cache.submitBtn.attr("disabled", true);
                    $cache.submitBtn.css("pointer-events", "none");
                    $cache.submitDuplicateBtn.attr("disabled", true).css("pointer-events", "none");
                    return false;
                }
            });
        });

        inputmask();

        // duplicate submit button visible only on mobile layout
        $cache.checkoutForm.on("click", ".js-btn-step-final-duplicate", function () {
            $cache.checkoutForm.find("#submit-opc").trigger("click");
        });

        // trigger jquery validate manually
        $cache.creditCardExpYear.on("change", function () {
            $(this).trigger("focusout");
        });

        $cache.submitBtn.on("click", function (e) {
            var $error = $cache.checkoutForm.find("input.required.error");
            if ($error && $error.length > 0) {
                e.preventDefault();
                var $legend = $error.closest("fieldset").find("legend").first();
                if (!$legend.hasClass("ui-state-active")) {
                    $legend.trigger("click");
                    $error.focus();
                } else {
                    util.scrollBrowser($error.offset().top);
                    $error.focus();
                }
            }
        });

        // accordion only for order summary footer
        $(document).on("click", "#total.place-order-totals .js-minisummary-accordion-toggle", function (e) {
            e.stopImmediatePropagation();
            e.preventDefault();

            if ($(this).hasClass("active")) {
                $(this)
                    .removeClass("active")
                    .closest(".js-minisummary-accordion")
                    .find(".js-minisummary-accordion-content")
                    .removeClass("active");
            } else {
                $(this)
                    .addClass("active")
                    .closest(".js-minisummary-accordion")
                    .find(".js-minisummary-accordion-content")
                    .addClass("active");
            }
        });

        // fire validation once if user attempts to click on disabled continue to review button
        $cache.submitBtnWrapper.one("click", function () {
            $cache.checkoutForm.valid();
        });

        $cache.checkoutForm.on("change select",  function (e) {
            $allFormElemets = $cache.checkoutForm.find(":input");
            var formValid = true;

            $allFormElemets.each(function (index) {
                var element = $allFormElemets[index];

                opc.checkout.removeErrorFrom(element);

                if ($(element).hasClass("required") && ($(element).hasClass("error") || !$(element).hasClass("valid"))) {
                    formValid = false;
                    return false;
                }
            });

            if (formValid) {
                $cache.submitBtn.attr("disabled", false);
                $cache.submitDuplicateBtn.attr("disabled", false);
            } else {
                $allFormElemets.each(function (index) {
                    var element = $allFormElemets[index];
                    opc.checkout.removeErrorFrom(element);
                });
            }
        });

        $cache.useInfoFromShipping.on("change", function () {
            if (this.checked) {
                opc.checkout.updateBillingStateOptions($cache.countryCode);
                opc.checkout.updateBillingFormWithShippingData();
            } else {
                opc.checkout.cleanBillingForm();
            }

            $cache.useForBilling.prop("checked", this.checked);
        });

        var $accordion = $(".accordion");

        $accordion.on("click", ".js-accordion-nav", function (e) {
            e.preventDefault();

            // address suggestion
            var $countrySelect = $cache.checkoutForm.find('select[name$="_shippingAddress_addressFields_country"]'),
                selectedCountryCode = $countrySelect.length && $countrySelect.val(),
                isCountryValidationEnabled = addressSuggestions.methods.countryAddressValidateEnabled(selectedCountryCode);

            if(SitePreferences.VERTEX_ENABLED && SitePreferences.VERTEX_CHECKOUT_ADDRESS_CHECK_ENABLED && isCountryValidationEnabled){
                var isShippingStepSubmitted = $(this).hasClass('js-shipping-go-next-btn');
                if(isShippingStepSubmitted){
                    var isAddressHasErrors = $('#checkout-shipping-address').find('select.error, input.error').length;
                    if(!isAddressHasErrors){
                        var shippingAddress = addressSuggestions.methods.getShippingFormData($cache.checkoutForm);

                        var isAddressValidated = (addressSuggestions.cache.validatedAddress && addressSuggestions.cache.validatedAddress.shipping_city == shippingAddress.shipping_city &&
                            addressSuggestions.cache.validatedAddress.shipping_postal == shippingAddress.shipping_postal &&
                            addressSuggestions.cache.validatedAddress.shipping_state == shippingAddress.shipping_state &&
                            addressSuggestions.cache.validatedAddress.shipping_country == shippingAddress.shipping_country &&
                            addressSuggestions.cache.validatedAddress.shipping_address1 == shippingAddress.shipping_address1);

                        if(!isAddressValidated){
                            addressSuggestions.cache.validatedAddress = shippingAddress;
                            var addressSuggestResponse = addressSuggestions.methods.getAddressSuggestions($cache.checkoutForm);
                            if(addressSuggestResponse){
                                addressSuggestResponse.done(function(suggestionData){
                                    dialog.open({
                                        html: suggestionData,
                                        options: addressSuggestions.settings.dialogSettings
                                    });
                                }).fail(function(){
                                    $('.js-shipping-go-next-btn').click();
                                });
                            }else{
                                $('.js-shipping-go-next-btn').click();
                            }
                            return false;
                        }
                    }
                }
            }
            // end address suggestion

            var delta = $(this).is(".js-next") ? 1 : -1;
            var activeValue = $accordion.accordion("option", "active") + delta;

            if (!$(".amazon-logout").length) {
                if ($cache.useForBilling.is(":checked")) {
                    // to skip 3-th (Billing) step of jQuery UI accordion
                    activeValue = opc.checkout.skipBillingStep(true, activeValue);
                    $cache.billingFormSection.find(".ui-accordion-header").addClass("completed-step-check-mark--active");
                    $cache.billingFormSection.find(".js-checkout-step-edit-btn").addClass("checkout-step-edit-btn--active");
                } else {
                    opc.checkout.skipBillingStep(false);
                    // remove check-mark and edit button if billing address is not the same as shipping
                    $cache.billingFormSection.find(".ui-accordion-header").removeClass("completed-step-check-mark--active");
                    $cache.billingFormSection.find(".js-checkout-step-edit-btn").removeClass("checkout-step-edit-btn--active");

                    // make sure the address is selected on shipping
                    var closestFieldset = $(this).closest("fieldset");
                    if (closestFieldset.is("#checkout-shipping-address") || closestFieldset.is("#checkout-billing-address")) {
                        // trigger click on selected address
                        var selectedAddress = closestFieldset.find(".checkout-box__item-selected")
                        if (selectedAddress) {
                            selectedAddress.trigger("click");
                        }
                    }
                }
                // make sure the shipping method is selected
                var closestFieldsetShip = $(this).closest("fieldset");
                if (closestFieldsetShip.is("#checkout-shipping-methods")) {
                    var selectedShippingMethod = closestFieldsetShip.find("input:checked");
                    if (selectedShippingMethod) {
                        selectedShippingMethod.trigger("click");
                    }
                }
            }

            // gtm ua tags
            if (SitePreferences.IS_GTM_UA_ENABLED) {
                ControllerUA.fireCheckoutEvent(activeValue + 1);
            }

            // trigger gtm in case of shipping method selected
            if (SitePreferences.IS_GTM_GA4_ENABLED && $(this).is('.shipping-button')) {
                Controller.fireCheckoutEvent(3);
            }

            opc.checkout.saveAddresses();
            // prevent accordion from toggling here if address suggestion is not empty
            var active =  $accordion.accordion("option", "active");
            $accordion.accordion("option", "active", activeValue);

            if ($("input[name=\"dwfrm_billing_paymentMethods_selectedPaymentMethodID\"]:checked").val() == "AFFIRM") {
                opc.checkout.refreshAffirm();
            }

            setTimeout(() => {
                if ($(".ui-accordion-header-active.ui-state-active").length > 0) {
                    $("html, body").animate({
                        scrollTop: $(".ui-accordion-header-active.ui-state-active").offset().top
                    }, 500);
                }
            }, 500);
        });

        $accordion.on("click", ".edit-shipping-addresses", function (e) {
            e.preventDefault();
            $accordion.accordion("option", "active", 0);
        });

        if ($cache.checkoutForm.hasClass("checkout-multi-shipping")) {
            $accordion.on("click", ".reload-shipments", function (e) {
                e.preventDefault();

                var productLineItems = $(".shippingaddress");
                var selectionMap = {};

                productLineItems.each(function (i, e) {
                    var selectedAddress = $(e).find("select").val();
                    var tr = $(e).closest("tr");

                    var pid = tr.find(".sku>.value").text();
                    selectionMap[pid] = selectedAddress;
                });

                $.ajax({
                    url: Urls.reloadShipments,
                    method: "GET",
                    data: { "selectionMap" : JSON.stringify(selectionMap) }
                }).done(function (data) {
                    if (data != "") {
                        $cache.multiShipments.html(data);
                        opc.checkout.updateShippingMethodList();
                    }
                });

            });

            $cache.document.on("change", "[name$='_shippingMethodID']", function (event) {

                opc.checkout.selectShippingMethods($(this).val(), $(this).data("shipmentid"));
                // update the summary
                opc.checkout.updateSummary();
                // recreate accordion
                opc.checkout.checkoutAccordion();
                progress.hide();
                tooltips.init();

            });

        }

        $cache.document.on("giftCertificateAdded", function (event, data) {
            // clear the current code and status message
            $("#dwfrm_billing_giftCertCode").val("");
            $(".giftcert-error").html("");

            // update the redemptions status
            $cache.redemption.html(data.htmlStatus);

            if (data.orderTotal == 0) {
                $.getJSON(Urls.onePageCheckoutRenderGiftDiv, function (data) {
                    $("div.payment-method").closest("fieldset").replaceWith(data.htmlStatus);
                });
            }

            opc.checkout.updateSummary();

            if (data.orderTotal == 0) {
                $cache.submitBtn.attr("disabled", false);
                $cache.submitDuplicateBtn.attr("disabled", false);
            }
        });

        $cache.document.on("click", ".summary-edit", function (event) {

            var $accordion = $(".accordion");
            var step = $(this).data("step");

            $accordion.accordion("option", "active", step);
        });

        $cache.document.on("click", ".js-remove-coupon", function (event) {
            event.preventDefault();

            $.getJSON(this.href, function (data) {
                opc.checkout.updateSummary();

                //re-render the Card section
                if (data.success) {
                    $.ajax({
                        url: Urls.onePageCheckoutRenderCardDiv,
                        method: "GET"
                    }).done(function (data) {

                        $(".accordion").accordion("refresh");

                        var $selectPaymentMethod = $(".payment-method-options");
                        var selectedPaymentMethod = $selectPaymentMethod.find(":checked").val();
                        opc.checkout.updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : "CREDIT_CARD");

                        $selectPaymentMethod.on("click", "input[type=\"radio\"]", function () {
                            opc.checkout.updatePaymentMethod($(this).val());
                        });

                        opc.checkout.updateSummary();

                        $cache.submitBtn.attr("disabled", true);
                        $cache.submitDuplicateBtn.attr("disabled", true);

                        $cache.redemptionCoupon.html("");

                        $cache.couponCode = $cache.checkoutForm.find("input[name$='_couponCode']");
                        $cache.couponCode.val("");
                        $(".cl-coupon-code-form").removeClass("ms-hide");
                    });
                }
            });
        });

        $cache.document.on("click", ".js-remove-gift-certificate", function (event) {
            event.preventDefault();

            $.getJSON(this.href, function (data) {
                $cache.redemption.html(data.htmlStatus);
                opc.checkout.updateSummary();

                //re-render the Card section
                if (data.orderTotal > 0 && $(".valid.repl").length == 0) {
                    $.ajax({
                        url: Urls.onePageCheckoutRenderCardDiv,
                        method: "GET"
                    }).done(function (data) {
                        giftCertApplied = false;
                        giftCertCode = "";

                        $("div.gift-cert-used").first().replaceWith(data);

                        $(".accordion").accordion("refresh");

                        var $selectPaymentMethod = $(".payment-method-options");
                        var selectedPaymentMethod = $selectPaymentMethod.find(":checked").val();
                        opc.checkout.updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : "CREDIT_CARD");

                        $selectPaymentMethod.on("click", "input[type=\"radio\"]", function () {
                            opc.checkout.updatePaymentMethod($(this).val());
                        });

                        opc.checkout.updateSummary();

                        $cache.submitBtn.attr("disabled", true);
                        $cache.submitDuplicateBtn.attr("disabled", true);

                        opc.checkout.reInitEvents();
                    });
                }
            });
        });

        opc.checkout.initRemoveSameAsShippingLabelEvent();
    },

    /**
         * @function
         * @description Checks if credit card fields are filled in Payment step
         * @return {boolean} - true in case credit card credit card was chosen as payment method
         */
    isCreditCardChosen: function () {
        if ($cache.creditCardRadioBtn.is(":checked")) {
            return true;
        }
        return false;
    },

    /**
         * @function
         * @description Checks if shipping address is same as shipping
         * @return {boolean} - true in case shipping address is same as shipping
         */
    isBillingSameAsShipping: function () {
        if ($cache.useForBilling.is(":checked")) {
            return true;
        }
        return false;
    },

    /**
         * @function
         * @description Checks if credit card field are filled
         * @return {boolean} - true in case credit card fields are filled
         */
    isCreditCardFilled: function () {
        if ($cache.creditCardOwner.val() !== ""
                && $cache.creditCardNumber.val() !== ""
                && $cache.creditCardExpMonth.val() !== ""
                && $cache.creditCardExpYear.val() !== ""
                && $cache.creditCardSecurityCode.val() !== "") {
            return true;
        }
        return false;
    },

    /**
        * @function
        * @description Checks if billing address are filled
        * @return {boolean} - true in case billing address are filled
        */
    isBillingAddressFilled: function () {
        if ($cache.firstNameBilling.val() !== ""
                && $cache.lastNameBilling.val() !== ""
                && $cache.address1Billing.val() !== ""
                && $cache.cityBilling.val() !== ""
                && $cache.postalCodeBilling.val() !== ""
                && $cache.countryCodeBilling .val() !== ""
                && $cache.phoneBilling.val() !== ""
                && $cache.stateCodeBilling.val() !== "") {
            return true;
        }
        return false;
    },

    /**
        * @function
        * @description inits event and removes billing same as shipping label, if user clicked to edit the Billing Address button
        */
    initRemoveSameAsShippingLabelEvent: function () {
        $cache.checkoutForm.on("click", ".js-checkout-step-edit-btn-billing", function () {
            $cache.sameAsShippingLabel.addClass("ms-hide");
        });
    },

    reInitEvents: function () {

        opc.checkout.initializeCache();
        opc.checkout.initializeEvents();
    },

    skipBillingStep: function (action, activeValue) {
        action = (action == "undefined") ? false : action;

        if (action && !$cache.billingWrapper.hasClass("edited") && $cache.useForBilling.prop("checked")) {
            $cache.sameAsShippingLabel.removeClass("ms-hide");

            $cache.changeBillingLabel.on("click", function (e) {
                e.preventDefault();
                if ($cache.useForBilling.is(":checked")) {
                    // sync shipping address selected box with billing address box, if user checks checkbox billing as shipping
                    if ($cache.shippingFormSection.find(".js-profile-address-filled")) {
                        var selectedAddress = $cache.shippingFormSection.find(".js-select-shipping-address:checked").closest(".js-mini-address-location").data("shipping-class");
                        $cache.billingFormSection.find(`.${selectedAddress}`).trigger("click");
                    }

                    $cache.billingAddressSelectList.val(null).trigger("change");
                }
            });

            // If the Billing form was eddited then hide label
            $cache.billingWrapper.on("change", function (e) {
                $cache.sameAsShippingLabel.addClass("ms-hide");
                $cache.billingWrapper.addClass("edited");
                $cache.useForBilling.prop("checked", false);
            });

            if (!$cache.billingWrapper.hasClass("edited")) {
                opc.checkout.updateBillingStateOptions($cache.countryCode);
                opc.checkout.updateBillingFormWithShippingData();
                opc.checkout.removeErrorFrom("#checkout-billing-address");
            }

            // Add valid class to the billing accordion step to skip it
            $("#checkout-billing-address").addClass("valid validate");

            return (activeValue == 2) ? 3 : activeValue;

        } else {
            $cache.sameAsShippingLabel.addClass("ms-hide");
            $cache.billingWrapper.removeClass("edited");

            return activeValue;
        }
    },

    viewSummaryInformation: function () {
        var activeTabIndex = $(".accordion").accordion("option", "active"),
            firstTD = "",
            secondTD = "",
            thirdTD = "",
            className = "",
            isMultishipping = $(".checkoutmultishipping").length > 0,
            defaultSize = 4;

        var accordionSize = $(".ui-accordion-header").length;
        var indexCorrection = (accordionSize != defaultSize) ? (defaultSize+1 - accordionSize) : 0;

        if (activeTabIndex + indexCorrection > 0) {

            firstTD     = Resources.OPC_SHIPPING_ADDRESS;

            if (isMultishipping) {

                var shippings = [];
                $(".checkout").find("select").each(function (i, e) {
                    shippings.push({
                        address: $(e).find("option:checked").data("label"),
                        country: $(e).find("option:checked").data("country")
                    });
                });

                var html = "";
                $(shippings).each(function (i, e) {
                    html += "<p>" + e.address + "</br>";
                    html += e.country + "</p>";
                });

                secondTD = html;

            } else {

                var address1    = $cache.address1.val(),
                    address2    = $cache.address2.val(),
                    city        = $cache.city.val(),
                    postal      = $cache.postalCode.val(),
                    country     = $cache.country,
                    state       = $cache.stateCode.val();

                secondTD    = address1 + " " + address2 + " " + city + " " + state + " " + postal,
                thirdTD     = country;

            }
            className   = ".shipping-summary";
        }

        if (activeTabIndex + indexCorrection > 1) {
            var shippingID        = $("input[name$='_shippingMethodID']:checked").val();
            var shippingTextArray = $("label[for$='shipping-method-" + shippingID + "']").clone().find("a").remove().end().text().trim().replace(/\s+/g, " ").split(" ");

            var shippingText = "";

            if (isMultishipping) {

                var methods = [];
                $(".multiship").find("select").each(function (i, e) {
                    methods.push($(e).find("option:checked").text());
                });

                var html = "<p>" + methods.join("<br/>") + "</p>";
                shippingText = html;

            } else {
                if (shippingTextArray.length == 3) {
                    shippingText = shippingTextArray[0] + " <strike>" + shippingTextArray[1] + "</strike> " + shippingTextArray[2];
                } else {
                    shippingText = shippingTextArray[0] + " " + shippingTextArray[1];
                }
            }

            firstTD     = Resources.OPC_SHIPPING,
            secondTD    = shippingText,
            thirdTD     = "",
            className   = ".shipping-method-summary";
        }

        if (activeTabIndex + indexCorrection > 2) {
            var address1    = $cache.address1Billing.val(),
                address2    = $cache.address2Billing.val(),
                city        = $cache.cityBilling.val(),
                postal      = $cache.postalCodeBilling.val(),
                country     = $cache.countryBilling,
                state       = $cache.stateCodeBilling.val();

            firstTD     = Resources.OPC_BILLING_ADDRESS,
            secondTD    = address1 + " " + address2 + " " + city + " " + state + " " + postal,
            thirdTD     = country,
            className   = ".billing-summary";
        }

        if (activeTabIndex + indexCorrection > 3) {
            if ($cache.couponApplied || giftCertApplied) {

                var couponCode = (typeof $cache.couponCode == "object") ? $cache.couponCode.val() : $cache.couponCode;

                firstTD     = Resources.OPC_COUPON_DISCOUNT,
                secondTD    = couponCode != "" ? Resources.OPC_COUPON_CODE + " " + couponCode : "",
                thirdTD     = giftCertCode != "" ? Resources.OPC_GIFT_COUPON_CODE + " : " + giftCertCode : "",
                className   = ".coupon-discount-code";
            } else {
                //avoids setting wrong index to currently rendered summary
                activeTabIndex--;
            }
        }


        var summary = "";
        var dataStep = parseInt(activeTabIndex)-1;

        if (firstTD != "") {
            summary += "<h3 class=\"section-header\">" + firstTD + "</h3>";
            summary += "<span class='summary-edit' data-step='" + dataStep.toString() + "'>" + Resources.EDIT + "</span>";
        }
        summary += "<div class=\"checkout-mini-cart\">";

        if (secondTD != "") {
            summary += "<p>" + secondTD + "</p>";
        }
        if (thirdTD != "") {
            summary += "<p>" + thirdTD + "</p>";
        }

        summary += "</div>";

        if (dataStep >= 0) {
            $(className).html(summary).removeClass("hide");
        }

        // Classes Array with position they render at OPC page.
        var classesArray = {
            0: ".shipping-summary",
            1: ".shipping-method-summary",
            2: ".billing-summary",
            3: ".coupon-discount-code",
            4: ".payment-summary"
        };

        $(classesArray[activeTabIndex+indexCorrection]).addClass("hide");
    },
    initAddressSuggestionDialogEvents: function() {
        addressSuggestions.settings.dialogSettings.buttons = [{
            text: Resources.ADDRESS_VALIDATION_USESELECTED,
            class: "cl-button cl-button--alternate cl-button--icon ms-font--montserrat ms-margin-top-10",
            click: function () {
                var selectedAddress = $('#vertex-suggestions input:checked');
                if(selectedAddress.length){
                    var selectedAddressData = selectedAddress.data('address');
                    addressSuggestions.methods.setShippingFormData(selectedAddressData, $cache.checkoutForm);
                    var selectedAddress = $('.js-shipping-box-item.checkout-box__item-selected');
                    if(selectedAddress.length){
                        var selectedAddressID = selectedAddress.attr('data-addressid');
                        if(selectedAddressID){
                            $('.js-shipping-address-update').attr('data-addressid', selectedAddressID);
                        }
                    }
                    addressSuggestions.cache.validatedAddress = addressSuggestions.methods.getShippingFormData($cache.checkoutForm);
                }
                $('.js-shipping-address-update').click();
                $('.js-shipping-go-next-btn').click();
                dialog.close();
            }
        },
        {
            text: Resources.ADDRESS_VALIDATION_EDIT,
            class: "cl-button cl-button--white ms-font--montserrat ms-margin-top-10",
            click: function () {
                var selectedSavedAddress = $('.js-shipping-box-item.checkout-box__item-selected');
                if(selectedSavedAddress.length){
                    var editAddressBtn = selectedSavedAddress.find('.js-shipping-address-edit');
                    if(editAddressBtn.length){
                        editAddressBtn.click();
                    }
                }
                dialog.close();
            }
        }];
    }
};



module.exports = opc;
