'use strict';
/**
 * Creates a Google Tag Manager event with the given element primary and description.
 * @param {string} element_primary - The primary element associated with the event.
 * @param {string} element_description - The description of the element associated with the event.
 */
function createEvent(element_primary, element_description) {
    dataLayer.push({
      event: 'gtm_event',
      non_interaction_hit: false,
      event_name: 'cart_interaction',
      element: 'mini cart',
      element_action: 'click',
      element_ordinal: 'primary',
      element_primary: element_primary.toLowerCase() || null,
      element_description: element_description.toLowerCase() || null,
    });
  }
  
  /**
   * Attaches click event listeners to the mini-cart elements.
   */
  module.exports = function () {
    $('body')
      .on('click', '.cl-minicart .mini-cart-product', function (e) {
        // Extract product name from the clicked mini-cart product element
        var productName = $(this).find('.mini-cart-name').text().trim();
        // Create a product click event with the extracted product name
        createEvent('product click', productName);
      })
      .on('click', '.mini-cart-link-checkout', function (e) {
        // Create a go to checkout event when the checkout link is clicked
        createEvent('go to checkout', '');
      })
      .on('click', '.mini-cart-link-cart', function (e) {
        // Create a view and edit cart event when the view/edit cart link is clicked
        createEvent('view and edit cart', '');
      });
  };
  