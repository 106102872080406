

/**
 * @function
 * @description Polifill for support of maxlength attribute for input type number
 **/
module.exports = function () {
    $(document).off("keyup change input paste", "input[type=\"number\"]").on("keyup change input paste", "input[type=\"number\"]", function () {
        var attr = $(this).attr("maxlength");

        if (typeof attr !== typeof undefined && attr !== false) {
            var $this     = $(this),
                val       = $this.val(),
                valLength = val.length,
                maxCount  = $this.attr("maxlength");

            if (valLength > maxCount) {
                $this.val($this.val().substring(0, maxCount));
            }
        }
    });
};
