var clyde = require("./pages/clyde"),
    dialog = require("./dialog");

var timerMiliseconds = 500,
    retryCount = 0,
    maxRetry = 10;

var clydeProtectionPlanHandler = {
    listenAPILoad: function(){
        if(clydeProtectionPlanHandler.isActive){
            if(window.Clyde && window.Clyde.checkInit() && window.Clyde.checkReady()){
                clydeProtectionPlanHandler.isReady = true;
                $(clydeProtectionPlanHandler).trigger('APILoaded');
            }else{
                $(document).ready(function(){
                    if(window.Clyde){
                        clyde.initializeAPI();
                        clydeProtectionPlanHandler.checkAPIReady();
                    }
                });
            }
        }
    },
    cart: {
        init: function(){
            if(clydeProtectionPlanHandler.isActive && !clydeProtectionPlanHandler.cart.initialized){
                $(clydeProtectionPlanHandler).on('APILoaded', function(){
                    clydeProtectionPlanHandler.loadProtectionPlanProducts();
                });

                clydeProtectionPlanHandler.cart.initEvents();
                clydeProtectionPlanHandler.listenAPILoad();
                clydeProtectionPlanHandler.cart.initialized = true;
            }
        },
        initEvents: function(){
            clydeProtectionPlanHandler.initGlobalEvents();

            $('body').on('click', '.js-protection-plan-wrapper.js-pp-cart .js-protection-plan-purchase', function(){
                var $target = $(this),
                    dataHolder = $target.closest('.js-protection-plan-wrapper'),
                    pliUUID = dataHolder.data('pliUuid'),
                    clydeProductID = dataHolder.find('.js-protection-plan-selector').val(),
                    purchaseData = {
                        Quantity: 0,
                        clydePID: clydeProductID,
                        uuid: pliUUID,
                        format: 'ajax',
                        pid: clydeProductID
                    };

                clydeProtectionPlanHandler.processCartAction(purchaseData, window.Urls.addProduct, function(){
                    $(document).trigger('refreshCart');
                });
            });

            $('body').on('click', '.js-protection-plan-wrapper.js-pp-cart .js-protection-plan-remove', function(){
                var $target = $(this),
                    protectionPlanUUID = $target.data('ppUuid'),
                    requesData = {
                        ppuuid: protectionPlanUUID,
                        format: 'ajax'
                    };

                clydeProtectionPlanHandler.processCartAction(requesData, window.Urls.removeProtectionPlanProduct, function(){
                    $(document).trigger('refreshCart');
                });
            });
        }
    },
    minicart: {
        init: function(){
            if(clydeProtectionPlanHandler.isActive && !clydeProtectionPlanHandler.minicart.initialized){
                $(clydeProtectionPlanHandler).on('APILoaded', function(){
                    clydeProtectionPlanHandler.loadProtectionPlanProducts();
                });

                clydeProtectionPlanHandler.minicart.initEvents();
                clydeProtectionPlanHandler.listenAPILoad();
                clydeProtectionPlanHandler.minicart.initialized = true;
            }
        },
        initEvents: function(){
            clydeProtectionPlanHandler.initGlobalEvents();

            $('body').on('click', '.js-protection-plan-wrapper.js-pp-minicart .js-protection-plan-purchase', function(){
                var $target = $(this),
                    dataHolder = $target.closest('.js-protection-plan-wrapper'),
                    pliUUID = dataHolder.data('pliUuid'),
                    clydeProductID = dataHolder.find('.js-protection-plan-selector').val(),
                    purchaseData = {
                        Quantity: 0,
                        clydePID: clydeProductID,
                        uuid: pliUUID,
                        format: 'ajax',
                        pid: clydeProductID
                    };

                clydeProtectionPlanHandler.processCartAction(purchaseData, window.Urls.addProduct, function(){
                    $(document).trigger('refreshMinicart', {isSlide: true});
                });
            });

            $('body').on('click', '.js-protection-plan-wrapper.js-pp-minicart .js-protection-plan-remove', function(){
                var $target = $(this),
                    protectionPlanUUID = $target.data('ppUuid'),
                    requesData = {
                        ppuuid: protectionPlanUUID,
                        format: 'ajax'
                    };

                clydeProtectionPlanHandler.processCartAction(requesData, window.Urls.removeProtectionPlanProduct, function(){
                    $(document).trigger('refreshMinicart', {isSlide: true});
                });
            });

        }
    },
    initGlobalEvents: function(){
        if(clydeProtectionPlanHandler.globalEventsInitialized){
            return false;
        }
        clydeProtectionPlanHandler.globalEventsInitialized = true;

        // protection plan content block accordion
        $('body').on('click', '.js-accordion-heading-clyde', function (e) {
            e.preventDefault();
            var $el = $(this),
                $plansdrawer = $el.next('.js-accordion-content-clyde');

            if ($el.hasClass('expanded')) {
                $plansdrawer.slideUp();
                $el.removeClass('expanded');
            } else {
                $('.js-accordion-content-clyde').slideUp();
                $plansdrawer.slideDown();
                $(".js-accordion-heading-clyde").removeClass('expanded');
                $el.addClass('expanded');
            }
        });

        // handle "What's Covered?" link click
        $('body').on('click', '.js-pp-learn-more-link', function (e) {
            e.preventDefault();
            var $contentWrapper = $(this).closest('.js-protection-item-select-content'),
                $clydeLearnMoreContent = $contentWrapper.length ? $contentWrapper.find(".js-pp-learn-more-content") : null;

            if (!$clydeLearnMoreContent) { return; }

            // create the dialog
            dialog.open({
                html: $clydeLearnMoreContent.html(),
                options: {
                    autoOpen: false,
                    draggable: false,
                    modal: true,
                    resizable: false,
                    width: "none",
                    position: {
                        my: "center",
                        at: "center",
                        of: window
                    },
                    dialogClass: "cl-actions-dialog clyde-learn-more-popup",
                    classes: {
                        "ui-dialog": "cl-actions-dialog",
                        "ui-dialog-title": "cl-actions-dialog__title",
                        "ui-dialog-titlebar": "cl-actions-dialog__titlebar",
                        "ui-dialog-titlebar-close": "cl-actions-dialog__close",
                        "ui-dialog-content": "cl-actions-dialog__content",
                        "ui-dialog-buttonpane": "ms-hide"
                    },
                    buttons: {},
                    open: function () {
                        //will set dialog title
                        var dialogTitle = $(this).closest(".ui-dialog")
                            .find(".ui-dialog-name")
                            .attr("data-name");

                        $(this).closest(".ui-dialog")
                            .find(".ui-dialog-title")
                            .text(dialogTitle);

                        // Add custom close button icon
                        $(this).closest(".ui-dialog")
                            .find(".ui-dialog-titlebar-close")
                            .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

                        // Close Dialog When Clicked Outside
                        $(document).find(".ui-widget-overlay").bind("click", function () {
                            dialog.close();
                        });
                    }
                }
            });
        });
    },
    renderItemContracts: function(itemContractsArr, protectionPlanWrapper){
        if(itemContractsArr && itemContractsArr.length){
            var protectionPlanSelect = protectionPlanWrapper.find('.js-protection-plan-selector'),
                optionTemplate = $(protectionPlanSelect.find('option').get(0)),
                priceFormat = protectionPlanSelect.data('priceFormat'),
                priceReplaceRegex = /([0-9]){0,}\.([0-9]){0,}/gi;

            for(var i = 0; i < itemContractsArr.length; i += 1){
                var contractArrItem = itemContractsArr[i];
                    newOption = optionTemplate.clone(),
                    priceVal = priceFormat.replace(priceReplaceRegex, contractArrItem.attributes.recommendedPrice.toFixed(2));

                newOption.text(newOption.text().replace('{0}', contractArrItem.attributes.term));
                newOption.text(newOption.text().replace('{1}', priceVal));
                newOption.val(contractArrItem.attributes.sku);
                
                protectionPlanSelect.append(newOption);
            }

            optionTemplate.remove();
            protectionPlanSelect.change();
            protectionPlanWrapper.removeClass('ms-hide');
        }
    },
    loadProtectionPlanProducts: function(){
        if(clydeProtectionPlanHandler.isActive && clydeProtectionPlanHandler.isReady){
            var protectionPlanItems = $('.js-protection-plan-wrapper');
            
            if(protectionPlanItems.length){
                protectionPlanItems.each(function(index, element){
                    var $el = $(element),
                        isInitialized = $el.data('initialized'),
                        planPurchased = $el.data('purchased'),
                        itemSKU = $el.data('itemSku');

                    if(itemSKU && !isInitialized && !planPurchased){
                        $el.data('initialized', true);
                        window.Clyde.loadContractsForProduct(itemSKU, function(itemContractsArr){
                            clydeProtectionPlanHandler.renderItemContracts(itemContractsArr, $el);
                        });
                    }
                });
                // TODO: use loadContractsForProductList instead of call loadContractsForProduct for each product
                /*if(cartItemsSKUs.length){
                    var clideContracts = window.Clyde.loadContractsForProductList(cartItemsSKUs);
                    console.log(clideContracts);
                    if(clideContracts && clideContracts.length){
                        console.log('TEST TEST');
                    }
                }*/
            }
        }
    },
    processCartAction: function(requesData, url, callbackFn){
        $.ajax({
            url: url,
            method: 'POST',
            data: requesData,
            success: function(responseData){
                if(callbackFn && typeof callbackFn == 'function'){
                    callbackFn();
                }
            },
            error: function(){
                window.location.reload();
            }
        });
    },
    checkAPIReady: function(){
        if(retryCount < maxRetry){
            setTimeout(function(){
                if(window.Clyde.checkReady()){
                    clydeProtectionPlanHandler.isReady = true;
                    $(clydeProtectionPlanHandler).trigger('APILoaded');
                    return;
                }
                retryCount += 1;
                clydeProtectionPlanHandler.checkAPIReady();
            }, timerMiliseconds);
        }
    },
    isActive: window.SitePreferences.IS_CLYDE_ENABLED,
    isReady: false,
    initialized: false,
    globalEventsInitialized: false,
    init: function(){

    }
};

module.exports = clydeProtectionPlanHandler;