var compareWidget    = require("../compare-widget"),
    productTile      = require("../product-tile"),
    util             = require("../util"),
    dialog           = require("../dialog"),
    gtmec            = require("../gtmec"),
    gtmecUA            = require("../gtmec_ua"),
    validator        = require("../validator"),
    login            = require("../login"),
    initQuickView    = require('./../../../../int_acf_core/cartridge/js/modules/_product-tile'),
    initTitleText    = require('./../../../../int_acf_core/cartridge/js/modules/_title-text-cta'),
    tilesmatchheight = require("../tilesmatchheight"),
    badges           = require("../badges"),
    populateHref     = require("./product/populateHref"),
    _ = require("lodash"); //eslint-disable-line

let dialogCongig = {
    autoOpen    : false,
    draggable   : false,
    modal       : true,
    resizable   : false,
    width       : "none",
    position    : {
        my: "center",
        at: "center",
        of: window
    },
    dialogClass : "cl-actions-dialog cl-actions-dialog--large cl-wishlist-dialog",
    classes: {
        "ui-dialog"                : "cl-actions-dialog cl-actions-dialog--large",
        "ui-dialog-title"          : "cl-actions-dialog__title",
        "ui-dialog-titlebar"       : "cl-actions-dialog__titlebar",
        "ui-dialog-titlebar-close" : "cl-actions-dialog__close",
        "ui-dialog-content"        : "cl-actions-dialog__content"
    },
    open: function () {
        //will set dialog title
        var dialogTitle = $(this).closest(".ui-dialog")
            .find(".ui-dialog-name")
            .attr("data-name");

        $(this).closest(".ui-dialog")
            .find(".ui-dialog-title")
            .text(dialogTitle);

        // Add custom close button icon
        $(this).closest(".ui-dialog")
            .find(".ui-dialog-titlebar-close")
            .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>")
            .on("click", function () {
                removeModalFromURL();
                dialog.close();
            });

        $(this).closest(".ui-dialog")
            .find(".js-select-blur")
            .blur();

        $(this).closest(".ui-dialog")
            .find(".js-create-new-list")
            .blur();

        $(this).closest(".ui-dialog")
            .find(".js-registration-link")
            .on("click", function () {
                $(".showForm").toggleClass("ms-hide");
            });

        $(this).closest(".ui-dialog")
            .find("#RegistrationForm .email-validation").on("input", function () {
                $(".customer-exist-error").addClass("ms-hide");
            });

        // Close Dialog When Clicked Outside
        $(document).find(".ui-widget-overlay").bind("click", function () {
            removeModalFromURL();
            dialog.close();
        });

        // Close dialog when key up on Escape button
        $(document).keyup(function (e) {
            // Escape key maps to keycode `27`
            if (e.key === "Escape") {
                removeModalFromURL();
                dialog.close();
            }
        });

        if ($(this).closest(".ui-dialog").find(".js-customer-authenticated").length) {
            window.history.pushState(null, null, util.removeParamFromURL(window.location.href, "scope"));
        }

        if (location.href.match(/(\?|&)scope($|&|=regerror)/)) {
            $(this).closest(".ui-dialog").find(".login-block").addClass("ms-hide");
            $(this).closest(".ui-dialog").find(".registration-block").removeClass("ms-hide");
        }

        validator.init();
        var $requestPasswordForm = $(this).closest(".ui-dialog").find("[name$=\"_requestpassword\"]");
        var $submitWishlistLogin = $requestPasswordForm.find("[name$=\"_requestpassword_send\"]");

        $($submitWishlistLogin).on("click", function (e) {
            e.preventDefault();

            if (!$requestPasswordForm.valid()) {
                return;
            }
        });

        login.init();
    }
};

/**
 * @function
 * @description Calculate the elements offset. If no offset pixels provided, the default one will be used.
 * @param {Object} $elements Node list of jQuery elements
 * @param {Number} offsetToTop The exact pixel number off offset
 * @returns {Array} result The array of elements in current viewport
 */
function getElementsInViewPort($elements, offsetToTop) {
    var calculatedOffset = typeof(offsetToTop) !== "undefined" && offsetToTop !== null ? offsetToTop : SitePreferences.LAZY_LOAD_OFFSET;
    var result = [];

    $elements.each(function (index, $element) {
        if (util.elementInViewport($element, calculatedOffset)) {
            result.push($element);
        }
    });

    return result;
}

/**
 * @function
 * @description Make AJAX call to load Product Tiles for existing placeholders in viewport batch vice
 */
function loadMoreTiles() {
    const requestInterval = 500;
    var $elements = $(".acfmodule-lazy:not(.loaded)");
    if ($elements.length) {
        var $elementsInViewPort = getElementsInViewPort($elements, 0);
        for (let i = 0; i < SitePreferences.LAZY_LOAD_TILE_COUNT; i++) {
            let $this = $($elements[i]);
            let options = {
                folderID: $this.data("acfmoduleid"),
                acfItemId: $this.data("acfitemid"),
                acfItemVariationId: $this.data("acfitemvariationid"),
                acfShowPricing: $this.data("acfshowpricing"),
                acfShowRating: $this.data("acfshowrating"),
                acfShowSwatches: $this.data("acfshowswatches"),
                acfShowPromotion: $this.data("acfshowpromotion"),
                acfShowQuickView: $this.data("acfshowquickview"),
                isCategory: $this.data("iscategory"),
                isNotContentAssetData: true
            };
            $this.addClass("loaded");
            $.ajax({
                type: "GET",
                data: options,
                dataType: "html",
                url: Urls.includeContentType,
                success: function (response) {
                    $this.html(response);
                    badges.init();
                    tilesmatchheight.elMatchHeight($elementsInViewPort, true);
                    initQuickView.init();
                    initAddToWIshlist();
                    populateHref.populateProductVariationOptionValue();
                },
                error: function (xhr, textStatus, errorThrow) {
                    if(xhr.status == 429){
                        setTimeout($.ajax(this), requestInterval);
                    }
                }
            });
        }
        let tileRequestTimeout;
        if ($(".acfmodule-lazy:not(.loaded)").length) {
            tileRequestTimeout = setTimeout(loadMoreTiles, requestInterval);
        } else {
            clearTimeout(tileRequestTimeout);
        }
    }
}

function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $(".infinite-scroll-placeholder[data-loading-state=\"unloaded\"]");
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr("data-grid-url");

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr("data-loading-state", "loading");
        loadingPlaceHolder.addClass("infinite-scroll-loading");


        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.removeClass("infinite-scroll-loading");
            loadingPlaceHolder.attr("data-loading-state", "loaded");
            $("div.search-result-content").append(html);
        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649

        $.ajax({
            type: "GET",
            dataType: "html",
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    sessionStorage["scroll-cache_" + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }
                // update UI
                fillEndlessScrollChunk(response);
                productTile.init();
                badges.init();
                initQuickView.init();
                initAddToWIshlist();
                gtmec.productImpressionsUpdate(gridUrl);
                gtmecUA.productImpressionsUpdate(gridUrl);
                populateHref.populateProductVariationOptionValue();

                // trigger gtm list load event
                $('body').trigger('custom:listLoad', [$('.pt_product-search-result')]);
            }
        });
    }
}

/**
 * @private
 * @function
 * @description retrieve refinement ID from the data attribute
 * @param {Object} context
 * @returns {String} refinementId
 */
function getRefinementId(context) {
    var refinementId = $(context).closest(".js-refinement").attr("data-refinement-id");
    return refinementId.length ? refinementId : null;
}

/**
 * @private
 * @function
 * @description retrieve refinement Value from the data attribute
 * @param {Object} context
 * @returns {String} refinementValue
 */
function getRefinementValue(context) {
    var refinementValue = $(context).attr("data-refinement-value");
    return refinementValue.length ? refinementValue : null;
}

/**
 * @private
 * @function
 * @description generate URL with refinements parameters from the selected refinements items
 * @returns {String} url
 */
function getRefinementsURI() {
    var i = 0,
        uri = util.getUri(""),
        url = uri.path,
        activeFilters = {};

    $(".js-refinement .selected .js-filter-category-link").each(function () {
        var refinementId = getRefinementId(this);
        var refinementValue = getRefinementValue(this);

        if (refinementValue && refinementId) {
            if (refinementId == "price") {
                var priceRange = refinementValue.split("-");
                url = util.appendParamToURL(url, "pmin", priceRange[1]);
                url = util.appendParamToURL(url, "pmax", priceRange[2]);
            } else {
                if (activeFilters[refinementId]) {
                    activeFilters[refinementId].push(refinementValue);
                } else {
                    activeFilters[refinementId] = [refinementValue];
                }
            }
        }
    });

    Object.keys(activeFilters).forEach(function (key) {
        // Check for range values to generate 3 prefs
        if (activeFilters[key].toString().includes("rangevalue")) {
            var rangeValue = activeFilters[key].toString().split("-");
            url = util.appendParamToURL(url, "prefn" + (++i), key);
            url = util.appendParamToURL(url, "prefmin" + (i), rangeValue[1]);
            url = util.appendParamToURL(url, "prefmax" + (i), rangeValue[2]);
        } else {
            url = util.appendParamToURL(url, "prefn" + (++i), key);
            url = util.appendParamToURL(url, "prefv" + (i), activeFilters[key].join("|"));
        }
    });

    return url;
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing. If is "reset" passed into function, refresh using current url.
 */
function updateProductListing(url) {
    if (!url || url === window.location.href) {
        return;
    } else if (url === "reset") {
        url = window.location.href;
    }

    var $content = $(".search-result-content").length ? $(".search-result-content") : $(".no-found-result-content");

    if ($content.length) {
        var loaderTopPosition = $(window).scrollTop() + Math.floor($(window).height() / 2) - $content.offset().top;

        if (loaderTopPosition < 100) {
            loaderTopPosition = 100;
        } else if (loaderTopPosition > $content.height()) {
            loaderTopPosition = $content.height() - 100;
        }

        var loaderSpinner = $("<span>").addClass("loading-spinner").css("top", loaderTopPosition);

        // Show spinner in case the page is not loading in the current moment
        if (!$content.hasClass("loading")) {
            $content.addClass("loading").append(loaderSpinner);
        }
    }

    // Verify if the refinemens comes from activity landing page or search page
    var showall = window.location.queryString && window.location.queryString.showall ? window.location.queryString.showall : util.getParameterByName("showall"),
        query   = window.location.queryString && window.location.queryString.q ? window.location.queryString.q : util.getParameterByName("q");

    if (showall && showall.length) {
        url = util.appendParamToURL(url, "showall", "true");
    }

    if (query && query.length) {
        url = util.appendParamToURL(url, "q", query);
    }

    $("#main").load(util.appendParamToURL(url, "format", "ajax"), function () {
        $content.removeClass("loading");
        compareWidget.init();
        productTile.init();
        gtmec.productImpressionsUpdate(url);
        gtmecUA.productImpressionsUpdate(url);
        history.pushState(undefined, "", url);
        initCustomDropdown();
        initSliderRange();
        initBrandsSearch();
        clpAccordionDefault();
        initQuickView.init();
        initTitleText.init();
        initAddToWIshlist();
        badges.init();
        tilesmatchheight.elMatchHeight();
        populateHref.populateProductVariationOptionValue();

        // trigger gtm list load event
        $('body').trigger('custom:listLoad', [$('.pt_product-search-result')]);
    });
}

/**
 * @private
 * @function
 * @description initialize jQuery UI dropdown
 */
function initCustomDropdown() {
    if ($(".js-sort-select").length > 0) {
        $(document).find(".js-sort-select").selectmenu({
            classes: {
                "ui-selectmenu-button": "cl-sorting-bar__button",
                "ui-selectmenu-icon": "cl-sorting-bar__icon",
                "ui-selectmenu-menu": "cl-sorting-bar__menu",
                "ui-menu-item": "cl-sorting-bar__menu-item"
            },
            create: function () {
                $(this).next().find(".ui-selectmenu-icon").html("<svg class=\"cl-svg-icon cl-sorting-bar__svg-icon\"><use xlink:href=\"#drop-down\"></use></svg>");

                $(this).next().find(".ui-selectmenu-text").parent().prepend("<span class=\"cl-sorting-bar__drop-label ms-font--thin\">" + $(this).attr("data-label") + "</span>");
            },
            change: function (event, ui) {
                $(this).trigger("change", ui);
            },
            close: function () {
                $(".js-sort-mobile-header").hide();
            },
            open: function () {
                $(document).find(".cl-sorting-bar__menu .ui-state-active").addClass("ui-active-option");
            }
        });
    }
}

/**
 * @private
 * @function
 * @description initialize jQuery UI slider-range
 */
function initSliderRange() {
    if ($(".js-slider-range").length > 0) {
        var $sliders = $(document).find(".js-slider-range");

        $sliders.each(function ($index, $slider) {
            var $sliderObj       = $($slider).find(".js-slider-range-obj"), // placeholder to init jQery UI range plugin
                $sliderMin       = $($slider).find(".js-slider-range-min"), // input field for min value
                $sliderMax       = $($slider).find(".js-slider-range-max"), // input fileld for max value
                $displayValueMin = $($slider).find(".js-range-min-update"), // placeholder to display range value
                $displayValueMax = $($slider).find(".js-range-max-update"), // placeholder to display range value
                valueMin         = $($slider).attr("data-value-min"), // min value from url parameters
                valueMax         = $($slider).attr("data-value-max"), // max value from url parameters
                valueFixedMin    = $($slider).attr("data-fixed-min"), // fixed min value for the range
                valueFixedMax    = $($slider).attr("data-fixed-max"); // fixed max value for the range

            // Add comma for span when big numbers are used (1,000 / 1,000,000)
            $.fn.spancomma = function () {
                return this.each(function () {
                    $(this).text($(this).text().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
                });
            };

            // Add comma for input when big numbers are used (1,000 / 1,000,000)
            $.fn.inputcomma = function () {
                $(this).each(function (index, element) {
                    $(element).val().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                });
            };

            if ($($slider).hasClass("js-slider-price")) {
                $displayValueMax.spancomma();
                $displayValueMin.spancomma();
                $sliderMax.inputcomma();
                $sliderMin.inputcomma();
            }

            $sliderObj.slider({
                range: true,
                classes: {
                    "ui-slider-range": "cl-search-aside-nav__range-highlight",
                    "ui-slider-handle": "cl-search-aside-nav__range-handle"
                },
                min: +valueFixedMin,
                max: +valueFixedMax,
                values: [valueMin, valueMax],
                slide: function (event, ui) {
                    if ($($slider).hasClass("js-slider-price")) {
                        $displayValueMin.text(ui.values[0]);
                        $displayValueMax.text(ui.values[1]);
                        $displayValueMax.spancomma();
                        $displayValueMin.spancomma();
                    } else {
                        $displayValueMin.text(ui.values[0]);
                        $displayValueMax.text(ui.values[1]);
                    }
                },
                stop: function (event, ui) {
                    $sliderMin.val(ui.values[0]);
                    $sliderMax.val(ui.values[1]).trigger("change").blur();
                    $sliderMax.inputcomma();
                    $sliderMin.inputcomma();

                    // gtm custom event on price filter
                    $('body').trigger('custom:priceSliderFilterList', [$($slider)]);
                }
            });
        });
    }
}

/**
 * @private
 * @function
 * @description initialize search for brands
 */
function initBrandsSearch() {
    if ($(document).find(".js-search-brand").length && $(document).find(".js-search-brands-container").length) {
        var $container = $(document).find(".js-search-brands-container"),
            $search    = $(document).find(".js-search-brand"),
            $therms    = $container.find("[data-refname]"),
            $refToggle = $container.find(".js-scrollable-toggle");

        $search.on("keyup", function () {
            var search = $(this).val().toLowerCase().trim();

            if (search.length > 0) {
                // Hide all list items
                $therms.hide();
                $container.addClass("inline-search-match");

                // Unwrap match letters of the highlighted class
                $therms.find(".evident").contents().unwrap();

                // Hide the accotdion item if this was initially hidden
                if (!$refToggle.hasClass("cutoff")) {
                    $refToggle.trigger("click").hide();
                } else {
                    $refToggle.hide();
                }

                // Search through all the brands
                $therms.each(function (index, el) {
                    if ($(el).attr("data-refname").toLowerCase().trim().includes(search)) {
                        $(el).show();
                        $(el).find(".js-seach-alias").highlight(search, {
                            class: "evident"
                        });
                    }
                });
            } else if (search.length <= 0 || search == "") {
                // Hide all list items
                $therms.show();
                $container.removeClass("inline-search-match");

                // Hide the accotdion item if this was initially expanded
                if ($refToggle.hasClass("cutoff")) {
                    $refToggle.trigger("click").show();
                }
            }
        });
    }
}

/**
 * @private
 * @function
 * @description Show edit modal for order review details
 */
function initAddToWIshlist() {
    if ($(".js-add-to-wishlist").length > 0) {
        $(".js-add-to-wishlist").off("click").on("click", function (event) {
            event.preventDefault();
            populateHref.populateAddToWishlistHrefCurrentElement($(this));

            // Sets pid to local storage
            var queryString = util.getQueryString($(this).attr("href"));
            var pidModal    = queryString.split("=")[1];
            setCookie("pidModal", pidModal, 1);
            // Opens the dialog
            dialog.open({
                url: $(this).attr("href"),
                options: dialogCongig
            });
        });
    }
}

function checkUrlParameter() {
    if (location.href.match(/(\?|&)modal($|&|=wishlist)/)) {
        var pid = getCookie("pidModal");
        var url = util.appendParamToURL(Urls.loadWishlistModal, "pid", pid);
        dialog.open({
            url: url,
            options: dialogCongig
        });
    }
}

function showCreateListForm() {
    // listen click to create new list
    $(document).on("click", ".js-create-new-list", function (e) {
        e.preventDefault();

        $(".js-create-new-list").fadeOut("fast", function () {
            $(".js-form-create").fadeIn("fast");
        });
    });
}

function addToWishlist() {
    $(document).on("click", ".js-save-to-product-list", function (e) {
        e.preventDefault();

        if (getCookie("pidModal")) {
            removeCookie("pidModal");
        }

        var $form         = $(document).find("#saveToWishlist");

        populateHref.populateFormActionUrl($form);

        var productId     = $form.attr("action").match(/pid=([^&]+)/)[1],
            $productTile  = $(document).find("[data-itemid=\"" + productId  + "\"]");

        $.ajax({
            url     : $form.attr("action"),
            data    : $form.serialize(),
            type    : "POST",
            success : function (dataofconfirm) {
                if (typeof(dataofconfirm) !== "string") {
                    if (dataofconfirm.success) {
                        removeModalFromURL();
                        dialog.close();

                        // Add active class to heart icon
                        $productTile.find(".js-add-to-wishlist").addClass("active");
                    } else {
                        var selectError = $(document).find(".js-selectwishlist-error");
                        validator.init();

                        if ($("#wishlists").find("option:selected").val() == "") {
                            selectError.removeClass("ms-hide").addClass("error");
                        }
                    }
                }
            }
        });
    });
}

function setCookie(name, value, days) {
    var expires = "";

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "")  + expires + "; path=/; SameSite=Lax;";
}

function getCookie(name) {
    var nameEQ  = name + "=";
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0) == " ") cookie = cookie.substring(1, cookie.length);
        if (cookie.indexOf(nameEQ) == 0) return cookie.substring(nameEQ.length, cookie.length);
    }

    return null;
}

function removeCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax;";
}

/**
 * @private
 * @function
 * @description open jQuery UI sorting selectmenu for mobile devices
 * @param e event
 */
function openMobileSortMenu(e) {
    e.preventDefault();
    $(".js-sort-mobile-header").show();
    $(".js-sort-by").selectmenu("open");
}

/**
 * @private
 * @function
 * @description close jQuery UI sorting selectmenu for mobile devices
 * @param e event
 */
function closeMobileSortMenu(e) {
    e.preventDefault();
    $(".js-sort-mobile-header").hide();
    $(".js-sort-by").selectmenu("close");
}

/**
 * @private
 * @function
 * @description open refine popup for mobile devices
 * @param e event
 */
function openMobileRefineMenu(e) {
    e.preventDefault();
    $(".js-refine-by").slideDown(function () {
        $(this).css("display", "flex");
        $(".helpButton").hide();
    });
}

/**
 * @private
 * @function
 * @description reset refinements selected
 * @param e event
 */
function resetMobileRefineMenu(e) {
    e.preventDefault();
    $(".js-refine-by").slideUp();
    updateProductListing("reset");
    $(".helpButton").show();
}

/**
 * @private
 * @function
 * @description apply mobile multiple refinements
 * @param e event
 */
function applyMobileRefinements(e) {
    e.preventDefault();
    closeMobileRefineMenu(e);

    var url = getRefinementsURI();
    var uri = util.getUri(url);

    if (uri.query.length > 1) {
        updateProductListing(uri.url + uri.query);
    } else {
        updateProductListing(uri.url);
    }
}

/**
 * @private
 * @function
 * @description close refine popup for mobile devices
 * @param e event
 */
function closeMobileRefineMenu(e) {
    e.preventDefault();
    $(".js-refine-by").slideUp(function () {
        $(".helpButton").show();
    });
}

/**
 * @private
 * @function
 * @description execute redirect if refinement link is category or folder refinement
 * @param {Object} el - html element
 * @returns code execution
 */
function noRefinementHandler(el) {
    var $this = el;
    // don't intercept for category and folder refinements, as well as unselectable
    if ($($this).parents(".category-refinement").length > 0 || $($this).parents(".folder-refinement").length > 0 || $($this).parent().hasClass("unselectable")) {
        var location = $this.attr("href");

        if (typeof location !== typeof undefined && location !== false) {
            window.location.href = location;
            $(".js-refine-by").slideUp();
        } else {
            return;
        }
    }
}

/**
 * @private
 * @function
 * @description generate refinement URL for range refinement
 * @param {Object} el - html element
 * @returns {Object} - calculated values for ranges
 */
function rangeHandler(el) {
    var $this = el;
    var rangeMin  = $($this).parent().parent().find(".range-min").val(),
        rangeMax  = $($this).parent().parent().find(".range-max").val(),
        rangeUrl  = $($this).parent().parent().find(".range-url").val(),
        rangeType = $($this).parent().parent().find(".range-url").data("type");

    // if needed values are empty, do nothing
    if (!rangeMin || !rangeMax || !rangeUrl) {
        $this.parent().addClass("empty");
        return;
    } else {
        $this.parent().removeClass("empty");
    }

    if (rangeType == "price") {
        rangeUrl = rangeUrl.replace(/(pmin=).*?(&|$)/, "$1" + rangeMin + "$2");
        rangeUrl = rangeUrl.replace(/(pmax=).*?(&|$)/, "$1" + rangeMax + "$2");
    } else {
        var refinementId = getRefinementId($this);
        var qs = util.getQueryStringParams(rangeUrl);
        var num, baseURL;

        Object.keys(qs).forEach(function (key) {
            if (qs[key] == refinementId) {
                num = key.match(/\d+/)[0];
                qs["prefmin" + num] = rangeMin;
                qs["prefmax" + num] = rangeMax;
            }
        });

        Object.keys(qs).forEach(function (key) {
            if (key.indexOf("http") != "-1") {
                baseURL = key;
            } else {
                baseURL = util.appendParamToURL(baseURL, key, qs[key]);
            }
        });

        rangeUrl = baseURL;
    }

    return {
        url: rangeUrl,
        min: rangeMin,
        max: rangeMax
    };
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["phone", "phoneLandscape"],
            enter: function () {
                dialogCongig.position = {
                    my: "center top",
                    at: "center top",
                    of: window
                };
            }
        }, {
            breakpoint: ["tablet", "desktop", "desktopLarge"],
            enter: function () {
                dialogCongig.position = {
                    my: "center",
                    at: "center",
                    of: window
                };
            }
        }
    ]);

    var $main = $("#main");
    // compare checked
    $main.on("click", "input[type=\"checkbox\"].compare-check", function () {
        var cb = $(this);
        var tile = cb.closest(".product-tile");

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find(".product-image a img").first();
        func({
            itemid: tile.data("itemid"),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });

    });

    // Toggle functionality for mobile sorting/ refinement popup
    $main.on("click", ".js-mobile-sort-by", openMobileSortMenu);
    $main.on("click", ".js-sort-mobile-close", closeMobileSortMenu);

    $main.on("click", ".js-mobile-refine-by", openMobileRefineMenu);
    $main.on("click", ".js-refine-mobile-close", closeMobileRefineMenu);
    $main.on("click", ".js-refine-mobile-reset", resetMobileRefineMenu);
    $main.on("click", ".js-mobile-apply-filters", applyMobileRefinements);

    // Initialize filters cutoff display threshold
    $main.on("click", ".js-scrollable-toggle", function (e) {
        e.preventDefault();
        $(this).toggleClass("cutoff");
        $(this).closest(".js-scrollable").find(".js-scrollable-hide").toggleClass("ms-hide");
    });

    // handle toggle refinement blocks
    $main.find(".js-accordion-content").hide();

    $main.on("click keydown", ".js-aside-accordion-heading", function (e) {
        e.preventDefault();
        e.stopPropagation();

        // Blurr accordion header to allow key navigation for filter links
        if ($(this).hasClass("expanded") && e.keyCode) {
            $(this).blur();
            $(this).next().focus();
            return;
        }

        $(this).toggleClass("expanded");
        $(this).next(".js-aside-accordion-content").slideToggle();
    });

    // handle events for refinements
    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["phone", "phoneLandscape", "tablet"],
            enter: function () {
                $main.off("click", ".refinements a").on("click", ".refinements a", function (e) {
                    var $this = $(this);
                    noRefinementHandler($this);
                    e.preventDefault();

                    var $li = $this.closest("li");

                    if ($li.hasClass("selected")) {
                        $li.removeClass("selected");
                    } else {
                        $li.addClass("selected");
                    }
                });
            }
        },
        {
            breakpoint: ["desktop", "desktopLarge"],
            enter: function () {
                $main.off("click", ".refinements a").on("click", ".refinements a", function (e) {
                    var $this = $(this);
                    e.preventDefault();

                    $("html, body").animate({
                        scrollTop: 0
                    }, 700);
                    updateProductListing($this.attr("href"));
                });
            }
        }
    ]);

    // handle events for pagination and breadcrumbs
    $main.on("click", ".pagination a, .breadcrumb-refinement-value a", function (e) {
        e.preventDefault();
        $("html, body").animate({
            scrollTop: 0
        }, 700);
        updateProductListing(this.href);
    });

    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["phone", "phoneLandscape", "tablet"],
            enter: function () {
                $main.off("focusout", ".range-min, .range-max").on("focusout", ".range-min, .range-max", function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    var rangehandler = rangeHandler($this);

                    // Append value to range refinement and add active class
                    var $li = $this.closest(".js-slider-range");
                    $li.find(".js-filter-category-link").attr("data-refinement-value", `rangevalue-${rangehandler.min}-${rangehandler.max}`);

                    if (!$li.hasClass("selected")) {
                        $li.addClass("selected");
                    }
                });
            }
        },
        {
            breakpoint: ["desktop", "desktopLarge"],
            enter: function () {
                $main.off("focusout keypress", ".range-min, .range-max").on("focusout", ".range-min, .range-max", function (e) {
                    // Update page accordingly to the refinement url returned
                    e.preventDefault();
                    let $this = $(this),
                        $val  = $this.val();

                    // Round the input field value.
                    $this.val(Math.round($val));

                    let rangehandler = rangeHandler($this);
                    updateProductListing(rangehandler.url);
                }).on("keypress", ".range-min, .range-max", function (e) {
                    var $key = e.keyCode || e.which,
                        $this = $(this),
                        rangehandler;

                    // Detect Enter key code
                    if ($key == 13 && $this.val()) {
                        rangehandler = rangeHandler($this);
                        updateProductListing(rangehandler.url);
                    }
                });
            }
        }
    ]);

    // handle sorting change
    $main.on("change", ".sort-by select", function (e) {
        e.preventDefault();
        updateProductListing($(this).find("option:selected").val());
        var urlParam = $(this).find("option:selected").val();
        urlParam = urlParam.substring(urlParam.indexOf("srule=")+6);
        urlParam = urlParam.substring(0, urlParam.indexOf("&"));
        var srules = {
            "best-matches": {sortBy: "Best Matches", sortOrder: "ASC"},
            "price-low-to-high": {sortBy: "Price", sortOrder: "ASC"},
            "price-high-to-low": {sortBy: "Price", sortOrder: "DESC"},
            "product-name-ascending": {sortBy: "Product Name", sortOrder: "ASC"},
            "product-name-descending": {sortBy: "Product Name", sortOrder: "DESC"},
            "brand": {sortBy: "Brand", sortOrder: "ASC"},
            "most-popular": {sortBy: "Most Popular", sortOrder: "ASC"},
            "top-sellers": {sortBy: "Top Sellers", sortOrder: "ASC"}
        };
        var params = srules[urlParam];
        if (typeof dynamicYield !== 'undefined') dynamicYield.callEvent("Sort Items", params); // eslint-disable-line no-undef
    })
        .on("change", ".items-per-page select", function () {
            var refineUrl = $(this).find("option:selected").val();
            if (refineUrl === "INFINITE_SCROLL") {
                $("html").addClass("infinite-scroll").removeClass("disable-infinite-scroll");
            } else {
                $("html").addClass("disable-infinite-scroll").removeClass("infinite-scroll");
                updateProductListing(refineUrl);
            }
        });

    initCustomDropdown();
    initSliderRange();
    initBrandsSearch();
    clpAccordionDefault();
}

/**
 * @private
 * @function
 * @description Expand refinements on desktop and collapse on devices
 */
function clpAccordionDefault() {
    window.modulesSystem.jRes.addFunc([{
        breakpoint: ["phone", "phoneLandscape", "tablet"],
        enter: function () {
            // Collapse the refinement headers if the accordion is hidden
            $(".js-aside-accordion-heading").each(function () {
                if ($(this).next(".js-aside-accordion-content").is(":hidden")) {
                    $(this).removeClass("expanded");
                }
            });
        }
    }, {
        breakpoint: ["desktop", "desktopLarge"],
        enter: function () {
            // Expand the refinement headers if the accordion is visible
            $(".js-aside-accordion-heading").each(function () {
                if ($(this).next(".js-aside-accordion-content").is(":visible")) {
                    $(this).addClass("expanded");
                }
            });
        }
    }]);
}

/**
 * @private
 * @function
 * @description Function to show/hide password in the input field
 */
function togglePswdVisibility() {
    $(document).on("click", "#RegistrationForm", function () {
        if ($(".js-show-pwd-toggle").length && $(".js-show-pwd .cl-form-input").length) {
            var $pwdBtnToggle   = $(document).find(".js-show-pwd-toggle"),
                $pwdInputField  = $(".js-show-pwd .cl-form-input"),
                hidePwdCssClass = "pwd-hide",
                showPwdCssClass = "pwd-show",
                isActive        = false;

            $pwdBtnToggle.on("click touchstart", function (e) {
                e.preventDefault();
                e.stopPropagation();

                if (!isActive) {
                    // Change the attribute to text
                    $pwdInputField.attr("type", "text");
                    $pwdBtnToggle.removeClass(hidePwdCssClass).addClass(showPwdCssClass);
                    isActive = true;
                } else {
                    // Change the attribute back to password
                    $pwdInputField.attr("type", "password");
                    $pwdBtnToggle.removeClass(showPwdCssClass).addClass(hidePwdCssClass);
                    isActive = false;
                }
            });
        }
    });
}

function removeModalFromURL() {
    window.history.pushState(null, null, util.removeParamFromURL(window.location.href, "modal"));

    if (window.location.href.indexOf("?") != -1 && window.location.href.split("?")[1].length == 0) {
        window.history.pushState(null, null, window.location.href.substr(0, window.location.href.indexOf("?")));
    }
}

exports.init = function () {
    compareWidget.init();

    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $(window).on("scroll", infiniteScroll);
    }

    if (SitePreferences.ENABLE_LAZY_LOAD) {
        loadMoreTiles();
        $(document).ajaxStop(function() {
            loadMoreTiles();
        });
    }

    // Check history entry for refinements and updates the page with new URL
    if (window.history && window.history.pushState) {
        $(window).on("popstate", () => {
            window.location.reload();
        });
    }

    productTile.init();
    badges.init();
    initQuickView.init();
    initializeEvents();
    initAddToWIshlist();
    checkUrlParameter();
    showCreateListForm();
    addToWishlist();
    togglePswdVisibility();
    tilesmatchheight.elMatchHeight();
    populateHref.populateProductVariationOptionValue();
};

exports.togglePswdVisibility = togglePswdVisibility;
exports.removeModalFromURL   = removeModalFromURL;
exports.getCookie            = getCookie;
exports.removeCookie         = removeCookie;
