var address        = require("./address"),
    dialog         = require("../../dialog"),
    billing        = require("./billing"),
    multiship      = require("./multiship"),
    submitting     = require("./submitting"),
    shipping       = require("./shipping"),
    progress       = require("../../progress"),
    page           = require("../../page"),
    validator      = require("../../validator"),
    applepay       = require("./applepay"),
    /* -- Start OSF One Page Checkout changes -- */
    opc            = require("../opc"),
    /* -- End OSF One Page Checkout changes -- */
    /* -- Start Amazon Pay Integration changes -- */
    amazonPayments = require("../amazonPayments"),
    /* -- End Amazon Pay Integration changes -- */
    inputmask      = require("../../inputmask"),
    util           = require("../../util"),
    addressSuggestions = require('../../addressSuggestions'),
    $cacheForm     = {};

const resolution = window.matchMedia("(max-width: 1024px)");

let dialogCongig = {
    autoOpen    : false,
    draggable   : false,
    modal       : true,
    resizable   : false,
    width       : "none",
    position    : {
        my: "center",
        at: "center",
        of: window
    },
    dialogClass : "cl-actions-dialog cl-actions-dialog--large",
    classes: {
        "ui-dialog"                : "cl-actions-dialog cl-actions-dialog--large",
        "ui-dialog-title"          : "cl-actions-dialog__title",
        "ui-dialog-titlebar"       : "cl-actions-dialog__titlebar",
        "ui-dialog-titlebar-close" : "cl-actions-dialog__close",
        "ui-dialog-content"        : "cl-actions-dialog__content"
    },
    open: function () {
        if ($(this).closest(".ui-dialog").find(".update-shipping-form")) {
            updateShipping();
        }

        if ($(this).closest(".ui-dialog").find(".update-billing-form")) {
            updateBilling();
        }

        if ($(this).closest(".ui-dialog").find(".update-creditcard-form")) {
            updatePaymentMethod();
        }
        //will set dialog title
        var dialogTitle = $(this).closest(".ui-dialog")
            .find(".ui-dialog-name")
            .attr("data-name");

        $(this).closest(".ui-dialog")
            .find(".ui-dialog-title")
            .text(dialogTitle);

        // Add custom close button icon
        $(this).closest(".ui-dialog")
            .find(".ui-dialog-titlebar-close")
            .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

        // Close Dialog When Clicked Outside
        $(document).find(".ui-widget-overlay").bind("click", function () {
            dialog.close();
        });

        payCardType();
        inputmask();
        util.customRequiredMsgValidator();
    }
};

/**
 * @private
 * @function
 * @description Initialize accordion funtionality for order summary
 */
function orderSummaryAccordion() {
    if ($(".js-minisummary-accordion").length && $(".js-minisummary-toggle").length) {

        $(document).on("click", ".js-minisummary-toggle", function (event) {
            event.preventDefault();
            $(this).toggleClass("active");
            $(document).find(".js-minisummary-accordion").slideToggle("slow");
        });
    }
}

/**
 * @private
 * @function
 * @description Show edit modal for order review details
 */
function reviewEditDetails() {
    if ($(".js-credit-card-block").length > 0) {
        var $ccExpiration = $(".js-credit-card-block .cc-exp");
        $ccExpiration.text($ccExpiration.text().replace(".", "/"));
    }

    if ($(".js-chechout-edit-details").length > 0) {
        $(document).on("click", ".js-chechout-edit-details", function (event) {
            event.preventDefault();
            var url = $(this).attr("href");
            dialog.open({
                url: (window.location.search.indexOf("error") != -1) ? util.appendParamToURL(url, "authNetError", "true") : url,
                options: dialogCongig
            });
        });
    }
}

/**
 * @private
 * @function
 * @description Initialize expand input fields functionality. Note: no collapse functionality
 */
function showAdditionalFields() {
    if ($(".js-checkout-field-expand").length) {

        $(".js-checkout-field-expand").map(function (index) {
            let $optionalField = $(this),
                $optionalLinkText = $optionalField.find(".tooltip").text(),
                $optionalExpandLink,
                linkTitle = Resources.OPC_CLICK_TO_EXPAND || "";

            // Create new element
            $optionalExpandLink = $("<div></div>")
                .addClass("form-row form-row__checkout")
                .html(`<span tabindex="0" class="js-expand-field cl-minisummary-link cl-minisummary-link--regular" title="${linkTitle}" data-expand="${index}">${$optionalLinkText} +</span>`);

            // Append created link before form field
            $optionalField
                .slideUp()
                .before($optionalExpandLink)
                .attr("data-field", index);

            // Expand functionality. No need for collapse back.
            $(document).on("click", ".js-expand-field", function (event) {
                event.preventDefault();
                $(event.currentTarget).fadeOut();
                $(document).find(`[data-field="${event.target.dataset.expand}"]`).slideDown();
            });
        });
    }
}

/**
 * @private
 * @function
 * @description Initialize order summary sticky bar
 */
function orderSummaryStickyBar() {
    if ((".js-minisummary-affix").length) {
        var affix = $(".js-minisummary-affix").mindforAffix();

        window.modulesSystem.jRes.addFunc([
            {
                breakpoint: ["phone", "phoneLandscape"],
                enter: function () {
                    if (affix) affix.remove();
                }
            }, {
                breakpoint: ["tablet", "desktop", "desktopLarge"],
                enter: function () {
                    affix = $(".js-minisummary-affix").mindforAffix();
                }
            }
        ]);
    }
}

/**
 * @private
 * @function
 * @description Initialize card type detection
 */
function payCardType() {
    if ($(".js-payment-card-number").length && $(".js-payment-card-type").length) {
        var cType   = "",
            cNumber = "",
            cObj    = {
                visa: "Visa",
                amex: "Amex",
                mastercard: "Master",
                discover: "Discover"
            },
            $cardNumber  = $(document).find("input.js-payment-card-number"),
            $cardType    = $(document).find("select.js-payment-card-type"),
            $cardCcvIcon = $(document).find(".js-ccv-icon");
        $cardNumber.attr("minlength", 15);

        $(document).on("change", "select.js-payment-card-type", function () {
            var cType = $cardType.val(); //NOSONAR
            setCardDataAttr(cType);
            changeCcvIcon(cType);
        });

        $(document).on("change keyup input", "input.js-payment-card-number", function () {
            cNumber = $cardNumber.val() ? $cardNumber.val() : "";

            // Remove card number spaces
            $cardNumber.val(cNumber.replace(/\s/g, "").replace(/[^0-9\.]+/g, "")); //eslint-disable-line
            if (cNumber.length > 3) {
                cType = $.payform.parseCardType(cNumber);

                // credit card length based on card type
                if (cType === "amex") {
                    $cardNumber.attr({
                        "maxlength": 15,
                        "minlength": 15
                    });
                } else if (cType === "visa" || cType === "mastercard") {
                    $cardNumber.attr("minlength", 16);
                    $cardNumber.attr("maxlength", 16);
                }

                if (cObj.hasOwnProperty(cType)) { //eslint-disable-line
                    $cardType.val(cObj[cType]).change();
                }
            } else {
                $cardNumber.removeAttr("data-type maxlength").attr("minlength", 15);
            }
        });

        var changeCcvIcon = function (cType) { //NOSONAR
            if (cType == cObj.amex || $cardType.val() == cObj.amex) {
                $cardCcvIcon.addClass(cObj.amex.toLowerCase());
            } else {
                $cardCcvIcon.removeClass(cObj.amex.toLowerCase());
            }
        };

        var setCardDataAttr = function (cType) { //NOSONAR
            $cardNumber.attr("data-type", cType.toLowerCase());
        };
    }
}

/**
 * @description Update the shipping address or return the html with error
 */
function updateShipping() {
    var $form = $(document).find(".update-shipping-form");

    $form.on("click", ".update-button", function (e) {
        e.preventDefault();
        var $clickedBtn = $(this);

        if (!$form.valid()) {
            return false;
        }

        if(window.SitePreferences.VERTEX_ENABLED && window.SitePreferences.VERTEX_CHECKOUT_ADDRESS_CHECK_ENABLED){
            var shippingAddress = $form.serialize(),
                $countrySelect = $form.find('select[name$=shippingAddress_addressFields_country]'),
                selectedCountryCode = $countrySelect.length && $countrySelect.val(),
                isCountryValidationEnabled = addressSuggestions.methods.countryAddressValidateEnabled(selectedCountryCode);

            if(isCountryValidationEnabled &&
                (!addressSuggestions.cache.lastValidatedAddressForm ||
                    (addressSuggestions.cache.lastValidatedAddressForm && addressSuggestions.cache.lastValidatedAddressForm.serialize() != shippingAddress))){

                addressSuggestions.settings.dialogSettings.buttons = [{
                    text: Resources.ADDRESS_VALIDATION_USESELECTED,
                    class: "cl-button cl-button--alternate cl-button--icon ms-font--montserrat ms-margin-top-10",
                    click: function () {
                        var selectedAddress = $('#vertex-suggestions input:checked');
                        if(selectedAddress.length){
                            var selectedAddressData = selectedAddress.data('address');
                           addressSuggestions.methods.setShippingFormData(selectedAddressData, addressSuggestions.cache.lastValidatedAddressForm);
                           var updateAddressRequestString = addressSuggestions.cache.lastValidatedAddressForm.serialize();

                            if(updateAddressRequestString.length){
                                var options = {
                                    url  : util.appendParamToURL(addressSuggestions.cache.updateAddressURL, "format", "ajax"),
                                    data : updateAddressRequestString,
                                    type : "POST"
                                };
                                $.ajax(options).done(function (data) {
                                    if (typeof(data) !== "string") {
                                        if (data.success) {
                                            dialog.close();
                                            page.redirect(Urls.refreshOrderReview);
                                        } else {
                                            window.alert(data.message); //NOSONAR
                                            return false;
                                        }
                                    } else {
                                        $(".dialog-content").html(data);
                                    }
                                });
                            }else{
                                dialog.close();
                            }
                        }
                        dialog.close();
                    }
                },
                {
                    text: Resources.ADDRESS_VALIDATION_EDIT,
                    class: "cl-button cl-button--white ms-font--montserrat ms-margin-top-10",
                    click: function () {
                        dialog.close();
                        $('.js-chechout-edit-details.js-shipping-address').click();
                    }
                }];

                addressSuggestions.cache.lastValidatedAddressForm = $form;
                addressSuggestions.cache.updateAddressURL = $form.attr("action");
                $clickedBtn.attr('disabled', 'disabled');

                var addressSuggestResponse = addressSuggestions.methods.getAddressSuggestions($form);
                if(addressSuggestResponse){
                    addressSuggestResponse.done(function(suggestionData){
                        $clickedBtn.removeAttr('disabled', 'disabled');
                        dialog.close();
                        dialog.open({
                            html: suggestionData,
                            options: addressSuggestions.settings.dialogSettings
                        });
                    }).fail(function(){
                        $clickedBtn.removeAttr('disabled', 'disabled');
                        $clickedBtn.click();
                    });
                }else{
                    $clickedBtn.removeAttr('disabled', 'disabled');
                    $clickedBtn.click();
                }
                return;
            }
        }

        var url     = $form.attr("action");
        var options = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            data : $form.serialize(),
            type : "POST"
        };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.success) {
                    dialog.close();
                    page.redirect(Urls.refreshOrderReview);
                } else {
                    window.alert(data.message); //NOSONAR
                    return false;
                }
            } else {
                $(".dialog-content").html(data);
            }
        });
    });
    validator.init();
    inputmask();
    // trigger postal validation
    $form.find("input.postal.required").trigger("focusout");
}

/**
 * @description Update the billing address or return the html with error
 */
function updateBilling() {
    var $form = $(document).find(".update-billing-form");

    $form.on("click", ".update-button", function (e) {
        e.preventDefault();

        if (!$form.valid()) {
            return false;
        }

        var url     = $form.attr("action");
        var options = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            data : $form.serialize(),
            type : "POST"
        };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.success) {
                    dialog.close();
                    page.redirect(Urls.refreshOrderReview);
                } else {
                    window.alert(data.message); //NOSONAR
                    return false;
                }
            } else {
                $(".dialog-content").html(data);
            }
        });
    });
    validator.init();
    inputmask();
    // trigger postal validation
    $form.find("input.postal.required").trigger("focusout");
}

/**
 * @description Update the credit card data or return the html with error
 */
function updatePaymentMethod() {
    $(document).on("submit", ".update-creditcard-form", function (e) {
        var $form = $(this);
        e.preventDefault();

        if (!$form.valid()) {
            return false;
        }

        var url     = $form.attr("action");
        var options = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            data : $form.serialize(),
            type : "POST"
        };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.success) {
                    dialog.close();
                    page.redirect(Urls.refreshOrderReview);
                } else {
                    $form.parents(".dialog-content").html(data);
                    payCardType();
                }
            } else {
                $form.parents(".dialog-content").html(data);
                payCardType();
            }
        });
    });

    validator.init();
}

function updateShippingTotalPrice() {
    $(document).on("click", ".js-update-shipping-total-price", function () {
        var totalShippingValue = $(document).find(".shipping-total-price").val();
        var url = util.appendParamToURL(Urls.updateShippingTotal, "shippingTotal", totalShippingValue);
        var options = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            type : "GET"
        };

        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.success) {
                    page.redirect(Urls.refreshOrderReview);
                }
            }
        });
    });
}

function updateTaxTotal() {
    $(document).on("click", ".js-update-tax-total", function () {
        var totalTaxValue = $(document).find(".tax-total").val();
        var url = util.appendParamToURL(Urls.updateTaxTotal, "taxTotal", totalTaxValue);
        var options = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            type : "GET"
        };

        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.success) {
                    page.redirect(Urls.refreshOrderReview);
                }
            }
        });
    });
}

/**
* @function
* @description initialize cancel delete event for shipping, billing boxes
*/
function initCancelDeleteBoxEvent() {
    $(document).on("click", ".js-remove-shipping-cancel, .js-remove-billing-cancel, .js-remove-credit-card-cancel", function (e) {
        e.preventDefault();
        $(this).parent().parent().removeClass("confirm-delete");
    });
}

/**
* @function
* @description initialize confirm delete event for shipping, billing boxes
*/
function initConfirmDeleteBoxEvent() {
    $(document).on("click", ".js-shipping-address-delete, .js-billing-address-delete, .js-credit-card-delete", function (e) {
        e.preventDefault();
        $(this).parent().parent().addClass("confirm-delete");
    });
}

/**
* @function
* @description initialize active box event
*/
function initActiveBoxEvent() {
    $(document).on("change", ".js-select-shipping-address, .js-select-billing-address, .js-select-credit-card", function () {
        if ($(this).is(":checked")) {
            if ($(this).hasClass("js-select-shipping-address")) {
                setActiveBoxClass($(this), ".js-select-shipping-address");
                $cacheForm.shippingSection.removeClass("add-new-address-in-progress");
                $cacheForm.shippingSection.find(".js-shipping-address-cancel, .js-shipping-address-update").addClass("ms-hide");
                $cacheForm.shippingSection.find(".ui-accordion-content").removeClass("edit-shipping-address");
                // hide full form
                $cacheForm.shippingSection.find(".js-editaddress-form").addClass("ms-hide");
                $cacheForm.shippingSection.find(".js-shipping-address-save").addClass("ms-hide");
            } else if ($(this).hasClass("js-select-billing-address")) {
                setActiveBoxClass($(this), ".js-select-billing-address");
                $cacheForm.billingSection.removeClass("add-new-address-in-progress");
                $cacheForm.billingSection.find(".js-billing-address-cancel, .js-billing-address-update").addClass("ms-hide");
                $cacheForm.billingSection.find(".ui-accordion-content").removeClass("edit-billing-address");

                // hide full form
                $cacheForm.billingSection.find(".js-edit-billing-address").addClass("ms-hide");
                $cacheForm.billingSection.find(".js-billing-address-save").addClass("ms-hide");
            } else if ($(this).hasClass("js-select-credit-card")) {
                setActiveBoxClass($(this), ".js-select-credit-card");
                // hide credit card form and append CCV field back to the credit card box in case user decided to use saved credit card instead of adding new
                $cacheForm.paymentMethodSection.find(".js-credit-card, .js-credit-card-button").addClass("ms-hide");
                $cacheForm.paymentMethodSection.find(".js-cvn-field-wrapper, .js-bubble-cvn, .cl-cvn-wrapper, .cl-cvn-wrapper__helper").removeClass("ms-hide");
                appendCvnToCheckedCardOnPageLoad();
                // remove cvn error returned from back-end on card change
                if ($cacheForm.paymentMethodSection.find(".js-cvn-field-wrapper .error-form").length) {
                    $cacheForm.paymentMethodSection.find(".js-cvn-field-wrapper .error-form").remove();
                }
                $cacheForm.paymentMethodSection.removeClass("add-new-card-in-progress");
                $cacheForm.paymentMethodSection.find(".ui-accordion-content").removeClass("edit-credit-card");
            }
        }
    });
}

/**
* @function
* @description sets active class for checked shipping, billing or credit card box
* @param {jQuery} checkedRadioButton - selected JQuery element, checked box
* @param {string} radioClassType - class type of radio buttons (shipping, billing, credit card)
*/
function setActiveBoxClass(checkedRadioButton, radioClassType) {
    $(radioClassType)
        .not($(checkedRadioButton))
        .parent()
        .removeClass("cl-mini-address__selected-box")
        .closest(".js-checkout-box-item")
        .removeClass("checkout-box__item-selected")
        .find(".js-select-box-label")
        .text(Resources.CHECKOUT_SELECT_BOX);

    $(checkedRadioButton)
        .parent()
        .addClass("cl-mini-address__selected-box")
        .closest(".js-checkout-box-item")
        .addClass("checkout-box__item-selected")
        .find(".js-select-box-label")
        .text(Resources.CHECKOUT_SELECTED_BOX);
}

/**
* @function
* @description unsets active box class, when user clicks on add new address or credit card
*/
function unsetActiveBoxClass() {
    $(document)
        .find(".js-checkout-box-item.checkout-box__item-selected")
        .removeClass("checkout-box__item-selected")
        .find(".cl-mini-address__select-box")
        .removeClass("cl-mini-address__selected-box")
        .find(".js-select-box-label")
        .text(Resources.CHECKOUT_SELECT_BOX);
}

/**
* @function
* @description checks if checkout form is valid
* @returns {boolean} - if checkout form is valid, returns true
*/
function isCheckoutFormValid() {
    var checkoutForm = $(document).find(".address");
    if (checkoutForm.valid()) {
        return true;
    }

    return false;
}


function addNewShippingAddress() {
    $(document).on("click", ".js-add-new-shipping-address", function (e) {
        e.preventDefault();
        uncheckBoxOnAddNew(".js-select-shipping-address");
        unsetActiveBoxClass();
        $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_firstName]").val("");
        $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_lastName]").val("");
        $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_company]").val("");
        $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_address1]").val("");
        $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_address2]").val("");
        $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_shippingComment]").val("");
        $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_city]").val("");
        $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_states_state]").prop("selectedIndex", 0);
        $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_country]").prop("selectedIndex", 0);
        $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_postal]").val("");
        $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_phone]").val("");
        $cacheForm.shippingSection.find(".js-shipping-address-cancel").removeClass("ms-hide");
        $cacheForm.shippingSection.find(".js-shipping-address-save").removeClass("ms-hide");
        $cacheForm.shippingSection.find(".js-shipping-address-update").addClass("ms-hide");
        $cacheForm.shippingSection.find(".add-to-address-book").addClass("ms-hide");
        $cacheForm.shippingSection.find(".js-editaddress-form").removeClass("ms-hide");
        $cacheForm.shippingSection.addClass("add-new-address-in-progress").find(".ui-accordion-content").removeClass("edit-shipping-address");
    });
}

function saveAddress() {
    $(document).on("click", ".js-shipping-address-save", function (e) {
        e.preventDefault();

        if (!isCheckoutFormValid()) {
            return;
        }
        // address suggestion
        var $countrySelect = $cacheForm.checkoutForm.find('select[name$=shippingAddress_addressFields_country]'),
            selectedCountryCode = $countrySelect.length && $countrySelect.val(),
            isCountryValidationEnabled = addressSuggestions.methods.countryAddressValidateEnabled(selectedCountryCode);

        if(window.SitePreferences.VERTEX_ENABLED && window.SitePreferences.VERTEX_CHECKOUT_ADDRESS_CHECK_ENABLED && isCountryValidationEnabled){
            var $clickedBtn = $(this);
            var isAddressHasErrors = $('#checkout-shipping-address').find('select.error, input.error').length;
            if(!isAddressHasErrors){
                var shippingAddress = addressSuggestions.methods.getShippingFormData($cacheForm.checkoutForm);

                var isAddressValidated = (addressSuggestions.cache.validatedAddress && addressSuggestions.cache.validatedAddress.shipping_city == shippingAddress.shipping_city &&
                    addressSuggestions.cache.validatedAddress.shipping_postal == shippingAddress.shipping_postal &&
                    addressSuggestions.cache.validatedAddress.shipping_state == shippingAddress.shipping_state &&
                    addressSuggestions.cache.validatedAddress.shipping_country == shippingAddress.shipping_country
                    && addressSuggestions.cache.validatedAddress.shipping_address1 == shippingAddress.shipping_address1);

                if(!isAddressValidated){
                    addressSuggestions.cache.validatedAddress = shippingAddress;
                    var addressSuggestResponse = addressSuggestions.methods.getAddressSuggestions($cacheForm.checkoutForm);
                    if(addressSuggestResponse){
                        addressSuggestResponse.done(function(suggestionData){
                            dialog.open({
                                html: suggestionData,
                                options: addressSuggestions.settings.newAddressSettings
                            });
                        }).fail(function(){
                            $clickedBtn.click();
                        });
                    }else{
                        $clickedBtn.click();
                    }
                    return false;
                }
            }
        }
        // end address suggestion

        var url                    = Urls.saveShippingAddress;
        var newShippingAddressData = {
            firstName : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_firstName]").val(),
            lastName  : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_lastName]").val(),
            company   : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_company]").val(),
            address1  : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_address1]").val(),
            address2  : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_address2]").val(),
            city      : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_city]").val(),
            state     : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_states_state]").val(),
            country   : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_country]").val(),
            postal    : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_postal]").val(),
            phone     : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_phone]").val(),
        };
        var options = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            type : "GET",
            data : newShippingAddressData
        };

        $cacheForm.shippingSection.find(".js-editaddress-form, .js-shipping-address-cancel, .js-shipping-address-save, .js-shipping-address-update").addClass("ms-hide");
        $cacheForm.shippingSection.find(".ui-accordion-content").removeClass("edit-shipping-address").addClass("loading");

        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.success) {
                    var prefAddressId = data.prefAddressId;
                    // synchronize shipping and billing addresses cards
                    $.when(
                        $.ajax({
                            url  : util.appendParamToURL(Urls.refreshShippingAddresses, "prefAddressId", prefAddressId),
                            type : "GET"
                        }),
                        $.ajax({
                            url  : util.appendParamToURL(Urls.refreshBillingAddresses, "prefAddressId", prefAddressId),
                            type : "GET"
                        })
                    ).done(function (ajax1, ajax2) {
                        if (ajax1[1] === "success" && ajax2[1] === "success") {
                            $cacheForm.shippingSection.find(".js-checkout-box-list").html(ajax1[0]);
                            $cacheForm.billingSection.find(".js-checkout-box-list").html(ajax2[0]);
                            $cacheForm.shippingSection.find(".ui-accordion-content").removeClass("loading");
                            $cacheForm.shippingSection.removeClass("add-new-address-in-progress");
                        } else {
                            alert(data.message); //NOSONAR
                        }
                    });
                } else {
                    window.alert(data.message); //NOSONAR
                    $cacheForm.shippingSection.find(".ui-accordion-content").removeClass("loading");
                    $cacheForm.shippingSection.removeClass("add-new-address-in-progress");
                }
            }
        });
    });

    $(document).on("click", ".js-billing-address-save", function (e) {
        e.preventDefault();

        if (!isCheckoutFormValid()) {
            return;
        }

        var url                   = Urls.saveShippingAddress;
        var newBillingAddressData = {
            firstName : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_firstName]").val(),
            lastName  : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_lastName]").val(),
            company   : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_company]").val(),
            address1  : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_address1]").val(),
            address2  : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_address2]").val(),
            city      : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_city]").val(),
            state     : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_states_state]").val(),
            country   : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_country]").val(),
            postal    : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_postal]").val(),
            phone     : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_phone]").val()
        };
        var options = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            type : "GET",
            data : newBillingAddressData
        };

        $cacheForm.billingSection.find(".js-edit-billing-address, .js-billing-address-cancel, .js-billing-address-update, js-billing-address-save").addClass("ms-hide");
        $cacheForm.billingSection.find(".ui-accordion-content").removeClass("edit-billing-address").addClass("loading");

        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.success) {
                    var prefAddressId = data.prefAddressId;
                    $.ajax({
                        url  : util.appendParamToURL(Urls.refreshBillingAddresses, "prefAddressId", prefAddressId),
                        type : "GET"
                    }).done(function (data) {//NOSONAR
                        $cacheForm.billingSection.find(".js-checkout-box-list").html(data);
                        $cacheForm.billingSection.find(".ui-accordion-content").removeClass("loading");
                        $cacheForm.billingSection.removeClass("add-new-address-in-progress");

                    });
                } else {
                    window.alert(data.message); //NOSONAR
                    $cacheForm.billingSection.find(".ui-accordion-content").removeClass("loading");
                    $cacheForm.billingSection.removeClass("add-new-address-in-progress");
                }
            }
        });
    });
}

function editShippingAddress() {
    $(document).on("click", ".js-shipping-address-edit", function (e) {
        e.preventDefault();
        $(this).parent().parent().find(".js-select-shipping-address").trigger("click");
        var addressId = $(this).parent().parent().find(".js-select-shipping-address").attr("id");
        $cacheForm.shippingSection.find(".js-shipping-address-update").attr("data-addressid", addressId);
        $cacheForm.shippingSection.find(".js-shipping-address-cancel").attr("data-addressid", addressId);
        $cacheForm.shippingSection.find(".js-editaddress-form").removeClass("ms-hide");
        $cacheForm.shippingSection.find(".js-shipping-address-cancel").removeClass("ms-hide");
        $cacheForm.shippingSection.find(".js-shipping-address-update").removeClass("ms-hide");
        $cacheForm.shippingSection.find(".js-shipping-address-save").addClass("ms-hide");
        $cacheForm.shippingSection.find(".ui-accordion-content").addClass("edit-shipping-address");
        // trigger postal validation
        $(document).find("[name$=shippingAddress_addressFields_postal]").trigger("focusout");

        if (resolution.matches) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".form-edit-shipping-address").offset().top
            }, 1000);
        }
    });
}

function updateShippingAddress() {
    $(document).on("click", ".js-shipping-address-update", function (e) {
        e.preventDefault();
        if (!isCheckoutFormValid()) {
            return;
        }
        // address suggestion
        var $countrySelect = $cacheForm.checkoutForm.find('select[name$=shippingAddress_addressFields_country]'),
            selectedCountryCode = $countrySelect.length && $countrySelect.val(),
            isCountryValidationEnabled = addressSuggestions.methods.countryAddressValidateEnabled(selectedCountryCode);

        if(window.SitePreferences.VERTEX_ENABLED && window.SitePreferences.VERTEX_CHECKOUT_ADDRESS_CHECK_ENABLED && isCountryValidationEnabled){
            var $clickedBtn = $(this);
            var isAddressHasErrors = $('#checkout-shipping-address').find('select.error, input.error').length;
            if(!isAddressHasErrors){
                var shippingAddress = addressSuggestions.methods.getShippingFormData($cacheForm.checkoutForm);

                var isAddressValidated = (addressSuggestions.cache.validatedAddress && addressSuggestions.cache.validatedAddress.shipping_city == shippingAddress.shipping_city &&
                    addressSuggestions.cache.validatedAddress.shipping_postal == shippingAddress.shipping_postal &&
                    addressSuggestions.cache.validatedAddress.shipping_state == shippingAddress.shipping_state &&
                    addressSuggestions.cache.validatedAddress.shipping_country == shippingAddress.shipping_country
                    && addressSuggestions.cache.validatedAddress.shipping_address1 == shippingAddress.shipping_address1);

                if(!isAddressValidated){
                    addressSuggestions.cache.validatedAddress = shippingAddress;
                    var addressSuggestResponse = addressSuggestions.methods.getAddressSuggestions($cacheForm.checkoutForm);
                    if(addressSuggestResponse){
                        addressSuggestResponse.done(function(suggestionData){
                            dialog.open({
                                html: suggestionData,
                                options: addressSuggestions.settings.editAddressSettings
                            });
                        }).fail(function(){
                            $clickedBtn.click();
                        });
                    }else{
                        $clickedBtn.click();
                    }
                    return false;
                }
            }
        }
        // end address suggestion

        var addressId = $(this).attr("data-addressId");
        var url       = util.appendParamToURL(Urls.editShippingAddress, "addressId", addressId);
        var editedShippingAddressData = {
            shipping_firstName       : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_firstName]").val(), //eslint-disable-line
            shipping_lastName        : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_lastName]").val(), //eslint-disable-line
            shipping_company         : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_company]").val(), //eslint-disable-line
            shipping_address1        : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_address1]").val(), //eslint-disable-line
            shipping_address2        : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_address2]").val(), //eslint-disable-line
            shipping_city            : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_city]").val(), //eslint-disable-line
            shipping_state           : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_states_state]").val(), //eslint-disable-line
            shipping_country         : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_country]").val(), //eslint-disable-line
            shipping_postal          : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_postal]").val(), //eslint-disable-line
            shipping_phone           : $cacheForm.shippingSection.find("[name$=shippingAddress_addressFields_phone]").val() //eslint-disable-line
        };

        var options   = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            type : "GET",
            data : editedShippingAddressData
        };

        $cacheForm.shippingSection.find(".js-editaddress-form, .js-shipping-address-cancel, .js-shipping-address-update").addClass("ms-hide");
        setCheckedStateForBoxes();
        $cacheForm.shippingSection.find(".ui-accordion-content").removeClass("edit-shipping-address").addClass("loading");

        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string" && data.status == "OK") {
                // receive updated list of shipping and billing address boxes
                $.ajax({
                    url  : util.appendParamToURL(Urls.refreshShippingAddresses, "prefAddressId", addressId),
                    type : "GET"
                }).done(function (data) {//NOSONAR
                    $cacheForm.shippingSection.find(".js-checkout-box-list").html(data);
                    $cacheForm.shippingSection.find(".ui-accordion-content").removeClass("loading");
                });
            } else {
                $cacheForm.shippingSection.find(".ui-accordion-content").removeClass("loading");
            }
        });
    });
}

function removeShippingAddress() {
    $(document).on("click", ".js-shipping-address-remove", function (e) {
        e.preventDefault();

        var addressId = $(this).attr("data-addressId");
        var url       = util.appendParamToURL(Urls.removeAddress, "addressId", addressId);
        var options   = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            type : "GET"
        };
        $cacheForm.shippingSection.find(".ui-accordion-content").addClass("loading");

        $.ajax(options).done(function (data) {
            progress.hide();
            if (typeof(data) !== "string") {
                if (data.status == "OK") {
                    // receive updated list of shipping and billing address boxes
                    $.ajax({
                        url  : Urls.refreshShippingAddresses,
                        type : "GET"
                    }).done(function (data) { //NOSONAR
                        $cacheForm.shippingSection.find(".js-checkout-box-list").html(data);
                        setCheckedStateForBoxes();
                        $cacheForm.shippingSection.find(".ui-accordion-content").removeClass("loading");
                        $cacheForm.shippingSection.find(".js-shipping-address-cancel").trigger("click");
                    });
                }
            }
        });
    });
}

function cancelShippingAddress() {
    $(document).on("click", ".js-shipping-address-cancel", function (e) {
        e.preventDefault();
        var addressId = $(this).attr("data-addressId");

        $(document).find(".js-select-billing-address").each(function () {
            if ($(this).val() == addressId) {
                $(this).trigger("click");
            }
        });

        $(this).attr("data-addressId", "");
        $cacheForm.shippingSection.find(".js-editaddress-form").addClass("ms-hide");
        $cacheForm.shippingSection.find(".js-shipping-address-cancel").addClass("ms-hide");
        $cacheForm.shippingSection.find(".js-shipping-address-update").addClass("ms-hide");
        $cacheForm.shippingSection.find(".js-shipping-address-save").addClass("ms-hide");
        $cacheForm.shippingSection.find(".ui-accordion-content").removeClass("edit-shipping-address");
        $cacheForm.shippingSection.removeClass("add-new-address-in-progress");
        setCheckedStateForBoxes();
    });
}

function addNewBillingAddress() {
    $(document).on("click", ".js-add-new-billing-address", function (e) {
        e.preventDefault();
        uncheckBoxOnAddNew(".js-select-billing-address");
        unsetActiveBoxClass();
        $cacheForm.billingSection.find("[name$=billingAddress_addressFields_firstName]").val("");
        $cacheForm.billingSection.find("[name$=billingAddress_addressFields_lastName]").val("");
        $cacheForm.billingSection.find("[name$=billingAddress_addressFields_company]").val("");
        $cacheForm.billingSection.find("[name$=billingAddress_addressFields_address1]").val("");
        $cacheForm.billingSection.find("[name$=billingAddress_addressFields_address2]").val("");
        $cacheForm.billingSection.find("[name$=billingAddress_addressFields_city]").val("");
        $cacheForm.billingSection.find("[name$=billingAddress_addressFields_states_state]").prop("selectedIndex", 0);
        $cacheForm.billingSection.find("[name$=billingAddress_addressFields_country]").prop("selectedIndex", 0);
        $cacheForm.billingSection.find("[name$=billingAddress_addressFields_postal]").val("");
        $cacheForm.billingSection.find("[name$=billingAddress_addressFields_phone]").val("");
        $cacheForm.billingSection.find(".js-billing-address-cancel").removeClass("ms-hide");
        $cacheForm.billingSection.find(".js-billing-address-save").removeClass("ms-hide");
        $cacheForm.billingSection.find(".js-billing-address-update").addClass("ms-hide");
        $cacheForm.billingSection.find(".add-to-address-book").addClass("ms-hide");
        $cacheForm.billingSection.find(".js-edit-billing-address").removeClass("ms-hide");
        $cacheForm.billingSection.addClass("add-new-address-in-progress").find(".ui-accordion-content").removeClass("edit-billing-address");
    });
}

function editBillingAddress() {
    $(document).on("click", ".js-billing-address-edit", function (e) {
        e.preventDefault();
        $(this).parent().parent().find(".js-select-billing-address").trigger("click");
        var addressId = $(this).parent().parent().find(".js-select-billing-address").attr("id");
        $cacheForm.billingSection.find(".js-billing-address-update").attr("data-addressid", addressId);
        $cacheForm.billingSection.find(".js-billing-address-cancel").attr("data-addressid", addressId);
        $cacheForm.billingSection.find(".js-edit-billing-address").removeClass("ms-hide");
        $cacheForm.billingSection.find(".js-billing-address-cancel").removeClass("ms-hide");
        $cacheForm.billingSection.find(".js-billing-address-update").removeClass("ms-hide");
        $cacheForm.billingSection.find(".js-billing-address-save").addClass("ms-hide");
        $cacheForm.billingSection.find(".ui-accordion-content").addClass("edit-billing-address");
        // trigger postal validation
        $(document).find("[name$=billingAddress_addressFields_postal]").trigger("focusout");

        if (resolution.matches) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#billingWrapper").offset().top
            }, 1000);
        }
    });
}

function updateBillingAddress() {
    $(document).on("click", ".js-billing-address-update", function (e) {
        e.preventDefault();
        if (!isCheckoutFormValid()) {
            return;
        }

        var addressId = $(this).attr("data-addressId");
        var url       = util.appendParamToURL(Urls.editBillingAddress, "addressId", addressId);
        var editedBillingAddressData = {
            billing_firstName : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_firstName]").val(), //eslint-disable-line
            billing_lastName  : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_lastName]").val(), //eslint-disable-line
            billing_company   : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_company]").val(), //eslint-disable-line
            billing_address1  : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_address1]").val(), //eslint-disable-line
            billing_address2  : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_address2]").val(), //eslint-disable-line
            billing_city      : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_city]").val(), //eslint-disable-line
            billing_state     : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_states_state]").val(), //eslint-disable-line
            billing_country   : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_country]").val(), //eslint-disable-line
            billing_postal    : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_postal]").val(), //eslint-disable-line
            billing_phone     : $cacheForm.billingSection.find("[name$=billingAddress_addressFields_phone]").val() //eslint-disable-line
        };
        var options   = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            type : "GET",
            data : editedBillingAddressData
        };
        $cacheForm.billingSection.find(".js-edit-billing-address, .js-billing-address-cancel, .js-billing-address-update ").addClass("ms-hide");
        $cacheForm.billingSection.find(".ui-accordion-content").removeClass("edit-billing-address").addClass("loading");

        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.status == "OK") {
                    $.ajax({
                        url  : util.appendParamToURL(Urls.refreshBillingAddresses, "prefAddressId", addressId),
                        type : "GET"
                    }).done(function (data) {//NOSONAR
                        // update list of shipping and billing address boxes
                        $cacheForm.billingSection.find(".js-checkout-box-list").html(data);
                        setCheckedStateForBoxes();
                        opc.checkout.setCompletedStep("shippingMethod");
                        $cacheForm.billingSection.find(".ui-accordion-content").removeClass("loading");
                    });
                }
            }
        });
    });
}

function removeBillingAddress() {
    $(document).on("click", ".js-billing-address-remove", function (e) {
        e.preventDefault();
        var addressId = $(this).attr("data-addressId");
        var url       = util.appendParamToURL(Urls.removeAddress, "addressId", addressId);
        var options   = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            type : "GET"
        };

        $cacheForm.billingSection.find(".ui-accordion-content").addClass("loading");
        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.status == "OK") {
                    // receive updated list of shipping and billing address boxes
                    $.ajax({
                        url  : Urls.refreshBillingAddresses,
                        type : "GET"
                    }).done(function (data) {//NOSONAR
                        $cacheForm.billingSection.find(".js-checkout-box-list").html(data);
                        setCheckedStateForBoxes();
                        opc.checkout.setCompletedStep("shippingMethod");
                        $cacheForm.billingSection.find(".ui-accordion-content").removeClass("loading");
                        $cacheForm.billingSection.find(".js-billing-address-cancel").trigger("click");
                    });
                }
            }
        });
    });
}

function cancelBillingAddress() {
    $(document).on("click", ".js-billing-address-cancel", function (e) {
        e.preventDefault();
        var addressId = $(this).attr("data-addressId");

        $(document).find(".js-select-billing-address").each(function () {
            if ($(this).val() == addressId) {
                $(this).trigger("click");
            }
        });

        $(this).attr("data-addressId", "");
        opc.checkout.setCompletedStep("shippingMethod");
        $cacheForm.billingSection.find(".js-edit-billing-address").addClass("ms-hide");
        $cacheForm.billingSection.find(".js-billing-address-cancel").addClass("ms-hide");
        $cacheForm.billingSection.find(".js-billing-address-update").addClass("ms-hide");
        $cacheForm.billingSection.find(".js-billing-address-save").addClass("ms-hide");

        $cacheForm.billingSection.find(".billing-next-button").removeClass("ms-hide");
        $cacheForm.billingSection.find(".ui-accordion-content").removeClass("edit-billing-address");
        $cacheForm.billingSection.removeClass("add-new-address-in-progress");
        setCheckedStateForBoxes();
    });
}

function clearCreditCardForm() {
    $('#cc_valError').addClass("ms-hide").empty();
}

function addNewCreditCard() {
    $(document).on("click", ".js-add-new-credit-card", function (e) {
        e.preventDefault();
        uncheckBoxOnAddNew(".js-select-credit-card");
        unsetActiveBoxClass();
        clearCreditCardForm();
        $cacheForm.paymentMethodSection.find("input[name$='_creditCard_owner']").val("");
        $cacheForm.paymentMethodSection.find("select[name$='_creditCard_type']").val("");
        $cacheForm.paymentMethodSection.find("input[name*='_creditCard_number']").val("").attr("data-type", "");
        $cacheForm.paymentMethodSection.find("[name$='_creditCard_expiration_month']").val("");
        $cacheForm.paymentMethodSection.find("[name$='_creditCard_expiration_year']").val("");
        $cacheForm.paymentMethodSection.find("input[name*='_creditCard_cvn']").val("").attr("");

        $cacheForm.paymentMethodSection.find(".js-cvn-field-wrapper").addClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".js-credit-card-cancel").removeClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".js-credit-card-save").removeClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".js-credit-card-update").addClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".js-credit-card").removeClass("ms-hide");
        $cacheForm.paymentMethodSection.addClass("add-new-card-in-progress").find(".ui-accordion-content").removeClass("edit-credit-card");
        appendCvnToPaymentForm();
    });
}

function saveNewCreditCard() {
    $(document).on("click", ".js-credit-card-save", function (e) {
        e.preventDefault();
        clearCreditCardForm();

        if (!isCheckoutFormValid()) {
            return;
        }

        $cacheForm.paymentMethodSection.find(".js-credit-card, .js-credit-card-button").addClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".ui-accordion-content").removeClass("edit-credit-card").addClass("loading");

        var ccNumber = Number($cacheForm.paymentMethodSection.find("input[name*='_creditCard_number']").val());
        var strNumber = ccNumber.toString(16).toUpperCase();

        appendCvnToPaymentForm();

        $.ajax({
            url  : util.appendParamToURL(Urls.saveNewCreditCard, "format", "ajax"),
            type : "POST",
            data : {
                creditCard_owner            : $cacheForm.paymentMethodSection.find("input[name$='_creditCard_owner']").val(), //eslint-disable-line
                creditCard_number           : strNumber, //eslint-disable-line
                creditCard_type             : $cacheForm.paymentMethodSection.find("select[name$='_creditCard_type']").val(), //eslint-disable-line
                creditCard_expiration_month : $cacheForm.paymentMethodSection.find("[name$='_creditCard_expiration_month']").val(), //eslint-disable-line
                creditCard_expiration_year  : $cacheForm.paymentMethodSection.find("[name$='_creditCard_expiration_year']").val() //eslint-disable-line
            }
        }).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.success) {
                    $.ajax({
                        url  : util.appendParamToURL(Urls.refreshCreditCardBlock, "ccUUID", data.ccUUID),
                        type : "GET"
                    }).done(function (data) {//NOSONAR
                        $('#cc_valError').addClass("ms-hide");
                        $cacheForm.paymentMethodSection.find(".js-checkout-box-list").html(data);
                        setCheckedStateForBoxes();
                        appendCvnToCheckedCardOnPageLoad();
                        $cacheForm.paymentMethodSection.find(".js-cvn-field-wrapper, .js-bubble-cvn, .cl-cvn-wrapper, .cl-cvn-wrapper__helper").removeClass("ms-hide");
                        $cacheForm.paymentMethodSection.find(".ui-accordion-content").removeClass("loading");
                        $cacheForm.paymentMethodSection.removeClass("add-new-card-in-progress");
                    });
                } else {
                    $cacheForm.paymentMethodSection.find(".ui-accordion-content").removeClass("loading");
                    $cacheForm.paymentMethodSection.find(".js-credit-card, .js-credit-card-button").removeClass("ms-hide");
                    $cacheForm.paymentMethodSection.find(".js-credit-card-update").addClass("ms-hide");
                    $('#cc_valError').append(data.message).removeClass("ms-hide");
                }
            }
        });
    });
}

function editCreditCard() {
    $(document).on("click", ".js-credit-card-edit", function (e) {
        e.preventDefault();
        clearCreditCardForm();
        $(this).parent().parent().find("#creditCardList").trigger("click");
        var ccId = $(this).parent().parent().find("#creditCardList").val();
        $cacheForm.paymentMethodSection.find(".js-credit-card-update").attr("data-ccid", ccId);
        $cacheForm.paymentMethodSection.find(".js-credit-card-cancel").attr("data-ccid", ccId);
        $cacheForm.paymentMethodSection.find(".js-credit-card").removeClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".js-credit-card-number").addClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".js-credit-card-cvn, .js-cvn-field-wrapper, .js-bubble-cvn").addClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".js-credit-card-cancel").removeClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".js-credit-card-update").removeClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".js-credit-card-save").addClass("ms-hide");
        appendCvnToCheckedCardOnPageLoad();
        $cacheForm.paymentMethodSection.find(".ui-accordion-content").addClass("edit-credit-card");

        if (resolution.matches) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".form-edit-credit-card-label").offset().top
            }, 1000);
        }
    });
}

function updateCreditCard() {
    $(document).on("click", ".js-credit-card-update", function (e) {
        e.preventDefault();
        if (!isCheckoutFormValid()) {
            return;
        }

        appendCvnToPaymentForm();

        var ccId    = $(this).attr("data-ccid");
        var url     = util.appendParamToURL(Urls.updateCreditCard, "ccId", ccId);
        var options = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            type : "GET",
            data : {
                creditCard_owner            : $cacheForm.paymentMethodSection.find("input[name$='_creditCard_owner']").val(), //eslint-disable-line
                creditCard_type             : $cacheForm.paymentMethodSection.find("select[name$='_creditCard_type']").val(), //eslint-disable-line
                creditCard_expiration_month : $cacheForm.paymentMethodSection.find("[name$='_creditCard_expiration_month']").val(), //eslint-disable-line
                creditCard_expiration_year  : $cacheForm.paymentMethodSection.find("[name$='_creditCard_expiration_year']").val() //eslint-disable-line
            }
        };
        $cacheForm.paymentMethodSection.find(".js-credit-card, .js-credit-card-button").addClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".ui-accordion-content").removeClass("edit-credit-card").addClass("loading");

        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.status == "OK") {
                    // receive updated credit card list from server
                    $.ajax({
                        url  : util.appendParamToURL(Urls.refreshCreditCardBlock, "ccUUID", ccId),
                        type : "GET"
                    }).done(function (data) {//NOSONAR
                        $cacheForm.paymentMethodSection.find(".js-checkout-box-list").html(data);
                        setCheckedStateForBoxes();
                        appendCvnToCheckedCardOnPageLoad();
                        opc.checkout.setCompletedStep("shippingMethod");
                        $cacheForm.paymentMethodSection.find(".js-cvn-field-wrapper, .js-bubble-cvn, .cl-cvn-wrapper, .cl-cvn-wrapper__helper").removeClass("ms-hide");
                        $cacheForm.paymentMethodSection.find(".ui-accordion-content").removeClass("loading");
                    });
                }
            }
        });
    });
}

function removeCreditCard() {
    $(document).on("click", ".js-credit-card-remove", function (e) {
        e.preventDefault();
        appendCvnToPaymentForm();
        var ccId    = $(this).attr("data-ccid");
        var url     = util.appendParamToURL(Urls.removeCreditCard, "ccId", ccId);
        var options = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            type : "GET"
        };

        $cacheForm.paymentMethodSection.find(".ui-accordion-content").addClass("loading");
        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.status == "OK") {
                    $.ajax({
                        url  : Urls.refreshCreditCardBlock,
                        type : "GET"
                    }).done(function (data) { //NOSONAR
                        $cacheForm.paymentMethodSection.find(".js-checkout-box-list").html(data);
                        setCheckedStateForBoxes();
                        appendCvnToCheckedCardOnPageLoad();
                        $cacheForm.paymentMethodSection.find(".ui-accordion-content").removeClass("loading");
                        $cacheForm.paymentMethodSection.find(".js-credit-card-cancel").trigger("click");
                    });
                }
            }
        });
    });
}

function cancelCreditCard() {
    $(document).on("click", ".js-credit-card-cancel", function (e) {
        e.preventDefault();
        var ccId = $(this).attr("data-ccid");

        $(document).find("#creditCardList").each(function () {
            if ($(this).val() == ccId) {
                $(this).trigger("click");
            }
        });

        $(this).attr("data-ccid", "");
        opc.checkout.setCompletedStep("shippingMethod");
        $cacheForm.paymentMethodSection.find(".js-credit-card").addClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".js-credit-card-button").addClass("ms-hide");
        appendCvnToCheckedCardOnPageLoad();
        $cacheForm.paymentMethodSection.find(".js-cvn-field-wrapper, .js-bubble-cvn, .cl-cvn-wrapper, .cl-cvn-wrapper__helper").removeClass("ms-hide");
        $cacheForm.paymentMethodSection.find(".ui-accordion-content").removeClass("edit-credit-card");
        $cacheForm.paymentMethodSection.removeClass("add-new-card-in-progress");
    });
}

function setCCAsDefault() {
    $(document).on("click", ".js-credit-card-default", function (e) {
        e.preventDefault();
        var $creditCard = $(this);
        var ccId = $(this).parent().parent().find("#creditCardList").val();
        var url  = util.appendParamToURL(Urls.setCCAsDefault, "ccUUID", ccId);
        $cacheForm.paymentMethodSection.find(".ui-accordion-content").addClass("loading");
        url = util.appendParamToURL(url, "checkoutUpdates", "true");

        var options = {
            url  : util.appendParamToURL(url, "format", "ajax"),
            type : "GET"
        };

        $.ajax(options).done(function () {
            setDefaultCreditCard($creditCard);
            $cacheForm.paymentMethodSection.find(".ui-accordion-content").removeClass("loading");
        });
    });
}

/**
* @function
* @description sets the new default card, removes active class from previous default card
* @param {JQuery} element - credit card to make default
*/
function setDefaultCreditCard(element) {
    $cacheForm.paymentMethodSection.find(".js-checkout-box-item.default-credit-card").removeClass("default-credit-card");
    element
        .closest(".js-checkout-box-item")
        .addClass("default-credit-card")
        .find(".js-select-credit-card")
        .trigger("click");
}

/**
* @function
* @description sets checked state for the shipping, billing and credit card boxes, if nothing is selected
*/
function setCheckedStateForBoxes() {
    var $shippingBoxes = $("#checkout-shipping-address").find(".js-select-shipping-address");
    var $billingBoxes = $("#checkout-billing-address").find(".js-select-billing-address");
    var $creditCardBoxes = $("#checkout-payment-methods").find(".js-select-credit-card");
    var boxesArray = [$shippingBoxes, $billingBoxes, $creditCardBoxes];

    $.each(boxesArray, function () {
        if (!$(this).filter(":checked").length) {
            $(this).first().trigger("click");
        }
    });
}

/**
* @function
* @description appends cvn field to payment form in case user click on "add new address"
*/
function appendCvnToPaymentForm() {
    $cacheForm.paymentMethodSection.find(".js-credit-card-list").after($(".js-bubble-cvn"));
    $cacheForm.paymentMethodSection
        .find(".js-credit-card-exp-wrapper")
        .append($(".js-cvn-field-wrapper"))
        .removeClass("ms-hide");
}

/**
* @function
* @description appends cvn field to checked credit card box, in case user is authorized and has saved credit cards in account
*/
function initEventAppendCvnToCheckedCardBox() {
    $(document).on("change", ".js-select-credit-card", function () {
        if ($(this).is(":checked")) {
            $(this)
                .closest(".js-checkout-box-item")
                .find(".cl-remove-edit ")
                .after($(".js-bubble-cvn"));
        }
    });
}

/**
* @function
* @description appends cvn field to checked credit card box on page load or AJAX requests, in case user is authorized and has saved credit cards in account
*/
function appendCvnToCheckedCardOnPageLoad() {
    var $bubbleCvn = $(".js-bubble-cvn");

    if ($bubbleCvn.length) {
        var $cvnFieldWrapper = $(".js-cvn-field-wrapper");
        var $checkedCreditCard = $("#checkout-payment-methods").find(".js-select-credit-card:checked");

        if ($checkedCreditCard.length) {
            $checkedCreditCard = $checkedCreditCard.closest(".js-checkout-box-item");
        } else {
            // in case user chose add new credit card, select the first saved credit card and append cvn
            $checkedCreditCard = $("#checkout-payment-methods")
                .find(".js-select-credit-card")
                .first()
                .trigger("click")
                .closest(".js-checkout-box-item");
        }

        $cvnFieldWrapper.removeClass("ms-hide");
        $bubbleCvn.append($cvnFieldWrapper);
        $checkedCreditCard.find(".cl-remove-edit").after($bubbleCvn);
    }
}

/**
* @function
* @description inits change active box on click event, when user clicks anywhere inside  box
*/
function setActiveAddressBoxOnClickEvent() {
    $(document).on("click", ".js-shipping-box-item, .js-billing-box-item", function (e) {
        var addressSection;
        if ($(this).find(".js-select-shipping-address").length > 0) {
            addressSection = "shippingAddress";
        } else if ($(this).find(".js-select-billing-address").length > 0) {
            addressSection = "billingAddress";
        }

        // if it is not a cta buttons
        if (!$(e.target).hasClass("js-box-edit-address") && !$(e.target).hasClass("js-box-address-remove") && !$(e.target).hasClass("js-box-address-delete") && !$(e.target).hasClass("js-box-cancel")) {
            $(this).find(".js-input-radio-btn").prop("checked", true).trigger("change");
            var selectedAddress = $(this).find(".js-input-radio-btn").data("address");
            util.fillAddressFields(selectedAddress, $(".address"), addressSection);
        }
    });
}

function setActiveCreditCardBoxOnClickEvent() {
    $(document).on("click", ".js-credit-card-box-item", function (e) {
        // if it is not a cta buttons
        if (!$(this).hasClass("checkout-box__item-selected") && !$(e.target).hasClass("js-credit-card-default") && !$(e.target).hasClass("js-bubble-cvn") && !$(e.target).hasClass("js-credit-card-edit") && !$(e.target).hasClass("js-credit-card-remove") && !$(e.target).hasClass("js-credit-card-delete") && !$(e.target).hasClass("js-remove-credit-card-cancel")) {
            $(this).find(".js-input-radio-btn").prop("checked", true).trigger("change");
            var cardUUID = $(this).find(".js-input-radio-btn").val();
            if (!cardUUID) { return; }
            var ccdata = $(".input-radio[id$='creditCardList']").data(cardUUID);
            if (ccdata && ccdata.holder) {
                opc.checkout.setCCFields(ccdata);
                return;
            }
            opc.checkout.populateCreditCardForm(cardUUID);
        }
    });
}

/**
* @function
* @description unchecks radio button, when user click on "add new address, credit card"
* @param {string} selector - selector class
*/
function uncheckBoxOnAddNew(selector) {
    $(document).find(`${selector}:checked`).prop("checked", false);
}

/**
* @function
* @description inits cache
*/
function initCacheForm() {
    $cacheForm.shippingSection      = $("#checkout-shipping-address");
    $cacheForm.billingSection       = $("#checkout-billing-address");
    $cacheForm.paymentMethodSection = $("#checkout-payment-methods");
    $cacheForm.checkoutForm         = $("form.address");
}
function initAddressSuggestionDialogEvents() {
    addressSuggestions.settings.newAddressSettings = $.extend(true, {}, addressSuggestions.settings.dialogSettings);
    addressSuggestions.settings.editAddressSettings = $.extend(true, {}, addressSuggestions.settings.dialogSettings);
    addressSuggestions.settings.newAddressSettings.buttons = [{
        text: Resources.ADDRESS_VALIDATION_USESELECTED,
        class: "cl-button cl-button--alternate cl-button--icon ms-font--montserrat ms-margin-top-10",
        click: function () {
            var selectedAddress = $('#vertex-suggestions input:checked');
            if(selectedAddress.length){
                var selectedAddressData = selectedAddress.data('address');
                $('.js-shipping-address-update').attr('data-addressid', '');
                addressSuggestions.methods.setShippingFormData(selectedAddressData, $cacheForm.checkoutForm);
                addressSuggestions.cache.validatedAddress = addressSuggestions.methods.getShippingFormData($cacheForm.checkoutForm);
            }
            $('.js-shipping-address-save').click();
            dialog.close();
        }
    },
    {
        text: Resources.ADDRESS_VALIDATION_EDIT,
        class: "cl-button cl-button--white ms-font--montserrat ms-margin-top-10",
        click: function () {
            dialog.close();
        }
    }];

    addressSuggestions.settings.editAddressSettings.buttons = [{
        text: Resources.ADDRESS_VALIDATION_USESELECTED,
        class: "cl-button cl-button--alternate cl-button--icon ms-font--montserrat ms-margin-top-10",
        click: function () {
            var selectedAddress = $('#vertex-suggestions input:checked');
            if(selectedAddress.length){
                var selectedAddressData = selectedAddress.data('address');
                var selectedAddressID = selectedAddress.attr('data-addressid');
                if(selectedAddressID){
                    $('.js-shipping-address-update').attr('data-addressid', selectedAddressID);
                }
                addressSuggestions.methods.setShippingFormData(selectedAddressData, $cacheForm.checkoutForm);
                addressSuggestions.cache.validatedAddress = addressSuggestions.methods.getShippingFormData($cacheForm.checkoutForm);
            }
            $('.js-shipping-address-update').click();
            dialog.close();
        }
    },
    {
        text: Resources.ADDRESS_VALIDATION_EDIT,
        class: "cl-button cl-button--white ms-font--montserrat ms-margin-top-10",
        click: function () {
            dialog.close();
        }
    }];
}

function disablePlaceOrderBtnOnClick() {
    $('.cl-submit-order__review-btn').one('click', function() {
        $(this).addClass('non-clickable');
    });
}
/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    address.init();
    if ($(".checkout-shipping").length > 0) {
        shipping.init();
    } else if ($(".checkout-multi-shipping").length > 0) {
        multiship.init();
    /* -- Start Amazon Pay Integration changes -- */
    } else if ($(".checkout-billing").length) {
    /* -- End Amazon Pay Integration changes -- */
        billing.init();
    }

    /* -- Start OSF One Page Checkout changes -- */
    opc.checkout.init();
    /* -- End OSF One Page Checkout changes -- */
    /* -- Start Amazon Pay Integration changes -- */
    applepay.init();
    amazonPayments.init();
    /* -- End Amazon Pay Integration changes -- */

    //if on the order review page and there are products that are not available diable the submit order button
    if ($(".order-summary-footer").length > 0) {
        if ($(".notavailable").length > 0) {
            $(".order-summary-footer .submit-order .button-fancy-large").attr("disabled", "disabled");
        }
    }

    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["phone", "phoneLandscape"],
            enter: function () {
                dialogCongig.position = {
                    my: "center top",
                    at: "center top",
                    of: window
                };
            }
        }, {
            breakpoint: ["tablet", "desktop", "desktopLarge"],
            enter: function () {
                dialogCongig.position = {
                    my: "center",
                    at: "center",
                    of: window
                };
            }
        }
    ]);

    //initialize order submitting events
    submitting.init();

    initCacheForm();
    orderSummaryAccordion();
    orderSummaryStickyBar();
    reviewEditDetails();
    showAdditionalFields();
    payCardType();
    updateShippingTotalPrice();
    updateTaxTotal();
    addNewShippingAddress();
    saveAddress();
    editShippingAddress();
    updateShippingAddress();
    removeShippingAddress();
    cancelShippingAddress();
    addNewBillingAddress();
    editBillingAddress();
    updateBillingAddress();
    removeBillingAddress();
    cancelBillingAddress();
    cancelShippingAddress();
    initActiveBoxEvent();
    initConfirmDeleteBoxEvent();
    initCancelDeleteBoxEvent();
    addNewCreditCard();
    saveNewCreditCard();
    editCreditCard();
    updateCreditCard();
    removeCreditCard();
    cancelCreditCard();
    setCCAsDefault();
    setCheckedStateForBoxes();

    initEventAppendCvnToCheckedCardBox();
    setActiveAddressBoxOnClickEvent();
    setActiveCreditCardBoxOnClickEvent();
    appendCvnToCheckedCardOnPageLoad();
    initAddressSuggestionDialogEvents();
    disablePlaceOrderBtnOnClick();
};
