

module.exports = {
    init: function () {
        $(".js-product-badge.ms-hide[data-new-date]").each(function () {
            var $that = $(this);
            var date = $that.data("newDate");

            if (new Date(date) > new Date()) {
                $that.removeClass("ms-hide");
            }
        });

        // Hide badges if user is logged in as PRO or animate if is Regular customer
        if ($(".js-pro-user").length) {
            $(".js-product-badge").addClass("ms-hide");
        } else {
            $(".js-product-badge").addClass("shown");
        }

        // Display Made To Order Label based on hidden input madeToOrder
        if ($("#madeToOrder").length) {
            if ($("#madeToOrder").val() == "true") {
                $(".pdpForm .made-to-order-label").removeClass("hide");
            }
        }
    }
};
