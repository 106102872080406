var addProductToCart = require("./product/addToCart"),
    page             = require("../page"),
    util             = require("../util"),
    quickview        = require("../quickview"),
    progress         = require("../progress"),
    dialog           = require("../dialog");

const dialogCongig = {
    autoOpen    : false,
    draggable   : false,
    modal       : true,
    resizable   : false,
    width       : "none",
    position    : {
        my: "center",
        at: "center",
        of: window
    },
    dialogClass : "cl-actions-dialog cl-actions-dialog--large",
    classes: {
        "ui-dialog"                : "cl-actions-dialog cl-actions-dialog--large",
        "ui-dialog-title"          : "cl-actions-dialog__title",
        "ui-dialog-titlebar"       : "cl-actions-dialog__titlebar",
        "ui-dialog-titlebar-close" : "cl-actions-dialog__close",
        "ui-dialog-content"        : "cl-actions-dialog__content"
    },
    open: function () {
        //will set dialog title
        var dialogTitle = $(this).closest(".ui-dialog")
            .find(".ui-dialog-name")
            .attr("data-name");

        $(this).closest(".ui-dialog")
            .find(".ui-dialog-title")
            .text(dialogTitle);

        // Add custom close button icon
        $(this).closest(".ui-dialog")
            .find(".ui-dialog-titlebar-close")
            .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

        // Close Dialog When Clicked Outside
        $(document).find(".ui-widget-overlay").bind("click", function () {
            dialog.close();
        });

        // Reset form fields
        if ($(".js-email-wishlist-form").length) {
            $(".js-email-wishlist-form")[0].reset();

            $("input[name$='wishlist_sendemail_recipientName']")
                .add("input[name$='wishlist_sendemail_recipientEmail']")
                .add("textarea[name$='wishlist_sendemail_message']").val("");
        }
    }
};

exports.init = function () {
    addProductToCart();
    $("#editAddress").on("change", function () {
        page.redirect(util.appendParamToURL(Urls.wishlistAddress, "AddressID", $(this).val()));
    });

    // add js logic to remove the , from the qty feild to pass regex expression on client side
    $(".option-quantity-desired input").on("focusout", function () {
        $(this).val($(this).val().replace(",", ""));
    });

    // listen click to create new list
    $(".js-create-new-list").on("click", function (e) {
        e.preventDefault();

        $(".js-create-new-list").fadeOut("fast", function () {
            $(".js-form-create").fadeIn("fast");
        });
    });

    $(".js-cancel").on("click", function (e) {
        e.preventDefault();

        $(".js-form-create").fadeOut("fast", function () {
            $(".js-create-new-list").fadeIn("fast");
        });
    });

    $(".js-edit-button").add($(".js-done-button")).on("click", function (e) {
        e.preventDefault();
        $(".js-edit-field").add($(".js-done-button")).add($(".js-remove-button")).add($(".js-edit-button")).add($(".js-title")).toggleClass("ms-hide");
    });

    $(".js-done-button").click(function (e) {
        e.preventDefault();
        var $form   = $(document).find("#dwfrm_wishlist_edit");
        var url     = $form.attr("action");
        var options = {
            url  : util.appendParamToURL(url, "action", "editWishlistName"),
            data : $form.serialize(),
            type : "POST"
        };

        $.ajax(options).done(function (data) {
            if (typeof(data) !== "string") {
                if (data.success) { //NOSONAR
                    page.redirect(util.appendParamToURL(Urls.wishlistDetailsPage, "wishlistId", window.location.queryString.wishlistId));
                } else {
                    // TODO: needs to be changed
                    page.redirect(util.appendParamToURL(Urls.wishlistDetailsPage, "wishlistId", window.location.queryString.wishlistId));
                }
            }
        });
    });

    $(".js-email-list").on("click", function (e) {
        e.preventDefault();
        dialog.open({
            url: $(this).attr("href"),
            options: dialogCongig
        });
        $(document).find(".js-email-cart-error").hide();
        $(document).find(".js-email-wishlist-success").hide();
        $(document).find(".ui-dialog-buttonpane").hide();
    });

    $(document).on("click", ".js-send-email-button", function (e) {
        e.preventDefault();
        var errorDiv   = $(".js-email-cart-error"),
            successDiv = $(".js-email-wishlist-success"),
            emailRegX  = /^[\w.%+\-]+@[\w.\-]+\.[\w]{2,6}$/; //eslint-disable-line

        if ($("input[name$='dwfrm_wishlist_sendemail_senderName']").val().length === 0) {
            errorDiv.text(Resources.SENDER_EMPTY_NAME).show();
            return false;
        }

        if ($("input[name$='dwfrm_wishlist_sendemail_senderEmail']").val().length === 0 || !emailRegX.test($.trim($("input[name$='dwfrm_wishlist_sendemail_senderEmail']").val()))) {
            errorDiv.text(Resources.SENDER_EMPTY_EMAIL).show();
            return false;
        }

        if ($("input[name$='dwfrm_wishlist_sendemail_recipientName']").val().length === 0) {
            errorDiv.text(Resources.RECIPIENT_EMPTY_NAME).show();
            return false;
        }

        if ($("input[name$='dwfrm_wishlist_sendemail_recipientEmail']").val().length === 0 || !emailRegX.test($.trim($("input[name$='dwfrm_wishlist_sendemail_recipientEmail']").val()))) {
            errorDiv.text(Resources.RECIPIENT_EMPTY_EMAIL).show();
            return false;
        }

        var $form = $(this).closest("form");
        var data  = $form.serialize();
        if ($form.valid()) {
            $.ajax({
                type : "POST",
                url  : $form.attr("action"),
                data : data
            }).done(function (response) {
                if (response && response.success) {
                    errorDiv.hide();
                    successDiv.show();
                    setTimeout(function () {
                        dialog.close();
                    }, 2000);
                } else {
                    errorDiv.text(Resources.VALIDATE_CONTENT_POPUP).show();
                }
            });
        }
    });

    // Hide the success/error messages after input change
    $(document).on("input", ".js-email-wishlist-form input", function () {
        var $errorBlock = $(document).find(".js-email-cart-error"),
            $successBlock = $(document).find(".js-email-wishlist-success");

        if ($errorBlock.is(":visible")) {
            $errorBlock.hide();
        } else if ($successBlock.is(":visible")) {
            $successBlock.hide();
        }
    });

    $(document).on("change", ".sendemail input.required", function () {
        var emptyfield = $(".sendemail").find("input").filter(function () {
            return this.value === "";
        });
        if (emptyfield.length > 0) {
            $(".js-send-email-button").attr("disabled", "disabled");
        } else {
            $(".js-send-email-button").removeAttr("disabled");
        }
    });

    $(".js-remove-button").on("click", function (e) {
        e.preventDefault();
        $(".js-remove-button").blur();
        $(document).find(".ui-dialog-buttonpane").show();
        openConfirmDialog(Resources.CONFIRMMESSAGEWISHLIST, Resources.WISHLISTDELETE, null);
    });

    //product items
    $(".js-remove-product").on("click", function (e) {
        e.preventDefault();
        var itemId = $(this).attr("data-productid");
        $(document).find(".ui-dialog-buttonpane").show();
        openConfirmDialog(Resources.CONFIRMMESSAGEITEM, Resources.WISHLISTREMOVEITEM, itemId);
    });

    $(document).on("click", ".js-select-options", function (e) {
        e.preventDefault();
        var changedItemId = $(this).attr("data-firstpid").toString();
        window.localStorage.setItem("fpid", changedItemId);

        quickview.show({
            url: util.appendParamToURL(Urls.getProductUrl, "pid", changedItemId),
            source: "wishlist"
        });
    });

    $(document).on("click", ".js-update-wishlist-item", function (e) {
        e.preventDefault();
        var wishlistIdParam = window.location.queryString && window.location.queryString.wishlistId ? window.location.queryString.wishlistId : util.getParameterByName("wishlistId");
        var $form  = $(document).find(".pdpForm");
        var itemId = $form.find("#update-wishlist-item").attr("data-productid");
        var url    = Urls.addWishlistProduct;
        url    = util.appendParamToURL(url, "plid", wishlistIdParam);

        var changedItemId = window.localStorage.getItem("fpid");

        progress.show();

        return Promise.resolve($.ajax({
            type : "POST",
            url  : itemId ? util.appendParamToURL(url, "pid", itemId) : url,
            data : $form.serialize()
        })).then(function (response) {
            // handle error in the response
            if (response.error) {
                throw new Error(response.error);
            } else {
                if ((itemId != "null") && changedItemId && changedItemId != itemId) {
                    var url = util.appendParamToURL(Urls.removeWishlistProduct, "plid", wishlistIdParam); //NOSONAR
                    url = util.appendParamToURL(url, "pid", changedItemId);
                    window.localStorage.removeItem("fpid");
                    window.location = url;
                } else {
                    progress.hide();
                }
                return response;
            }
        });
    });

    function openConfirmDialog(title, buttonText, itemId) {
        dialog.open({
            html: "<h5>" + title + "</h1>",
            options: {
                draggable: false,
                modal: true,
                resizable: false,
                width: "90%",
                position: {
                    my: "center",
                    at: "center",
                    of: window
                },
                classes: {
                    "ui-dialog": "cl-actions-dialog cl-actions-dialog--zip",
                    "ui-dialog-title": "cl-actions-dialog__title",
                    "ui-dialog-titlebar": "cl-actions-dialog__titlebar ui-dialog-titlebar--show-close-button",
                    "ui-dialog-titlebar-close": "cl-actions-dialog__close js-cl-actions-dialog__close",
                    "ui-dialog-content": "cl-actions-dialog__content ms-font--center"
                },
                buttons: [{
                    text: buttonText,
                    class: "cl-button cl-button--alternate cl-button--icon ms-font--montserrat ms-margin-top-10",
                    click: function () {
                        var url;
                        var wishlistIdParam = window.location.queryString && window.location.queryString.wishlistId ? window.location.queryString.wishlistId : util.getParameterByName("wishlistId");
                        if (buttonText == "Delete") {
                            url = util.appendParamToURL(Urls.removeWishlist, "plid", wishlistIdParam);
                            window.location = url;
                        } else {
                            url = util.appendParamToURL(Urls.removeWishlistProduct, "plid", wishlistIdParam);
                            url = util.appendParamToURL(url, "pid", itemId);
                            window.location = url;
                        }
                    }
                }, {
                    text: Resources.CANCEL,
                    class: "cl-button cl-button--white ms-font--montserrat",
                    click: function () {
                        dialog.close();
                    }
                }],
                open: function () {
                    $(this).next().find(".ui-selectmenu-icon").html("<svg class=\"cl-svg-icon cl-sorting-bar__svg-icon\"><use xlink:href=\"#drop-down\"></use></svg>");
                    $(this).next().find("button").blur();
                }
            }
        });
    }
};
