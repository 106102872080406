'use strict';

/**
 * @private
 * @function
 * @description Function to initialize Activity Landing Page Menu
 */
function landingPageRefinements() {
    // Initialize filters cutoff display threshold
    if ($('.js-activity-refinements').length > 0) {
        $('.js-activity-refinements').find('.js-scrollable-toggle').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('cutoff');
            $(this).closest('.js-scrollable').find('.js-scrollable-hide').toggleClass('ms-hide');
        });
    }

    // Initialize search for brands
    if ($(document).find('.js-search-brand').length && $(document).find('.js-search-brands-container').length) {
        var $container = $(document).find('.js-search-brands-container'),
            $search    = $(document).find('.js-search-brand'),
            $therms    = $container.find('[data-refname]'),
            $refToggle = $container.find('.js-scrollable-toggle');

        $search.on('keyup', function () {
            var search = $(this).val().toLowerCase().trim();

            if (search.length > 2) {
                $therms.hide();

                if (!$refToggle.hasClass('cutoff')) {
                    $refToggle.trigger('click').hide();
                } else {
                    $refToggle.hide();
                }

                $therms.each(function (index, el) {
                    if ($(el).attr('data-refname').toLowerCase().trim().includes(search)) {
                        $(el).show();
                    }
                });
            } else if (search.length <= 2 || search == '') {
                $therms.show();

                if ($refToggle.hasClass('cutoff')) {
                    $refToggle.trigger('click').show();
                }
            }
        });
    }
}

exports.init = landingPageRefinements;