function setupClydePDPWatcher() {
    if (Clyde.checkInit()) {
        $(document).on('clyde:setActiveProduct', function () {
            var el = $('#clyde_div');
            if (el) {
                var sku = el.data('sku');
                var protectionContractEligible = $('input.protectionContractEligible').val();
                if (protectionContractEligible && protectionContractEligible == 'true') {
                    Clyde.setActiveProduct(sku, function () {
                        var type = SitePreferences.CLYDE_PDP_DISPLAY_TYPE || 'select';
                        Clyde.appendToSelector('#product-content #clyde_div', type, false);
                    });
                }
            }
        });
    }
}

function initializeClydePDPEvents() {
    var $productPageSku = $('.product-number > span[itemprop="productID"]').first().text();
    var protectionContractEligible = $('input.protectionContractEligible').val();

    if (SitePreferences.IS_CLYDE_ENABLED) {
        if (typeof Clyde !== 'undefined' && !Clyde.checkInit()) {
            Clyde.init({
                key: SitePreferences.CLYDE_KEY,
                type: SitePreferences.CLYDE_PDP_DISPLAY_TYPE || 'select',
                defaultSelector: '#product-content #clyde_div',
                skipGeoIp: !SitePreferences.IS_CLYDE_GEOIP_ENABLED
            }, function() {
                // Set the active product.
                if (protectionContractEligible && protectionContractEligible == 'true') {
                    Clyde.setActiveProduct($productPageSku);
                }
                // Event to reconfigure Clyde with a new SKU
                setupClydePDPWatcher();
            });
        }
    }
}

function refreshPDP() {
    $(document).trigger('clyde:setActiveProduct');
}

function initializeClydeCartWatchers() {
    if (Clyde.checkInit()) {
        $('#cl-cart-table span.clyde_div').each(function() {
            var el = $(this)
            var $sku = el.data('sku');
            var $uuid = el.data('uuid');
            var $addToCartUrl = el.data('url');

            if ($sku && $uuid && $addToCartUrl) {
                Clyde.appendToSelectorWithSku($sku, '#cl-cart-table span.clyde_div[data-uuid="' + $uuid + '"]', function () {
                    var activeProduct = Clyde.getActiveProduct();
                    var selectedContract = Clyde.getSelectedContract();

                    if (selectedContract && selectedContract.sku && activeProduct.sku === $sku) {
                        $.ajax({
                            url: $addToCartUrl,
                            method: 'POST',
                            data: {
                                pid: activeProduct.sku,
                                uuid: $uuid,
                                Quantity: 0,
                                clydePID: selectedContract.sku
                            },
                            success: function () {
                                $(".js-item-quantity-input").trigger("change");
                            }
                        });
                    }
                });
            }
        });
    }
}

function initializeClydeCartEvents() {
    if (SitePreferences.IS_CLYDE_ENABLED) {
        if (typeof Clyde !== 'undefined' && !Clyde.checkInit()) {
            Clyde.init({
                key: SitePreferences.CLYDE_KEY,
                type: 'select',
                skipGeoIp: !SitePreferences.IS_CLYDE_GEOIP_ENABLED
            }, function() {
                $(document).on('clyde:appendToSelectorWithSku', function () {
                    initializeClydeCartWatchers();
                })
                // Trigger event for initial page load.
                $(document).trigger('clyde:appendToSelectorWithSku');
            });
        }
    }
}

function refreshCart() {
    $(document).trigger("clyde:appendToSelectorWithSku");
}

function initializeAPI(){
    if (SitePreferences.IS_CLYDE_ENABLED) {
        if (typeof Clyde !== 'undefined' && !Clyde.checkInit()) {
            Clyde.init({
                key: SitePreferences.CLYDE_KEY,
                type: 'select',
                skipGeoIp: !SitePreferences.IS_CLYDE_GEOIP_ENABLED
            }, function() {});
        }
    }
}

var clyde = {
    initPDP: initializeClydePDPEvents,
    refreshPDP: refreshPDP,
    initCart: initializeClydeCartEvents,
    refreshCart: refreshCart,
    initializeAPI: initializeAPI
};

module.exports = clyde;
