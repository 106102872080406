
/**
 * @function Enables conditional flow control on order submission
 */
exports.init = function () {

    var isOPCEnabled = ($(".onepagecheckout").length > 0);

    //Verifies if Affirm was selected as payment method. Prepare flow
    if (!isOPCEnabled && $("#affirmData").length > 0) {
        var checkout = $("#affirmData").data("checkout"),
            csrf     = $("input[name='csrf_token']").val();

        //incorporate CSRF token
        checkout.merchant.user_confirmation_url += "?csrf_token=" + csrf;
        //init checkout
        affirm.checkout(checkout);

        $("form.submit-order").find("button").click(function (e) {
            e.preventDefault();
            affirm.checkout.open();
        });
    }
};
