var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/; //eslint-disable-line
var regex = {
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$/, //eslint-disable-line
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/ //eslint-disable-line
    },
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/
    },
    stateValidation: {
        us: function (zip) {
            var state;
            if (zip >= "00601" && zip <= "00987") {
                state = "PR";
            } else if (zip >= "01001" && zip <= "02791") {
                state = "MA";
            } else if (zip >= "02802" && zip <= "02921") {
                state = "RI";
            } else if (zip >= "03031" && zip <= "03894") {
                state = "NH";
            } else if (zip >= "03901" && zip <= "04992") {
                state = "ME";
            } else if (zip >= "05001" && zip <= "05907") {
                state = "VT";
            } else if (zip >= "06001" && zip <= "06907") {
                state = "CT";
            } else if (zip >= "07001" && zip <= "08904") {
                state = "NJ";
            } else if (zip >= "10001" && zip <= "14905") {
                state = "NY";
            } else if (zip >= "15001" && zip <= "19611") {
                state = "PA";
            } else if (zip >= "19701" && zip <= "19979") {
                state = "DE";
            } else if (zip >= "20001" && zip <= "20064") {
                state = "DC";
            } else if (zip >= "20105" && zip <= "20198") {
                state = "VA";
            } else if (zip >= "20202" && zip <= "20593") {
                state = "DC";
            } else if (zip >= "20601" && zip <= "21930") {
                state = "MD";
            } else if (zip >= "22003" && zip <= "24657") {
                state = "VA";
            } else if (zip >= "24701" && zip <= "26884") {
                state = "WV";
            } else if (zip >= "27006" && zip <= "28909") {
                state = "NC";
            } else if (zip >= "29001" && zip <= "29945") {
                state = "SC";
            } else if (zip >= "30002" && zip <= "31909") {
                state = "GA";
            } else if (zip >= "32003" && zip <= "34997") {
                state = "FL";
            } else if (zip >= "35004" && zip <= "36925") {
                state = "AL";
            } else if (zip >= "37010" && zip <= "38589") {
                state = "TN";
            } else if (zip >= "38601" && zip <= "39776") {
                state = "MS";
            } else if (zip >= "39813" && zip <= "39897") {
                state = "GA";
            } else if (zip >= "40003" && zip <= "42788") {
                state = "KY";
            } else if (zip >= "43001" && zip <= "45899") {
                state = "OH";
            } else if (zip >= "46001" && zip <= "47997") {
                state = "IN";
            } else if (zip >= "48001" && zip <= "49971") {
                state = "MI";
            } else if (zip >= "50001" && zip <= "52807") {
                state = "IA";
            } else if (zip >= "53001" && zip <= "54986") {
                state = "WI";
            } else if (zip >= "55001" && zip <= "56763") {
                state = "MN";
            } else if (zip >= "57001" && zip <= "57799") {
                state = "SD";
            } else if (zip >= "58001" && zip <= "58856") {
                state = "ND";
            } else if (zip >= "59001" && zip <= "59937") {
                state = "MT";
            } else if (zip >= "60002" && zip <= "62999") {
                state = "IL";
            } else if (zip >= "63005" && zip <= "65810") {
                state = "MO";
            } else if (zip >= "66002" && zip <= "67954") {
                state = "KS";
            } else if (zip >= "68001" && zip <= "69367") {
                state = "NE";
            } else if (zip >= "70001" && zip <= "71486") {
                state = "LA";
            } else if (zip >= "71601" && zip <= "72959") {
                state = "AR";
            } else if (zip >= "73002" && zip <= "74966") {
                state = "OK";
            } else if (zip >= "75001" && zip <= "79942") {
                state = "TX";
            } else if (zip >= "80002" && zip <= "81657") {
                state = "CO";
            } else if (zip >= "82001" && zip <= "83128") {
                state = "WY";
            } else if (zip == "83414") {
                state = "WY";
            } else if (zip >= "83201" && zip <= "83876") {
                state = "ID";
            } else if (zip >= "84001" && zip <= "84790") {
                state = "UT";
            } else if (zip >= "85003" && zip <= "86556") {
                state = "AZ";
            } else if (zip >= "87001" && zip <= "88436") {
                state = "NM";
            } else if (zip >= "89001" && zip <= "89883") {
                state = "NV";
            } else if (zip >= "90001" && zip <= "96161") {
                state = "CA";
            } else if (zip >= "96701" && zip <= "96863") {
                state = "HI";
            } else if (zip >= "97001" && zip <= "97920") {
                state = "OR";
            } else if (zip >= "98001" && zip <= "99403") {
                state = "WA";
            } else if (zip >= "99501" && zip <= "99929") {
                state = "AK";
            }
            return state;
        },
        ca: function (zip) {
            var state;
            if (zip[0] == "A") {
                state = "NL";
            } else if (zip[0] == "B") {
                state = "NS";
            } else if (zip[0] == "C") {
                state = "PE";
            } else if (zip[0] == "E") {
                state = "NB";
            } else if (zip[0] == "G" || zip[0] == "H" || zip[0] == "J") {
                state = "QC";
            } else if (zip[0] == "K" || zip[0] == "L" || zip[0] == "M" || zip[0] == "N" || zip[0] == "P") {
                state = "ON";
            } else if (zip[0] == "R") {
                state = "MB";
            } else if (zip[0] == "S") {
                state = "SK";
            } else if (zip[0] == "T") {
                state = "AB";
            } else if (zip[0] == "V") {
                state = "BC";
            } else if (zip[0] == "X") {
                state = "NU/NT";
            } else if (zip[0] == "Y") {
                state = "YT";
            }
            return state;
        }
    },

    password: /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]{8,}$/, //eslint-disable-line
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    maskcvn: /^[0-9|*]{3,4}$/,
    cvn: /^[0-9]{3,4}$/,
    labels: /^([1-9][0-9]*)$/
};

// global form validator settings
var settings = {
    errorClass: "error",
    errorElement: "span",
    errorPlacement: function (error, element) {
        var parent = element.parent().parent();
        if (parent.hasClass("cl-account_profile-box__input--edit") || parent.hasClass("js-trade-professionals-form") || parent.hasClass("js-become-member-form")) {
            error.prependTo(element.parent());
        } else if ($("form.onepagecheckout.address").length && parent.hasClass("month") || parent.hasClass("year")) {
            // checkout credit card expiration
            error.prependTo(parent.parent());
        } else if (parent.hasClass("js-email-share-product")) {
            error.appendTo(element.parent());
        } else {
            error.prependTo(parent);
        }
    },
    success: function (label) {
        label.remove();
    },
    onfocusout: function (element) {
        setTimeout(() => {
            if (!this.checkable(element)) {
                this.element(element);
            }
        }, 200);
    },
    messages: {
        city: {
            required: Resources.INVALID_CITY_INPUT
        },
        password: {
            required: Resources.INVALID_PASSWORD
        },
        phone: {
            required: Resources.INVALID_PHONE_NUMBER
        },
        required: Resources.VALIDATE_REQUIRED,
        remote: Resources.VALIDATE_REMOTE,
        email: Resources.VALIDATE_EMAIL,
        url: Resources.VALIDATE_URL,
        date: Resources.VALIDATE_DATE,
        dateISO: Resources.VALIDATE_DATEISO,
        number: Resources.VALIDATE_NUMBER,
        digits: Resources.VALIDATE_DIGITS,
        creditcard: Resources.VALIDATE_CREDITCARD,
        equalTo: Resources.VALIDATE_EQUALTO,
        maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
        minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
        rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
        range: $.validator.format(Resources.VALIDATE_RANGE),
        max: $.validator.format(Resources.VALIDATE_MAX),
        min: $.validator.format(Resources.VALIDATE_MIN)
    }
};

/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
    var country = $(el).parents("form").find(".country");
    if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.phone[country.val().toLowerCase()],
        isOptional = this.optional(el),
        isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates password
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validatePassword = function (value) {
    var isValid = regex.password.test($.trim(value));
    return isValid;
};


/**
 * @function
 * @description Validates Confirm Password
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateConfirmPassword = function (value) {
    var isValid = $.trim(value) == $.trim($(".password-validation").val());
    return isValid;
};

/**
 * @function
 * @description Validates Confirm Email
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateConfirmEmail = function (value) {
    var isValid;
    if ($(".email-validation").is("[data-regemail]")) {
        isValid = $.trim($(".email-confirm-validation[data-regemail]").val()) == $.trim($(".email-validation[data-regemail]").val());
    } else {
        isValid = $.trim(value) == $.trim($(".email-validation").val());
    }
    return isValid;
};

/**
 * @function
 * @description Validates a given postal code number against the countries postal regex and state mapping
 * @param {String} value The postal code which will be validated
 * @param {String} el The input field
 */
var validatePostal = function (value, el) {
    var country = $(el).parents("fieldset").find(".country");

    if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()] || value.length < 5 || value.length > 10) {
        return true;
    }

    $(document).off("click", "#skipPostalCodeValidation").on("click", "#skipPostalCodeValidation", function (e) {
        e.preventDefault();
        if (e.handled !== true) {
            var skipPostalCodeValidationShipping = $("#dwfrm_singleshipping_shippingAddress_addressFields_skipPostalCodeValidation"); //NOSONAR
            var skipPostalCodeValidationBilling = $("#dwfrm_billing_billingAddress_addressFields_skipPostalCodeValidation"); //NOSONAR

            if (skipPostalCodeValidationShipping.is(":checked")) {
                skipPostalCodeValidationShipping.prop("checked", false);
            } else {
                skipPostalCodeValidationShipping.prop("checked", true);
            }

            if (skipPostalCodeValidationBilling.is(":checked")) {
                skipPostalCodeValidationBilling.prop("checked", false);
            } else {
                skipPostalCodeValidationBilling.prop("checked", true);
            }

            $("input.postal.required").keyup();
            e.handled = true;
        }
    });

    // if value is valid us or canada postal code then update country select
    $("input.postal.required").on("keyup", function () {
        if ($(this).val().length >= 5) {
            if (regex.postal["ca"].test($.trim($(this).val().toUpperCase()))) { //eslint-disable-line
                country.find("option[value='CA']").prop("selected", true);
            } else if (regex.postal["us"].test($.trim($(this).val()))) { //eslint-disable-line
                country.find("option[value='US']").prop("selected", true);
            }
        }
    });

    var state = $(el).parents("fieldset").find("*[id*=state]");
    if (state.length === 0 || state.val().length === 0) {
        return true;
    }

    // trigger postal validation
    state.on("change", function () {
        if ($(el).val() !== "") {
            $(el).trigger("focusout");
        }
    });

    // trigger postal validation
    country.on("change", function () {
        if ($(el).val() !== "") {
            $(el).trigger("focusout");
        }
    });

    var rgx = regex.postal[country.val().toLowerCase()],
        isOptional = this.optional(el),
        isValid = rgx.test($.trim(value));

    var skipPostalCodeValidationShipping = $("#dwfrm_singleshipping_shippingAddress_addressFields_skipPostalCodeValidation");
    var skipPostalCodeValidationBilling = $("#dwfrm_billing_billingAddress_addressFields_skipPostalCodeValidation");

    if (skipPostalCodeValidationShipping.length > 0 && !skipPostalCodeValidationShipping.is(":checked")) {
        isValid = isValid && regex.stateValidation[country.val().toLowerCase()](value).indexOf(state.val()) != -1;
    }

    if (skipPostalCodeValidationBilling.length > 0 && !skipPostalCodeValidationBilling.is(":checked")) {
        isValid = isValid && regex.stateValidation[country.val().toLowerCase()](value).indexOf(state.val()) != -1;
    }

    return isOptional || isValid;
};

/**
 * @function
 * @description Validates an email address
 * @param {String} value The email field which will be validated
 */
var validateEmail = function (value) {
    if (value.length === 0) {
        return true;
    }
    var isValid = regex.email.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates Masked CVV Code input field
 * @param {String} value The cvv field which will be validated
 */
var validateMaskCvn = function (value) {
    if (value.length === 0) {
        return true;
    }
    var isValid = regex.maskcvn.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates CVV Code input field
 * @param {String} value The cvv field which will be validated
 */
var validateCvn = function (value) {
    if (value.length === 0) {
        return true;
    }

    // checkout form
    if ($("form.onepagecheckout.address").length) {
        var $checkoutForm = $("form.onepagecheckout.address"),
            cardLength = $checkoutForm.find(".js-cvn-field").val().length,
            cardType = $checkoutForm.find(".js-payment-card-type").val(),
            isCVNMatchCardType = false;

        if (cardLength < 3) {
            return false;
        }

        if (cardType !== "Amex" && cardLength === 3) {
            isCVNMatchCardType = true;
        }

        if (cardType === "Amex" && cardLength === 4) {
            isCVNMatchCardType = true;
        }
        return regex.cvn.test($.trim(value)) && isCVNMatchCardType;
    } else {

        return regex.cvn.test($.trim(value));
    }
};

/**
 * @function
 * @private
 * @description gets credit card expiration date value on checkout form
 * @return {object} ccExpirationInfo
 */
function getccExpirationInfo() {
    var $paymentSection = {};
    var $onepagecheckoutCC = $("form.onepagecheckout.address");
    var $userprofileCC = $("form.credit-card-form");

    if ($onepagecheckoutCC.length) {
        $paymentSection = $onepagecheckoutCC;
    } else if ($userprofileCC.length) {
        $paymentSection = $userprofileCC;
    }

    if ($paymentSection.length) {
        return {
            ccMonthElement: $paymentSection.find("select[name$='_month']"),
            ccMonthValue: $paymentSection.find("select[name$='_month']").val(),
            ccYearElement: $paymentSection.find("select[name$='_year']"),
            ccYearValue: $paymentSection.find("select[name$='_year']").val()
        };
    }
}

/**
 * @function
 * @description validates credit card expiration
 * @param {String} value The credit card expiration field which will be validated
 */
var validateCreditCardExpiration = function () {
    // checkout form
    if ($("form.onepagecheckout.address").length || $("form.credit-card-form").length) {
        var ccExpirationInfo = getccExpirationInfo();

        ccExpirationInfo.ccMonthElement.off(".validation").on("change.validation", function () {
            var ccExpirationInfo = getccExpirationInfo(); //NOSONAR
            if (ccExpirationInfo.ccYearValue !== "") {
                ccExpirationInfo.ccYearElement.trigger("focusout");
            }

            if ($.payform.validateCardExpiry(ccExpirationInfo.ccMonthValue, ccExpirationInfo.ccYearValue)) {
                return true;
            }
        });

        if ($.payform.validateCardExpiry(ccExpirationInfo.ccMonthValue, ccExpirationInfo.ccYearValue)) {
            return true;
        }

        return false;
    }
};

/**
 * Add credit card expiration validation method to jQuery validation plugin.
 * Text fields must have 'validateCCExpiration' css class to be validated as credit card expiration
 */
$.validator.addMethod("validateCCExpiration", validateCreditCardExpiration, Resources.VALIDATE_DATE);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod("phone", validatePhone, Resources.INVALID_PHONE);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod("owner", validateOwner, Resources.INVALID_OWNER);

/**
 * Add password validation method to jQuery validation plugin.
 * Text fields must have 'current-password-validation' css class to be validated as a password
 */
$.validator.addMethod("current-password-validation", validatePassword, Resources.INVALID_PASSWORD);

/**
 * Add phone number method to jQuery validation plugin.
 * Text fields must have 'current-phone-validation' css class to be validated as a phone number
 */
$.validator.addMethod("current-phone-validation", validatePhone, Resources.INVALID_PHONE_NUMBER);

/**
 * Add password validation method to jQuery validation plugin.
 * Text fields must have 'password-validation' css class to be validated as a password
 */
$.validator.addMethod("password-validation", validatePassword, Resources.INVALID_PASSWORD);

/**
 * Add password validation method to jQuery validation plugin.
 * Text fields must have 'password-confirm-validation' css class to be validated as a Confirm Password
 */
$.validator.addMethod("password-confirm-validation", validateConfirmPassword, Resources.INVALID_CONFIRM_PASSWORD);

/**
 * Add current password validation method to jQuery validation plugin.
 * Text fields must have 'password-current-validation' css class to be validated as a Current Validation Password
 */
$.validator.addMethod("password-current-validation", validateConfirmPassword, Resources.INVALID_CURRENT_PASSWORD);

/**
 * Add email validation method to jQuery validation plugin.
 * Text fields must have 'email-validation' css class to be validated
 */
$.validator.addMethod("email-validation", validateEmail, Resources.VALIDATE_EMAIL);

/**
 * Add email validation method to jQuery validation plugin.
 * Text fields must have 'email-confirm-validation' css class to be validated as a Confirm Email
 */
$.validator.addMethod("email-confirm-validation", validateConfirmEmail, Resources.INVALID_CONFIRM_EMAIL);

/**
 * Add Postal code validation method to jQuery validation plugin.
 */
$.validator.addMethod("postal", validatePostal, Resources.INVALID_POSTAL_CODE);

/**
 * Add Masked CVV validation method to jQuery validation plugin.
 */
$.validator.addMethod("masked-cvn", validateMaskCvn, Resources.VALIDATE_CVN);

/**
 * Add CVV validation method to jQuery validation plugin.
 */
$.validator.addMethod("cvn-field", validateCvn, Resources.VALIDATE_CVN);

/**
 * Add email validation method to jQuery validation plugin.
 * Text fields must have 'email' css class to be validated
 */
$.validator.addMethod("email", validateEmail, Resources.VALIDATE_EMAIL);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod("gift-cert-amount", function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod("positivenumber", function (value) {
    if ($.trim(value).length === 0) {
        return true;
    }
    return (!isNaN(value) && Number(value) >= 0);
}, ""); // '' should be replaced with error message if needed

/**
 * Add labels validation method to jQuery validation plugin.
 * Text fields must have 'labels-validation' css class to be validated a positive number more than 0
 */
$.validator.addMethod("labels-validation", function (value) {
    if ($.trim(value).length === 0) {
        return true;
    }
    return Number(value) && regex.labels.test($.trim(value));
}, Resources.VALIDATE_LABELS);

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $("form:not(.suppress)").each(function () {
            $(this).validate(self.settings);
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

module.exports = validator;
