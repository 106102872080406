'use strict';
//this file keep only js(push action, hash creation) functionality

exports.gtmInit = function () {
    var    exeption,
        getUri = function (o) {
            var a;
            if (o && o.tagName && $(o).attr('href')) {
                a = o;
            } else if (typeof o === 'string') {
                a = document.createElement('a');
                a.href = o;
            } else {
                return null;
            }
            return {
                protocol: a.protocol, //http:
                host: a.host, //www.myexample.com
                hostname: a.hostname, //www.myexample.com'
                port: a.port, //:80
                path: a.pathname, // /sub1/sub2
                query: a.search, // ?param1=val1&param2=val2
                queryParams: a.search.length > 1 ? this.getQueryStringParams(a.search.substr(1)) : {},
                hash: a.hash, // #OU812,5150
                url: a.protocol + '//' + a.host + a.pathname,
                urlWithQuery: a.protocol + '//' + a.host + a.port + a.pathname + a.search
            };
        },
        appendParamsToUrl = function (url, params) {
            var uri = getUri(url),
                includeHash = arguments.length < 3 ? false : arguments[2],
                qsParams = $.extend(uri.queryParams, params),
                result = uri.path + '?' + $.param(qsParams);

            if (includeHash) {
                result += uri.hash;
            }
            if (result.indexOf('http') < 0 && result.charAt(0) !== '/') {
                result = '/' + result;
            }
            return result;
        };

    window.Controller = {
        //function to push data to the dataLayer, and add hash
        hashPush : function(data){
            if (data){
                if ((location.hash.indexOf(data.event + 'gtm') == -1)) {
                    dataLayer.push(data);
                }
            }
        },
        //check data type and parse string into JSON
        dataPush: function(Data) {
            if((typeof(Data) === 'string') && (Data != "")){
                if((Data != null) || (Data != 'null')){
                    //Parse JSON and remove all excessive symbols
                    Data = Data.replace(/&reg;/gi, '');
                    Data = JSON.parse(Data.replace(/&quot;/gi, '"'));
                    //set hash - for prevent tag fire when user just reload the page
                    Controller.hashPush(Data)
                }
            } else {
                Controller.hashPush(Data)
            }
        },
        // Enable add to cart tracking
        addToCart: function( data ) {
            Controller.addToCartEnabled = data.enabled;
        },
        // Fire add to cart tracking event
        fireAddToCartEvent: function ($form) {
            if (!Controller.addToCartEnabled) {
                return;
            }

            var ajURL = appendParamsToUrl( Urls.addToCart, {
                "pid": $form.pid,
                "Quantity": $form.Quantity || 1,
            });

            $.getJSON(ajURL, function(data) {
                if (dataLayer) {
                    dataLayer.push(data);
                }
            });
        },
        removeFromCart: function( data ){
            $(document).on("click", ".item-user-actions button", function removeFromCartGAHandler(e){
                var $self = $(this),
                    pid = $self.parent().data("productid"),
                    qty = Number($self.parents(".cart-row").find("input[name$='_quantity']").val());

                var params = {
                        "pid": pid,
                        "Quantity": qty
                    };

                var ajURL = appendParamsToUrl(Urls.removeFromCart, params);
                $.getJSON(ajURL, function(data) {
                    if (dataLayer) {
                        dataLayer.push(data);
                    }
                });
            });
            // on quantity decrease
            $('body').on('custom:itemQtyDown', function(e, params) {
                var ajURL = appendParamsToUrl(Urls.removeFromCart, params);
                $.getJSON(ajURL, function(data) {
                    if (dataLayer) {
                        dataLayer.push(data);
                    }
                });
            });
            // on quantity increase
            $('body').on('custom:itemQtyUp', function(e, params) {
                var ajURL = appendParamsToUrl(Urls.addToCart, params);
                $.getJSON(ajURL, function(data) {
                    if (dataLayer) {
                        dataLayer.push(data);
                    }
                });
            });
        },
        checkout: function(step) {
            var dataLayer = $('.cl-onepagecheckout').data('gtmcheckout');

            if (!dataLayer) {
                return '';
            }

            dataLayer.ecommerce.checkout.actionField = {step: step};
           
            return dataLayer;
        },
        fireCheckoutEvent: function(step) {
            var ajURL = appendParamsToUrl( Urls.checkoutWithStep, {
                "step": step
            });

            $.getJSON(ajURL, function(data) {
                if (dataLayer) {
                    dataLayer.push(data);
                }
            });

            // Controller.dataPush(Controller.checkout(step));
        },
        //run different function from controller based on 'model'
        init: function(obj) {
            if (obj.length){
                exeption = obj;
            }
            if (obj.enabled) {
                switch (obj.event) {
                    case 'addToCart':
                        Controller.addToCart(obj);
                        break;
                    case 'removeFromCart':
                        Controller.removeFromCart(obj);
                        break;
                    case 'backInStockPDP':
                        Controller.backInStockNotify(obj);
                        break;
                    case 'ecomListClick':
                        Controller.plpProductClickFire(obj);
                        break;
                    default:
                        if (obj.condition) {
                            Controller.dataPush(obj.functionToCall);
                        }
                }
            }
        },
        checkState: function(el, ns) {
            if (ns) {
                return ($(el).length > 0) && (window.pageContext.ns == ns);
            } else {
                return $(el).length > 0;
            }

        },
        plpProductClickFire: function(obj) {
            $(document).on('click', '.product-tile-container div.product-tile', function() {
                var $self = $(this);
                var $elementHolder = $self.parents('.product-tile-container');
                var gtmData = $elementHolder.data('gtag');
                var $pid = $self.find('.product-tile__wrapper').data('itemid');
                
                if( gtmData && $pid ) {
                    var index = $('.product-tile-container div.product-tile').index($self);
                    var ajURL = appendParamsToUrl( Urls.productSelectedTile, {
                        "pid": $pid,
                        "listname": gtmData.item_list_name,
                        "index": index,
                    });
                    $.getJSON(ajURL, function(data) {
                        if (dataLayer) {
                            dataLayer.push(data);
                        }
                    });
                }
            });
            $(document).on('click', '.js-einstein-recommender div.product-tile', function() {
                var $self = $(this);
                var $parent = $self.parents('.js-einstein-recommender');
                if($parent){
                    var index = $('.js-einstein-recommender div.product-tile').index($self);
                    var $title = $parent.find('.product-recommendations-slider__title').text();
                    var $pid = $self.find('.product-tile__wrapper').data('itemid');

                    if($pid){
                        var ajURL = appendParamsToUrl( Urls.productSelectedTile, {
                            "pid": $pid,
                            "listname": $title,
                            "index": index,
                        });
            
                        $.getJSON(ajURL, function(data) {
                            if (dataLayer) {
                                dataLayer.push(data);
                            }
                        });
                    }
                }
            });
        },
        // Fire add to cart tracking event
        fireAddToWishlistEvent: function (pid) {
            var listname = '';
            // need to add listname and id so, will have to pass it from 'gtag' prepared for tiles
            $('[data-gtag]').each((index, element) => {
                const gtag = $(element).data('gtag');
                if (gtag.item_id == pid) {
                    listname = gtag.item_list_name;
                    return false;
                }
            });
            var ajURL = appendParamsToUrl( Urls.addToWishlist, {
                "pid": pid,
                "listname": listname
            });

            $.getJSON(ajURL, function(data) {
                if (dataLayer) {
                    dataLayer.push(data);
                }
            });
        },
    };
};
