'use strict';

/**
 * @private
 * @function
 * @description Function to initialize Google Map
 * @todo Include Google Maps API and initialize the map
 */
function initGoogleMap() {
    if ($('.js-store-banner-map').length) {

        var $mapWrapper = $('.js-store-banner-map'),
            mapLng = $mapWrapper.attr('data-lng'),
            mapLat = $mapWrapper.attr('data-lat'),
            mapPosition = {
                lat: Number(mapLat),
                lng: Number(mapLng)
            };
    
        var map = new google.maps.Map(document.getElementById('storelocatorMap'), {
            zoom: 16,
            center: mapPosition
        });

        var marker = new google.maps.Marker({
            position: mapPosition,
            map: map
        });
    }
}

/**
 * @private
 * @function
 * @description Function to show/hide storelocator map
 */
function toggleStorelocatorMap() {
    if ($('.js-store-banner-show-map').length) {

        var $mapContainer   = $('.js-store-banner-map'),
            $mapToggler     = $('.js-store-banner-show-map'),
            $mapTogglerText = $mapToggler.find('.js-store-banner-show-map-text'),
            showText        = $mapTogglerText.text(),
            hideText        = $mapTogglerText.attr('data-hide-text'),
            mapHideClass    = 'ms-hide',
            activeMap       = false;
        
        $mapToggler.on('click touchstart', (e) => {
            e.preventDefault();
            $mapContainer.toggleClass(mapHideClass);

            if (!activeMap) {
                $mapTogglerText.text(hideText);
                activeMap = true;
            } else {
                $mapTogglerText.text(showText);
                activeMap = false;
            }
        });
    }
}

exports.init = function () {
    initGoogleMap();
    toggleStorelocatorMap();
};