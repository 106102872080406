'use strict';

function createEvent(description) {
    dataLayer.push({
		event: 'gtm_event',
		non_interaction_hit: false,
        event_name: 'element_interaction',
        element: 'email signup',
        element_action: 'click',
        element_ordinal: 'primary',
        element_primary: 'signup',
        element_description: description.toLowerCase() || null
    });
}

module.exports = function () {
    $('body').on('custom:emailsignup', function (event, data) {
        var type = data.type;
        var page = data.formOfSignup || pageContext.type;
        createEvent(type + ' - ' + page);
    });
};
