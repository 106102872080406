

function validateEmail() {

    function validateCheckOrder(e) {
        e.preventDefault();
        var emailRegex  = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
        var $form       = $(document).find(".check-order");
        var emailValue  = $form.find(".email-validation").val();
        var isValid     = emailRegex.test($.trim(emailValue));
        var orderNumber = $form.find(".js-order-number").val() ? $form.find(".js-order-number").val().length : 0;
        var zipCode     = $form.find(".js-order-zipcode").val() ? $form.find(".js-order-zipcode").val().length : 0;
        var phoneNumber = $form.find(".js-order-phone").val() ? $form.find(".js-order-phone").val().length : 0;

        if (isValid && ((orderNumber > 0) || (zipCode > 0) || (phoneNumber > 0))) {
            $form.find(".js-find-order").removeAttr("disabled");
        } else {
            $form.find(".js-find-order").attr("disabled", "disabled");
        }
    }

    $(".email-validation").on("blur keyup", validateCheckOrder);
}

function submitTrackForm() {
    var $form       = $(document).find(".check-order");
    var $formsubmit = $(document).find(".js-find-order");
    var $checkGroup = $form.find(".js-check-group");
    var textInputs  = $checkGroup.find("input[type=\"text\"]");

    textInputs.each(function (index, element) {
        $(element).on("focus blur change keyup", function () {
            if ($(this).val().length > 0) {
                $form.find(".js-find-order").removeAttr("disabled");
            } else {
                $form.find(".js-find-order").attr("disabled", "disabled");
            }
        });
    });

    var checkFields = function () {
        var elems = $checkGroup.find("input[data-target]");

        for (var i = 0; i < elems.length; i++) {
            var radio   = elems[i].getAttribute("data-target");
            var inputID = "dwfrm_ordertrack_" + radio;

            if (!elems[i].checked) {
                $checkGroup.find("input[id$=" + inputID + "]").val("");
            }
        }
    };

    $formsubmit.on("click", function (e) {
        e.preventDefault();
        checkFields();
        $form.submit();
    });
}

var checkorder = {
    init: function () {
        validateEmail();
        submitTrackForm();
    }
};

module.exports = checkorder;
