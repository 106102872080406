'use strict';

function createEvent(event) {
    dataLayer.push({
		event: 'gtm_event',
		non_interaction_hit: false,
        event_name: 'element_interaction',
        element: 'account',
        element_action: 'click',
        element_ordinal: 'primary',
        element_primary: event,
        element_description: event
    });
}

module.exports = function () {
   $('body').one('success:login', function (e) {
        createEvent('sign in');
    });

    $('body').on('submit', 'form#RegistrationForm', function (e) {
        if (!$('.cl-account-wrapper ').length) {
            if ($('input[name$=addtoemaillist]').is(':checked')) {
                $('body').trigger('custom:emailsignup', [{type: 'form', formOfSignup: 'create an account'}]);
            }
        }
    });
    $('body').on('success:PaswordReset', function (e) {
        createEvent('forgot your password');
    });
    
    // for confirmation page
    $('body').on('success:createAccount', function () {
       createEvent('create an account');
    });
    
    $(function() {
        if (location.href.indexOf('registration=true') > -1) {
            createEvent('create an account');
        }
    });
};
