'use strict';

const EMAIL_CHECK = 1;
const PASSWORD_CHECK = 2;

function extractFormData(form) {
    return {
        firstName: form.find('#first-name').val(),
        lastName: form.find('#last-name').val(),
        email: form.find('#email').val(),
        emailConfirm: form.find('#confirm-email').val(),
        password: form.find('#password').val(),
        passwordConfirm: form.find('#confirm-password').val()
    };
}


function validateConfirmationField(check, elem) {
    var form = elem.closest('form');
    var formData = extractFormData(form);
    var message, field, confirmationField, errorField;
    
    switch (check) {
        case EMAIL_CHECK:
            message = Resources.INVALID_CONFIRM_EMAIL;
            field = formData.email;
            confirmationField = formData.emailConfirm;
            errorField = $('#confirm-email');
            break;
        case PASSWORD_CHECK:
            message = Resources.INVALID_CONFIRM_PASSWORD;
            field = formData.password;
            confirmationField = formData.passwordConfirm;
            errorField = $('#confirm-password');
            break;
        default:
            return; // Exit function if check is invalid
    }
    
    var errorFieldent = errorField.parent().find('.tp-native-form__error-message');
    errorFieldent.text(field !== confirmationField ? message : '');
}

function validateForm(form) {
    const REQUIRED_FIELD = Resources.VALIDATE_REQUIRED;
    const EMAIL_REGEX = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

    var status = {
        success: true,
        errorField: null,
        message: ''
    };

    var fieldsToValidate = [
        { id: '#first-name', message: REQUIRED_FIELD },
        { id: '#last-name', message: REQUIRED_FIELD },
        { id: '#email', message: REQUIRED_FIELD, regex: EMAIL_REGEX },
        { id: '#confirm-email', message: REQUIRED_FIELD },
        { id: '#password', message: REQUIRED_FIELD, regex: PASSWORD_REGEX },
        { id: '#confirm-password', message: REQUIRED_FIELD }
    ];

    fieldsToValidate.every(function(field) {
        var fieldElem = form.find(field.id);
        var errorElem = $(fieldElem).parent().find('.tp-native-form__error-message');
        var value = fieldElem.val();
        if (status.success && value === '' || (field.regex && !field.regex.test(value))) {
            status.success = false;
            status.errorField = fieldElem;
            if (value !== '' && field.regex && !field.regex.test(value)) {
                if (field.id === '#email') {
                    status.message = Resources.VALIDATE_EMAIL;
                } else {
                    status.message = Resources.INVALID_PASSWORD;
                }
            } else {
                status.message = field.message;
            }
            return false; // Exit the loop early if any field fails validation
        } else {
            if ($(errorElem).attr('submissionError')) {
                $(errorElem).text('').removeAttr('submissionError');
             }
             return true;
        }
    });

    if (status.success) {
        status.formValues = {
            firstName: form.find('#first-name').val(),
            lastName: form.find('#last-name').val(),
            email: form.find('#email').val(),
            emailConfirm: form.find('#confirm-email').val(),
            password: form.find('#password').val(),
            passwordConfirm: form.find('#confirm-password').val()
        };
    }

    return status;
}

module.exports = function () {
    var $form = $('#tp-registration-form');
    
    $('body').on('blur', '#confirm-email', function () {
        validateConfirmationField(EMAIL_CHECK, $(this));
    });
    
    $('body').on('blur', '#confirm-password', function () {
        validateConfirmationField(PASSWORD_CHECK, $(this));
    });

    $('body').on('blur', '#email', function () {
        if ($('#confirm-email').val() !== '') {
            validateConfirmationField(EMAIL_CHECK, $(this));
        }
    });
    
    $('body').on('blur', '#password', function () {
        if ($('#confirm-password').val()) {
            validateConfirmationField(PASSWORD_CHECK, $(this));
        }
    });

    $('body').on('blur', '#tp-registration-form input', function () {
        validateForm($form);
    });

    $form.on('submit', function (e) {
        e.preventDefault();
        var validate = validateForm($form);
        if (!validate.success) {
            $(validate.errorField).parent().find('.tp-native-form__error-message').text(validate.message).attr('submissionError', true);
            return;
        }
        var formData = validate.formValues;
        $.ajax({
            url: Urls.proTradeRegistration,
            method: 'post',
            data: formData,
            success: function(data) {
                var res = new Object(data);
                if (!res.error && res.redirectURL) {
                    location.href = res.redirectURL;
                } else {
                    // @TODO - Handle the errors
                    if (res.error && 'reasonCode' in res && res.reasonCode === 'PASSWORD_MISMATCH') {
                        $('#confirm-password').next().text(res.message);
                    } else {
                        $('#email').next().text(res.message);
                    }
                }
            },
            error: function(err) {
                // @TODO - Handle the exceptions
                alert(res.message);
                
            }
        });
    });
}