var siteWideContent = {
    init: function () {
        var $content = $("#siteWideHeaderContent");

        if ($content.length) {

            var slickSpeed = 3000;

            if (Number($content.attr("slick-speed"))) {
                slickSpeed = $content.attr("slick-speed");
            }

            var contentConfigs = {
                arrows: false,
                dots: false,
                autoplay: true,
                speed: 600,
                autoplaySpeed: slickSpeed,
                fade: true,
                cssEase: "linear",
                centerMode: true,
                pauseOnHover: true,
            };

            $(window).on("load", function () {
                $content.slick(contentConfigs);
            });
        }
    }
};

module.exports = siteWideContent;
