'use strict';

function getProductName() {
    return $('#QuickViewDialog .pdp-main .product-name').first().text().trim().toLowerCase();
}

function createEvent(elementPrimary, elementDescription) {
    dataLayer.push({
		event: 'gtm_event',
		non_interaction_hit: false,
        event_name: 'quick_view_interaction',
        element: 'quick view',
        element_action: 'click',
        element_ordinal: 'primary',
        element_primary: elementPrimary.toLowerCase() || null,
        element_description: elementDescription.toLowerCase() || null,
        quick_view_product: getProductName()
    });
}

module.exports = function () {
    if (pageContext.type === 'search') {
        $(document).on('product:quickview:open', function () {
            createEvent('open', '');
            window.quickViewOpen = true;
        })  
      
        $('body').on('product:afterAddToCart', function () {
            var quantitySelected = $('#QuickViewDialog .pdp-main #Quantity').val();
            createEvent('add to cart', quantitySelected);
        });
        
        $('body').on('change', '#QuickViewDialog .pdp-main #Quantity', function () {
            var quantitySelected = $(this).val();
            createEvent('quantity', quantitySelected);
        });
        
        $('body').on('click', '.quickview-more-details', function () {
            createEvent('view more details', '');
        });
        
        $('body').on('change', '#pdpMain .product-variations select.js-variation-select', function () {
            var elementDescription = $(this).find('option:selected').data('gtm-attribute-type');
            createEvent('configuration', elementDescription);
        });

        $('body').on('beforeChange', '#pdpMain .carousel-navigation', function(event, slick, currentSlide, nextSlide){
            var alt = $('.pdp-image-box .slick-current img').attr('alt');
            var position = nextSlide + 1;
            createEvent('alternate image', 'position: ' + position + ' | ' + 'image: ' + alt);
        });
        
        $('body').on("click", '.cl-quickview-modal .cl-modal-dialog__close', function () {
            createEvent('close', '');
        });
        
        $(document).on('click', '.ui-widget-overlay.ui-front', function () {
            if (window.quickViewOpen) {
                createEvent('close', '');
                delete window.quickViewOpen;
            }
        });
    }
};