

var _ = require("lodash"),
    dialog = require("../dialog"),
    util = require("../util"),
    inputmask = require("../inputmask");

var newLine = "\n";
var storeTemplate = function (store, selectedStoreId, selectedStoreText) {
    return [
        "<li class=\"store-tile cl-store-item ms-body-text ms-flex " + store.storeId + (store.storeId === selectedStoreId ? " selected" : "") + "\">",
        "<div class=\"store-details\">",
        "<p class=\"store-address ms-font--montserrat\">",
        store.address1 + "<br/>",
        store.city + ", " + store.stateCode + " " + store.postalCode,
        "</p>",
        "<p class=\"store-status ms-font--montserrat\" data-status=\"" + store.statusclass + "\">" + store.status + "</p>",
        "</div>",
        "<button class=\"select-store-button cl-button cl-button--white ms-font--montserrat ms-margin-top-10\" data-store-id=\"" + store.storeId + "\"" +
        (store.statusclass !== "store-in-stock" ? "disabled=\"disabled\"" : "") + ">",
        (store.storeId === selectedStoreId ? selectedStoreText : Resources.SELECT_STORE),
        "</button>",
        "</li>"
    ].join(newLine);
};

var storeListTemplate = function (stores, selectedStoreId, selectedStoreText) {
    if (stores && stores.length) {
        return [
            "<div class=\"store-list-container\">",
            "<ul class=\"store-list\">",
            _.map(stores, function (store) {
                return storeTemplate(store, selectedStoreId, selectedStoreText);
            }).join(newLine),
            "</ul>",
            "</div>",
            "<div class=\"store-list-pagination\">",
            "</div>"
        ].join(newLine);
    } else {
        return `<div class="no-results ms-body-text col-xs-12 ms-font--center ms-margin-top-20 ms-margin-bottom-10">${Resources.NO_LOCATION_FOUND}</div>`;
    }
};

var zipPromptTemplate = function () {
    return [
        "<div id=\"preferred-store-panel\" class=\"cl-actions-dialog__zip-container\">",
        "<span class=\"cl-form-label\">" + Resources.ENTER_ZIP + "</span>",
        "<input type=\"text\" id=\"user-zip\" class=\"cl-form-input ms-font--montserrat postal ms-font--uppercase\" name=\"zipCode\"/>",
        "<span class=\"error js-store-panel-error ms-hide\"></span>",
        "</div>"
    ].join(newLine);
};

/**
 * @description test whether zipcode is valid for either US or Canada and append validation messages
 * @param {String} zipCode - ZIP code value
 * @param {Object} el - ZIP code input
 * @return {Boolean} true if the zipcode is valid for either country, false if it's invalid for both
 **/
var validateZipCode = function (zipCode, el) {
    var regexes = {
            canada: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
            usa: /^\d{5}(-\d{4})?$/
        },
        valid = false;

    // Show required error for empty field
    if (!zipCode) {
        el.next(".js-store-panel-error").text(Resources.VALIDATE_REQUIRED).removeClass("ms-hide");
        return;
    }

    _.each(regexes, function (re) {
        var regexp = new RegExp(re);
        valid = regexp.test(zipCode);

        // Stop lodash _.each loop if zip matched
        if (valid) {
            return false;
        }
    });

    // Show error for invalid ZIP
    if (!valid) {
        el.next(".js-store-panel-error").text(Resources.INVALID_ZIP).removeClass("ms-hide");
    }

    return valid;
};

var storeinventory = {
    zipPrompt: function (callback) {
        var self = this;
        dialog.open({
            html: zipPromptTemplate(),
            options: {
                title: Resources.STORE_NEAR_YOU,
                draggable: false,
                modal: true,
                resizable: false,
                width:"90%",
                position: {
                    my: "center",
                    at: "center",
                    of: window
                },
                classes: {
                    "ui-dialog": "cl-actions-dialog cl-actions-dialog--zip",
                    "ui-dialog-title": "cl-actions-dialog__title",
                    "ui-dialog-titlebar": "cl-actions-dialog__titlebar",
                    "ui-dialog-titlebar-close": "cl-actions-dialog__close js-cl-actions-dialog__close ui-dialog-titlebar-close",
                    "ui-dialog-content": "cl-actions-dialog__content"
                },
                buttons: [{
                    text: Resources.SEARCH,
                    class: "cl-button cl-button--alternate cl-button--icon ms-font--montserrat ms-full-width ms-margin-top-10",
                    click: function () {
                        var $zipCodeInput = $("#user-zip"),
                            zipCode = $zipCodeInput.val().trim();

                        if (validateZipCode(zipCode, $zipCodeInput)) {
                            self.setUserZip(zipCode);
                            if (callback) {
                                callback(zipCode);
                            }
                        }
                    }
                }],
                open: function () {
                    // Append SVG close icon
                    $(".cl-actions-dialog__titlebar").closest(".ui-dialog")
                        .find(".ui-dialog-titlebar-close")
                        .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

                    $("#user-zip").on("keypress", function (e) {
                        // Clear error messages
                        $(this).next(".js-store-panel-error").text("").addClass("ms-hide");

                        if (e.which === 13) {
                            // trigger the search button
                            $(".ui-dialog-buttonset .ui-button").trigger("click");
                        }
                    });

                    // Close Dialog When Clicked Outside
                    $(document).find(".ui-widget-overlay").bind("click", function () {
                        dialog.close();
                    });

                    // Apply mask for Canada ZIP code
                    inputmask();
                }
            }
        });
    },
    getStoresInventory: function (pid) {
        return Promise.resolve($.ajax({
            url: util.appendParamsToUrl(Urls.storesInventory, {
                pid: pid,
                zipCode: User.zip
            }),
            dataType: "json"
        }));
    },
    /**
     * @description open the dialog to select store
     * @param {Array} options.stores
     * @param {String} options.selectedStoreId
     * @param {String} options.selectedStoreText
     * @param {Function} options.continueCallback
     * @param {Function} options.selectStoreCallback
     **/
    selectStoreDialog: function (options) {
        var self = this,
            stores = options.stores,
            selectedStoreId = options.selectedStoreId,
            selectedStoreText = options.selectedStoreText,
            storeList = storeListTemplate(stores, selectedStoreId, selectedStoreText);
        dialog.open({
            html: storeList,
            options: {
                title: Resources.SELECT_STORE + " - " + User.zip,
                draggable: false,
                modal: true,
                resizable: false,
                buttons: [{
                    text: Resources.CHANGE_LOCATION,
                    class: "cl-button cl-button--white ms-font--montserrat ms-inline-block ms-margin-top-10",
                    click: function () {
                        self.setUserZip(null);
                        // trigger the event to start the process all over again
                        $(".set-preferred-store").trigger("click");
                    }.bind(this)
                }, {
                    text: Resources.CONTINUE,
                    class: "cl-button cl-button--alternate ms-font--montserrat ms-inline-block ms-margin-top-10 ms-margin-left-10",
                    click: function () {
                        if (options.continueCallback) {
                            options.continueCallback(stores);
                        }
                        dialog.close();
                    }
                }],
                classes: {
                    "ui-dialog": "cl-actions-dialog cl-actions-dialog--zip cl-select-store-dialog",
                    "ui-dialog-title": "cl-actions-dialog__title",
                    "ui-dialog-titlebar": "cl-actions-dialog__titlebar",
                    "ui-dialog-titlebar-close": "cl-actions-dialog__close js-cl-actions-dialog__close ui-dialog-titlebar-close",
                    "ui-dialog-content": "cl-actions-dialog__content"
                },
                open: function () {
                    $(".cl-actions-dialog__titlebar").closest(".ui-dialog")
                        .find(".ui-dialog-titlebar-close")
                        .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

                    $(".select-store-button").on("click", function (e) {
                        e.preventDefault();
                        var storeId = $(this).data("storeId");
                        // if the store is already selected, don't select again
                        if (storeId === selectedStoreId) { return; }
                        $(".store-list .store-tile.selected").removeClass("selected")
                            .find(".select-store-button").text(Resources.SELECT_STORE);
                        $(this).text(selectedStoreText)
                            .closest(".store-tile").addClass("selected");
                        if (options.selectStoreCallback) {
                            options.selectStoreCallback(storeId);
                        }
                    });
                }
            }
        });
    },
    setUserZip: function (zip) {
        User.zip = zip;
        $.ajax({
            type: "POST",
            url: Urls.setZipCode,
            data: {
                zipCode: zip
            }
        });
    },
    shippingLoad: function () {
        var $checkoutForm = $(".address");
        $checkoutForm.off("click");
        $checkoutForm.on("click", "input[name$=\"_shippingAddress_isGift\"]", function () {
            $(this).parent().siblings(".gift-message-text").toggleClass("hidden", $("input[name$=\"_shippingAddress_isGift\"]:checked").val());
        });
    }
};

module.exports = storeinventory;
