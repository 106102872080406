

/**
 * This .js file initializes all of the href attributes after page load.
 */

var init = function () {
    populateEmailProductHref();
    populateAddToWishlistHref();
    populateAddToWishlistHrefBundledProduct();
    populateProductVariationOptionValue();
};

var populateEmailProductHref = function () {
    let emailShareElements = $(".js-email-share");

    if (emailShareElements.length) {
        emailShareElements.each(function () {
            let dataUrl = $(this).data("url");

            if (dataUrl && dataUrl.length) {
                $(this).attr("href", dataUrl);
            }
        });
    }
};

var populateAddToWishlistHref = function () {
    let addToWishlistElements = $(".js-add-to-wishlist");

    if (addToWishlistElements.length) {
        addToWishlistElements.each(function () {
            let dataUrl = $(this).data("url");

            if (dataUrl && dataUrl.length) {
                $(this).attr("href", dataUrl);
            }
        });
    }
};

var populateAddToWishlistHrefCurrentElement = function (element) {
    let _this = $(element);

    if (_this.length) {
        let dataUrl = _this.data("url");

        if (dataUrl && dataUrl.length) {
            _this.attr("href", dataUrl);
        }
    }
};

var populateFormActionUrl = function (element) {
    let _this = $(element);

    if (_this.length) {
        let dataUrl = _this.data("url");

        if (dataUrl && dataUrl.length) {
            _this.attr("action", dataUrl);
        }
    }
};

var populateAddToWishlistHrefBundledProduct = function () {
    let addToWishlistAnchorElements = $("a[data-action=\"wishlist\"]");

    if (addToWishlistAnchorElements.length) {
        addToWishlistAnchorElements.each(function () {
            let dataUrl = $(this).data("url");

            if (dataUrl && dataUrl.length) {
                $(this).attr("href", dataUrl);
            }
        });
    }
};

var populateProductVariationOptionValue = function () {
    let optionElements = $(".js-variation-select option");

    if (optionElements.length) {
        optionElements.each(function () {
            let optionValue = $(this).data("optionvalue");

            if (optionValue && optionValue.length) {
                $(this).attr("value", optionValue);
                $(this).val(optionValue);
            }
        });
    }
};

module.exports.init = init;
module.exports.populateAddToWishlistHref = populateAddToWishlistHref;
module.exports.populateAddToWishlistHrefCurrentElement = populateAddToWishlistHrefCurrentElement;
module.exports.populateFormActionUrl = populateFormActionUrl;
module.exports.populateProductVariationOptionValue = populateProductVariationOptionValue;
module.exports.populateEmailProductHref = populateEmailProductHref;
