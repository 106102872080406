'use strict';

var homeEvents = require('./events/homeEvents'),
    pdpEvents = require('./events/pdpEvents'),
    minicartEvents = require('./events/minicartEvents'),
    loginEvents = require('./events/loginEvents'),
    accountEvents = require('./events/accountEvents'),
    cartEvents = require('./events/cartEvents'),
    emailSignupEvents = require('./events/emailSignupEvents'),
    quickviewEvents = require('./events/quickviewEvents'),
    checkoutEvents = require('./events/checkoutEvents'),
    navigationCustomEvents = require('./events/navigationCustomEvents'),
    headerCustomEvents = require('./events/headerCustomEvents'),
    footerCustomEvents = require('./events/footerCustomEvents'),
    listCustomEvents = require('./events/listCustomEvents');


function pushToDataLayer(couponCode) {
    for (const dataItem of dataLayer) {
        if ('sessionCouponCode' in dataItem && couponCode) {
            dataItem.sessionCouponCode = couponCode || '';
            localStorage.setItem('sessionCouponCode', couponCode);
            return true;
        }
    }
    return false;
}

function captureCouponCode() {
    $('body').on('click', 'form.needsclick button svg.needsclick', function () {
        var couponCode = $(this).prev().text().trim();
        pushToDataLayer(couponCode);
    });

    $(function() {
        var regex = new RegExp('[?&]coupon=([^&#]*)');
        var results = regex.exec(window.location.search);
        var sessionCouponCode = localStorage.getItem('sessionCouponCode');
        var code =  results ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : null;
        var couponCode = code || sessionCouponCode;
        var interval = setInterval(() => {
            if (pushToDataLayer(couponCode)) {
                clearInterval(interval);
            }
        }, 100);
    });
}


var init = function () {
    pdpEvents();
    homeEvents();
    minicartEvents();
    loginEvents();
    accountEvents();
    cartEvents();
    emailSignupEvents();
    quickviewEvents();
    checkoutEvents();
    navigationCustomEvents.init();
    headerCustomEvents.init();
    footerCustomEvents.init();
    listCustomEvents.init();
    captureCouponCode();
}
module.exports = {
    init: init
};
