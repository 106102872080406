
/**
 * @function Initializes Save Cart features on Account page.
 */
var util = require("../util"),
    $cache;

exports.init = function () {
    $cache = {};

    cacheElements();
    initDropDownPlugin();
    initListeners();
};

function cacheElements() {
    $cache.saveCartDD = $("#selectSavedCart");
    $cache.detailsArea = $("#detailsArea");
    $cache.cartActionsForm = $("#saved-cart-action");
}

function initListeners() {
    $cache.saveCartDD.on("change", function () {
        var savedCartID = $(this).val(),
            url = Urls.selectSavedCart;

        if (savedCartID != "") {
            $cache.detailsArea.load(util.appendParamToURL(url, "scid", savedCartID));
        }
    });

    $(document).on("click", "#saved-cart-action button", function (event) {
        event.preventDefault();
        if ($(event.target).attr("id") === "btnDeleteCart") {
            $cache.cartActionsForm.attr("action", Urls.deleteCart);
        } else {
            $cache.cartActionsForm.attr("action", Urls.restoreCart);
        }

        $cache.cartActionsForm.submit();
    });
}

function initDropDownPlugin() {
    $cache.saveCartDD.selectmenu({
        width: "100%",
        classes: {
            "ui-selectmenu-button": "cl-sorting-bar__button",
            "ui-selectmenu-icon": "cl-sorting-bar__icon",
            "ui-selectmenu-menu": "cl-sorting-bar__menu cl-sorting-bar__menu--backdrop",
            "ui-menu-item": "cl-sorting-bar__menu-item"
        },
        create: function () {
            $(this).next().find(".ui-selectmenu-icon").html("<svg class=\"cl-svg-icon cl-sorting-bar__svg-icon\"><use xlink:href=\"#drop-down\"></use></svg>");
        },
        change: function (event, ui) {
            $(this).trigger("change", ui);
        }
    }).selectmenu("menuWidget");
}
