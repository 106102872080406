

var util = require("./util");

var productImpressionsUpdate = function (url) {
    if (!url ||
         Object.prototype.toString.call(url).slice(8, -1) !== "String") {
        return;
    }

    var queryStingParams = util.getQueryStringParams(url),
        size = parseInt(queryStingParams.sz, 10),
        skip = queryStingParams.start ? parseInt(queryStingParams.start, 10) : 0,
        impressions = [],
        dataLayerObj = {
            event: "gtm_event",
            event_name: "view_item_list",
            non_interaction_hit: true,
            ecommerce: {
                currency: gaECGlobals.siteCurrency.toLowerCase(),
            }
        },
        $items = $(".product-tile-container:gt(" + (skip - 1) + ")").slice(0, size);

    if (0 === $items.length) {
        $items = $(".product-tile-container");
    }

    $items.each(function (index) {
        var impression = $(this).data("gtag");
        if (impression) {
            impression.index = index + skip;
            impressions.push(impression);
        }
    });
    dataLayerObj.ecommerce.items = impressions;

    // update list id and name at parent level
    if(dataLayerObj.ecommerce.items && dataLayerObj.ecommerce.items[0].item_list_name){
        dataLayerObj.ecommerce.item_list_id = dataLayerObj.ecommerce.items[0].item_list_id;
        dataLayerObj.ecommerce.item_list_name = dataLayerObj.ecommerce.items[0].item_list_name;
    }

    dataLayer.push(dataLayerObj);
};

exports.productImpressionsUpdate = productImpressionsUpdate;
