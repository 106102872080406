'use strict';
//this file keep only js(push action, hash creation) functionality

exports.gtmInit = function () {
    var    exeption,
        getUri = function (o) {
            var a;
            if (o && o.tagName && $(o).attr('href')) {
                a = o;
            } else if (typeof o === 'string') {
                a = document.createElement('a');
                a.href = o;
            } else {
                return null;
            }
            return {
                protocol: a.protocol, //http:
                host: a.host, //www.myexample.com
                hostname: a.hostname, //www.myexample.com'
                port: a.port, //:80
                path: a.pathname, // /sub1/sub2
                query: a.search, // ?param1=val1&param2=val2
                queryParams: a.search.length > 1 ? this.getQueryStringParams(a.search.substr(1)) : {},
                hash: a.hash, // #OU812,5150
                url: a.protocol + '//' + a.host + a.pathname,
                urlWithQuery: a.protocol + '//' + a.host + a.port + a.pathname + a.search
            };
        },
        appendParamsToUrl = function (url, params) {
            var uri = getUri(url),
                includeHash = arguments.length < 3 ? false : arguments[2],
                qsParams = $.extend(uri.queryParams, params),
                result = uri.path + '?' + $.param(qsParams);

            if (includeHash) {
                result += uri.hash;
            }
            if (result.indexOf('http') < 0 && result.charAt(0) !== '/') {
                result = '/' + result;
            }
            return result;
        };

    window.ControllerUA = {
        //function to push data to the dataLayer, and add hash
        hashPush : function(data){
            if (data){
                if ((location.hash.indexOf(data.event + 'gtm') == -1)) {
                    dataLayer.push(data);
                }
            }
        },
        //check data type and parse string into JSON
        dataPush: function(Data) {
            if((typeof(Data) === 'string') && (Data != "")){
                if((Data != null) || (Data != 'null')){
                    //Parse JSON and remove all excessive symbols
                    Data = Data.replace(/&reg;/gi, '');
                    Data = JSON.parse(Data.replace(/&quot;/gi, '"'));
                    //set hash - for prevent tag fire when user just reload the page
                    ControllerUA.hashPush(Data)
                }
            } else {
                ControllerUA.hashPush(Data)
            }
        },
        // Enable add to cart tracking
        addToCart: function( data ) {
            ControllerUA.addToCartEnabled = data.enabled;
        },
        // Fire add to cart tracking event
        fireAddToCartEvent: function ($form) {
            if (!ControllerUA.addToCartEnabled) {
                return;
            }

            var event = 'ecomAdd';
            var pid = $form.find('input[name="pid"]').val();
            var isQuote = $form.find('input[name="isQuote"]').val();

            var ajURL = appendParamsToUrl( Urls.addToCartUA, {
                "pid": pid,
                "Quantity": $('#Quantity', $form).val() || 1,
                "event": event,
                "isQuote": (isQuote == 'true')
            });

            // for update need to remove previous item, and add current
            if (gaECGlobals.cartUpdateItemID == pid) {
                var remURL = appendParamsToUrl(Urls.removeFromCartUA, {
                    "pid": gaECGlobals.cartUpdateItemID,
                    "Quantity": gaECGlobals.cartUpdateItemQty,
                    "event": event + 'Remove'
                });

                $.when(
                    $.getJSON(ajURL, function(data) {
                        if (dataLayer) {
                            dataLayer.push(data);
                        }
                    }),
                    $.getJSON(remURL, function(remData) {
                        if (dataLayer) {
                            dataLayer.push(remData);
                        }
                    })
                ).then();
            } else {
                $.getJSON(ajURL, function(data) {
                    if (dataLayer) {
                        dataLayer.push(data);
                    }
                });
            }
        },
        removeFromCart: function( data ){
            $(document).on("click", ".item-user-actions button", function removeFromCartGAHandler(e){
                var $self = $(this),
                    pid = $self.parent().data("productid"),
                    qty = Number($self.parents(".cart-row").find("input[name$='_quantity']").val());

                var params = {
                        "pid": pid,
                        "Quantity": qty
                    };

                var ajURL = appendParamsToUrl(Urls.removeFromCartUA, params);

                $.getJSON(ajURL, function(data) {
                    if (dataLayer) {
                        dataLayer.push(data);
                    }
                });
            });
        },
        checkout: function(step) {
            var dataLayer = $('.cl-onepagecheckout').data('gtmcheckout');

            if (!dataLayer) {
                return '';
            }
            
            dataLayer.ecommerce.checkout.actionField = {step: step};

            return dataLayer;
        },
        fireCheckoutEvent: function(step) {
            ControllerUA.dataPush(ControllerUA.checkout(step));
        },
        //run different function from controller based on 'model'
        init: function(obj) {
            if (obj.length){
                exeption = obj;
            }
            if (obj.enabled) {
                switch (obj.event) {
                    case 'addToCart':
                        ControllerUA.addToCart(obj);
                        break;
                    case 'removeFromCart':
                        ControllerUA.removeFromCart(obj);
                        break;
                    case 'backInStockPDP':
                        ControllerUA.backInStockNotify(obj);
                        break;
                    case 'ecomListClick':
                        ControllerUA.plpProductClickFire(obj);
                        break;
                    default:
                        if (obj.condition) {
                            ControllerUA.dataPush(obj.functionToCall);
                        }
                }
            }
        },
        checkState: function(el, ns) {
            if (ns) {
                return ($(el).length > 0) && (window.pageContext.ns == ns);
            } else {
                return $(el).length > 0;
            }

        },
        plpProductClickFire: function(obj) {
            $(document).on('click', '.product-tile-container div.product-tile', function() {
                var $self = $(this);
                var $elementHolder = $self.parents('.product-tile-container');
                var gtmData = $elementHolder.data('gtag-ua');
                var position = $('div.product-tile').index($self) + 1;
                var list = '';
                
                if(window.pageContext.ns === 'wishlist'){
                    list = 'Wish List';
                }else{
                    var pageCategoryData = $elementHolder.data('iscategory');
                    var productCategoryPath = (gtmData.category ? gtmData.category : 'Product Listing Page');
                    list = (pageCategoryData == true) ? productCategoryPath : 'Search Results Page';
                }

                var Data =  {
                    'event': 'ecomListClick',
                    'ecommerce': {
                        'click': {
                            'actionField': { 'list': list },
                            'products': [gtmData]
                        }
                    }
                };
                Data.ecommerce.click.products[0].position = position;
                if (dataLayer) {
                    dataLayer.push(Data);
                }
            });
        }
    };
};
