'use strict';
const quickview = require('./../../../../app_capitollighting_core/cartridge/js/quickview');

function initQuickView() {
    if ($('.js-product-quick-view').length > 0) {
        $('.js-product-quick-view').off('click').on('click', function (e) {
            e.preventDefault();

            // trigger gtm select_item event
            $(this).parents('.product-tile').trigger('click');

            // trigger gtm custom event for quickview
            $('body').trigger('custom:quickViewList', [$(this)]);

            quickview.show({
                url: $(this).attr('href'),
                source: 'quickview'
            });

            return false;
        });
    }
}

exports.init = initQuickView;
