'use strict';

/**
 * @private
 * @function
 * @description Function to initialize slick slider for hero carousel
 */
function initHeroCarousel() {
    if ($('.js-hero-carousel').length > 0) {

        let $heroCarousel = $('.js-hero-carousel'),
            svgArrowRightIcon = '<svg class="hero-carousel__svg-icon"><use xlink:href="#arrow-right"></use></svg>',
            svgArrowLeftIcon = '<svg class="hero-carousel__svg-icon"><use xlink:href="#arrow-left"></use></svg>',
            carouselArrowNext = '<button class="hero-carousel__arrow hero-carousel__arrow--next">' + svgArrowRightIcon + '</button>',
            carouselArrowPrev = '<button class="hero-carousel__arrow hero-carousel__arrow--prev">' + svgArrowLeftIcon + '</button>',
            carouselDotsCssClass = 'hero-carousel__dots ms-no-margin ms-no-padding ms-no-list-items ms-flex';

        let heroCarouselConfigs = {
            nextArrow: carouselArrowNext,
            prevArrow: carouselArrowPrev,
            dotsClass: carouselDotsCssClass,
            arrows: false,
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 600,
            slidesToShow: 1,
            variableWidth: false,
            adaptiveHeight: true,
            slidesToScroll: 1,
            mobileFirst: true,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1365,
                    settings: {
                        arrows: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1023,
                    settings: {
                        arrows: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        arrows: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 639,
                    settings: {
                        arrows: false,
                        dots: true
                    }
                }
            ]
        };

        $(window).on('load', function () {
            $heroCarousel.slick(heroCarouselConfigs);
        });
    }
}

exports.init = initHeroCarousel;