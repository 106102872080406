/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */



var countries = require("./countries"),
    dialog = require("./dialog"),
    minicart = require("./minicart"),
    page = require("./page"),
    rating = require("./rating"),
    searchplaceholder = require("./searchplaceholder"),
    searchsuggest = require("./searchsuggest"),
    tooltip = require("./tooltip"),
    util = require("./util"),
    validator = require("./validator"),
    tls = require("./tls"),
    consentTracking = require("./consentTracking"),
    pdpSlickCarousel = require("./pdp-slick-carousel"),
    modulesSystem = require('./../../../int_acf_core/cartridge/js/modules_system'),
    orderdetail = require("./pages/orderdetail"),
    quotes = require("./quotes"),
    checkorder =  require("./pages/checkorder"),
    digital =  require("./digitalCatalog"),
    newsletter = require("./newsletter"),
    gtm = require("../../../int_gtm/cartridge/static/default/js/gtm"),
    gtmUA = require("../../../int_gtm/cartridge/static/default/js/gtmUA"),
    sticky = require("./sticky-nav"),
    siteWideContent = require("./site-wide-carousel"),
    gtmGlobal = require('../../../int_gtm/cartridge/js/gtmGlobal'),
    blog = require('./bloglink');
const accessories = require("./pages/accessories");
const proTradeRegistration = require('./proTradeRegistration');
// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement("script");
    s.setAttribute("src", "https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js");
    s.setAttribute("type", "text/javascript");
    document.getElementsByTagName("head")[0].appendChild(s);
}

require("./jquery-ext")();
require("./cookieprivacy")();
consentTracking.init();
require("./captcha")();

function initializeEvents() {
    var controlKeys = ["8", "13", "46", "45", "36", "35", "38", "37", "40", "39"];

    $("body")
        .on("keydown", "textarea[data-character-limit]", function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data("character-limit"),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on("change keyup mouseup", "textarea[data-character-limit]", function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data("character-limit"),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next("div.char-count").find(".char-remain-count").html(charsRemain);
        });

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    var $searchContainer = $(".js-header-search");

    if ($searchContainer.length) {
        // initialize header search
        searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);

        let $body        = $("body"),
            $searchOpen  = $(".js-search-toggle"),
            $searchClose = $(".js-search-close"),
            $searchInput = $searchContainer.find("input[name=\"q\"]");

        // expand search form
        let openSearch = function (event) {
            event.preventDefault();

            window.modulesSystem.jRes.addFunc([
                {
                    breakpoint: ["phone", "phoneLandscape", "tablet"],
                    enter: function () {
                        $searchOpen.addClass("active");
                        $searchContainer.slideDown(300, function () {
                            $body.addClass("search-active");
                        }).addClass("expanded");
                        closeMobileMenu(event);
                        $searchInput.focus();
                    }
                }, {
                    breakpoint: ["desktop", "desktopLarge"],
                    enter: function () {
                        $searchInput.focus();
                        $body.addClass("search-active");
                    }
                }
            ]);
        };

        // collapse search form
        let closeSearch = function (event) {
            event.preventDefault();
            searchsuggest.clearResults();

            window.modulesSystem.jRes.addFunc([
                {
                    breakpoint: ["phone", "phoneLandscape", "tablet"],
                    enter: function () {
                        $searchOpen.removeClass("active");
                        $searchContainer.slideUp(300, function () {
                            $body.removeClass("search-active");
                        }).removeClass("expanded");
                        $searchInput.blur();
                    }
                }, {
                    breakpoint: ["desktop", "desktopLarge"],
                    enter: function () {
                        setTimeout(() => {
                            $searchInput.blur();
                            $body.removeClass("search-active");
                        }, 200);
                    }
                }
            ]);
        };

        // events
        $searchOpen.on("click", (event) => {
            event.stopPropagation();

            if (!$searchOpen.hasClass("active")) {
                openSearch(event);
            } else {
                closeSearch(event);
                $searchInput.val("");
                $searchInput.parent(".js-search-suggestion-input-wrapper").removeClass("filled");
            }
        });

        $searchInput.on("click touchstart", (event) => {
            event.stopPropagation();
            openSearch(event);
        });

        $searchClose.on("click", function (e) {
            $searchInput.val("");
            $searchInput.parent(".js-search-suggestion-input-wrapper").removeClass("filled");
            closeSearch(e);
        });

        // $searchContainer.on("click", (event) => event.stopPropagation());

        // close search area on click outside the wrapper
        $(document).on("click", function (e) {
            if (!$searchContainer.is($(e.target)) || !$searchInput.is($(e.target)) || !$searchOpen.is($(e.target))) {
                if ($body.hasClass("search-active")) {
                    setTimeout(() => {
                        closeSearch(e);
                    }, 200);
                }
            }
        });
    }

    //dynamic year value for copyright in footer
    let currentYear = (new Date()).getFullYear();
    let $copyrightYear = $(".js-year");

    if ($copyrightYear) {
        $copyrightYear.text(currentYear);
    }

    // add show/hide navigation elements
    $(".secondary-navigation .toggle").click(function () {
        $(this).toggleClass("expanded").next("ul").toggle();
    });

    // add generic toggle functionality
    $(".toggle").next(".toggle-content").hide();
    $(".toggle").click(function () {
        $(this).toggleClass("expanded").next(".toggle-content").toggle();
    });

    // subscribe email box
    var $subscribeEmail = $(".subscribe-email");
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: "#999999"}, 500, "linear", function () {
                $(this).val("").css("color", "#333333");
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css("color", "#999999")
                .animate({color: "#333333"}, 500, "linear");
        });
    }

    $(".privacy-policy").on("click", function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr("href"),
            options: {
                height: 600
            }
        });
    });

    $(".consent-tracking-policy").on("click", function (e) {
        e.preventDefault();
        consentTracking.show();
    });

    // Close mobile menu
    let closeMobileMenu = function (event) {
        event.preventDefault();

        window.modulesSystem.jRes.addFunc([
            {
                breakpoint: ["phone", "phoneLandscape", "tablet"],
                enter: function () {
                    $("body").removeClass("menu-active");
                    $(".js-menu-toggle").removeClass("active");
                }
            }
        ]);
    };

    // Close PRO Banner
    var closeProAccount =  $(".closeproaccountmessage");
    var proMessage = $(".message-preliminary-proaccount");

    if (closeProAccount.length && proMessage.length) {
        closeProAccount.on("click", function () {
            proMessage.slideUp("slow");
        });
    }

    //detect mobile
    if ($(".js-clickable-mobile-elements").length && window.userAgent.isMobile()) {
        $(".js-clickable-mobile-elements").addClass("clickable-mobile-elements");
    }


    // Mobile navigation toggle
    $(".js-menu-toggle").on("click touchstart", function (e) {
        e.preventDefault();
        $("body").toggleClass("menu-active");
        $(this).toggleClass("active");

        var isMenuActive = $("body").hasClass("menu-active");
        var checkoutReviewPages = $('.pt_checkout').length > 0 || $('.pt_account').length > 0;

        // Toggle the visibility of the chat button based on the menu status and page type
        var chatBtn = $(".helpButton");
        if (checkoutReviewPages) {
            chatBtn.hide();
        } else {
            if (isMenuActive) {
                chatBtn.hide();
            } else {
                chatBtn.show();
            }
        }
    });

    // Hide Chat Button on cart, checkout and order review pages on mobile
    if (window.innerWidth <= 768 && ($('.pt_checkout').length > 0 || $('.pt_account').length > 0)) {
        var observerConfig = { childList: true, subtree: true };

        var observerCallback = function(mutationsList, observer) {
            for(var mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    var addedHelpButton = $(mutation.addedNodes).find('.helpButton');
                    if (addedHelpButton.length > 0) {
                        addedHelpButton.hide();
                    }
                }
            }
        };

        var observer = new MutationObserver(observerCallback);
        observer.observe(document.documentElement, observerConfig);
    }



    $(document).on("mouseenter", ".affirm-as-low-as .affirm-modal-trigger", function () {
        $(this).attr("title", Resources.PDP_AFFIRM_LEARN_MORE);
    });

    // Close site wide header banner for session
    if ($(".js-close-site-wide-header").length) {
        function getCookie(c_name) {
            if (document.cookie.length > 0) {
                c_start = document.cookie.indexOf(c_name + "=");
                if (c_start != -1) {
                    c_start = c_start + c_name.length + 1;
                    c_end = document.cookie.indexOf(";", c_start);
                    if (c_end == -1) {
                        c_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return "";
        }

        $(".js-close-site-wide-header").on("click", function (e) {
            e.preventDefault();

            document.cookie = "sessionStatus=exist; path=/";
            $(".js-site-wide-header").hide();
        });

        var sessionStatus = getCookie("sessionStatus");

        if (sessionStatus === "exist") {
            $(".js-site-wide-header").hide();
        }
    }

    // Main navigation functionality
    if ($(".js-menu-category").length) {
        window.modulesSystem.jRes.addFunc([
            {
                breakpoint: ["phone", "phoneLandscape", "tablet"],
                enter: function () {
                    // Mobile navigation
                    $(".js-menu-category .js-has-sub-menu").on("click", function (e) {
                        e.preventDefault();
                        var $link = $(e.target),
                            $parentLi = $link.closest(".js-menu-main-category");

                        $("body").removeClass("sub-menu-active");
                        $parentLi.siblings("li").removeClass("active").find(".js-has-sub-menu").removeClass("active");
                        $parentLi.toggleClass("active");
                        $link.toggleClass("active");

                        if ($parentLi.hasClass("active")) {
                            $("body").addClass("sub-menu-active");
                        }
                    });
                }
            }, {
                breakpoint: ["desktop", "desktopLarge"],
                enter: function () {
                    // Remove active classes added from mobile navigation
                    $("body").removeClass("sub-menu-active");
                    $(".js-has-sub-menu").removeClass("active");
                    $(".js-menu-main-category").removeClass("active");

                    // Allow first click on main navigation items on touchscreen devices to expand drop-down
                    if (("ontouchstart" in window) || navigator.msMaxTouchPoints > 0) {
                        window.modulesSystem.$cache.body.addClass("is-touch");

                        //Сlose navigation on touch outside the cointainer
                        $(document).on("touchstart", function (e) {
                            if ($(".cl-footer").is($(e.target))) {
                                setTimeout(() => {
                                    $(".js-menu-main-category").removeClass("active").blur();
                                    window.modulesSystem.$cache.body.removeClass("cl-modal-overlay");
                                }, 200);
                            }
                        });
                    }

                    // Unbind event listener for click on menu link and prevent link default
                    $(".js-menu-category .prevent-link").off("click").on("click", function (e) {
                        e.preventDefault();
                        $(this).blur();
                    });

                    // Add/remove blurred overlay on body
                    $(".js-navigation-drop-down").bind("mouseover", function () {
                        window.modulesSystem.$cache.body.addClass("cl-modal-overlay");
                        $(".js-navigation-drop-down").removeClass("active");
                        $(this).addClass("active");
                    }).bind("mouseleave", function () {
                        window.modulesSystem.$cache.body.removeClass("cl-modal-overlay");
                        $(".js-navigation-drop-down").removeClass("active");
                        $(this).blur();
                        $(".js-submenu-link").blur();
                    });
                }
            }
        ]);
    }

    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["phone", "phoneLandscape", "tablet"],
            enter: function () {
                $(".js-menu-utility-user").off("mouseenter focus focusout");
            }
        },  {
            breakpoint: ["desktop", "desktopLarge"],
            enter: function () {
                $(".js-menu-utility-user").on("mouseenter focus", function (e) {
                    e.preventDefault();
                    $(".js-user-info").addClass("active");

                    // Add blurred overlay to the body
                    window.modulesSystem.$cache.body.addClass("cl-modal-overlay");
                });

                $(".js-menu-utility-user").on("mouseleave focusout", function (e) {
                    e.preventDefault();
                    $(".js-user-info").removeClass("active");

                    // Remove blurred overlay from the body
                    window.modulesSystem.$cache.body.removeClass("cl-modal-overlay");
                });
            }
        }
    ]);

    if ($(".js-double-click-fix").length) {
        $(".js-double-click-fix").on("click touchend", function () {
            var el = $(this);
            var link = el.attr("href");
            window.location = link;
        });
    }

    if ($(".js-back-to-top").length) {
        $(".js-back-to-top").on("click", function (e) {
            e.preventDefault();

            $("html, body").animate({
                scrollTop: 0
            }, 700);
        });
    }

    // Header/Footer navigation accordion functionality
    if ($(".js-accordion-content").length) {
        var $allPanels = $(".js-accordion-content").hide(),
            $allHeadings = $(".js-accordion-heading"),
            $defaultExpanded = $(".js-ul-expanded");

        if ($defaultExpanded.length) {
            $defaultExpanded.prev(".js-accordion-heading").addClass("expanded");
            $defaultExpanded.show();
        }

        window.modulesSystem.jRes.addFunc([
            {
                breakpoint: ["phone", "phoneLandscape", "tablet"],
                enter: function () {
                    // Mobile accordion
                    $(".js-accordion-heading").on("click", function (e) {
                        e.preventDefault();

                        if ($(this).hasClass("expanded")) {
                            $(this).toggleClass("expanded");
                            $(this).next(".js-accordion-content").slideToggle();
                            return false;
                        }

                        $allPanels.slideUp();
                        $allHeadings.removeClass("expanded");

                        $(this).toggleClass("expanded");
                        $(this).next(".js-accordion-content").slideToggle();
                    });
                }
            }, {
                breakpoint: ["desktop", "desktopLarge"],
                enter: function () {
                    // Remove active classes added from mobile accordion
                    $allHeadings.removeClass("expanded");
                    $allPanels.slideDown();

                    // Unbind event listener for click on accordion link
                    $(".js-accordion-heading").off("click");
                }
            }
        ]);
    }

    // Initialize fixed navigation for brands page
    if ((".js-brands-affix").length) {
        var affix = $(".js-brands-affix").mindforAffix();

        window.modulesSystem.jRes.addFunc([
            {
                breakpoint: ["phone", "phoneLandscape"],
                enter: function () {
                    if (affix) affix.remove();
                }
            }, {
                breakpoint: ["tablet", "desktop", "desktopLarge"],
                enter: function () {
                    affix = $(".js-brands-affix").mindforAffix();
                }
            }
        ]);
    }
}

/**
* @private
* @function
* @description Function to handle onchange on sidemenu dropdown ( mobile only )
*/
function initializeSideMenuDropDown() {
    var $sideMenuDd = $(document).find(".js-cl-sidemenu");

    if ($sideMenuDd.length) {
        $sideMenuDd.on("change", function () {
            if (this.value === "") {
                return false;
            }

            window.location = this.value;
        });
    }
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $("html").addClass("js");
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $("html").addClass("infinite-scroll");
    }
    // load js specific styles
    util.limitCharacters();

    // Append additional class to the body for specific User Agent
    if (window.userAgent.getInfo()) {
        switch (true) {
            case window.userAgent.isIpad():
                window.modulesSystem.$cache.body.addClass("is-ipad");
                break;
            case window.userAgent.isEdge():
                window.modulesSystem.$cache.body.addClass("is-edge");
                break;
            case window.userAgent.isIe():
                window.modulesSystem.$cache.body.addClass("is-ie");
                break;
            case window.userAgent.isFirefox():
                window.modulesSystem.$cache.body.addClass("is-firefox");
                break;
            case window.userAgent.isChrome():
                window.modulesSystem.$cache.body.addClass("is-chrome");
                break;
            case window.userAgent.isSafari():
                window.modulesSystem.$cache.body.addClass("is-safari");
                break;
            default:
                return;
        }
    }
}

var pages = {
    account: require("./pages/account"),
    cart: require("./pages/cart"),
    checkout: require("./pages/checkout"),
    compare: require("./pages/compare"),
    product: require("./pages/product"),
    registry: require("./pages/registry"),
    search: require("./pages/search"),
    storefront: require("./pages/storefront"),
    savedcart: require("./pages/accountcarts"),
    wishlist: require("./pages/wishlist"),
    storelocator: require("./pages/storelocator"),
    orderconfirmation: require("./pages/orderconfirmation"),
    accessories: require("./pages/accessories")
};

var app = {
    init: function () {
        modulesSystem.init();

        if (document.cookie.length === 0) {
            $("<div/>").addClass("browser-compatibility-alert").append($("<p/>").addClass("browser-error").html(Resources.COOKIES_DISABLED)).appendTo("#browser-check");
        }
        accessories.init();
        initializeDom();
        initializeEvents();

        // init GTM Ecom tags
        gtmUA.gtmInit();

        if(SitePreferences.IS_GTM_GA4_ENABLED){
            gtm.gtmInit();
        }
        // End init GTM Ecom tags

        gtmGlobal.init()
        // init specific global components
        newsletter.init();
        countries.init();
        tooltip.init();
        minicart.init();
        validator.init();
        rating.init();
        searchplaceholder.init();
        pdpSlickCarousel.init();
        orderdetail.init();
        quotes.init();
        checkorder.init();
        // digital.init();
        if ($(".js-menu-toggle").length) {
            sticky.init();
        }
        siteWideContent.init();

        if($('#wrapper').hasClass('pt_blogcontent')) {
            blog.init();
        }

        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }

        initializeSideMenuDropDown();

        util.customRequiredMsgValidator();
        sendDeviceType();
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0]; //NOSONAR
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp("\\{" + i + "\\}", "gm");
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

function sendDeviceType() {
    var jres = window.modulesSystem.jRes;
    var deviceType;
    if (jres.isPhone || jres.isPhoneLandscape) deviceType = "Mobile";
    else if (jres.isTablet) deviceType = "Tablet";
    else deviceType = "Desktop";
    liveagent.setCustomVariable('deviceType', deviceType);
}

function updateSubmitButton() {
    $(".commercial-landing-popup #dialog-container .cl-form-input").each(function () {
        var self = $(this);
        var inputs = $('#dialog-container #CommercialLandingForm').find('.cl-form-input');
    
        self.on('blur', function() {
            inputs.each(function() {
                if (($(this).hasClass('required-field')) && ($(this).val().length < 1)) {
                    $('.commercial-landing-popup-content-button').prop("disabled", true);
                } else {
                    $('.commercial-landing-popup-content-button').prop("disabled", false);
                }
            })
        })
    });
}

function openCommercialLandingPopup() {
    var $commercialLandingForm = document.querySelector(".commercial-landing-popup-content");
    if (!$commercialLandingForm) { return; }

    var $commercialLandingFormHtml = $commercialLandingForm.innerHTML;

    // create the dialog
    dialog.open({
        html: $commercialLandingFormHtml,
        options: {
            autoOpen: false,
            draggable: false,
            modal: true,
            resizable: false,
            width: "none",
            position: {
                my: "center",
                at: "center",
                of: window
            },
            dialogClass: "cl-actions-dialog commercial-landing-popup",
            classes: {
                "ui-dialog": "cl-actions-dialog",
                "ui-dialog-title": "cl-actions-dialog__title",
                "ui-dialog-titlebar": "cl-actions-dialog__titlebar",
                "ui-dialog-titlebar-close": "cl-actions-dialog__close",
                "ui-dialog-content": "cl-actions-dialog__content",
                "ui-dialog-buttonpane": "ms-hide"
            },
            buttons: {},
            open: function () {
                //will set dialog title
                var dialogTitle = $(this).closest(".ui-dialog")
                    .find(".ui-dialog-name")
                    .attr("data-name");

                $(this).closest(".ui-dialog")
                    .find(".ui-dialog-title")
                    .text(dialogTitle);

                // Add custom close button icon
                $(this).closest(".ui-dialog")
                    .find(".ui-dialog-titlebar-close")
                    .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

                // Close Dialog When Clicked Outside
                $(document).find(".ui-widget-overlay").bind("click", function () {
                    dialog.close();
                });

                validator.init();
                util.customRequiredMsgValidatorCommercialLanding();
                updateSubmitButton();
            }
        }
    });
}

function showSuccessMessage() {
    if (window.location.href.indexOf("success") > -1) {
        $(".commercial-landing-page .success-message").removeClass("d-none");
        window.history.replaceState({}, document.title, window.location.pathname);
        setTimeout(function () {
            $(".commercial-landing-page .success-message").addClass("d-none");
        }, 3000);
    }
}

$(".commercial-landing-page .landing-modal-button").on("click", function (e) {
    e.preventDefault();
    openCommercialLandingPopup();
});

// initialize app
$(document).ready(function () {
    app.init();
    showSuccessMessage();
    proTradeRegistration();
});
