

var dialog = require("./dialog"),
    page = require("./page"),
    validator = require("./validator");

function checkCustomerPassword(form) {
    var deferred = $.Deferred();
    var thisFormLogin = form.find("[name*=\"login_username\"]");
    var url = Urls.isShowPasswordResetOnLogin;
    $.ajax({
        type: "GET",
        url: url,
        data: {
            login: thisFormLogin.val()
        },
        success: function (response) {
            if (typeof response === "object") {
                if (response.showPasswordReset) {
                    deferred.reject();
                } else {
                    deferred.resolve();
                }
            }
        },
        error: function () {
            deferred.resolve();
        }
    });
    
    return deferred.promise();
}

function openResetPasswordPopup() {
    dialog.open({
        url:Urls.resetPasswordDialog,
        options: {
            autoOpen: false,
            draggable: false,
            modal: true,
            resizable: false,
            width: "none",
            position: {
                my: "center",
                at: "center",
                of: window
            },
            dialogClass: "cl-actions-dialog password-reset-popup",
            classes: {
                "ui-dialog": "cl-actions-dialog",
                "ui-dialog-title": "cl-actions-dialog__title",
                "ui-dialog-titlebar": "cl-actions-dialog__titlebar",
                "ui-dialog-titlebar-close": "cl-actions-dialog__close",
                "ui-dialog-content": "cl-actions-dialog__content",
                "ui-dialog-buttonpane": "ms-hide"
            },
            buttons: {},
            open: function () {
                //will set dialog title
                var dialogTitle = $(this).closest(".ui-dialog")
                    .find(".ui-dialog-name")
                    .attr("data-name");

                $(this).closest(".ui-dialog")
                    .find(".ui-dialog-title")
                    .text(dialogTitle);

                // Add custom close button icon
                $(this).closest(".ui-dialog")
                    .find(".ui-dialog-titlebar-close")
                    .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

                // Close Dialog When Clicked Outside
                $(document).find(".ui-widget-overlay").bind("click", function () {
                    dialog.close();
                });

                login.reset();
            }
        }
    });
}
var login = {
    /**
     * @private
     * @function
     * @description init submit reset form events
     */
    reset: function () {
        validator.init();
        var $requestPasswordForm = $("[name$=\"_requestpassword\"]"),
            $submit              = $requestPasswordForm.find("[name$=\"_requestpassword_send\"]"),
            $email               = $requestPasswordForm.find("[name$=\"_requestpassword_email\"]");

        // Hide error messages
        $email.on("change", function () {
            dialog.$container.find(".error-message").hide();
        });

        $submit.on("click", function (e) {
            e.preventDefault();

            if (!$requestPasswordForm.valid()) {
                return;
            }

            var data = $requestPasswordForm.serialize();
            // add form action to data
            data += "&" + $submit.attr("name") + "=";
            // make sure the server knows this is an ajax request
            if (data.indexOf("ajax") === -1) {
                data += "&format=ajax";
            }

            $.ajax({
                type: "POST",
                url: $requestPasswordForm.attr("action"),
                data: data,
                success: function (response) {
                    if (typeof response === "object" && !response.success && response.error === Resources.CSRF_TOKEN_MISMATCH) {
                        page.redirect(Urls.csrffailed);
                    } else if (typeof response === "string") {
                        $('body').trigger('success:PaswordReset');
                        dialog.$container.html(response);
                        // Show error messages
                        dialog.$container.find(".error-message").show();
                        // Call recursive function to attach event listeners to new injected HTML
                        login.reset();
                    }
                },
                failure: function () {
                    dialog.$container.html("<h1>" + Resources.SERVER_ERROR + "</h1>");
                }
            });
        });
    },

    /**
     * @private
     * @function
     * @description init events for the loginPage
     */
    init: function () {
        var $registrEmail = $("#RegistrationForm").find(".cl-form-input.email-validation"),
            $registrEmailTaken = $registrEmail.parent().next();

        //o-auth binding for which icon is clicked
        $(".oAuthIcon").bind("click", function () {
            $("#OAuthProvider").val(this.id);
        });

        $("#dwfrm_login").on("submit", function (e) {
            var thisForm = $(this);
            if (thisForm.hasClass("check-login")) {
                e.preventDefault();
                checkCustomerPassword(thisForm).then(function () {
                    var submitButtonName = thisForm.find("[type=submit]").attr("name");
                    thisForm.append("<input type=\"hidden\" name=\"" + submitButtonName + "\"/>").removeClass("check-login");
                    thisForm.submit();
                    $('body').trigger('success:login');
                }, function () {
                    openResetPasswordPopup();
                });
            }
        });

        //toggle the value of the rememberme checkbox
        $("#dwfrm_login_rememberme").bind("change", function () {
            if ($("#dwfrm_login_rememberme").attr("checked")) {
                $("#rememberme").val("true");
            } else {
                $("#rememberme").val("false");
            }
        });

        // realizes correct work for the login checkbox and removes cookie with customer UUID if customer unchecks the remember me box
        $("#dwfrm_login_rememberme").bind("change", function () {
            if ($("#dwfrm_login_rememberme").attr("checked")) {
                $("#dwfrm_login_rememberme").attr("checked", false);
                $("#dwfrm_login_rememberme").val("false");
                var expDate = new Date("January 01, 1970 00:00:00");
                document.cookie = "customerUUID=; expires=" + expDate +"; path=/; SameSite=Lax;";
            } else {
                $("#dwfrm_login_rememberme").val("true");
                $("#dwfrm_login_rememberme").attr("checked", true);
            }
        });

        // emit submit the form
        $("#PasswordResetForm").on("submit", function (e) {
            e.preventDefault();
            return;
        });

        $("#password-reset").on("click", function (e) {
            e.preventDefault();
            openResetPasswordPopup();
        });

        if ($registrEmailTaken.is(":visible")) {
            $registrEmail.on("input", function () {
                $registrEmailTaken.addClass("ms-hide");
            });
        }
    }
};

module.exports = login;
