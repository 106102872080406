

var ajax         = require("../../ajax"),
    atcdialog    = require("../../atcdialog"),
    minicart     = require("../../minicart"),
    productpopup = require("../product/productpopup"),
    progress     = require("../../progress"),
    page         = require("../../page"),
    util         = require("../../util"),
    tilesmatchheight = require("./../../tilesmatchheight"),
    populateHref = require("./populateHref"),
    accessories  = require('../accessories'),
    _            = require("lodash");

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {promise}
 */
var addItemToCart = function (form) {
    var $form = $(form),
        $qty  = $form.find("input[name=\"Quantity\"]"),
        $button = $form.find("button"),
        url;

    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val("1");
    }

    var quantity = $qty.val();
    var productId = $form.find("input[name=\"pid\"]").val();
    if (typeof dynamicYield !== 'undefined') dynamicYield.callEvent("setAddedItem", {productId: productId, quantity: quantity}); // eslint-disable-line no-undef

    var itemId = $form.find("#add-to-cart").attr("data-productid");
    // if ($button.length == 1 && $button.hasClass("addaccessory")) {
    //     url = Urls.addProduct;
    // } else {
    //     url = util.ajaxUrl(Urls.addProduct);
    // }
    url = util.ajaxUrl(Urls.addProduct);

    if ($('#wrapper').hasClass('pt_product-details')) {
        url = util.appendParamToURL(url, 'source', 'pdp');
    }

    // Show error message if quantity is lower then minimum
    var minQuantity = $(".js-qty-error-message");
    if (minQuantity.data("quantity") && $qty.val() < minQuantity.data("quantity")) {
        minQuantity.removeClass("ms-hide");
        progress.hide();
        return;
    } else {
        minQuantity.addClass("ms-hide");
    }

    // Show error message if quantity is higer then maximum
    var maxQuantity = $(".js-max-qty-error-message");
    if (maxQuantity.data("quantity") && parseInt($qty.val()) > parseInt(maxQuantity.data("quantity"))) {
        maxQuantity.removeClass("ms-hide");
        progress.hide();
        return;
    } else {
        maxQuantity.addClass("ms-hide");
    }

    // if ($form.context.id == "bii") {
    //     var biiButton = $(form).find(".bii-submit");
    //     if (biiButton.attr("data-skucode") && biiButton.attr("data-skuprice") && biiButton.attr("data-skutitle")) {
    //         url = util.appendParamToURL(url, "skuCode", biiButton.attr("data-skucode"));
    //         url = util.appendParamToURL(url, "skuPrice", biiButton.attr("data-skuprice"));
    //         url = util.appendParamToURL(url, "skuTitle", biiButton.attr("data-skutitle"));
    //     }
    // }

    var formData = $form.serializeArray();
    if (typeof Clyde !== 'undefined' && Clyde.checkInit()) {
        var selectedContract = Clyde.getSelectedContract();

        if (selectedContract) {
            formData.push({
                name: 'clydePID',
                value: selectedContract.sku
            });
        }
    }

    formData.push({
        name: 'hideRecommendation',
        value:  window.innerWidth < 1025
    });
    return Promise.resolve($.ajax({
        type : "POST",
        url  : itemId ? util.appendParamToURL(url, "pid", itemId) : url,
        data : formData
    })).then(function (response) {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        } else {
            // gtm ua tags
            if (SitePreferences.IS_GTM_UA_ENABLED && ( typeof response != 'object' || response.success) ) {
                ControllerUA.fireAddToCartEvent($form);
            }
            // gtm GA4 Tags
            if (SitePreferences.IS_GTM_GA4_ENABLED && ( typeof response != 'object' || response.success) ) {
                Controller.fireAddToCartEvent({pid: productId, Quantity: quantity});
            }
            
            // Redirect to Cart-Accessories or Cart-Show, depending on result
            if (response.url) {
                window.location = response.url;
            }
            if (typeof dynamicYield !== 'undefined') dynamicYield.callEvent("Add to Cart", {productId: productId, quantity: quantity}); // eslint-disable-line no-undef
            $('body').trigger('product:afterAddToCart', {productId: productId});
            return response;
        }
    });
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e, extraBii) {
    e.preventDefault();
    var isFromCatalog = $("#prod-frame").length && ($(e.currentTarget).closest(document)[0] === $("iframe#prod-frame").contents().context);

    if (e.handled !== true) {
        e.handled = true;
        var $form = $(this).closest("form");
        progress.show();

        // QUOTES MODIFICATION
        if ($("input[name=isQuote]").val() == "true") {
            window.localStorage.setItem("isQuote", true);
            page.redirect(Urls.cartShow);
        } else {
            addItemToCart($form).then(function (response) {
                if (response.success == false) {
                    $(".js-pdp-error-message").text(response.message).removeClass("ms-hide");
                    progress.hide();
                    return;
                } else {
                    $(".js-pdp-error-message").addClass("ms-hide");
                }

                if (response.url) {
                    // we are redirecting to Cart-Accessories, no need to update page elements
                    return;
                }
                var $uuid = $form.find("input[name=\"uuid\"]");
                if ($uuid.length > 0 && $uuid.val().length > 0) {
                    page.refresh();
                } else {
                    // do not close quickview if adding individual item that is part of product set
                    // @TODO should notify the user some other way that the add action has completed successfully
                    if (!$(this).hasClass("sub-product-item")) {
                        atcdialog.close();
                    }

                    // Show notitification popup only on devices
                    if (!(window.modulesSystem.jRes.isDesktop || window.modulesSystem.jRes.isDesktopLarge) && !response.modalUrl) {
                        productpopup.show(response);
                    }

                    // Close product popup on tablet landscape
                    window.modulesSystem.jRes.addFunc([{
                        breakpoint: ["desktop", "desktopLarge"],
                        exit: function () {
                            productpopup.hide();
                        }
                    }]);
                    if(!isFromCatalog && response.modalUrl) {
                        var $dialogWidth;

                        if (!window.userAgent.isDesktop()) {
                            $dialogWidth = "90%";
                        } else {
                            $dialogWidth = "75%";
                        }
                        var modalUrl = response.modalUrl;
                        if (response.crossSellURL) {
                            modalUrl += '&crossSellURL=' + response.crossSellURL;
                        }
                        atcdialog.open({
                            url: modalUrl,
                            resizable: false,
                            options: {
                                title: Resources.MODAL_ITEM_ADDED,
                                draggable: false,
                                dialogClass: "pdp-atc-popup",
                                open: function(){
                                    $('body').css('overflow','hidden');
                                    progress.show();
                                    accessories.init();

                                    // Close Dialog When Clicked Outside
                                    $(document).find(".ui-widget-overlay").on("click", function () {
                                        atcdialog.close();
                                    });

                                    //Close Dialog when clicked on continue shopping button
                                    $(document).find('.continue-btn').on("click", function (event) {
                                        event.preventDefault();
                                        atcdialog.close();
                                    });

                                    // Add custom close button icon
                                    $(this).closest(".ui-dialog")
                                    .find(".ui-dialog-titlebar-close")
                                    .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

                                },
                                close:function() {
                                    progress.hide();
                                    $('body').removeAttr("style");
                                    $('html').removeAttr("style");	
                                }
                            },

                            callback: function () {

                                // // Add class to title bar for styling purposes
                                // $('#atc-modal').closest('.ui-dialog').find('.ui-dialog-titlebar').addClass('atc-modal-titlebar');

                                // //Add continue shopping link: TODO - make sure
                                // if($('.ui-dialog-titlebar .go-to-cart').length === 0) {

                                //     $("<a href='" + Urls.cartShow + "' class='go-to-cart'>" + Resources.GO_TO_CART + "</a>").appendTo('.ui-dialog-titlebar');
                                // }


                                // Slick Tiles
                                // Accessories carousel 
                                
                                if ($('.recc-items .item').length > 3) {
                                    $('.recc-items').slick({
                                      slidesToShow: 3,
                                      slidesToScroll: 1,
                                      arrows: true,
                                      prevArrow: '<span class="prev"><i class="fa fa-angle-left fa-1x" aria-hidden="true"></i></span>',
                                      nextArrow: '<span class="next"><i class="fa fa-angle-right fa-1x" aria-hidden="true"></i></span>',
                                      dots: false,
                                      responsive: [
                                        {
                                          breakpoint: 1024,
                                          settings: {
                                            slidesToShow: 3,
                                            slidesToScroll: 1,
                                            arrows: true
                                          }
                                        },
                                        {
                                          breakpoint: 768,
                                          settings: "unslick"
                                        },
                                      ]
                                    })
                                }
                                // End of accessories carousel configs
    
                                setInterval(function() {
                                if ($('.atc-also-bought .js-product-recommendations-slider').length) {
                                        $('.atc-also-bought .js-product-recommendations-slider').slick({
                                            slidesToShow: 4,
                                            slidesToScroll: 1,
                                            swipeToSlide: true,
                                            autoplay: true,
                                            autoplaySpeed: 2000,
                                            pauseOnHover: true,
                                            arrows: true,
                                            dots: false,
                                            responsive: [
                                                {
                                                breakpoint: 1024,
                                                settings: {
                                                    slidesToShow: 4,
                                                    slidesToScroll: 1,
                                                    arrows: false
                                                    }
                                                },
                                                {
                                                breakpoint: 768,
                                                settings: {
                                                    slidesToShow: 2,
                                                    slidesToScroll: 1,
                                                    arrows: false
                                                    }
                                                }
                                            ]
                                        });
                                    }
                                }, 500);

                                // Products with available bulbs carousel

                                if($('.atc-tiles .product-atc-tile').length > 3) {
                                    $('.atc-tiles').slick({
                                        slidesToShow: 3,
                                        slidesToScroll: 1,
                                        swipeToSlide: true,
                                        autoplay: true,
                                        autoplaySpeed: 2000,
                                        pauseOnHover: true,
                                        arrows: true,
                                        prevArrow: '<span class="prev"><i class="fa fa-angle-left fa-1x" aria-hidden="true"></i></span>',
                                        nextArrow: '<span class="next"><i class="fa fa-angle-right fa-1x" aria-hidden="true"></i></span>',
                                        dots: false,
                                        responsive: [
                                            {
                                            breakpoint: 1024,
                                            settings: {
                                                slidesToShow: 3,
                                                slidesToScroll: 1,
                                                arrows: true
                                                }
                                            },
                                            {
                                            breakpoint: 768,
                                            settings: {
                                                vertical: true,
                                                autoplay: false,
                                                verticalSwiping: true,
                                                slidesToShow: 3,
                                                slidesToScroll: 1,
                                                arrows: false
                                                }
                                            },
                                        ]
                                    });
                                }
                                // End of products with bulbs carousel
                                accessories.pdpItemQuantityHandler($('.atc-tiles .product-atc-tile'), true);
                            }
                        });
                        minicart.update(response.miniCartHtml);
                    } else {
                        minicart.show(response.miniCartHtml);
                        minicart.close(400, 3000);
                    }

                    progress.hide();
                }
            }.bind(this));
        }
    }
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $("#product-set-list").find("form").toArray();
    Promise.all(_.map($productForms, addItemToCart))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1]);
            minicart.close(400, 3000);
        });
};

/**
 * @description Handle error display and disable/enable add to cart button
 */
var disableButton = function () {
    var actionObj = {
        errorMessage        : $(".js-select-error-message"),
        errorMessageTrigger : $(".js-add-to-cart-wrapper"),
        productVariations   : $(".js-variation-select"),
        addToCartBtn        : $("#add-to-cart, #add-to-cart-sticky, #update-wishlist-item"),
        inactiveBtnClass    : "add-to-cart-btn--inactive",
        inactiveErrorClass  : "ms-hide",
        showError           : false
    };

    // Check if variants are selected
    if (actionObj.productVariations.length) {
        populateHref.populateProductVariationOptionValue();

        actionObj.productVariations.each(function () {
            if ($(this).val() !== null && $(this).val().length === 0) {
                actionObj.showError = true;
            }
        });
    }

    // Disable add to cart button if error message is present
    if (actionObj.showError) {
        actionObj.addToCartBtn.addClass(actionObj.inactiveBtnClass);

        // Show error message if not all variants was selected
        actionObj.errorMessageTrigger.on("click", function (e) {
            e.preventDefault();
            var $this = $(this);
            var clickedOnStickyBtn = $this.find("#add-to-cart-sticky").length;

            if (clickedOnStickyBtn) {
                var $addToCartContainer = $(".product-add-to-cart");

                util.scrollToContainer($addToCartContainer);
            }
            actionObj.errorMessage.removeClass(actionObj.inactiveErrorClass);
        });

        return;
    } else {
        actionObj.addToCartBtn.removeClass(actionObj.inactiveBtnClass);

        // Release event listeners and hide error message
        actionObj.errorMessageTrigger.off("click");
        actionObj.errorMessage.addClass(actionObj.inactiveErrorClass);
    }
};

/**
 * @description Handle bii session click before adding product to cart
 */
var addToCartBii = function () {
    window.localStorage.setItem("biiAccept", true);
};

/**
 * @description Handle modal ATC
 */
var addToCartModal = function() {
    var addToCartUrl;
    var pid;
    var $this = $(this);
    var Quantity;
    var $btn = $(this);

    pid = $this.data('pid');
    addToCartUrl = $this.data('url');
    Quantity = $this.parent().find('.atc-tile-qty input').val() || 1;

    var form = {
        pid: pid,
        Quantity: parseInt(Quantity, 10)
    };

    if (addToCartUrl) {
        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: form,
            success: function (data) {
                var minOrder = $btn.closest('.product-details-wrapper').find('.min-order');
                var qtyBox = $btn.closest('.product-details-wrapper').find('.js-item-quantity-input');
                var minQunatity = parseInt(minOrder.data('min-value'), 10);
                qtyBox.val(minQunatity);
                minicart.update(data.miniCartHtml);
                $btn.addClass('modal-atc-added');
                $btn.text(Resources.MODAL_ADDED_TO_CART);

                if (SitePreferences.IS_GTM_GA4_ENABLED && ( typeof data != 'object' || data.success) ) {
                    Controller.fireAddToCartEvent(form);
                }

                setTimeout(function(){
                    $btn.text(Resources.MODAL_ADD_TO_CART);
                    $btn.removeClass('modal-atc-added');
                }, 4000);
            }
        });
    }
}

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function () {
    $(".add-to-cart[disabled]").attr("title", $(".availability-msg").text());
    $(".add-to-cart").on("click", addToCart);
    $("#add-all-to-cart").on("click", addAllToCart);
    $(document).off("click", ".js--add-to-cart-tile").on("click", ".js--add-to-cart-tile", addToCartModal);
    $(document).on("click", ".js-accept-bii", addToCartBii);
    $(document).on("click", ".js-accept-bii", addToCart);
    // If PDP, enable addToCart event to support Dynamic Yield test
    $('#pdpMain').on("addToCart", function (e, el) {
        if (el) addToCart.apply(el, arguments);
    });
};

module.exports.disableButton = disableButton;
