/**
 * @function Enables conditional flow control on order submission for Apple Pay
 */
exports.init = function () {
    var checkoutDataDiv = $("#applePayData"),
        $applePayDiv = $("#hidden-DW_APPLE_PAY"),
        $appleRadio = $("#is-DW_APPLE_PAY");

    if ($applePayDiv.length > 0 && window.ApplePaySession) {
        // Show the radio
        $appleRadio.parents(".is-DW_APPLE_PAY").removeClass("hide");

        // Bind the event to click on the hidden ApplePay button
        $appleRadio.off("click").on("click", function () {
            $applePayDiv.find("button").trigger("click");
        });
    }

    // Not used
    //Verifies if Apple Pay was selected as payment method. Prepare flow
    if (checkoutDataDiv.length > 0) {

        var checkoutData = $(checkoutDataDiv).data("apple");

        if (window.ApplePaySession) {
            $("input[value=\"DW_APPLE_PAY\"]").on("click", function () {
                initPayment(checkoutData);
            });
        }
        //eslint-disable-next-line
        function initPayment(checkoutData) { //NOSONAR
            var shippingName = "Shipping Ground",
                shippingCost = $(".applePayShippingTotal")[0].value,
                totalCost    = $(".applePayOrderTotal")[0].value;

            var lineItems = checkoutData.items;
            lineItems.push({
                "label"  : shippingName,
                "amount" : shippingCost
            });
            var request = {
                countryCode: "US",
                currencyCode: "USD",
                supportedNetworks: ["visa", "masterCard", "amex", "discover"],
                merchantCapabilities: ["supports3DS"],
                lineItems : lineItems,
                total: {"label": checkoutData.merchant.label, "type": "final", "amount": totalCost}
            };

            var session = new ApplePaySession(1, request);

            // Merchant Validation
            session.onvalidatemerchant = function () {
                var promise = performValidation();
                promise.then(function (merchantSession) {
                    session.completeMerchantValidation(merchantSession);
                });
            };

            function performValidation() {
                return new Promise(function (resolve, reject) {
                    var xhr = new XMLHttpRequest();
                    xhr.onload = function () {
                        var data = JSON.parse(this.responseText);
                        resolve(data);
                    };
                    xhr.onerror = reject;
                    xhr.open("GET", checkoutData.merchant.validationURL, true);
                    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                    xhr.send();
                });
            }

            session.onpaymentmethodselected = function () {
                var newTotal     = {
                    type   : "final",
                    label  : checkoutData.label,
                    amount : checkoutData.total
                };
                var newLineItems = [];

                session.completePaymentMethodSelection(newTotal, newLineItems);
            };

            session.onpaymentauthorized = function () {
                let objJsonStr = JSON.stringify(paymentToken.paymentData); //eslint-disable-line
                let objJsonB64 = window.btoa(objJsonStr);
                $("#applePayData").data("token", objJsonB64);
            };

            session.oncancel = function () {

            };

            session.begin();
        }
    }
};

