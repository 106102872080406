

const dialogCongig = {
    autoOpen: false,
    draggable: false,
    modal: true,
    resizable: false,
    width: "none",
    position: {
        my: "center",
        at: "center",
        of: window
    },
    dialogClass: "cl-actions-dialog",
    classes: {
        "ui-dialog": "cl-actions-dialog cl-actions-dialog--large cl-actions-dialog--zindex",
        "ui-dialog-title": "cl-actions-dialog__title",
        "ui-dialog-titlebar": "cl-actions-dialog__titlebar",
        "ui-dialog-titlebar-close": "cl-actions-dialog__close js-cl-actions-dialog__close",
        "ui-dialog-content": "cl-actions-dialog__content"
    },
    open: function () {
        //will set dialog title
        var dialogTitle = $(this).closest(".ui-dialog")
            .find(".ui-dialog-name")
            .attr("data-name");

        $(this).closest(".ui-dialog")
            .find(".ui-dialog-title")
            .text(dialogTitle);

        // Add custom close button icon
        $(this).closest(".ui-dialog")
            .find(".ui-dialog-titlebar-close")
            .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

        // Close Dialog When Clicked Outside
        $(document).find(".ui-widget-overlay").bind("click", function () {
            $(document).find(".js-save-cart").dialog("close");
        });

        // Check for error message in registration form
        var errorsLength = $(".form-caption.error-message").length;
        if (errorsLength > 0) {
            $(".form-caption.error-message").show();
            $(".js-save-cart-error").show();
        }
    }
};

/**
* @private
* @function
* @description Initialize additional functionality for Save Cart
*/
function saveCart() {
    var $saveCartButton     = $("#show-save-cart"),
        $saveCartArea       = $(".js-save-cart"), // modal window
        $saveCartError      = $(".js-save-cart-error"), // validation success message
        $saveCartSuccess    = $(".js-save-cart-success"), // validation error message
        $formControls       = $(".js-save-cart-controls"), // controlls elements block
        $replaceCart        = $(".js-save-cart-replace"), // replace cart elements block
        $replaceCartConfirm = $(".js-save-cart-replace-confirm"),
        $replaceCartCancel  = $(".js-save-cart-replace-cancel"),
        $saveCartSubmit     = $(".js-save-cart-button"),
        $saveCartNoName     = $(".js-save-cart-no-name");

    dialogCongig.close = function () {
        $saveCartSuccess.hide();
        $saveCartError.hide();
        $replaceCart.hide();
        $formControls.show();
        $saveCartNoName.hide();
    };

    $saveCartArea.dialog(dialogCongig);

    var formSelector = "#save-cart",
        postSaveCart = function () {
            var url = Urls.saveCart;

            $.ajax({
                type: "POST",
                url: url,
                data: $(formSelector).serialize()
            }).done(function (data) {
                if (data.success) {
                    $saveCartSuccess.show();
                    $formControls.hide();
                } else {
                    $saveCartError.show();
                    $formControls.show();
                }
            });

            return false;
        };

    if ($saveCartButton.data("load-state") == "open") {
        $saveCartArea.dialog("open");
    }

    $saveCartButton.on("click", function () {
        var isLogin = ($(this).data("action") == "login");

        if (isLogin) {
            $saveCartArea.dialog("open");
        } else {
            var url = Urls.saveCartProd;

            $.ajax({
                type: "GET",
                url: url
            }).done(function (response) {
                if (response != "" && response != "null") {
                    $saveCartArea.dialog("open");
                    $("#dwfrm_savedCart").html(response);
                    $("#dwfrm_savedCart").selectmenu({
                        classes: {
                            "ui-selectmenu-button": "cl-sorting-bar__button ms-hide",
                            "ui-selectmenu-icon": "cl-sorting-bar__icon",
                            "ui-selectmenu-menu": "cl-sorting-bar__menu cl-sorting-bar__menu--backdrop",
                            "ui-menu-item": "cl-sorting-bar__menu-item"
                        },
                        create: function () {
                            $(this).next().find(".ui-selectmenu-icon").html("<svg class=\"cl-svg-icon cl-sorting-bar__svg-icon\"><use xlink:href=\"#drop-down\"></use></svg>");
                        },
                        change: function (event, ui) {
                            $(this).trigger("change", ui);
                        }
                    });
                }
            });
        }
    });

    $saveCartSubmit.on("click", function (e) {
        e.preventDefault();
        var cartName = $(formSelector).find("input[name=\"cartName\"]").val(),
            selectedCartID = $(formSelector).find("select").val(),
            replaceMatch = false;

        $saveCartSuccess.hide();
        $saveCartError.hide();

        //validations
        if (selectedCartID == "" && cartName == "") {
            $saveCartNoName.show();
            return false;
        }

        $(formSelector).find("select").find("option").each(function (i, el) {
            var thisName = $(el)[0].innerText;
            if (thisName == cartName) {
                replaceMatch = true;
                return false;
            }
        });

        if (cartName != "" && replaceMatch) {
            $replaceCart.show();
            $formControls.hide();
            return false;
        }

        postSaveCart();
    });

    $replaceCartConfirm.on("click", function (e) {
        e.preventDefault();
        $replaceCart.hide();

        var cartName = $(formSelector).find("input[name=\"cartName\"]").val();

        $(formSelector).find("select").find("option").each(function (i, el) {
            var thisName = $(el)[0].innerText;

            if (thisName == cartName) {
                $(formSelector).find("select").val(el.value);
            }
        });

        $(formSelector).find("input").val("");

        postSaveCart();
    });

    $replaceCartCancel.on("click", function (e) {
        e.preventDefault();
        $replaceCart.hide();
        $formControls.show();
    });

    // Close Save Cart modal when you click outside
    $(".js-save-cart-link").on("click", function () {
        var modalOverlay = $(".ui-widget-overlay");

        if (modalOverlay.length > 0) {
            modalOverlay.mouseup(function (e) {
                var container = $(".ui-dialog");

                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    $(".js-cl-actions-dialog__close").trigger("click");
                }
            });
        }
    });
}

exports.init = function () {
    saveCart();
};
