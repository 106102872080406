'use strict';

function getCurrentAccountSection() {
    return $('.cl-account__title').text().trim().toLowerCase() || $('.cl-credit-card-page__title').text().trim().toLowerCase();
}

function createEvent(elementPrimary, elementSecondary, elementDescription) {
    dataLayer.push({
		event: 'gtm_event',
		non_interaction_hit: false,
        event_name: 'account_dashboard_interaction',
        element: 'account dashboard',
        element_action: 'click',
        element_ordinal: 'primary',
        element_primary: elementPrimary,
        element_secondary: elementSecondary.toLowerCase() || null,
        element_description: elementDescription.toLowerCase() || null,
        account_section: getCurrentAccountSection() || null,
    });
}

module.exports = function () {
    $('body').on('click', '.cl-account-options .cl-account-options__item-link', function () {
        var itemName = $(this).text().trim();
        createEvent('account section', '', itemName);
    }).on('click', '#editProfile', function () {
        createEvent('edit', '', 'edit');
    } ).on('click', '#saveProfile', function () {
        createEvent('edit', '', 'save');
    }).on('change', '.cl-email-preferences__subscriptions input[type="checkbox"]', function () {
        var type;
        var $formRow = $(this).closest('.form-row');
        var label = $formRow.text().trim();
        var status = !!$(this).is(':checked') ? 'checked' : 'unchecked';
        if ($formRow.hasClass('subscription')) {
            type = "subscriptions";
        } else if ($formRow.hasClass('wishlist')) {
            type = "wishlist alerts";
        }
        createEvent('newsletter', type, 'change to:' + status + ' | name:' + label);
    }).on('custom:updateAddress', function () {
        var action = window.addressAction;
        if (action) {
            createEvent('addresses', action, 'manage addresses');
            delete window.addressAction;
        }
    }).on('click', '.address-make-default', function () {
        createEvent('addresses', 'change default', 'manage addresses');
    });
    $('body').on('click', '.order-details-button', function () {
        createEvent('order history', '', 'view');
    });
};
