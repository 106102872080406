'use strict';

function accordionTabs() {
    $(".js-content-toggle").on('click', '.widget-title', function (e) {
        $(this).next('.widget-content').toggle(200);
        $(this).parents('.widget').toggleClass('active');
    });
}

exports.init = function () {
    accordionTabs();
};