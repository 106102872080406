var progress = require("../progress"),
    ajax = require("../ajax"),
    tooltip = require("../tooltip"),
    dialog = require("../dialog"),
    util = require("../util"),
    /* -- Start OSF One Page Checkout changes -- */
    opc = require("./opc");
    /* -- End OSF One Page Checkout changes -- */

var shippingMethods,
    isRecurring = $("[name$=\"_billingAgreementID\"]").val() !== "null";

var amazonPaymentsObject = {

    /*
    * This function is used to initialize address book widget
    */
    initializeAddressBookWidget : function () {

        var refID              = null,
            billingAgreementId = null;

        var params = {
            sellerId : SitePreferences.AMAZON_MERCHANT_ID  || "",
            design : {
                designMode : this.getDesignMode()
            },
            onError : function (error) {
                // display error message
                alert(error.getErrorMessage()); //NOSONAR
            }
        };

        if (isRecurring) {
            billingAgreementId = $("[name$=\"_billingAgreementID\"]").val();
            params.agreementType = "BillingAgreement";
            params.amazonBillingAgreementId = billingAgreementId;
        } else {
            refID = $("[name$=\"_orderReferenceID\"]").val();
            params.amazonOrderReferenceId = refID;
        }

        params.onAddressSelect = function () {
            // update shipping methods list
            var params = isRecurring ? {billingAgreementID : billingAgreementId} : {orderReferenceID : refID}; //NOSONAR

            ajax.getJson({
                url : util.appendParamsToUrl(Urls.getAddressDetails, params),
                callback : function (address) {
                    amazonPaymentsObject.updateFormsWithAmazonData(address);
                    
                    /* -- Start OSF One Page Checkout changes -- */
                    opc.checkout.updateShippingMethodList(params);
                    /* -- End OSF One Page Checkout changes -- */

                    // Update billing summary
                    $.ajax({
                        url : isRecurring ? Urls.getBillingAgreementBillingDetails : Urls.getOrderReferenceBillingDetails,
                        method : "GET",
                        success : function (data) {
                            $("div.apAddressBilling").replaceWith(data);
                        }
                    });
                }
            });
        };

        new OffAmazonPayments.Widgets.AddressBook(params).bind("addressBookWidgetDiv");
    },

    /*
     * Wallet widget for billing page.
     * Widget content id: walletWidgetDiv
     */
    initializeWalletWidget : function () {
        var params = {
            sellerId : SitePreferences.AMAZON_MERCHANT_ID || "",
            onPaymentSelect : function () {
                // enable continue button after payment selection
                if (!isRecurring || $("#consentWidgetDiv").length == 0) {
                    // disable continue button
                    $("[name$=\"_save\"]").removeAttr("disabled");
                }

                $.ajax({
                    url : isRecurring ? Urls.getBillingAgreementBillingDetails : Urls.getOrderReferenceBillingDetails,
                    method : "GET",
                    success : function (data) {
                        $("div.apAddressBilling").replaceWith(data);
                    }
                });
            },
            design : {
                designMode : this.getDesignMode()
            },
            onError : function (error) {
                // display error message
                alert(error.getErrorMessage()); //NOSONAR
            }
        };

        if (isRecurring) {
            params.amazonBillingAgreementId = $("[name$=\"_billingAgreementID\"]").val();
        } else {
            params.amazonOrderReferenceId = $("[name$=\"_orderReferenceID\"]").val();
        }

        // disable continue button
        $("[name$=\"_save\"]").attr("disabled", "disabled");

        new OffAmazonPayments.Widgets.Wallet(params).bind("walletWidgetDiv");

    },

    /*
     * Consent widget for billing page.
     * Widget content id: consentWidgetDiv
     */
    initializeConsentWidget : function () {
        var buyerBillingAgreementConsentStatus,
            billingAgreementId = $("[name$=\"_billingAgreementID\"]").val(),
            consent            = $("[name$=\"consent\"]").val(),
            $orderSubmitBtn    = $("[name$=\"_save\"]");

        if (consent == "optional") {
            $orderSubmitBtn.removeAttr("disabled");
        }

        new OffAmazonPayments.Widgets.Consent({
            sellerId : SitePreferences.AMAZON_MERCHANT_ID  || "",
            // amazonBillingAgreementId obtained from the Amazon Address Book widget.
            amazonBillingAgreementId : billingAgreementId,
            onReady : function (billingAgreementConsentStatus) {
                // Called after widget renders
                if (consent == "optional") {
                    $orderSubmitBtn.removeAttr("disabled");
                } else {
                    if (typeof billingAgreementConsentStatus.getConsentStatus == "function") {
                        buyerBillingAgreementConsentStatus = billingAgreementConsentStatus.getConsentStatus();
                    }
                    if (buyerBillingAgreementConsentStatus == "false") {
                        $orderSubmitBtn.attr("disabled", "disabled");
                    } else {
                        $orderSubmitBtn.removeAttr("disabled");
                    }
                }
            },
            onConsent : function (billingAgreementConsentStatus) {
                // getConsentStatus returns true or false
                // true – checkbox is selected – buyer has consented
                // false – checkbox is unselected – buyer has not consented
                buyerBillingAgreementConsentStatus = billingAgreementConsentStatus.getConsentStatus();

                if (consent == "optional") {
                    $orderSubmitBtn.removeAttr("disabled");
                } else {
                    if (buyerBillingAgreementConsentStatus == "false") {
                        $orderSubmitBtn.attr("disabled", "disabled");
                    } else {
                        $orderSubmitBtn.removeAttr("disabled");
                    }
                }

                $.ajax({
                    url : Urls.setConsentStatus,
                    method : "POST",
                    data : {
                        consentStatus : buyerBillingAgreementConsentStatus
                    }
                });
            },
            design : {
                designMode : this.getDesignMode()
            },
            onError : function (error) {
                // display error message
                alert(error.getErrorMessage()); //NOSONAR
            }
        }).bind("consentWidgetDiv");
    },

    /*
     * This function is used to determine the design mode for the amazon widgets.
     */
    getDesignMode: function () {
        // check if it's a touch device with a small screen
        if (("ontouchstart" in window) && $(window).width() < 600) {
            return "smartphoneCollapsible";
        }

        return "responsive";
    },

    /*
     * AmazonPayButton.
     * Content id: AmazonPayButton
     */
    initializeAmazonPayButton: function ($btn) {
        var authRequest,
            data = {},
            btnData = $btn.data(),
            id = $btn.attr("id");

        data.merchantId = SitePreferences.AMAZON_MERCHANT_ID;
        data.scope = SitePreferences.AMAZON_SCOPE;

        // attach a random generated id
        if (!id) {
            id = amazonPaymentsObject.getUniqueId();
            $btn.attr("id", id);
        }

        if (btnData.button == "payButton") {
            data.type = SitePreferences.AMAZON_PAY_BUTTON_TYPE;
            data.color = SitePreferences.AMAZON_PAY_BUTTON_COLOR;
            data.size = SitePreferences.AMAZON_PAY_BUTTON_SIZE;
            data.redirect = SitePreferences.AMAZON_PAY_REDIRECT_URL;
        } else if (btnData.button == "editButton") {
            data.type = "EditOrUpdate";
            data.color = SitePreferences.AMAZON_PAY_BUTTON_COLOR;
            data.size = SitePreferences.AMAZON_PAY_BUTTON_SIZE;
            data.redirect = Urls.editButtonRedirect;
        } else {
            // login button functionality
            data.type = SitePreferences.AMAZON_LOGIN_BUTTON_TYPE;
            data.color = SitePreferences.AMAZON_LOGIN_BUTTON_COLOR;
            data.size = SitePreferences.AMAZON_LOGIN_BUTTON_SIZE;
            data.redirect = SitePreferences.AMAZON_LOGIN_REDIRECT_URL;
        }

        if (btnData.page == "shipping") {
            data.redirect += "?shipping=true";
        }

        var params = {
            type : data.type,
            color : data.color,
            size : data.size,
            language : btnData.locale,
            useAmazonAddressBook : true,
            authorization : function () {
                if ($("[name$=\"amazonPayLogin\"]").val() == "false") {
                    window.location.href = data.redirect;
                } else {
                    var loginOptions = {scope : data.scope};
                    authRequest = amazon.Login.authorize(loginOptions, function () {
                        // do nothing here, instead wait for billing agreement to be generated in onSignIn
                    });
                }
            },
            onError : function () {
                // Write your custom error handling
            }
        };


        if ($("[name$=\"isRecurring\"]").val() == "true" || data.type == "EditOrUpdate") {
            params.agreementType = "BillingAgreement";

            params.onSignIn = function (billingAgreement) {
                var billingAgreementId = billingAgreement.getAmazonBillingAgreementId(),
                    url                = util.appendParamToURL(data.redirect, "billingAgreementID", billingAgreementId);

                if (data.type == "EditOrUpdate") {
                    authRequest.onComplete(function (a) {
                        amazon.Login.retrieveProfile(a.access_token, function (resp) {
                            if ($(".emailAddress").text() != resp.profile.PrimaryEmail) {
                                amazon.Login.logout();

                                dialog.open({
                                    html: "<h1>" + Resources.AMAZON_LOGIN_ERROR + "</h1>",
                                    options: {
                                        buttons: [{
                                            text: Resources.OK,
                                            click: function () {
                                                $(this).dialog("close");
                                            }
                                        }]
                                    }
                                });
                            } else {
                                window.location = url + "&access_token=" + a.access_token;
                            }
                        });
                    });
                } else {
                    if (authRequest) {
                        authRequest.onComplete(url);
                    }
                }

            };
        } else {
            params.onSignIn = function (orderReference) {
                var orderReferenceId = orderReference.getAmazonOrderReferenceId(),
                    url              = util.appendParamToURL(data.redirect, "orderReferenceID", orderReferenceId);

                authRequest.onComplete(url);
            };
        }

        OffAmazonPayments.Button(id, data.merchantId, params); //eslint-disable-line
    },

    /**
     * Adds the event listener on the logout button.
     */
    initLogout: function () {

        var $logoutBtn = $(".js-logout");

        if ((typeof amazon != "undefined") && amazon.Login) {

            if ($logoutBtn.length) {
                $logoutBtn.on("click", function (e) {
                    e.preventDefault();
                    amazon.Login.logout();
                    if ($(this).attr("href")) {
                        window.location = $(this).attr("href");
                    }
                });
            }
            // if an element on the page has this attribute
            // logout the user from the amazon account
            if ($("[data-amz-logout]").length) {
                amazon.Login.logout();
            }
        }
    },

    /*
     * This function is used to update shipping methods list with appropriate shipping methods
     */
    updateShippingMethodsList : function (params) {
        var $shippingMethodList = $("#shipping-method-list"),
            url                 = util.appendParamsToUrl(Urls.shippingMethodsJSON, params);

        if (!$shippingMethodList || $shippingMethodList.length === 0) { return; }

        $.ajax({
            dataType: "json",
            url: url,
        })
            .done(function (data) {
                if (!data) {
                    window.alert("Couldn't get list of applicable shipping methods."); //NOSONAR
                    return false;
                }

                if (shippingMethods && shippingMethods.toString() === data.toString()) {
                    $("#apShippingContinue").removeAttr("disabled");
                    // No need to update the UI.  The list has not changed.
                    return true;
                }

                // We need to update the UI.  The list has changed.
                // Cache the array of returned shipping methods.
                shippingMethods = data;
                // indicate progress
                progress.show($shippingMethodList);
                // load the shipping method form
                var smlUrl =  util.appendParamsToUrl(Urls.shippingMethodsList, params);
                $shippingMethodList.load(smlUrl, function () {
                    $shippingMethodList.fadeIn("fast");
                    // rebind the radio buttons onclick function to a handler.
                    $shippingMethodList.find("[name$=\"_shippingMethodID\"]").click(function () {
                        amazonPaymentsObject.selectShippingMethod($(this).val(), params);
                    });

                    // update the summary
                    amazonPaymentsObject.updateSummary();
                    progress.hide();
                    $("#apShippingContinue").removeAttr("disabled");
                    tooltip.init();
                    //if nothing is selected in the shipping methods select the first one
                    if ($shippingMethodList.find(".input-radio:checked").length === 0) {
                        $shippingMethodList.find(".input-radio:first").attr("checked", true);
                    }
                });
            });
    },

    /*
     * This function is used to select active shipping method
    */
    selectShippingMethod : function (shippingMethodID, params) {
        // nothing entered
        if (!shippingMethodID) {
            return;
        }
        params.shippingMethodID = shippingMethodID;
        // attempt to set shipping method
        var url = util.appendParamsToUrl(Urls.selectShippingMethodsList, params);
        $.ajax({
            dataType: "json",
            url: url,
        })
            .done(function (data) {

                amazonPaymentsObject.updateSummary();

                if (!data || !data.shippingMethodID) {
                    window.alert("Couldn't select shipping method."); //NOSONAR
                    return false;
                }
                // display promotion in UI and update the summary section,
                // if some promotions were applied
                $(".shippingpromotions").empty();
            });
    },

    /*
     * This function is used to update summary section
     */
    updateSummary : function () {
        var $summary = $("#secondary.summary");
        // indicate progress
        progress.show($summary);
        // load the updated summary area
        $summary.load(Urls.summaryRefreshURL, function () {
            // hide edit shipping method link
            $summary.fadeIn("fast");
            $summary.find(".checkout-mini-cart .minishipment .header a").hide();
            $summary.find(".order-totals-table .order-shipping .label a").hide();
        });
    },

    /**
     * This function will generate a unique id
     */
    getUniqueId: function () {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        return "random-" + Math.random().toString(36).substr(2, 9);
    },

    /*
     * this function is used to init Amazon Payments popup
     */
    initPopup : function () {
        $(document).on("click", "a.amazonpopup", function (e) {
            e.preventDefault();
            window.open($(this).attr("href"), "", "width=626,height=436");
            return false;
        });
    },

    /*
     * this function is used to init Amazon Payments wallet load event
     */
    initWalletLoadEvent : function () {
        if ($("#walletWidgetDiv").length > 0) {
            amazonPaymentsObject.initializeWalletWidget();
        }
    },

    /*
     * this function is used to init shipping handling during checkout
     */
    initHandleShipping : function () {
        var $billingAddress = $("#checkout-billing-address"),
            url             = Urls.amazonHandleShipping;

        $(document).on("click", "#apShippingContinue", function () {
            progress.show($billingAddress);
            $.ajax({
                url: url
            })
                .done(function () {
                    progress.hide();
                    $("#apBillingContinue, #apGiftContinue").removeAttr("disabled");
                    amazonPaymentsObject.initWalletLoadEvent();
                });
        });

        if ($(".js-logout-amazon").length > 0) {
            $(document).on("click", ".js-button-step", function () {
                $(".js-button-step-final").removeAttr("disabled");
                $(".js-button-step-final").css("pointer-events", "auto");
            });
        }
    },

    /*
     * this function is used to init popup for cancel billing agreement on orderhistory page
     */
    initCancelBAPopup : function () {
        $(document).on("click", "a.ap-cancel-order", function (e) {
            e.preventDefault();

            dialog.open({
                url: $(e.target).attr("href"),
                options: {
                    open: function () {
                        $(".close").on("click", function (e) { //NOSONAR
                            e.preventDefault();
                            $.ajax({
                                url: $(this).attr("href")
                            }).done(function (data) {
                                if (data.success) {
                                    window.location.assign(window.location.href);
                                } else {
                                    $(".ba-wrapper").html(data.error);
                                }
                            });
                        });
                    }
                }
            });
        });
    },

    /*
     * This function will be used to scroll the page to the wallet widget if
     * it has the data-scroll-to-element attribute set.
     */
    scrollToWidget: function () {
        var $el = $("[data-scroll-to-element]").last();
        var offset;
        if ($el.length) {
            offset = $el.offset();
            $("html, body").animate({scrollTop: offset.top + "px"});
        }
    },


    /*
        This function is used to update shipping form with Amazon Address Book information
    */
    updateFormsWithAmazonData : function (address) {
        var checkoutForm = $("form.address"),
            fullName     = address.fullName,
            name         = [],
            firstName    = "",
            lastName     = "";
            
        if (fullName) {
            name         = fullName.split(" ");
            firstName    = name.shift();
            lastName     = name.join(" ");
        }

        checkoutForm.find("input[name$='shippingAddress_addressFields_firstName']").val(firstName);
        checkoutForm.find("input[name$='shippingAddress_addressFields_lastName']").val(lastName);
        checkoutForm.find("input[name$='shippingAddress_addressFields_address1']").val(address.address1);
        checkoutForm.find("input[name$='shippingAddress_addressFields_address2']").val(address.address2);
        checkoutForm.find("select[name$='shippingAddress_addressFields_country']").val(address.countryCode);
        checkoutForm.find("select[name$='shippingAddress_addressFields_states_state']").val(address.stateCode);
        checkoutForm.find("input[name$='shippingAddress_addressFields_city']").val(address.city);
        checkoutForm.find("input[name$='shippingAddress_addressFields_postal']").val(address.postalCode);
        checkoutForm.find("input[name$='shippingAddress_addressFields_phone']").val(address.phone);

        checkoutForm.find("input[name$='billing_billingAddress_addressFields_firstName']").val(firstName);
        checkoutForm.find("input[name$='billing_billingAddress_addressFields_lastName']").val(lastName);
        checkoutForm.find("input[name$='billing_billingAddress_addressFields_address1']").val(address.address1);
        checkoutForm.find("input[name$='billing_billingAddress_addressFields_address2']").val(address.address2);
        checkoutForm.find("select[name$='billing_billingAddress_addressFields_country']").val(address.countryCode);
        checkoutForm.find("select[name$='billing_billingAddress_addressFields_states_state']").val(address.stateCode);
        checkoutForm.find("input[name$='billing_billingAddress_addressFields_city']").val(address.city);
        checkoutForm.find("input[name$='billing_billingAddress_addressFields_postal']").val(address.postalCode);
        checkoutForm.find("input[name$='billing_billingAddress_addressFields_phone']").val(address.phone);
        checkoutForm.find("input[name$='billing_billingAddress_email_emailAddress']").val(address.email);
    },

    /*
     * This function is used initialize necessary functions
     */
    init : function () {
        var $amazonBtns = $(".js-amazon-button");

        if ($("#addressBookWidgetDiv").length > 0) {
            amazonPaymentsObject.initializeAddressBookWidget();
        } else if ($(".apAddressShipping").length > 0 && $("[name$=\"_billingAgreementID\"]").val()) {
            var params = {billingAgreementID: $("[name$=\"_billingAgreementID\"]").val()};
            /* -- Start OSF One Page Checkout changes -- */
            opc.checkout.updateShippingMethodsList(params);
            /* -- End OSF One Page Checkout changes -- */
        }

        amazonPaymentsObject.initHandleShipping();

        if ($("#consentWidgetDiv").length > 0) {
            amazonPaymentsObject.initializeConsentWidget();
        }

        if ($amazonBtns.length > 0) {
            $amazonBtns.each(function () {
                amazonPaymentsObject.initializeAmazonPayButton($(this));
            });
            amazonPaymentsObject.initPopup();
        }

        amazonPaymentsObject.initCancelBAPopup();

        amazonPaymentsObject.initLogout();
        amazonPaymentsObject.scrollToWidget();
    }
};

module.exports = amazonPaymentsObject;
