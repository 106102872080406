

var maxlength = require("../maxlength"),
    minicart = require('../minicart');

/**
 * @private
 * @function
 * @description create custom jQuery UI widget
 */
function customJquertUiSelect() {
    $.widget("ui.selectmenu", $.ui.selectmenu, {
        _renderItem: function (ul, item) {
            var li = $("<li>"),
                wrapper = $("<div>", {
                    text: item.label
                });

            if (item.disabled) {
                li.addClass("ui-state-disabled");
            }

            if (item.element.attr("data-hidden")) {
                li.addClass("ui-state-hidden");
            }

            if (item.element.attr("data-icon")) {
                $("<img>", {
                    "src": item.element.attr("data-icon"),
                    "alt": Resources.VENDOR_SKU,
                    "class": "cl-sorting-bar__thumb"
                }).prependTo(wrapper);
            }

            return li.append(wrapper).appendTo(ul);
        }
    });
}

/**
 * @private
 * @function
 * @description Initialize accessory events listeners
 */
function accessoriesEvents() {
    var listAccessories = $(".js-accessories-list"),
        descAccessories = $(".js-accessories-desc");

    // Load additional variations for the product
    $("body").off("change").on("change", ".cross-accessories", function (e) {

        var $this = $(this),
            $itemWrapper = $this.closest(".js-product-item");
        
        e.preventDefault();

        // in case master product was changed after accessories options were selected
        $itemWrapper.find(".js-item-variants").empty();
        $itemWrapper.find(".js-price-section").empty();
        $itemWrapper.find(".add-to-cart-acc").attr("disabled", "disabled");

        // Make ajax call only if we have pid
        if ($this.val()) {
            jQuery.ajax({
                type: "GET",
                url: Urls.getAccessoryImage,
                data: {
                    pid: $(this).val()
                }
            }).done(function (response) {
                if (response) {
                    $itemWrapper.find(".js-accessory-image").html(response);
                }
            });

            jQuery.ajax({
                type: "GET",
                url: Urls.getVariantAttributes,
                data: {
                    pid: $(this).val()
                }
            }).done(function (response) {
                if (response) {
                    $this.siblings(".item-variants").html(response);
                    optionSelect();
                    accessoriesEvents();
                    // trigger AJAX call if there is only one accessories variant option
                    var accessories = $this.siblings(".item-variants").find(".accessories-variant:not(.cross-accessories)"); //NOSONAR
                    if (accessories) {
                        accessories.each(function () {
                            if ($(this).find("option").length === 1) {
                                $(this).trigger("change");
                            }
                        });
                    }
                }
            });
        } else {
            // Clean elements
            $itemWrapper.find(".js-item-variants").empty();
            $itemWrapper.find(".js-price-section").empty();
        }
    });

    // Get variations availability
    $("body").on("change", ".accessories-variant:not(.cross-accessories)", function (e) {
        e.preventDefault();
        var $this = $(this),
            $optionValue = $this.val() ? $this.val() : "",
            $priceSection = $this.parents(".js-product-item").find(".js-price-section"),
            //eslint-disable-next-line
            $itemWrapper = $this.closest(".js-product-item"); //NOSONAR

        // Make ajax call only if we have pid
        if ($optionValue.length) {
            jQuery.ajax({
                type: "GET",
                url: Urls.getVariantAttributes,
                data: urlQueryToObject($optionValue)
            }).done(function (response) {
                if (response) {
                    var $wrapper = $this.parents(".item-variants");
                    $wrapper.html(response);
                    optionSelect();
                    accessoriesEvents();
                    // disable select menu if there is only one accessories variant option
                    var accessories = $wrapper.find(".accessories-variant:not(.cross-accessories)"); //NOSONAR
                    if (accessories) {
                        accessories.each(function () {
                            if ($(this).find("option").length === 1) {
                                $(this).siblings(".cl-variation-select").addClass("ui-selectmenu-disabled ui-state-disabled");
                            }
                        });
                    }
                    jQuery.ajax({
                        type: "GET",
                        url: $optionValue
                    }).done(function (response) {//NOSONAR
                        if (response.success) {
                            if (response.availableCount > 0) {
                                $wrapper.siblings(".cart-acc").find(".itempid").val(response.pid);
                                $wrapper.siblings(".cart-acc").find(".add-to-cart-acc").removeAttr("disabled");
                                $wrapper.siblings(".js-validation-message").fadeOut();
                                $priceSection.html(response.html);
                            } else {
                                $wrapper.siblings(".cart-acc").find(".add-to-cart-acc").attr("disabled", "disabled");
                            }

                            var attributes      = $wrapper.find(".accessories-variant"),
                                attrName        = $this.attr("name"),
                                resetAttributes = false;

                            for (var i = 0; i < attributes.length; i++) {
                                var attribute = $(attributes[i]);

                                // if last selected attribute already found, reset subsequent attributes
                                if (resetAttributes) {
                                    attribute.val("");
                                    attribute.selectmenu("refresh");
                                    attribute.selectmenu("option", "disabled", true);
                                    continue;
                                }

                                var prevAttribute = $(attributes[i - 1]);
                                if (!prevAttribute.find("option:selected").hasClass("emptytext")) {
                                    if (attribute.find("option").length > 1) {
                                        attribute.selectmenu("option", "disabled", false);
                                    }
                                }

                                if ((!attribute.val() && (!attrName || prevAttribute.attr("name") == attrName)) || attribute.find("option:selected").hasClass("emptytext")) {
                                    resetAttributes = true;
                                }
                            }

                            // Updates product information for Out Stock product when all selected attributes already found
                            if (response.availableCount == 0 && i == attributes.length && !resetAttributes) { //eslint-disable-line
                                $wrapper.siblings(".cart-acc").find(".itempid").val(response.pid);
                                $wrapper.siblings(".cart-acc").find(".add-to-cart-acc").attr("disabled");
                                $priceSection.html(response.html);
                            }
                        } else {
                            $this.parents(".item-variants").siblings(".cart-acc").find(".add-to-cart-acc").attr("disabled", "disabled");
                        }
                    });
                }
            });
        } else {
            $this.parents(".item-variants").siblings(".cart-acc").find(".add-to-cart-acc").attr("disabled", "disabled");
            $this.parents(".item-variants").find(".cl-more-variants").not($this.parents(".cl-more-variants")).each(function () {
                $(this).find(".accessories-variant").val("");
                $(this).find(".accessories-variant").attr("disabled", "disabled");
                $(this).find(".accessories-variant").selectmenu("option", "disabled", true);
                $(this).find(".accessories-variant").selectmenu("refresh");
            });
            return false;
        }
    });

    // Remove item from the Accessory list
    $(".js-remove-accessory-item").off("click").on("click", function (e) {
        e.preventDefault();
        var removeItemId = $(this).attr("data-remove");
        $(document).find(".js-accessories-list [data-item=\"" + removeItemId + "\"]").remove();

        // Visual feedback of summary block
        if (listAccessories.children().length < 1) {
            listAccessories.hide();
            descAccessories.fadeIn();
        }
    });

    // Accessory option select control
    $('body').off("click").on("click", ".js-accessories-add-to-cart", function (e) {
        e.preventDefault();
        e.stopPropagation();

        var buttonwrapper     = $(this).parents(".js-product-item"),
            validationMessage = buttonwrapper.find(".js-validation-message");

        validationMessage.fadeIn();
    });

    // trigger when adding an accessory to the cart
    $('body').off("click").on("click", '.add-to-cart-acc', function (e) {
        var itemWrapper = $(this).closest(".js-product-item"),
            $priceSection = $(this).parents(".js-product-item").find(".js-price-section"),
            $container = $(this).closest('.recc-items__info-wrapper'),
            pid = $container.find('.js-acc-id').data('pid').toString(),
            quantity = parseInt($container.find('.item-quantity__input').val(), 10),
            allAccessoriesJSON = {[pid]: quantity};

        $.ajax({
            type: "POST",
            url: Urls.addAllAccessories,
            data: {
                pids: JSON.stringify(allAccessoriesJSON)
            },
            success: function (data) {
                var atcBtn = $container.find('.js-accessories-add-to-cart input');
                var successMsg = $container.find('.added-to-cart-fake');

                if (data.success) {
                    atcBtn.hide()
                    successMsg.fadeIn();
                    
                    setTimeout(() => {
                        atcBtn.fadeIn().attr('disabled', true);
                        successMsg.hide();
                    }, 4000);
                    if (data.miniCartHtml) {
                        minicart.update(data.miniCartHtml);
                    }
                }
                $container.find('input.js-item-quantity-input').val(1);
                itemWrapper.find(".js-item-variants").empty(); // Clear variation selects block
                itemWrapper.find(".js-accessories-variant").val(""); // Set first variant to initial
                $priceSection.html("");
                accessoriesEvents();
                optionSelect(true); // Refresh select menu
            }
        });
    });
}


/**
 * @private
 * @function
 * @description Initialize change item quantity
 */
function itemQuantityHandler() {
    $(document).find(".js-product-item").each(function () {
        var itemQty        = $(this),
            itemQtyInput   = itemQty.find(".js-item-quantity-input"),
            itemQtyBtnUp   = itemQty.find(".js-item-quantity-up"),
            itemQtyBtnDown = itemQty.find(".js-item-quantity-down"),
            itemQtyMin     = itemQtyInput.attr("min") ? itemQtyInput.attr("min") : 1;

        itemQtyInput.on("change", function () {
            if (itemQtyInput.val() < 1) {
                itemQtyInput.val(1);
            }
        });

        itemQtyBtnUp.on("click", function () {
            var oldValue = parseFloat(itemQtyInput.val());
            var newVal = oldValue + 1;
            if (newVal.toString().length > itemQtyInput.attr("maxlength")) {
                return false;
            }
            itemQty.find("input[type=\"number\"]").val(newVal).trigger("change");
        });

        itemQtyBtnDown.on("click", function () {
            var oldValue = parseFloat(itemQtyInput.val());
            var newVal;
            if (oldValue <= itemQtyMin) {
                newVal = oldValue;
            } else {
                newVal = oldValue - 1;
            }
            itemQty.find("input[type=\"number\"]").val(newVal).trigger("change");
        });
    });
}

/**
 * @public
 * @function
 * @description Initialize change item quantity on pdp
 */
function pdpItemQuantityHandler($container, isTile) {
    var $itemQtyInput   = $container.find(".js-item-quantity-input");
    var $itemQtyBtnUp   = $container.find(".js-item-quantity-up");
    var $itemQtyBtnDown = $container.find(".js-item-quantity-down");
    var itemQtyMin      = $itemQtyInput.attr("min") ? $itemQtyInput.attr("min") : 1;

    $itemQtyInput.on("change", function () {
        var self = $(this);
        if (isTile) {
            if (self.val() < 1) {
                self.val(1);
            }
        } else {
            if ($itemQtyInput.val() < 1) {
                $itemQtyInput.val(1);
            }
        }
    });

    $itemQtyBtnUp.on("click", function () {
        var self = $(this);
        var oldValue = isTile ? parseFloat(self.parent().find('.js-item-quantity-input').val()) : parseFloat($itemQtyInput.val());
        var newVal = oldValue + parseInt(self.parent().find('.js-item-quantity-input').attr('step'));

        if (newVal.toString().length > $itemQtyInput.attr("maxlength")) {
            return false;
        }
        if(isTile) {
            self.parent().find(".js-pdp-qty-input").val(newVal).trigger("change");
        } else {
            $container.find(".js-pdp-qty-input").val(newVal).trigger("change");
        }
    });

    $itemQtyBtnDown.on("click", function () {
        var self = $(this);
        var oldValue = isTile ? parseFloat(self.parent().find('.js-item-quantity-input').val()) : parseFloat($itemQtyInput.val());
        var newVal;
    
        if (oldValue <= itemQtyMin) {
            newVal = oldValue;
        } else {
            newVal = oldValue - parseInt(self.parent().find('.js-item-quantity-input').attr('step'));
        }
        
        if(isTile) {
            self.parent().find(".js-pdp-qty-input").val(newVal).trigger("change");
        } else {
            $container.find(".js-pdp-qty-input").val(newVal).trigger("change");
        }
        
    });
}

/**
 * @private
 * @function
 * @description initialize jQuery UI dropdown
 * @param action true - refresh select menu plugin
 */
function optionSelect(action = false) {
    if (!action) {
        $(document).find(".accessories-variant").selectmenu({
            classes: {
                "ui-selectmenu-button": "cl-variation-select",
                "ui-selectmenu-icon": "cl-sorting-bar__icon",
                "ui-selectmenu-menu": "cl-variation-select__menu cl-variation-select__custom-menu cl-variation-select__menu--lg",
                "ui-menu-item": "cl-variation-select__menu-item",
                "ui-selectmenu-text": "cl-variation-select__text-icon"
            },
            create: function (event) {
                $(this).next().find(".ui-selectmenu-icon").html("<svg class=\"cl-svg-icon cl-sorting-bar__svg-icon\"><use xlink:href=\"#drop-down\"></use></svg>");

                if ($(event.target).hasClass("js-variation-finish")) {
                    var activeData = $(event.target).find(":selected").attr("data-icon");

                    if (activeData !== undefined) {
                        $("<img>", {
                            "src": activeData,
                            "alt": Resources.VENDOR_SKU,
                            "class": "cl-sorting-bar__thumb"
                        }).prependTo($(this).next().find(".ui-selectmenu-text"));
                    }
                }
            },
            change: function (event, ui) {
                $(this).trigger("change", ui);
            }
        });
    } else {
        $(document).find(".accessories-variant").selectmenu("refresh");
    }
}

function urlQueryToObject(url) {
    var params = {};
    var parser = document.createElement("a");
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        params[pair[0]] = decodeURIComponent(pair[1]);
    }

    return params;
}

var accessories = {
    init: function () {
        maxlength();
        accessoriesEvents();
        itemQuantityHandler();
        customJquertUiSelect();
        optionSelect();
    },
    pdpItemQuantityHandler: pdpItemQuantityHandler
};

module.exports = accessories;


