'use strict';

var modalConfigObj = {
    autoOpen: false,
    draggable: false,
    modal: true,
    resizable: false,
    width: "none",
    position: { 
        my: "center",
        at: "center",
        of: window 
    },
    dialogClass: "cl-dialog",
    classes: {
        "ui-dialog": "cl-dialog",
        "ui-dialog-titlebar": "cl-dialog__titlebar",
        "ui-dialog-titlebar-close": "cl-dialog__close",
        "ui-dialog-content": "cl-dialog__content"
    },
    open: function () {
        // Add custom close button icon
        $(this).closest(".ui-dialog")
            .find(".ui-dialog-titlebar-close")
            .html('<svg class="cl-svg-icon cl-svg-icon--close-small"><use xlink:href="#close" /></svg>');
    }
}

/**
 * @private
 * @function
 * @description Function show Reset Password modal
 */
function resetPasswordModals() {
    if ($(".js-forgot-password-popup-open").length) {
        $(".js-forgot-password-popup-open").each(function () {

            $("#reset-password-modal, #check-inbox-popup").dialog(modalConfigObj);

            $(this).on( "click", function(e) {
                e.preventDefault();
                $("#reset-password-modal").dialog("open");
                return false;
            });
        });
    }
}

/**
 * @private
 * @function
 * @description Function validate reset password form
 * @todo Implement validation logic once form is submited
 */
function resetPasswordValidation() {
    if ($(".js-forgot-password-form").length) {

        $(".js-forgot-password-form").on("submit", function (e) {
            e.preventDefault();
            // Your code here
            if (true) {
                $("#reset-password-modal").dialog("close");
                $("#check-inbox-popup").dialog("open");
            }
        });
    }
}

exports.init = function () {
    resetPasswordModals();
    resetPasswordValidation();
};