

var dialog = require("./dialog");
var util = require("./util");
var SessionAttributes = window.SessionAttributes;

const dialogCongig = {
    autoOpen: false,
    draggable: false,
    modal: true,
    resizable: false,
    width: "none",
    position: {
        my: "center",
        at: "center",
        of: window
    },
    dialogClass: "cl-actions-dialog",
    classes: {
        "ui-dialog": "cl-actions-dialog",
        "ui-dialog-title": "cl-actions-dialog__title",
        "ui-dialog-titlebar": "cl-actions-dialog__titlebar",
        "ui-dialog-titlebar-close": "cl-actions-dialog__close",
        "ui-dialog-content": "cl-actions-dialog__content"
    },
    open: function () {
        $(this).closest(".ui-dialog")
            .find(".ui-dialog-titlebar")
            .addClass("cl-title");

        // Add custom close button icon
        $(this).closest(".ui-dialog")
            .find(".ui-dialog-titlebar-close")
            .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

        // Add custom button
        $(this).closest(".ui-dialog")
            .find(".ui-button")
            .removeClass("ui-button ui-corner-all ui-widget")
            .addClass("cl-button cl-button--alternate ms-font--montserrat");

        $(this).closest(".ui-dialog")
            .find(".ui-dialog-buttonpane")
            .addClass("cl-bottom");

        // Close Dialog When Clicked Outside
        $(document).find(".ui-widget-overlay").bind("click", function () {
            dialog.close();
        });
    },
    buttons: [{
        text: Resources.OK,
        click: function () {
            var url = util.appendParamsToUrl(Urls.rateLimiterReset, {format: "ajax"});
            $.ajax({
                url: url
            });
            $(this).dialog("close");
        }
    }]
};

/**
 * @function captcha    Used to display/control the scrim containing the simulated captcha code
 **/
module.exports = function () {
    /**
     * if the session.privacy.ratelimited element is present then show the notification
     * NOTE: You will probably want to replace this with a call to an actual CAPTCHA system to replace the simple one here
     */
    if (SessionAttributes.SHOW_CAPTCHA) {
        dialog.open({
            html: "<h2 class=\"cl-question\">" + Resources.ARE_YOU_HUMAN + "</h2>",
            options: dialogCongig
        });
    }
};
