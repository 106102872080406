'use strict';

/**
 * @private
 * @function
 * @description Function to validate forms
 * @todo Implement form validation logic after the submission
 */
function formValidation() {
    var validator = require('./../../../../app_capitollighting_core/cartridge/js/validator');

    var professionError = $(document).find('.js-profession-error'),
        stateError      = $(document).find('.js-state-error');

    validator.init();

    if ($('.js-become-member-form').length) {
        var $becomeMemberForm = $('.js-become-member-form'),
            validatedForm = true;

        $becomeMemberForm.on('submit', function (e) {
            var profession, phone, city, state;

            $('.js-become-member-error').hide();

            profession = $('#profession').val() == 'null' ? '' : $('#profession').val();
            phone      = $('#phonenumber').val();
            city       = $('#city').val();
            state      = $('#state').val() == 'null' ? '' : $('#state').val();

            if ($('#profession').find('option:selected').val() == 'null') {
                professionError.removeClass('ms-hide').addClass('error');
                $('#profession').addClass('error');

                validatedForm = false;
            }

            if ($('#state').find('option:selected').val() == 'null') {
                stateError.removeClass('ms-hide').addClass('error');
                $('#state').addClass('error');

                validatedForm = false;
            }

            if (!validatedForm) {
                return false;
            }
        });
    }

    $('#profession').on('change', function () {

        if ($('#profession').find('option:selected').val() == 'null') {
            professionError.removeClass('ms-hide');
            if (professionError.hasClass('error')) {
                professionError.addClass('error');
            }
            $(this).addClass('error');
        } else {
            professionError.addClass('ms-hide');
            if (professionError.hasClass('error')) {
                professionError.removeClass('error');
            }
            $(this).removeClass('error');

            validatedForm = true;
        }
    });

    $('#state').on('change', function () {

        if ($('#state').find('option:selected').val() == 'null') {
            stateError.removeClass('ms-hide');
            if (stateError.hasClass('error')) {
                stateError.addClass('error');
            }
            $(this).addClass('error');
        } else {
            stateError.addClass('ms-hide');
            if (stateError.hasClass('error')) {
                stateError.removeClass('error');
            }
            $(this).removeClass('error');

            validatedForm = true;
        }
    });
}

/**
 * @private
 * @function
 * @description Function to show/hide password in the input field wishlist registration modal window
 */
function togglePwdVisibilityModal() {

    var hidePwdCssClass = 'pwd-hide',
        showPwdCssClass = 'pwd-show',
        isActive        = false;

    $(document).on('click','.js-show-pwd-toggle', function(e) {
        var $pwdInputField  = $('.js-show-pwd .cl-form-input');

        e.preventDefault();
        e.stopPropagation();

        if (!isActive) {
            // Change the attribute to text
            $pwdInputField.attr('type', 'text');
            $(this).removeClass(hidePwdCssClass).addClass(showPwdCssClass);
            isActive = true;
        } else {
            // Change the attribute back to password
            $pwdInputField.attr('type', 'password');
            $(this).removeClass(showPwdCssClass).addClass(hidePwdCssClass);
            isActive = false;
        }
    });
}

/**
 * @private
 * @function
 * @description Function to show/hide password in the input field for trade program section
 */
function togglePwdVisibility() {
       if ($('.js-show-pwd-toggle').length && $('.js-show-pwd-field').length) {
                var $pwdBtnToggle = $('.js-show-pwd-toggle'),
                    $pwdInputField = $('.js-show-pwd-field'),
                    hidePwdCssClass = 'pwd-hide',
                    showPwdCssClass = 'pwd-show',
                    isActive = false;

                $pwdBtnToggle.on('click touchstart', function(e) {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!isActive) {
                        // Change the attribute to text
                        $pwdInputField.attr('type', 'text');
                        $pwdBtnToggle.removeClass(hidePwdCssClass).addClass(showPwdCssClass);
                        isActive = true;
                    } else {
                        // Change the attribute back to password
                        $pwdInputField.attr('type', 'password');
                        $pwdBtnToggle.removeClass(showPwdCssClass).addClass(hidePwdCssClass);
                        isActive = false;
                    }
                });
            }
}

/**
 * @private
 * @function
 * @description Function to append second part of the label
 */
function twoPartsLabel() {
    if ($('.js-label-bold-part').length) {
        var $labelText = $('.js-label-bold-part').attr('data-label-bold');
        $('.js-label-bold-part').find('.cl-form-label').append(' <span class="ms-font--bold">' + $labelText + '</span>');
    }
}

exports.init = function () {
    togglePwdVisibility();
    formValidation();
    twoPartsLabel();
    togglePwdVisibilityModal();
};
