

var util = require("./util");

var productImpressionsUpdate = function (url) {
    if (!url ||
         Object.prototype.toString.call(url).slice(8, -1) !== "String") {
        return;
    }

    var queryStingParams = util.getQueryStringParams(url),
        size = parseInt(queryStingParams.sz, 10),
        skip = queryStingParams.start ? parseInt(queryStingParams.start, 10) : 0,
        impressions = [],
        dataLayerObj = {
            "event": "ecomListImpression",
            "ecommerce": {
                "currencyCode": gaECGlobals.siteCurrency
            }
        },
        $items = $(".product-tile-container:gt(" + (skip - 1) + ")").slice(0, size);

    if (0 === $items.length) {
        $items = $(".product-tile-container");
    }

    $items.each(function (index) {
        var impression = $(this).data("gtag-ua");

        if (impression) {
            if ("Search Results" !== impression.category) {
                impression.list = gaECGlobals.listName;
            }

            impression.position = 1 + index + skip;
            impressions.push(impression);
        }
    });
    dataLayerObj.ecommerce.impressions = impressions;
    dataLayer.push(dataLayerObj);
};

exports.productImpressionsUpdate = productImpressionsUpdate;
