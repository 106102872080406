
var dialog = require("../dialog");

exports.init = function () {
    $(".store-details-link").on("click", function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr("href")
        });
    });
    var geolocation = $("[id$=\"_storelocator\"]").data("geolocation"),
        postalCode  = $("[id$=\"_storelocator_postalCode\"]");
    if (geolocation && postalCode.val() === "") {
        postalCode.val(geolocation);
    }

};


