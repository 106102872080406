var ajax = require("../../ajax"),
    image = require("./image"),
    addToCart = require("./addToCart"),
    progress = require("../../progress"),
    productStoreInventory = require("../../storeinventory/product"),
    tooltip = require("../../tooltip"),
    pdpSlickInit = require("../../pdp-slick-carousel"),
    util = require("../../util"),
    badges = require("../../badges"),
    accessories = require("../../pages/accessories"),
    populateHref = require("./populateHref")
    clyde = require("../clyde");

/**
 * @private
 * @function
 * @description save custom jQuery UI widget
 */
function customJquertUiSelect() {
    $.widget("ui.selectmenu", $.ui.selectmenu, {
        _renderItem: function (ul, item) {
            var li = $("<li>"),
                wrapper = $("<div>", {
                    text: item.label
                });

            if (item.disabled) {
                li.addClass("ui-state-disabled");
            }

            if (item.element.attr("data-hidden")) {
                li.addClass("ui-state-hidden");
            }

            if (item.element.attr("data-icon")) {
                $("<img>", {
                    "src": item.element.attr("data-icon"),
                    "alt": Resources.VENDOR_SKU,
                    "class": "cl-sorting-bar__thumb"
                }).prependTo(wrapper);
            }

            return li.append(wrapper).appendTo(ul);
        }
    });
}

/**
 * @private
 * @function
 * @description initialise funtionality for Finish Colors lightbox
 */
function initColorsLightbox() {
    var dialogCongig = {
        width: "90%",
        height: "auto",
        autoOpen: false,
        draggable: false,
        resizable: false,
        modal: true,
        classes: {
            "ui-dialog": "cl-finish-variations",
            "ui-dialog-titlebar": "cl-finish-variations__titlebar",
            "ui-dialog-title": "cl-finish-variations__title"
        },
        open: function () {
            // Add custom close button icon
            $(this).closest(".ui-dialog")
                .find(".ui-dialog-titlebar-close")
                .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

            $(this).closest(".ui-dialog").find(".js-trigger-finish-select").on("click", function (e) {
                e.preventDefault();

                // Trigger change for original select
                populateHref.populateProductVariationOptionValue();
                updateContent($(this).attr("href"), $(this).data("name"), $("#product-content"), true);

                // Close and remove dialog
                var $dialogContent = $(this).closest(".ui-dialog-content");

                if ($dialogContent.length) {
                    $dialogContent.dialog("close").remove();
                }
            });

            // Close Dialog When Clicked Outside
            $(document).find(".ui-widget-overlay").bind("click", function () {
                $(document).find(".js-finish-refinements").dialog("close");
            });

            //will set dialog title
            var dialogTitle = $(this).closest(".ui-dialog")
                .find(".ui-dialog-name")
                .attr("data-name");

            $(this).closest(".ui-dialog")
                .find(".ui-dialog-title")
                .text(dialogTitle);
        }
    };

    $(document).find(".js-show-variation-colors").off("click").on("click", function (e) {
        e.preventDefault();

        var $dialog = $(this).data("dialog") || $(this).parents(".js-drop-down-parent").find(".js-finish-refinements");

        // Remove finish refimenets colors already initialized dialog
        if ($dialog.hasClass("ui-dialog-content")) {
            $dialog.removeData();
        }

        $(this).data("dialog", $dialog);

        $dialog.dialog(dialogCongig);
        $dialog.dialog("open");
    });
}

/**
 * @private
 * @function
 * @description initialize jQuery UI dropdown
 */
function pdpOptionSelect() {
    if ($(".js-variation-select").length > 0) {
        $(document).find(".js-variation-select").selectmenu({
            classes: {
                "ui-selectmenu-button": "cl-variation-select",
                "ui-selectmenu-icon": "cl-sorting-bar__icon",
                "ui-selectmenu-menu": "cl-variation-select__menu",
                "ui-menu-item": "cl-variation-select__menu-item",
                "ui-selectmenu-text": "cl-variation-select__text-icon"
            },
            create: function (event) {
                $(this).next().find(".ui-selectmenu-icon").html("<svg class=\"cl-svg-icon cl-sorting-bar__svg-icon\"><use xlink:href=\"#drop-down\"></use></svg>");

                if ($(event.target).hasClass("js-variation-finish")) {
                    var activeData = $(event.target).find(":selected").attr("data-icon");

                    if (activeData !== undefined) {
                        $("<img>", {
                            "src": activeData,
                            "alt": Resources.VENDOR_SKU,
                            "class": "cl-sorting-bar__thumb"
                        }).prependTo($(this).next().find(".ui-selectmenu-text"));
                    }
                }

                window.modulesSystem.jRes.addFunc([
                    {
                        breakpoint: ["phone", "phoneLandscape", "tablet"],
                        enter: function () {
                            window.addEventListener("orientationchange", function () {
                                if ($(".js-variation-select").next().attr("aria-expanded") == "true") {
                                    $(".js-variation-select").selectmenu("close").selectmenu("open");
                                }
                            });
                        }
                    }
                ]);
            },
            change: function (event, ui) {
                $(this).trigger("change", ui);

                if ($(".js-finish-refinements").length) {
                    $(".js-finish-refinements").each(function () {
                        // Check if exists instance of dialog initialized
                        if ($(this).hasClass("ui-dialog-content")) {
                            $(this).dialog("destroy").remove();
                        }
                    });
                }
            }
        });
    }
}

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 * @param {String} attrName - name of the attribute selected
 **/
var updateContent = function (href, attrName, $container, notfromModal) {
    if (notfromModal) {
        var $pdpForm = $(".pdpForm");
        var qty = $pdpForm.find("input[name=\"Quantity\"]").first().val();
        var params = {
            Quantity: isNaN(qty) ? "1" : qty,
            format: "ajax",
            productlistid: $pdpForm.find("input[name=\"productlistid\"]").first().val(),
            /* -- Start Amazon Pay Integration changes -- */
            recurringPayment: $pdpForm.find("input[name=\"recurringPayment\"]:checked").val()
            /* -- End Amazon Pay Integration changes -- */
        };

        if (window.location.hasOwnProperty("queryString")) {//eslint-disable-line
            if (window.location.queryString.hasOwnProperty("wishlistId")) {//eslint-disable-line
                params["source"] = "wishlist";//eslint-disable-line
            }
        }

        progress.show();
    }

    var url = util.appendParamsToUrl(href, params);//eslint-disable-line

    ajax.load({
        url      : notfromModal ? url : util.appendParamToURL(url, "modal", "showroomstock"),
        target   : $container,
        callback : function (data) {
            if (notfromModal) {
                // Initializations for PDP page
                var $pdpQtyContainer = $(".js-pdp-quantity");

                if (SitePreferences.STORE_PICKUP) {
                    productStoreInventory.init();
                }
                image.replaceImages();
                tooltip.init();
                addToCart();
                addToCart.disableButton();
                pdpOptionSelect();
                updateProductId(data);
                updateProductInfo(data);
                pdpSlickInit.init(attrName);
                window.affirm.ui.refresh();
                clyde.refreshPDP();

                if (typeof window.houzz.processWidgets === "function") {
                    window.houzz.processWidgets();
                }

                badges.init();
                accessories.pdpItemQuantityHandler($pdpQtyContainer, false);
            }
            else {
                // Initializations for Showroom Stock Modal
                if (SitePreferences.STORE_PICKUP) {
                    productStoreInventory.init();
                }

                pdpOptionSelect();
                updateProductId(data);
                updateProductInfo(data);

                // Detach from DOM unusefull eleemnts
                $container.find(".js-check-showroom-stock").detach();
                $container.find(".inventory").detach();
                $container.find(".cl-svg-icon-shipping").detach();
                $container.find(".free-shipping-tooltip").detach();
                $container.find(".product-actions").detach();
                $container.find(".socialsharing").detach();
                $container.find(".product-price").detach();
            }

            $(document).trigger("product:variant:updated");

            var variantId = $("#pid").val();
            var variantPageUrl = $("#pdpURL").val();
            if (DY && variantId && variantPageUrl) {
                console.log('Firing Dynamic yield event for product variant ' + variantId);
                DY.API('spa', {
                        context: {
                        type: 'PRODUCT',
                        data: [variantId]
                    },
                    url: variantPageUrl,
                    countAsPageview: false
                });
            }
        }
    });
};

/**
 * @description Updates PDP product id;
 * @param {String} responseData ajax html response
 */
function updateProductId(responseData) {

    try {
        var productId    = $(responseData).find("#capitolID").val();
        var skuNumber    = $(responseData).find("#skuNumber").val();
        var productBadge = $(responseData).find("#product-badge").html();

        $(".product-number [itemprop=\"productID\"]").each(function () {
            $(this).text(productId);
        });

        if (skuNumber.length) {
            $(".product-number [itemprop=\"sku\"]").each(function () {
                $(this).removeClass("ms-hide").text(Resources.VENDOR_SKU + " " + skuNumber);
            });

            $(".product-number [itemprop=\"mpn\"]").attr("content", skuNumber);
        }

        if (productBadge.length) {
            $(".cl-product-top .product-badge").each(function () {
                $(this).html(productBadge);
            });
        }

    } catch (e) {
        // Simply do not update the product id;
    }
}

/**
 * @description Updates PDP product information;
 * @param {String} responseData ajax html response
 */
function updateProductInfo(responseData) {
    try {
        var productInfoTemplate = $(responseData).filter(".product-info-template").html();
        var productInformationHtml = $(productInfoTemplate).filter(".js-product-information-container").html();

        $(".tab_container .js-product-information-container").html(productInformationHtml);
    } catch (e) {
        // Simply do not update the product info;
    }
}

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} attrName - name of the attribute selected
 **/
var updateAttributes = function (attrName) {
    var $pdpMain        = $(".pdp-main"),
        attributes      = $pdpMain.find(".js-variation-select"),
        resetAttributes = false;

    populateHref.populateProductVariationOptionValue();

    for (var i = 0; i < attributes.length; i++) {
        var attribute = $(attributes[i]),
            viewAllAttr = attribute.parents(".js-drop-down-parent").find(".js-show-variation-colors"),
            emptyValue = attribute.find(".emptytext").val();

        // if last selected attribute already found, reset subsequent attributes
        if (resetAttributes) {
            attribute.val(emptyValue);
            attribute.selectmenu("refresh");
            attribute.selectmenu("option", "disabled", true);
            viewAllAttr.hide();
            continue;
        }

        var prevAttribute = $(attributes[i - 1]);

        if (!prevAttribute.find("option:selected").hasClass("emptytext")) {
            if (attribute.find("option").length > 1) {
                attribute.selectmenu("option", "disabled", false);
                viewAllAttr.show();
            }
        }

        if ((attribute.val() == emptyValue && (!attrName || prevAttribute.attr("name") == attrName)) || attribute.find("option:selected").hasClass("emptytext")) {
            resetAttributes = true;
        }
    }
};

var variant = {
    init: function (attrName) {
        var $pdpMain = $("#pdpMain");
        // hover on swatch - should update main image with swatch image
        $pdpMain.on("mouseenter mouseleave", ".swatchanchor", function () {
            var largeImg = $(this).data("lgimg"),
                $imgZoom = $pdpMain.find(".main-image"),
                $mainImage = $pdpMain.find(".primary-image");

            if (!largeImg) { return; }
            // store the old data from main image for mouseleave handler
            $(this).data("lgimg", {
                hires: $imgZoom.attr("href"),
                url: $mainImage.attr("src"),
                alt: $mainImage.attr("alt"),
                title: $mainImage.attr("title")
            });
            // set the main image
            image.setMainImage(largeImg);
        });

        // click on swatch - should replace product content with new variant
        $pdpMain.on("click", ".product-detail .swatchanchor", function (e) {
            e.preventDefault();
            if ($(this).parents("li").hasClass("unselectable")) { return; }
            populateHref.populateProductVariationOptionValue();
            updateContent(this.href, "", $("#product-content"), true);

            var swatchtype = $(this).data("swatchtype");
            if (typeof dynamicYield !== 'undefined') dynamicYield.callEvent("Change Attribute", swatchtype); // eslint-disable-line no-undef
        });

        // change drop down variation attribute - should replace product content with new variant
        $pdpMain.off("change", ".variation-select").on("change", ".variation-select", function () {
            populateHref.populateProductVariationOptionValue();
            updateContent($(this).val(), $(this).attr("name"), $("#product-content"), true);

            // eslint-disable-next-line no-undef
            if (typeof dynamicYield !== 'undefined') dynamicYield.callEvent("Change Attribute", {
                type: $(this).parent().parent().find("span").text().trim(),
                value: $(this).find(":selected").text().trim()
            });
        });

        customJquertUiSelect();
        initColorsLightbox();

        pdpOptionSelect();
        updateAttributes(attrName);
        addToCart.disableButton();
    },
    updateContent: updateContent
};

module.exports = variant;
