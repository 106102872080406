'use strict';

function getProductName(target) {
    return $(target).closest('.cart-row').find('.product-name-link').text().trim();
}

function createEvent(elementOrdinal, elementPrimary, elementSecondary, elementDescription) {
    dataLayer.push({
		event: 'gtm_event',
		non_interaction_hit: false,
        event_name: 'cart_interaction',
        element: 'cart',
        element_action: 'click',
        element_ordinal: elementOrdinal.toLowerCase() || null,
        element_primary: elementPrimary.toLowerCase() || null,
        element_secondary: elementSecondary.toLowerCase() || null,
        element_description: elementPrimary === 'promo' ? elementDescription || null: elementDescription.toLowerCase() || null
    })
}

module.exports = function () {
    var beforeQuantity;
    // on clicking the chekout buttons including paypal and  amazon
    $('body').on('click', '.cl-cart #checkout-form', function (e) {
        var $target = $(e.target);
        var method;
        if ($target.is('.cl-cart__button') || $('.cl-cart__button')[0].contains(e.target)) {
            method = 'standard';
        } 
        if ($target.is('[id^="paypalExpressButton"]')  || $('[id^="paypalExpressButton"]')[0].contains(e.target)) {
            method = 'paypal';
        } 
        if ($target.is('.amazonpay-button-inner-image')) {
            method = 'amazon';
        } 
        createEvent('primary', 'checkout',  '', method);
    });
    
    // on clicking affirm learn more link.
    $('body').on('click', 'a.affirm-modal-trigger', function (e) {
        createEvent('primary', 'affirm',  '', 'learn more');
    });
    
    // on applying a promo code.
    $(function () {
        var couponStatus;
        var $couponContainer = $('.cl-cart-coupon-code');
        var promoCode = $couponContainer.data('gtm-coupon') || $('.applied-message').data('gtm-coupon');
        if ($couponContainer.find('.applied-message').length) {
            couponStatus = 'success';
        } else if ($couponContainer.find('.error-message').length) {
            couponStatus = 'failure';
        }
        if (couponStatus && promoCode) {
            var interval = setInterval(function(){
                if (window.dataLayer) {
                    createEvent('primary', 'promo',  '', couponStatus + ' | ' + promoCode);
                    clearInterval(interval);
                }
              }, 500);
            
        }
    });
    
    // on clicking product image on cart.
    $('body').on('click', '.cl-cart__item-list-body .ms-picture', function (e) {
        createEvent('primary', 'product image',  '', getProductName(this));
    });
    
    $('body').on('click', '.js-protection-plan-purchase', function () {
        var selectedPlan = $(this).parent().find('select').val();
        createEvent('primary', 'product warranty',  '', selectedPlan);
    });
    
    $('body').on('focus', 'input.js-item-quantity-input', function () {
        $(this).attr('data-gtm-before-quantity', parseInt($(this).val(), 10));
    });
    
    $('body').on('change', 'input.js-item-quantity-input', function (e) {
        var changeStatus;
        var afterQuantity = parseInt($(this).val(), 10);
        beforeQuantity =  $(this).data('gtm-before-quantity');
        changeStatus = beforeQuantity > afterQuantity ? 'decrease quantity' : 'increase quantity';
        if (beforeQuantity !== afterQuantity) {
            createEvent('secondary', 'quantity', changeStatus, getProductName(this));
        }
        $(this).attr('data-gtm-before-quantity', afterQuantity);
    });
    
    $('body').on('click', '.item-quantity__link', function () {
        createEvent('secondary', 'remove', 'remove', getProductName(this));
    });
};
