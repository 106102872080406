

/**
 * @private
 * @function
 * @description Binds event to the place holder (.blur)
 */
function initializeEvents() {
    $("#q").on('focus', function (e) {
        e.stopImmediatePropagation();
        var input = $(this);
        if (input.val() === input.attr("placeholder")) {
            input.val("");
        }
        if (Object.keys(e).indexOf('originalEvent') !== -1) {
            $('body').trigger('custom:searchFieldClickOrEngagement', [$(this)]);
        }
        // trigger gtm custom event
    });
    $("#q").on('blur', function () {
        var input = $(this);
        if (input.val() === "" || input.val() === input.attr("placeholder")) {
            input.val(input.attr("placeholder"));
        }
    });

    $(".cl-header-search__button--submit").click(function (e) {
        if ($("input[name=q]").val().toLowerCase() === "search") {
            e.preventDefault();
        }
        // trigger gtm custom event
        $('body').trigger('custom:searchFieldClickOrEngagement', [$(this)]);
    });

    $('body').on('click', '.cl-search-phrase__link, .phrase-suggestions__link, .product-suggestion__link', function(e) {
        e.stopPropagation();
        // trigger gtm custom event
        $('body').trigger('custom:searchFieldClickOrEngagement', [$(this)]);
    });
}

exports.init = initializeEvents;
