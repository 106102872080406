

let tilesmatchheight = {
    elMatchHeight: function ($productTileContent, isJqObject = false) {
        let config = {"mode": "height"};

        if (!isJqObject) {
            $productTileContent = $productTileContent ? $productTileContent : $(".mh-product-tile-wrapper");
        } else {
            $productTileContent = $($productTileContent);
        }

        $productTileContent.find(".mh-product-tile-name").conformity(config);
        $productTileContent.find(".mh-product-tile-vendor").conformity(config);
        $productTileContent.find(".mh-product-tile-price").conformity(config);
        $productTileContent.find(".mh-product-tile-promotion").conformity(config);
        $productTileContent.find(".mh-product-tile-reviews").conformity(config);
        $productTileContent.find(".mh-product-tile-options").conformity(config);
    }
};

module.exports = tilesmatchheight;
