'use strict';

var giftcert = require('../giftcert'),
    tooltip = require('../tooltip'),
    util = require('../util'),
    dialog = require('../dialog'),
    page = require('../page'),
    login = require('../login'),
    progress = require('../progress'),
    inputmask = require('../inputmask'),
    validator = require('../validator'),
    address = require('./checkout/address'),
    addressSuggestions = require('../addressSuggestions');

var returns = require('./returns');

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    address.init();
    var $form = $('#edit-address-form');
    var postalCode = $form.find('input.postal.required');

    $form.find('input[name="format"]').remove();
    tooltip.init();
    //$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);

    $form.on('click', '.apply-button', function (e) {
        e.preventDefault();
        var $clickedBtn = $(this);
        var formButtons = $form.find('button');

        if (!$form.valid()) {
            return false;
        }

        // Address suggestions
        if(window.SitePreferences.VERTEX_ENABLED && window.SitePreferences.VERTEX_ACCOUNT_ADDRESS_CHECK_ENABLED){
            var shippingAddress = $form.serialize(),
                $countrySelect = $form.find('select[name$="_address_country"]'),
                selectedCountryCode = $countrySelect.length && $countrySelect.val(),
                isCountryValidationEnabled = addressSuggestions.methods.countryAddressValidateEnabled(selectedCountryCode);

            if(isCountryValidationEnabled &&
                (!addressSuggestions.cache.lastValidatedAddressForm ||
                    (addressSuggestions.cache.lastValidatedAddressForm && addressSuggestions.cache.lastValidatedAddressForm.serialize() != shippingAddress))){

                addressSuggestions.settings.dialogSettings.buttons = [{
                    text: Resources.ADDRESS_VALIDATION_USESELECTED,
                    class: 'cl-button cl-button--alternate cl-button--icon ms-font--montserrat ms-margin-top-10',
                    click: function () {
                        var selectedAddress = $('#vertex-suggestions input:checked');
                        if(selectedAddress.length){
                            var selectedAddressData = selectedAddress.data('address');

                            addressSuggestions.methods.setShippingFormData(selectedAddressData, addressSuggestions.cache.lastValidatedAddressForm, 'account');
                            var updateAddressRequestString = addressSuggestions.cache.lastValidatedAddressForm.serialize();

                            if(updateAddressRequestString.length){
                                var options = {
                                    url: addressSuggestions.cache.updateAddressURL,
                                    data: updateAddressRequestString + '&' + addressSuggestions.cache.applyActionName + '=x',
                                    type: 'POST'
                                };
                                $.ajax(options).done(function (data) {
                                    if (typeof(data) !== 'string') {
                                        if (data.success) {
                                            $('body').trigger('custom:updateAddress');
                                            dialog.close();
                                            page.refresh();
                                        } else if (data.error) {
                                            page.redirect(Urls.csrffailed);
                                        } else {
                                            window.alert(data.message); //NOSONAR
                                            return false;
                                        }
                                    } else {
                                        $('ui-dialog').find('.dialog-content').html(data);
                                        account.init();
                                        tooltip.init();
                                    }
                                });
                            }else{
                                dialog.close();
                            }
                        }
                        dialog.close();
                    }
                },
                {
                    text: Resources.ADDRESS_VALIDATION_EDIT,
                    class: 'cl-button cl-button--white ms-font--montserrat ms-margin-top-10',
                    click: function () {
                        addressSuggestions.cache.deserializeAddressForm = true;
                        dialog.close();
                        addressSuggestions.cache.lastEditedAddressLink.click();
                    }
                }];
                addressSuggestions.cache.lastValidatedAddressForm = $form;
                addressSuggestions.cache.updateAddressURL = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
                addressSuggestions.cache.applyActionName = $form.find('.apply-button').attr('name');
                formButtons.attr('disabled', 'disabled');

                var addressSuggestResponse = addressSuggestions.methods.getAddressSuggestions($form, 'account');
                if(addressSuggestResponse){
                    addressSuggestResponse.done(function(suggestionData){
                        formButtons.removeAttr('disabled', 'disabled');
                        dialog.close();
                        dialog.open({
                            html: suggestionData,
                            options: addressSuggestions.settings.dialogSettings
                        });
                    }).fail(function(){
                        formButtons.removeAttr('disabled', 'disabled');
                        $clickedBtn.click(); 
                    });
                }else{
                    formButtons.removeAttr('disabled', 'disabled');
                    $clickedBtn.click(); 
                }
                return;
            }
        }
        // End address suggestions

        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $form.find('.apply-button').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== 'string') {
                if (data.success) {
                    dialog.close();
                    $('body').trigger('custom:updateAddress');
                    page.refresh();
                } else if (data.error) {
                    page.redirect(Urls.csrffailed);
                } else {
                    window.alert(data.message); //NOSONAR
                    return false;
                }
            } else {
                $form.parents('.dialog-content').html(data);
                account.init();
                tooltip.init();
            }
        });
    })
        .on('click', '.cancel-button, .close-button, .cl-cancel-address', function (e) {
            e.preventDefault();
            dialog.close();
        })
        .on('click', '.delete-button', function (e) {
            e.preventDefault();
            if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) { //NOSONAR
                var url = util.appendParamsToUrl(Urls.deleteAddress, {
                    AddressID: $form.find('#addressid').val(),
                    format: 'ajax'
                });
                $.ajax({
                    url: url,
                    method: 'POST',
                    dataType: 'json'
                }).done(function (data) {
                    if (data.status.toLowerCase() === 'ok') {
                        dialog.close();
                        page.refresh();
                    } else if (data.message.length > 0) {
                        window.alert(data.message); //NOSONAR
                        return false;
                    } else { //NOSONAR
                        dialog.close(); 
                        page.refresh();
                    }
                });
            }
        });

    inputmask();
    validator.init();
    util.customRequiredMsgValidator();
    // trigger postal validation for edit form
    if (postalCode.val() !== '') {
        postalCode.trigger('focusout');
    }

    // Close Dialog When Clicked Outside
    $(document).find('.ui-widget-overlay').bind('click', function () {
        dialog.close();
    });
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder() {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .click(function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}

/**
 * @private
 * @function
 * @description Initialize card type detection
 */
function payCardType() {
    var $creditCardForm = $(document).find('.js-credit-card-form');

    if ($creditCardForm.find('.js-payment-card-number').length && $creditCardForm.find('.js-payment-card-type').length) {
        var cType = '',
            cNumber = '',
            cObj = {
                visa: 'Visa',
                amex: 'Amex',
                mastercard: 'Master',
                discover: 'Discover'
            },
            $cardNumber = $creditCardForm.find('input.js-payment-card-number'),
            $cardType = $creditCardForm.find('select.js-payment-card-type');

        $cardType.on('change', function () {
            var cType = $cardType.val(); //NOSONAR
            setCardDataAttr(cType);
        });

        $cardNumber.on('change keyup input', function () {
            cNumber = $cardNumber.val() ? $cardNumber.val() : '';
            if (cNumber.length > 3) {
                cType = $.payform.parseCardType(cNumber);

                if (cObj.hasOwnProperty(cType)) { //eslint-disable-line
                    $cardType.val(cObj[cType]).change();
                }
            } else {
                $cardNumber.removeAttr('data-type');
            }
        });

        var setCardDataAttr = function (cType) { //NOSONAR
            $cardNumber.attr('data-type', cType.toLowerCase());
        };
    }
}

/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#addresses2');
    if (addresses.length === 0) { return; }

    addresses.on('click', '.address-create', function (e) {
        e.preventDefault();
        window.addressAction = 'add';
        var title = Resources.TITLE_ADD_ADDRESS;
        
        if ($(this).hasClass('js-edit-address')) {
            title = Resources.TITLE_EDIT_ADDRESS;
            window.addressAction = 'edit';
        }
        addressSuggestions.cache.lastEditedAddressLink = $(this);
        dialog.open({
            url: this.href,
            options: {
                classes: {
                    'ui-dialog': 'cl-dialog-content',
                    'ui-dialog-title': 'cl-dialog-title'
                },
                open: function(){
                    if(addressSuggestions.cache.deserializeAddressForm){
                        addressSuggestions.methods.deserializeForm($('#edit-address-form'), addressSuggestions.cache.lastValidatedAddressForm.serialize());
                        addressSuggestions.cache.deserializeAddressForm = false;
                    }
                    initializeAddressForm();

                },
                buttons: [],
                title: title,
                draggable: false,
                resizable: false
            }
        });
    }).on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) { //NOSONAR
            $.ajax({
                url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    page.redirect(Urls.addressesList);
                } else if (data.message.length > 0) {
                    window.alert(data.message); //NOSONAR
                } else {
                    page.refresh();
                }
            });
        }
    });
}

/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    var title;

    $('.add-card').on('click', function (e) {
        e.preventDefault();

        switch (true) {
            case $(this).hasClass('js-add-new-card'):
                title = Resources.TITLE_ADD_CREDITCARD;
                break;
            case $(this).hasClass('js-credit-card-edit'):
                title = Resources.TITLE_EDIT_CREDITCARD;
                break;
            case $(this).hasClass('js-edit-address'):
                title = Resources.TITLE_EDIT_ADDRESS;
                break;
            case $(this).hasClass('js-add-new-address'):
                title = Resources.TITLE_ADD_ADDRESS;
                break;
            default:
                title = '';
        }

        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                classes: {
                    'ui-dialog': 'cl-dialog-content',
                    'ui-dialog-title': 'cl-dialog-title'
                },
                open: initializePaymentForm,
                width: '90%',
                title: title,
                draggable: false,
                resizable: false
            }
        });
    });

    var paymentList = $('.payment-list-delete');
    if (paymentList.length === 0) { return; }

    util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find('.delete');
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value') || 'delete card'
        }).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        })
            .done(function () {
                page.redirect(Urls.paymentsList);
            });
    });
}

function initializePaymentForm() {
    // Initialize card type detection
    payCardType();

    var $form = $('.credit-card-form'),
        $ccName = $form.find('.cl-form-input.js-payment-card-number'),
        $ccInvalid = $ccName.parent().next(),
        $ccMonth = $form.find('.input-select.cl-form-input'),
        $ccMonthInvalid = $ccMonth.parent().next();
    tooltip.init();

    $form.on('click', '.apply-button', function (e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $form.find('.apply-button').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== 'string') {
                if (data.success) {
                    dialog.close();
                    page.refresh();
                } else if (data.error) {
                    page.redirect(Urls.csrffailed);
                } else {
                    window.alert(data.message); //NOSONAR
                    return false;
                }
            } else {
                $('#dialog-container').html(data);
                account.init();
                tooltip.init();
                initializePaymentForm();
            }
        });
    }).on('click', '.cancel-button', function (e) {
        e.preventDefault();
        dialog.close();
    });

    validator.init();

    util.customRequiredMsgValidator();

    if ($ccInvalid.is(':visible')) {
        $ccName.on('input', function () {
            $ccInvalid.addClass('ms-hide');
        });
    }

    if ($ccMonthInvalid.is(':visible')) {
        $ccMonth.on('input', function () {
            $ccMonthInvalid.addClass('ms-hide');
        });
    }

    // Close Dialog When Clicked Outside
    $(document).find('.ui-widget-overlay').bind('click', function () {
        dialog.close();
    });
}

/**
 * @private
 * @function
 * @description Function to show/hide password in the input field
 */
function togglePswdVisibility() {
    if ($('.js-show-pwd-toggle').length && $('.js-show-pwd .cl-form-input').length) {
        var $pwdBtnToggle   = $('.js-show-pwd-toggle'),
            $pwdInputField  = $('.js-show-pwd .cl-form-input'),
            hidePwdCssClass = 'pwd-hide',
            showPwdCssClass = 'pwd-show',
            isActive        = false;

        $pwdBtnToggle.on('click touchstart', function (e) {
            e.preventDefault();
            e.stopPropagation();

            if (!isActive) {
                // Change the attribute to text
                $pwdInputField.attr('type', 'text');
                $pwdBtnToggle.removeClass(hidePwdCssClass).addClass(showPwdCssClass);
                isActive = true;
            } else {
                // Change the attribute back to password
                $pwdInputField.attr('type', 'password');
                $pwdBtnToggle.removeClass(showPwdCssClass).addClass(hidePwdCssClass);
                isActive = false;
            }
        });
    }
}

function loadMore() {
    var $loadMoreOrdersButton = $('.js-load-more-orders');

    $loadMoreOrdersButton.on('click', function (e) {
        e.preventDefault();
        progress.show($loadMoreOrdersButton)
        $('.js-loading-hide-span').hide();
        $loadMoreOrdersButton.addClass('loading');
        $loadMoreOrdersButton.attr('disabled','disabled');

        var url = $loadMoreOrdersButton.attr('data-url');
        var options = {
            url: url,
            type: 'GET'
        };

        $.ajax(options).done(function (data) {
            $loadMoreOrdersButton.removeAttr('disabled');

            if(data) {
                // check if "load more" button should be visible for current page
                var $paginationStatus = $(data).find('.js-order-history-pagination-status');

                if($paginationStatus.length){
                    var isNextPageExists = $paginationStatus.attr('data-nextpageexists');
                    if(isNextPageExists == 'false'){
                        $loadMoreOrdersButton.hide();
                    } else {
                        var newURL = $paginationStatus.attr('data-url');
                        if(newURL){
                            $loadMoreOrdersButton.attr('data-url', newURL);
                        }
                    }
                }

                $('.cl-order-history-box__container').append(data);
                progress.hide();
                $('.js-loading-hide-span').show();
                $loadMoreOrdersButton.removeClass('loading');
                $('.back-top-box.order-history-back-to-top').removeClass('d-none').addClass('more-loaded');
            } else {
                $loadMoreOrdersButton.hide();
            }
        });
    });
}

function backToTop() {
    var $backToTopButton = $('.back-top-box.order-history-back-to-top');

    $(window).scroll(function () {
        if ($backToTopButton.hasClass('more-loaded')) {
            if ($(window).scrollTop() > $('body').height() / 3) {
                $backToTopButton.removeClass('d-none');
            } else {
                $backToTopButton.addClass('d-none');
            }
        }
    });

    $backToTopButton.on('click', function () {
        $(this).addClass('d-none');
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    togglePswdVisibility();
    loadMore();
    backToTop();
    login.init();
}

var account = {
    init: function () {
        initializeEvents();
        giftcert.init();
        returns.init();
    },
    initCartLogin: function () {
        login.init();
    }
};

module.exports = account;
