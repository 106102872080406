module.exports = {
    setNameIndexOfProductTile: function(element){
        let elementTileWrapper = element.parents('.product-tile__wrapper');
        let index = $('.product-tile').index($(element).parents('.product-tile'));
        let name = elementTileWrapper.find('.product-tile__name').text().trim().replace(/(\r\n|\n|\r)/gm,"");
        return 'position: ' + index + ' - name: ' + name;
    },
    parentPageType: function (element, elemStatic) {
        // check if it element is or coming from carousel 
        if(element.is('.js-einstein-recommender')){
            // in case of plp and search
            if(window.pageContext.ns == 'search'){
                elemStatic['list_parent_page_type'] = "plp";
            }else if(window.pageContext.ns == 'product'){
                elemStatic['list_parent_page_type'] = "pdp";
            }else{
                elemStatic['list_parent_page_type'] = window.pageContext.ns;
            }
        }
    },
    listType: function (element, elemStatic) {
        if(element.is('.js-einstein-recommender')){
            elemStatic['list_type'] = 'carousel'
        }
        else if($('.breadcrumb-search-result').length){
            elemStatic['list_type'] = 'search result';
        }
        else if(window.pageContext.ns == 'search'){
            elemStatic['list_type'] = "plp";
        }
    },
    listName: function (element, elemStatic) {
        if(element.is('.js-einstein-recommender')){
            elemStatic['list_name'] = element.find('.product-recommendations-slider__title').text().trim();
        }
        else {
            var bannerTitle = $('a.breadcrumb-element');
            if(bannerTitle.length && window.pageContext.ns == 'search'){
                bannerTitle = $($('a.breadcrumb-element')[$('a.breadcrumb-element').length - 1]);
                elemStatic['list_name'] = bannerTitle.find('[itemprop="name"]').text().trim();
            }else if($('.breadcrumb-search-result-url').length){
                elemStatic['list_name'] = $($('.breadcrumb-search-result-url')[0]).text().trim();
            }
        }
    },
    listResultsCount: function (element, elemStatic) {
        if(element.is('.js-einstein-recommender')){
            elemStatic['list_results_count'] = element.find('.product-recommendations-slider__single-tile').length;
        }
        else if ($('.results-hits:first')){
            elemStatic['list_results_count'] = $('.results-hits:first').data('gtm-pagecount');
        }
    },
    listPageIndex: function (element, elemStatic) {
        if(!element.is('.js-einstein-recommender') && $('.cl-pagination__links > .current-page:first'))
            elemStatic['list_page_index'] = $('.cl-pagination__links > .current-page:first').text().trim();
    },
    listResultsPerPage: function (element, elemStatic) {
        if(element.is('.js-einstein-recommender'))
            elemStatic['list_results_per_page'] = element.find("div.product-recommendations-slider__single-tile.slick-active").length;
        else if($('.cl-items-per-page').find('.ui-selectmenu-text'))
            elemStatic['list_results_per_page'] = $('.cl-items-per-page').find('.ui-selectmenu-text').text().trim();
    },
    listPageSortBy: function (element, elemStatic) {
        if(!element.is('.js-einstein-recommender') && $('.cl-sort-by').find('.ui-selectmenu-text'))
            elemStatic['list_page_sort_by'] = $('.cl-sort-by').find('.ui-selectmenu-text').text().trim();
    },
    listFiltersActive: function (element, elemStatic) {
        if(element.is('.js-einstein-recommender'))
            return;

        let filterList = [];
        $('.breadcrumb-refinement[data-divider="and"]').each(function() {
            let filter_name = $(this).find('.breadcrumb-refinement-name ').text().trim();
            var filter_value = '';
            // it could has many elments let's try to get text only
            $(this).find('.breadcrumb-refinement-value ').each(function() {
                filter_value = $(this).text().trim().replace(/(\r\n|\n|\r)/gm,"");
            });
            
            filterList[filter_name] = filter_value;
        });
        // set the values
        if(Object.keys(filterList).length){
            elemStatic['list_filters_active'] = '';
            Object.keys(filterList).forEach(function(key) {
                if(elemStatic['list_filters_active']){
                    elemStatic['list_filters_active'] = elemStatic['list_filters_active'] + ' | ';
                }
                elemStatic['list_filters_active'] += key +': '+ filterList[key];
            });
        };
    }
};

