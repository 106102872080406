'use strict';

var tilesmatchheight = require('./../../../../app_capitollighting_core/cartridge/js/tilesmatchheight');

/**
 * @private
 * @function
 * @description Function to initialize slick slider for the product recommendations.
 */
function initProductRecommendationsCarousel() {
    var $productsCarousel = $('.js-product-recommendations-slider:not(.loaded)');

    if ($productsCarousel.length > 0) {
        let svgArrowRightIcon    = '<svg class="product-recommendations-slider__svg-icon"><use xlink:href="#arrow-right"></use></svg>',
            svgArrowLeftIcon     = '<svg class="product-recommendations-slider__svg-icon"><use xlink:href="#arrow-left"></use></svg>',
            carouselArrowNext    = '<button class="product-recommendations-slider__arrow product-recommendations-slider__arrow--next js-slider-next">' + svgArrowRightIcon + '</button>',
            carouselArrowPrev    = '<button class="product-recommendations-slider__arrow product-recommendations-slider__arrow--prev js-slider-prev">' + svgArrowLeftIcon + '</button>',
            carouselDotsCssClass = 'product-recommendations-slider__dots ms-no-margin ms-no-padding ms-no-list-items ms-flex';

        let productsCarouselConfigs = {
            nextArrow: carouselArrowNext,
            prevArrow: carouselArrowPrev,
            dotsClass: carouselDotsCssClass,
            arrows: false,
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: 2,
            variableWidth: false,
            slidesToScroll: 1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 1365,
                    settings: {
                        slidesToShow: 5,
                        arrows: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 4,
                        arrows: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        arrows: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 639,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                        dots: true
                    }
                }
            ]
        };

        // Slick carousel equal height slides
        if ($productsCarousel.attr('data-equal-slides') == 'true') {
            $productsCarousel.on('setPosition', function () {
                $(this).find('.slick-slide').height('auto');

                let slickTrack = $(this).find('.slick-track'),
                    slickTrackHeight = $(slickTrack).height();

                $(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
            });
        }

        $productsCarousel.on('breakpoint init reInit', function(e, config) {
            if (config.options.slidesToShow > config.slideCount) {
                $productsCarousel.addClass('slick-inactive');
            } else {
                $productsCarousel.removeClass('slick-inactive');
            }

            $productsCarousel.find('.slick-slide').removeAttr('aria-describedby');
        });

        $productsCarousel.slick(productsCarouselConfigs);
        $productsCarousel.addClass('loaded');
        tilesmatchheight.elMatchHeight();
    }
}

/**
 * @private
 * @function
 * @description check carousels to be initialized
 * @returns {Boolean} the count of not initialized carousels
 */
function checkNotLoadedCarousels() {
    var $productsCarousel = $('.js-product-recommendations-slider:not(.loaded)');
    return $productsCarousel.length > 0;
}

/**
 * @private
 * @function
 * @description recursive function to initialize dynamically loaded carousels
 */
function initCarousels() {
    if (checkNotLoadedCarousels()) {
        initProductRecommendationsCarousel();
        return;
    }
}

exports.init = function () {
    initCarousels();
    $(window).on('load scroll', initCarousels);
};
