

var pdpOptionInit    = require("./pages/product/variant"),
    tilesmatchheight = require("./tilesmatchheight"),
    dialog           = require("./dialog"),
    validator        = require("./validator"),
    login            = require("./login"),
    search           = require("./pages/search"),
    util             = require("./util"),
    populateHref     = require("./pages/product/populateHref"),
    _                = require("lodash");

let dialogCongig = {
    autoOpen    : false,
    draggable   : false,
    modal       : true,
    resizable   : false,
    width       : "none",
    position    : {
        my: "center",
        at: "center",
        of: window
    },
    dialogClass : "cl-actions-dialog cl-actions-dialog--large cl-wishlist-dialog",
    classes: {
        "ui-dialog"                : "cl-actions-dialog cl-actions-dialog--large",
        "ui-dialog-title"          : "cl-actions-dialog__title",
        "ui-dialog-titlebar"       : "cl-actions-dialog__titlebar",
        "ui-dialog-titlebar-close" : "cl-actions-dialog__close",
        "ui-dialog-content"        : "cl-actions-dialog__content"
    },
    open: function () {
        //will set dialog title
        var dialogTitle = $(this).closest(".ui-dialog")
            .find(".ui-dialog-name")
            .attr("data-name");

        $(this).closest(".ui-dialog")
            .find(".ui-dialog-title")
            .text(dialogTitle);

        // Add custom close button icon
        $(this).closest(".ui-dialog")
            .find(".ui-dialog-titlebar-close")
            .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>")
            .on("click", function () {
                search.removeModalFromURL();
                dialog.close();
            });

        $(this).closest(".ui-dialog")
            .find(".js-registration-link")
            .on("click", function () {
                $(".showForm").toggleClass("ms-hide");
            });

        $(this).closest(".ui-dialog")
            .find("#RegistrationForm .email-validation").on("input", function () {
                $(".customer-exist-error").addClass("ms-hide");
            });

        // Close Dialog When Clicked Outside
        $(document).find(".ui-widget-overlay").bind("click", function () {
            search.removeModalFromURL();
            dialog.close();
        });

        // Close dialog when key up on Escape button
        $(document).keyup(function (e) {
            // Escape key maps to keycode `27`
            if (e.key === "Escape") {
                search.removeModalFromURL();
                dialog.close();
            }
        });

        if ($(this).closest(".ui-dialog").find(".js-customer-authenticated").length) {
            window.history.pushState(null, null, util.removeParamFromURL(window.location.href, "scope"));
        }

        if (location.href.match(/(\?|&)scope($|&|=regerror)/)) {
            $(this).closest(".ui-dialog").find(".login-block").addClass("ms-hide");
            $(this).closest(".ui-dialog").find(".registration-block").removeClass("ms-hide");
        }

        validator.init();
        var $requestPasswordForm = $(this).closest(".ui-dialog").find("[name$=\"_requestpassword\"]");
        var $submitWishlistLogin = $requestPasswordForm.find("[name$=\"_requestpassword_send\"]");

        $($submitWishlistLogin).on("click", function (e) {
            e.preventDefault();

            if (!$requestPasswordForm.valid()) {
                return;
            }
        });

        login.init();
        sendProductEmail();
    }
};

/**
 * @private
 * @function
 * @description Initialise events for email product form
 */
function sendProductEmail() {
    if ($(document).find(".js-email-product-button").length > 0) {
        var $errorDiv   = $(document).find(".js-email-product-error"),
            $successDiv = $(document).find(".js-email-product-success"),
            $container  = $(".js-email-product-form");

        $errorDiv.hide();
        $successDiv.hide();

        $(document).find(".js-email-product-button").on("click", function (event) {
            event.preventDefault();

            if (!$container.valid()) {
                return;
            }

            jQuery.ajax({
                type : "POST",
                url  : $(this).attr("data-url"),
                data : {
                    sendername         : $container.find("input[name$='sendername']").val(),
                    senderemail        : $container.find("input[name$='senderemail']").val(),
                    recipientname      : $container.find("input[name$='recipientname']").val(),
                    recipientemail     : $container.find("input[name$='recipientemail']").val(),
                    message            : $container.find("textarea[name$='message']").val(),
                    checkboxsederemail : $container.find("input[name$='checkboxsederemail']").is(":checked")
                }
            }).done(function (response) {
                if (response && response.success) {
                    $errorDiv.hide();
                    $successDiv.show();

                    setTimeout(function () {
                        dialog.close();
                    }, 2000);

                } else {
                    $errorDiv.text(Resources.VALIDATE_CONTENT_POPUP).show();
                }
            });
        });
    }
}

exports.init = function init(attrName) {
    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["phone", "phoneLandscape"],
            enter: function () {
                dialogCongig.position = {
                    my: "center top",
                    at: "center top",
                    of: window
                };
            }
        }, {
            breakpoint: ["tablet", "desktop", "desktopLarge"],
            enter: function () {
                dialogCongig.position = {
                    my: "center",
                    at: "center",
                    of: window
                };
            }
        }
    ]);

    var svgArrowRightIcon = "<svg class=\"slider-nav__svg-icon\"><use xlink:href=\"#down-arrow-empty\"></use></svg>",
        svgArrowLeftIcon = "<svg class=\"slider-nav__svg-icon\"><use xlink:href=\"#up-arrow-empty\"></use></svg>",
        carouselArrowNext = "<button class=\"slider-nav__arrow slider-nav__arrow--next\">" + svgArrowRightIcon + "</button>",
        carouselArrowPrev = "<button class=\"slider-nav__arrow slider-nav__arrow--prev\">" + svgArrowLeftIcon + "</button>";

    var zoomSvgArrowRightIcon = "<svg class=\"modal-slider-for__svg-icon\"><use xlink:href=\"#arrow-right\"></use></svg>",
        zoomSvgArrowLeftIcon = "<svg class=\"modal-slider-for__svg-icon\"><use xlink:href=\"#arrow-left\"></use></svg>",
        zoomCarouselArrowNext = "<button class=\"modal-slider-for__arrow modal-slider-for__arrow--next\">" + zoomSvgArrowRightIcon + "</button>",
        zoomCarouselArrowPrev = "<button class=\"modal-slider-for__arrow modal-slider-for__arrow--prev\">" + zoomSvgArrowLeftIcon + "</button>";

    $(".slider-for").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        asNavFor: ".slider-nav",
        infinite: false,
        draggable: false,
        verticalSwiping: false,
        touchMove: false,
        swipeToSlide: false,
        swipe: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    fade: true
                }
            }
        ]
    });

    $(".slider-nav").slick({
        asNavFor: ".slider-for",
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: true,
        dots: false,
        nextArrow: carouselArrowNext,
        prevArrow: carouselArrowPrev,
        centerMode: true,
        centerPadding: "0",
        focusOnSelect: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1365,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    vertical: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    vertical: false,
                    draggable: true,
                    centerMode: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    vertical: false,
                    draggable: true,
                    centerMode: false
                }
            },
            {
                breakpoint: 639,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    vertical: false,
                    draggable: true
                }
            }
        ]
    });

    $(".modal-slider-for").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: false,
        nextArrow: zoomCarouselArrowNext,
        prevArrow: zoomCarouselArrowPrev,
        asNavFor: ".modal-slider-nav",
        vertical: false,
        infinite: false,
        draggable: false,
        verticalSwiping: true,
        touchMove: false,
        swipeToSlide: false,
        swipe: false
    }).on("setPosition", function () {
        // Set slider caption from image alt
        var $captionHolder = $(document).find(".js-zoom-image-caption"),
            $slideImage    = $(document).find(".modal-slider-for .slick-current img");

        $captionHolder.text($slideImage.attr("alt") || "");
    });

    var imageCount = 10;

    if ($(".pdp-carousel-wrapper").length) {
        imageCount = parseInt($(".pdp-carousel-wrapper").attr("data-images"), 10);
    }

    $(".modal-slider-nav").slick({
        asNavFor: ".modal-slider-for",
        arrows: false,
        slidesToShow: imageCount,
        vertical: false,
        verticalSwiping: false,
        dots: false,
        nextArrow: carouselArrowNext,
        prevArrow: carouselArrowPrev,
        centerMode: false,
        focusOnSelect: true,
        infinite: false,
        draggable: false,
        swipe: false,
        responsive: [
            {
                breakpoint: 1365,
                settings: {
                    slidesToShow: imageCount,
                    vertical: false,
                    draggable: false,
                    swipe: false
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: imageCount,
                    vertical: false,
                    draggable: false,
                    swipe: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 5,
                    vertical: false,
                    draggable: false,
                    swipe: false
                }
            },
            {
                breakpoint: 639,
                settings: {
                    slidesToShow: 4,
                    vertical: false,
                    draggable: false,
                    swipe: false
                }
            }
        ]
    });

    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["desktop", "desktopLarge"],
            enter: function () {
                if ($(".js-primary-image").length) {
                    $(".js-primary-image").each(function () {
                        var $imageHolder = $(document).find(".js-product-image-holder"),
                            $zoomImage   = $(document).find(".js-product-image-holder .slick-current img");

                        // Set zoom preview offset for PDP by formula: image wrapper - image width / 2 + padding
                        var xZoomOffset = ($(document).find("#QuickViewDialog").length) ? 0 : ($imageHolder.width() - $zoomImage.width()) / 2 + 10;

                        $(this).xzoom({
                            position: "right",
                            mposition: "inside",
                            fadeIn: true,
                            fadeOut: true,
                            smooth: false,
                            tintOpacity: 0.5,
                            scroll: false,
                            tint: "#fff",
                            defaultScale: 0.25,
                            openOnSmall: false,
                            Xoffset: xZoomOffset,
                            onopen: function () {
                                $(document).find(".xzoom-lens img").attr("src", $(document).find(".js-product-image-holder .slick-current img").attr("xoriginal"));
                            }
                        });
                    });
                }
            }
        }
    ]);

    let panZoomConfigs = {
        $zoomIn: $(".zoom-in"),
        $zoomOut: $(".zoom-out"),
        $reset: $(".reset"),
        cursor: "pointer",
        contain: "automatic"
    };

    /**
     * @private
     * @function
     * @param {Obj} configs panZoom configuration object
     * @description Check if panzoom is initialized, if yes - destroy and re-init
     */
    let checkPanZoom = function (configs) {
        let $panZoomContainer = $(".draggable-item .panzoom-parent-target");

        if ($panZoomContainer.prop("style")) {
            $panZoomContainer.panzoom("destroy");
            $panZoomContainer.panzoom(configs);
        } else {
            $panZoomContainer.panzoom(configs);
        }
    };

    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["phone", "phoneLandscape", "tablet"],
            enter: function () {
                var $modalSlider = $(".modal-slider-for");
                var $zoomInButtons = $(".js-zoom-in-button");

                panZoomConfigs.minScale = 1;
                panZoomConfigs.maxScale = 3;
                panZoomConfigs.increment = 0.5;

                checkPanZoom(_.extend(panZoomConfigs, {
                    onZoom: function (e, panzoom) {
                        if (panzoom.scale === panZoomConfigs.minScale) {
                            panzoom.resetPan();
                        }
                    }
                }));

                $modalSlider.on("beforeChange", function () {
                    var $panZoomParent =  $(".draggable-item .panzoom-parent-target");

                    $panZoomParent.panzoom("resetZoom");
                });

                $zoomInButtons.addClass("mobile-zoom-buttons");
            }
        }, {
            breakpoint: ["desktop", "desktopLarge"],
            enter: function () {
                var $zoomInButtons = $(".js-zoom-in-button");

                panZoomConfigs.minScale = 1;
                panZoomConfigs.maxScale = 5;
                panZoomConfigs.increment = 0.2;
                checkPanZoom(panZoomConfigs);
                $zoomInButtons.removeClass("mobile-zoom-buttons");
            }
        }
    ]);

    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["phone", "phoneLandscape"],
            enter: function () {
                // Used to show Zoom modal on phone when user clicks on product image
                $("a.js-thumb-image").attr("href", "#zoom-modal");

                $("#open-zoom-modal, .js-thumb-image").on("click", function () {
                    $("body").addClass("ms-overflow-hidden");
                    openZoomModal();
                });
            }
        }, {
            breakpoint: ["tablet", "desktop", "desktopLarge"],
            enter: function () {
                $("a.js-thumb-image").attr("href", "#");

                $("a.js-thumb-image").on("click", function (e) {
                    e.preventDefault();
                });

                // Used to show Zoom modal on tablet / desktop when user clicks on primary image
                $("#open-zoom-modal, .js-primary-image").on("click", function () {
                    openZoomModal();
                });
            }
        }
    ]);

    $(document).off("click", ".js-add-to-wishlist").on("click", ".js-add-to-wishlist", function (event) {
        event.preventDefault();
        event.stopPropagation();
        populateHref.populateAddToWishlistHrefCurrentElement($(this));

        dialog.open({
            url: $(this).attr("href"),
            options: dialogCongig
        });
    });

    if (location.href.match(/(\?|&)modal($|&|=wishlist)/)) {
        populateHref.populateAddToWishlistHref();

        dialog.open({
            url: $(document).find(".js-add-to-wishlist").attr("href"),
            options: dialogCongig
        });
    }

    // listen click to create new list
    $(document).on("click", ".js-create-new-list", function (e) {
        e.preventDefault();

        if ($(".wishlist-select").length) {
            $(".wishlist-select").fadeOut("fast");
        }

        $(".js-create-new-list").fadeOut("fast", function () {
            $(".js-form-create").fadeIn("fast");
        });
    });

    $(document).on("click", ".js-cancel", function (e) {
        e.preventDefault();

        if ($(".js-form-create").length) {
            $(".js-form-create").fadeOut("fast", function () {
                $(".js-create-new-list").fadeIn("fast");
                $(".wishlist-select").fadeIn("fast");
            });
        }
    });

    $(document).on("click", ".js-save-to-product-list", function (e) {
        e.preventDefault();
        var $form          = $(document).find(".cl-wishlist__field-create");

        populateHref.populateFormActionUrl($form);

        var productListId  = $form.find("select[name$='dwfrm_wishlist_productlists']").val(),
            productId      = $form.attr("action").match(/pid=([^&]+)/)[1],
            $producDetails = $(document).find("[data-itemid=\"" + productId  + "\"]");
        $form.find("input[name$='wfrm_wishlist_productlistname']").val(productListId);

        var options = {
            url  : $form.attr("action"),
            data : $form.serialize(),
            type : "POST"
        };

        $.ajax(options).done(function (dataofconfirm) {
            if (typeof(dataofconfirm) !== "string") {
                if (dataofconfirm.success) {
                    // Fire add to whishlist dataLayer event
                    if (SitePreferences.IS_GTM_GA4_ENABLED) {
                        Controller.fireAddToWishlistEvent(productId);
                    }

                    search.removeModalFromURL();
                    dialog.close();

                    // Add active class to heart icon
                    $producDetails.find(".js-add-to-wishlist").addClass("active");
                } else {
                    var selectError = $(document).find(".js-selectwishlist-error");
                    validator.init();

                    if ($("#wishlists").find("option:selected").val() == "") {
                        selectError.removeClass("ms-hide").addClass("error");
                    }
                }
            }
        });
    });

    $(document).on("submit", ".js-update-wishlist-form", function (e) {
        e.preventDefault();

        if (search.getCookie("pidModal")) {
            search.removeCookie("pidModal");
        }

        var $form         = $(document).find(".cl-wishlist__field-create");

        populateHref.populateFormActionUrl($form);

        var productListId = $form.find("select[name$='dwfrm_wishlist_productlists']").val();
        $form.find("input[name$='wfrm_wishlist_productlistname']").val(productListId);
        var nameInput     = $form.find("input[name$=\"_wishlist_create_name\"]");
        var productId     = $form.attr("action").match(/pid=([^&]+)/)[1];
        var $productTile  = $(document).find("[data-itemid=\"" + productId  + "\"]");
        var options       = {
            url  : $form.attr("action"),
            data : $form.serialize(),
            type : "POST"
        };

        if (!$form.valid()) {
            return false;
        }

        nameInput.on("change", function () {
            $(document).find(".js-createwishlist-error").addClass("ms-hide");
        });

        $.ajax(options).done(function (dataofconfirm) {
            if (typeof(dataofconfirm) !== "string") {
                if (dataofconfirm.success) {
                    // Fire add to whishlist dataLayer event
                    if (SitePreferences.IS_GTM_GA4_ENABLED) {
                        Controller.fireAddToWishlistEvent(productId);
                    }

                    window.history.pushState(null, null, util.removeParamFromURL(window.location.href, "modal"));

                    if (window.location.href.indexOf("?") != -1 && window.location.href.split("?")[1].length == 0) {
                        window.history.pushState(null, null, window.location.href.substr(0, window.location.href.indexOf("?")));
                    }

                    dialog.close();
                    // Add active class to heart icon
                    $productTile.find(".js-add-to-wishlist").addClass("active");
                } else {
                    var createError = $(document).find(".js-createwishlist-error");
                    validator.init();

                    if (dataofconfirm.isExistSameName) {
                        createError.removeClass("ms-hide").addClass("error");
                    }
                }
            }
        });
    });

    search.togglePswdVisibility();

    function openZoomModal() {
        var modalContainer = $(".js-zoom-modal-container");
        var closeZoomModal = $(".js-close-zoom-modal");

        window.modulesSystem.jRes.addFunc([
            {
                breakpoint: ["desktop", "desktopLarge"],
                enter: function () {
                    modalContainer.css("top", 5 + "%");

                    closeZoomModal.on("click", function (e) {
                        e.preventDefault();
                        modalContainer.css("top", "-100vh");
                    });
                }
            }
        ]);
    }

    $(".draggable-item .slick-list").on("panzoomend", function (e, panzoom, matrix, changed) {
        if (changed) {
            // deal with drags or touch moves
        } else {
            // deal with clicks or taps
            var clicks = $(this).data("clicks");

            if (!clicks) {
                $(".zoom-in").trigger("click");
            } else {
                $(".reset").trigger("click");
            }

            $(this).data("clicks", !clicks);
        }
    });

    // Close Zoom Modal when you click outside container
    $("#open-zoom-modal, .js-primary-image, .js-thumb-image").on("click", function (e) {
        e.preventDefault();

        var zoomModalOverlay = $(".js-zoom-modal");
        var closeZoomModal = $(".js-close-zoom-modal");

        zoomModalOverlay.addClass("is-visible");

        if ($(e.target).closest(".js-thumb-image").length) {
            window.modulesSystem.jRes.addFunc([
                {
                    breakpoint: ["tablet", "desktop", "desktopLarge"],
                    enter: function () {
                        $(".js-zoom-modal").removeClass("is-visible");
                    }
                }
            ]);
        }

        if (zoomModalOverlay.length > 0) {
            zoomModalOverlay.mouseup(function (e) { //NOSONAR
                var container = $(".js-zoom-modal-container");

                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    closeZoomModal.trigger("click");
                    zoomModalOverlay.removeClass("is-visible");
                }
            });
        }

        closeZoomModal.on("click", function () {
            zoomModalOverlay.removeClass("is-visible");
            $("body").removeClass("ms-overflow-hidden");
        });
    });

    // PDP Tab Functionality
    var tabContent = $(".js-tab-content");
    var tabDrawer = $(".js-tab-drawer-heading");
    var ulTabs = $("ul.ms-tabs li");

    tabContent.hide();
    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["desktop", "desktopLarge"],
            enter: function () {
                tabContent.first().show();
            }
        }
    ]);

    tabDrawer.removeClass("d_active");

    /* if in tab mode */
    ulTabs.on("click focus", function () {
        if ($(this).hasClass("active")) {
            return;
        }

        tabContent.hide();
        var activeTab = $(this).attr("rel");

        $("#" + activeTab).fadeIn();

        ulTabs.removeClass("active");
        $(this).addClass("active");

        tabDrawer.removeClass("d_active");
        $(".js-tab-drawer-heading[rel^='" + activeTab + "']").addClass("d_active");
    });

    if (typeof TurnTo !== "undefined") {
        // Functionality for custom Write Review button
        $(document).off("click", ".js-write-review-btn").on("click", ".js-write-review-btn", function (e) {
            e.preventDefault();
            var $btn = $(this);

            // Prevent user double click
            $btn.css("pointer-events", "none");
            window.setTimeout(function () {
                $btn.css("pointer-events", "auto");
            }, 1000);

            TurnTo.writeReview();
        });

        // Close review dialog by click on overlay
        $(document).off("click", "#TTtraBackOverlay").on("click", "#TTtraBackOverlay", function (e) {
            e.preventDefault();
            $("#TTtraWindowClose").trigger("click");
        });
    }

    $("body").on("click", ".js-from-collection", function () {
        $(".js-from-collection-wrapper .js-product-recommendations-slider").slick("refresh");
        tilesmatchheight.elMatchHeight();
    });

    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["phone", "phoneLandscape", "tablet"],
            enter: function () {
                $("body").on("click", ".js-product-recommendations", function () {
                    $(".js-from-collection-wrapper .js-product-recommendations-slider").slick("refresh");
                    tilesmatchheight.elMatchHeight();
                });
            }
        }
    ]);

    // Functionality that  will show Options message and make CTA inactive
    var addToCart = $("#add-to-cart, #add-to-cart-sticky");
    var optionsSelect = $(".js-pdp-error-message");
    if (addToCart.hasClass("add-to-cart-disabled")) {
        addToCart.addClass("add-to-cart-btn--inactive");
    } else {
        addToCart.removeClass("add-to-cart-btn--inactive");
        optionsSelect.addClass("ms-hide");
    }

    $(".js-hide-show-error").on("click", function () {
        if (addToCart.hasClass("add-to-cart-disabled")) {
            optionsSelect.removeClass("ms-hide");
        }
    });

    // Reinit PDP variant
    pdpOptionInit.init(attrName);

    // Open Email Product Modal Window
    if ($(document).find(".js-email-share").length > 0) {
        $(document).find(".js-email-share").on("click", function (event) {
            event.preventDefault();
            populateHref.populateEmailProductHref();

            dialog.open({
                url: $(this).attr("href"),
                options: dialogCongig
            });
        });
    }

    // Functionality for Order Confirm Page -- Show Create Account content
    var orderConfirmRegister = $(".js-order-confirm-register");
    var orderConfirmForm = $(".js-order-confirm-form");

    orderConfirmRegister.on("click", function () {
        orderConfirmForm.show();
        orderConfirmRegister.addClass("ms-hide");
    });

    $(".js-print-page").on("click", function (e) {
        e.preventDefault();
        window.print();
    });

    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["phone", "phoneLandscape", "tablet"],
            enter: function () {
                // Function that hide and show review section
                var reviewSection = $(".js-reviews-section"),
                    reviewToggle = $(".js-show-reviews");
                reviewSection.addClass("ms-hide");
                reviewToggle.removeClass("expanded");

                $(".js-show-reviews").off("click").on("click", function (e) {
                    e.preventDefault();

                    var clicks = $(this).data("clicks");

                    if (!clicks) {
                        reviewSection.removeClass("ms-hide");
                        $(this).addClass("expanded");
                    } else {
                        reviewSection.addClass("ms-hide");
                        $(this).removeClass("expanded");
                    }

                    $(this).data("clicks", !clicks);
                });
            }
        },
        {
            breakpoint: ["desktop", "desktopLarge"],
            enter: function () {
                var reviewSection = $(".js-reviews-section");
                reviewSection.removeClass("ms-hide");
            }
        }
    ]);
};
