'use strict';
const CHECKOUT_STEPS = ['shipping address', 'shipping methods', 'billing address', 'payment methods', 'order review','order confirmation'];

function getCurrentStep() {
    var currentStep = '';
    var step = $('.ui-state-active').data('gtm-step');
    currentStep = CHECKOUT_STEPS[step];
    if (!currentStep && $('.confirmation').length) {
        currentStep = CHECKOUT_STEPS[5];
    } else if (!currentStep && $('.order-review-page').length) {
        currentStep = CHECKOUT_STEPS[4];
    }
    return currentStep || '';
}

function getCheckoutShippingMethod() {
    var checkoutShippingMethod = $('#checkout-shipping-methods input:checked').data('gtm-shipping-method');
    if (!checkoutShippingMethod) {
        checkoutShippingMethod = $('.order-shipping').data('gtm-shipping');
    }
    return checkoutShippingMethod ? checkoutShippingMethod.toLowerCase() : '';
}

function getCheckoutPaymentMethod() {
    var selectedPaymentMethod;
    if (['order review','order confirmation'].indexOf(getCurrentStep()) === -1) {
        if (window.paymentSelected) {
            selectedPaymentMethod = $('input[name$=_selectedPaymentMethodID]:checked').val();
            if (!selectedPaymentMethod && $('.cc-info').length) {
                selectedPaymentMethod = 'credit_card';
            }
        }
    } else {
        if ($('#cc-details').length) {
            selectedPaymentMethod = 'credit_card';
        } else if ($('#payment-method').length) {
            selectedPaymentMethod = $('#payment-method').text().trim();
        }
    }
    return selectedPaymentMethod ? selectedPaymentMethod.toLowerCase() : '';
}

function getCheckoutPaymentMethodDetail() {
    var paymentType;
   if (getCheckoutPaymentMethod() === 'credit_card') {
        if ($('input[name$=_creditCardList]:checked').length) {
            paymentType = $('input[name$=_creditCardList]:checked').closest('li').find('.cc-card-img').data('type');
        } else if ($('.js-payment-card-number').length){
            paymentType = $('.js-payment-card-number')[0].dataset.type;
        } else if (!paymentType && $('.cc-card-img').length) {
            paymentType = $('.cc-card-img').data('type');
        }
    }
    
    return  paymentType ? paymentType.toLowerCase() : '';
}

function createEvent(elementPrimary, elementSecondary, elementDescription) {
    dataLayer.push({
		event: 'gtm_event',
		non_interaction_hit: false,
        event_name: 'checkout_interaction',
        element: 'checkout',
        element_action: 'click',
        element_ordinal: elementPrimary === 'edit' ? 'secondary' : 'primary',
        element_primary: elementPrimary.toLowerCase() || null,
        element_secondary: elementSecondary.toLowerCase() || null,
        element_description: elementDescription,
        checkout_step: getCurrentStep() || null,
        checkout_shipping_method: getCheckoutShippingMethod().toLowerCase() || null,
        checkout_payment_method: getCheckoutPaymentMethod() || null, 
        checkout_payment_method_detail: getCheckoutPaymentMethodDetail() || null
    });
}

module.exports = function () {
    $('body').on('change', 'input[name$=_shippingMethodID]', function() {
        var shippingMethodName = $(this).data('gtm-shipping-method').toLowerCase() || null;
        createEvent('shipping method', '', shippingMethodName);
    });
    
    $('body').on('change', 'input[name$=_selectedPaymentMethodID]', function () {
        var selectedPaymentMethod = $(this).val().toLowerCase() || null;
        createEvent('payment method', '', selectedPaymentMethod);
        window.paymentSelected = true;
    });
    
    $('body').on('change', 'input[name$=_useAsBillingAddress]', function () {
        var isBillingSameAsShipping = $(this).is(':checked');
        createEvent('billing same as shipping', '', isBillingSameAsShipping);
    });
    
    $('body').on('click', '#submit-opc', function () {
        window.paymentSelected = true;
        var selectedPayment = getCheckoutPaymentMethod();
        if (selectedPayment === "paypalcredit") {
            createEvent('paypal', '', 'proceed to paypal');
        } else if (selectedPayment === 'credit_card') {
            var cardType = $('.js-payment-card-number').data('type').toLowerCase() || null;
            createEvent('credit card', '', cardType);
        }
    });
    
    $('body').on('click', '.js-checkout-step-edit-btn', function () {
        var editItem = $(this).closest('legend').data('gtm-step');
        createEvent('edit', CHECKOUT_STEPS[editItem], null);
    });
    
    $('body').on('click', '.js-chechout-edit-details', function () {
        var editItem = $(this).data('gtm-edititem');
        createEvent('edit', editItem, null);
    });
    
    $('body').on('submit', '.submit-order', function () {
        createEvent('continue to place order', '', null);
    });
    
    $('body').on('click', '.cl-continue-shopping-button', function () {
        delete window.paymentSelected;
        createEvent('order confirmation', '', 'continue shopping');
    });

    $('body').on('change', 'input[name$=_creditCardList]', function () {
        window.paymentSelected = true;
        createEvent('payment method', '', 'credit_card');
    });
};
