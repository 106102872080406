

var account            = require("./account"),
    dialog             = require("../dialog"),
    bonusProductsView  = require("../bonus-products-view"),
    quickview          = require("../quickview"),
    progress           = require("../progress"),
    util               = require("../util"),
    minicart           = require("../minicart"),
    cartStoreInventory = require("../storeinventory/cart"),
    /* -- Start Amazon Pay Integration changes -- */
    amazonPayments     = require("./amazonPayments"),
    /* -- End Amazon Pay Integration changes -- */
    savecart           = require("./savecart"),
    debounce           = require('./../../../../int_acf_core/cartridge/js/debounce'),
    validator          = require("../validator"),
    maxlength          = require("../maxlength"),
    protectionPlan = require("../protectionPlan");

const dialogCongig = {
    autoOpen: false,
    draggable: false,
    modal: true,
    resizable: false,
    width: "none",
    position: {
        my: "center",
        at: "center",
        of: window
    },
    dialogClass: "cl-actions-dialog",
    classes: {
        "ui-dialog": "cl-actions-dialog",
        "ui-dialog-title": "cl-actions-dialog__title",
        "ui-dialog-titlebar": "cl-actions-dialog__titlebar",
        "ui-dialog-titlebar-close": "cl-actions-dialog__close",
        "ui-dialog-content": "cl-actions-dialog__content"
    },
    open: function () {
        //will set dialog title
        var dialogTitle = $(this).closest(".ui-dialog")
            .find(".ui-dialog-name")
            .attr("data-name");

        $(this).closest(".ui-dialog")
            .find(".ui-dialog-title")
            .text(dialogTitle);

        // Add custom close button icon
        $(this).closest(".ui-dialog")
            .find(".ui-dialog-titlebar-close")
            .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

        // Close Dialog When Clicked Outside
        $(document).find(".ui-widget-overlay").bind("click", function () {
            dialog.close();
        });

        validator.init();
        util.customRequiredMsgValidator();
    }
};

function activeQuote() {
    var showQuoteDialog = window.localStorage.getItem("isQuote");

    if (showQuoteDialog == "true") {
        dialog.open({
            url     : Urls.quoteActive,
            options : {
                autoOpen    : false,
                draggable   : false,
                modal       : true,
                resizable   : false,
                width       : "none",
                position    : {
                    my : "center",
                    at : "center",
                    of : window
                },
                dialogClass : "cl-actions-dialog",
                classes     : {
                    "ui-dialog"                : "cl-actions-dialog",
                    "ui-dialog-title"          : "cl-actions-dialog__title",
                    "ui-dialog-titlebar"       : "cl-actions-dialog__titlebar",
                    "ui-dialog-titlebar-close" : "cl-actions-dialog__close",
                    "ui-dialog-content"        : "cl-actions-dialog__content"
                },
                open        : function () {
                    $("#btnOkQuoteWarning").on("click", function () {
                        dialog.close();
                    });

                    //will set dialog title
                    var dialogTitle = $(this).closest(".ui-dialog")
                        .find(".ui-dialog-name")
                        .attr("data-name");

                    $(this).closest(".ui-dialog")
                        .find(".ui-dialog-title")
                        .text(dialogTitle);

                    // Add custom close button icon
                    $(this).closest(".ui-dialog")
                        .find(".ui-dialog-titlebar-close")
                        .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

                    // Close Dialog When Clicked Outside
                    $(document).find(".ui-widget-overlay").bind("click", function () {
                        dialog.close();
                    });
                }
            }
        });
        window.localStorage.setItem("isQuote", false);
    }
};

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    $("#cart-table").on("click", ".item-edit-details a", function (e) {
        var $self = $(this);
        e.preventDefault();
        // Google Analytics Enhanced Commerce. Hook for cart item update correct tracking
        gaECGlobals.cartUpdateItemID = $self.parent().parent().find(".sku").find(".value").text();
        gaECGlobals.cartUpdateItemQty = $self.parent().parent().parent().find("[type=\"number\"]").val();
        quickview.show({
            url: e.target.href,
            source: "cart"
        });
    })
        .on("click", ".bonus-item-actions a, .item-details .bonusproducts a", function (e) {
            e.preventDefault();
            bonusProductsView.show(this.href);
        });

    // override enter key for coupon code entry
    $("form input[name$=\"_couponCode\"]").on("keydown", function (e) {
        if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    $("#trigger-print").on("click", function () {
        window.print();
    });

    // Update cart page on quantity change
    var updateDelay;
    $(".js-item-quantity-input").each(function() {
        $(this).data("prevValue", $(this).val());
    }).on("change", function(e) {
        var qtyInput = $(this),
            originalVal = qtyInput.attr('originalVal'),
            newVal;
        
        if(originalVal){
            if (qtyInput.attr('max') && parseInt(qtyInput.val()) > parseInt(qtyInput.attr('max'))) {
                qtyInput.val(parseInt(qtyInput.attr('max')));
            }
            newVal = parseInt(qtyInput.val());
            qtyInput.attr('originalVal', newVal);

            if(originalVal == newVal){
                e.preventDefault();
                return false;
            }
        }

        clearTimeout(updateDelay);
        
        if (SitePreferences.IS_GTM_UA_ENABLED) {
            var $cartForm = $("#cart-items-form");
            var data = {};
            if ($cartForm.find(".js-item-quantity-input").val() == 0) {
                var gtag = $(this).parents("tr").data("gtag");
                data = {
                    "event"     : "ecomRemove",
                    "ecommerce" : {
                        "remove" : {
                            "products" : [gtag]
                        }
                    }
                };
            } else {
                var products = [];
                $cartForm.find(".js-item-quantity-input").each(function () {
                    var gtag = $(this).parents("tr").data("gtag"); //NOSONAR
                    gtag.quantity = this.value;
                    products.push(gtag);
                });
                data = {
                    "event"          : "cartUpdate",
                    "updatedProduct" : {
                        "ecommerce" : {
                            "detail" : {
                                "products" : products
                            }
                        }
                    }
                };
            }
            dataLayer.push(data);
        }
        updateDelay = setTimeout(function () {
            updateCartPage.call($(this));
        }, 700);

        // start gtm ecom events based on qty up or down
        var prevValue = $(this).data("prevValue");
        var currentValue = $(this).val();
        if (parseInt(currentValue) > parseInt(prevValue)) {
            $('body').trigger('custom:itemQtyUp', [{
                pid: $(this).closest('.item-quantity').find('.item-user-actions button').parent().data("productid"),
                Quantity: currentValue - prevValue
            }]);
        } else if (parseInt(currentValue) < parseInt(prevValue)) {
            $('body').trigger('custom:itemQtyDown', [{
                pid: $(this).closest('.item-quantity').find('.item-user-actions button').parent().data("productid"),
                Quantity: prevValue - currentValue
            }]);
        }
        $(this).data("prevValue", currentValue);
        // end gtm ecom events based on qty up or down

    });

    $(document).off('refreshCart');
    $(document).on('refreshCart', function(){
        updateCartPage.call($(this));
    });
}

/**
* @private
* @function
* @description Initialize additional functionality related with PayPal integration
*/
function initPaypalFunctionality() {
    var dialog = require("../dialog"); //For SiteGenesis v100- must be './dialog' NOSONAR
    //eslint-disable-next-line
    var util = require("../util"); //For SiteGenesis v100- must be './util' NOSONAR
    var $expressButton = $(".js_paypal_start_ba_checkout");

    $expressButton.on("click", function () {
        if ($expressButton.data("is-address-exist") === true) {
            return true;
        }
        dialog.open({
            url: $expressButton.data("edit-address-url"),
            options: {
                title: $expressButton.data("edit-address-title"),
                open: initEditDefaultShippingAddressForm
            }
        });
        return false;
    });

    function initEditDefaultShippingAddressForm() {
        var $form = $("#paypalEditDefaultShippingAddress");
        $form.on("click", ".apply-button", function () {
            if (!$form.valid()) {
                return false;
            }
            var applyName = $form.find(".apply-button").attr("name");
            var options   = {
                url: $form.attr("action"),
                data: $form.serialize() + "&" + applyName + "=x",
                type: "POST"
            };

            $.ajax(options).done(function (data) {
                if (typeof(data) !== "string") {
                    if (data.success) {
                        dialog.close();
                        window.location = $expressButton.attr("href");
                    } else {
                        window.alert(data.message);//NOSONAR
                        return false;
                    }
                } else {

                    $("#dialog-container").html(data);
                    initEditDefaultShippingAddressForm();
                }
            });
            return false;
        });
        $form.on("click", ".cancel-button, .close-button", function () {
            dialog.close();
            return false;
        });

        $("#paypalSelectSavedAddress").change(function () {
            var data = $(this).val();
            try {
                data = JSON.parse(data);

                for (let name in data) {
                    var val = data[name];
                    if (typeof val === "string") {
                        val = val.replace(/\^/g, "'");
                    }
                    $("#dwfrm_profile_address_" + name).val(val);
                }
            } catch (e) {
                $form.find("input:text").val("");
                $form.find("select").val("");
            }
        });
    }
}

/**
* @private
* @function
* @description Initialize additional functionality for Email Cart
*/
function initializeEmailCart() {
    var url       = Urls.emailCart,
        emailRegX = /^[\w.%+\-]+@[\w.\-]+\.[\w]{2,6}$/; //eslint-disable-line

    $(document).on("click", "#email-cart", function (e) {
        e.preventDefault();
        dialog.open({
            url : url,
            options: dialogCongig
        });

        $(document).find(".js-email-cart-error").hide();
    });

    $(document).on("keydown", ".js-cart-coupone", function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            $("#add-coupon").click();
        }
    });

    $(document).on("click", ".js-apply-button", function (e) {
        e.preventDefault();

        if (!$("#sendEmail").valid()) {
            $(this).attr("disabled", "disabled");
            return false;
        }

        var errorDiv = $(".js-email-cart-error");

        if ($("input[name$='sendername']").val().length === 0) {
            errorDiv.text(Resources.SENDER_EMPTY_NAME).show();
            return false;
        }

        if ($("input[name$='senderemail']").val().length === 0 || !emailRegX.test($.trim($("input[name$='senderemail']").val()))) {
            errorDiv.text(Resources.SENDER_EMPTY_EMAIL).show();
            return false;
        }

        if ($("input[name$='recipientemail']").val().length === 0 || !emailRegX.test($.trim($("input[name$='recipientemail']").val()))) {
            errorDiv.text(Resources.RECIPIENT_EMPTY_EMAIL).show();
            return false;
        }

        if ($("input[name$='recipientname']").val().length === 0) {
            errorDiv.text(Resources.RECIPIENT_EMPTY_NAME).show();
            return false;
        }

        jQuery.ajax({
            type : "POST",
            url  : Urls.sendEmail,
            data : {
                sendername        : $("input[name$='sendername']").val(),
                senderemail       : $("input[name$='senderemail']").val(),
                recipientname     : $("input[name$='recipientname']").val(),
                recipientemail    : $("input[name$='recipientemail']").val(),
                message           : $("textarea[name$='message']").val(),
                checkboxsederemail: $("input[name$='checkboxsederemail']").is(":checked")
            }
        }).done(function (response) {
            if (response && response.success) {
                dialog.close();
            } else {
                errorDiv.text(Resources.VALIDATE_CONTENT_POPUP).show();
            }
        });
    });

    $(document).on("change, keyup", ".sendemail .cl-form-input.required", function () {
        if ($(this).val().trim() === "" || $(this).hasClass("error")) {
            $(".js-apply-button").attr("disabled", "disabled");
        } else {
            var emptyAndErrorsFields = $(".sendemail").find(".cl-form-input.required").filter(function () {
                return this.value === "" || $(this).hasClass("error");
            });

            if (emptyAndErrorsFields.length > 0) {
                $(".js-apply-button").attr("disabled", "disabled");
            } else {
                $(".js-apply-button").removeAttr("disabled");
            }
        }
    });

}

/**
 * @private
 * @function
 * @description Initialize order summary sticky bar
 */
function orderSummaryStickyBar() {
    if ((".js-minisummary-affix").length) {
        var affix;

        window.modulesSystem.jRes.addFunc([
            {
                breakpoint: ["phone", "phoneLandscape"],
                enter: function () {
                    if (affix) affix.remove();
                }
            }, {
                breakpoint: ["tablet", "desktop", "desktopLarge"],
                enter: function () {
                    affix = $(".js-minisummary-affix").mindforAffix();

                    if (affix) {
                        affix.options.marginBottom = 0;
                    }
                }
            }
        ]);
    }
}

/**
 * @private
 * @function
 * @description Initialize change item quantity
 */
function itemQuantityHandler() {
    if ($(".js-product-item").length) {
        $(".js-product-item").each(function () {
            var itemQty = $(this),
                itemQtyInput = itemQty.find(".js-item-quantity-input"),
                itemQtyBtnUp = itemQty.find(".js-item-quantity-up"),
                itemQtyBtnDown = itemQty.find(".js-item-quantity-down"),
                itemQtyMin = itemQtyInput.attr("min") ? itemQtyInput.attr("min") : 1,
                itemQtyPrice = itemQty.find(".js-item-quantity-price"),
                itemQtyHelpText = itemQty.attr("data-help-text"),
                isProductSet = false;

            if (itemQtyInput.val() > 1) {
                itemQtyPrice.find(".price-sales").append("<span class=\"js-price-helper\">" + itemQtyHelpText + "</span>");
                isProductSet = true;
            }

            itemQtyInput.on("change", function () {
                //eslint-disable-next-line
                if (parseInt(itemQtyInput.val(), 10) == NaN) {//NOSONAR
                    itemQtyInput.val(itemQtyMin);
                }

                if (itemQtyInput.val() > 1 && !isProductSet) {
                    itemQtyPrice.find(".price-sales").append("<span class=\"js-price-helper\">" + itemQtyHelpText + "</span>");
                    isProductSet = true;
                } else if (itemQtyInput.val() == 1 && isProductSet) {
                    itemQtyPrice.find(".price-sales .js-price-helper").remove();
                    isProductSet = false;
                }

                if (parseInt(itemQtyInput.val()) < parseInt(itemQtyInput.attr('min'))) {
                    itemQtyInput.val(parseInt(itemQtyInput.attr('min')));
                }
            });

            itemQtyBtnUp.off('click').on("click", function () {
                var self = $(this);
                var oldValue = parseFloat(self.parent().find('.js-item-quantity-input').val());
                var newVal = oldValue + parseInt(self.parent().find('.js-item-quantity-input').attr('step'));

                if (newVal.toString().length > self.parent().find('.js-item-quantity-input').attr("maxlength")) {
                    return false;
                }
                self.parent().find(".js-item-quantity-input").val(newVal).trigger("change");
            });

            itemQtyBtnDown.off('click').on("click", function () {
                var self = $(this),
                    oldValue = parseFloat(self.parent().find('.js-item-quantity-input').val()),
                    newVal,
                    minQtyError = self.closest('.cl-counter-wrapper').find('.min-quantity-cart');

                if (oldValue <= itemQtyMin) {
                    newVal = oldValue;
                    minQtyError.removeClass('ms-no-display');

                    setTimeout(function(){
                        minQtyError.addClass('ms-no-display');
                    }, 2000);

                    return false;

                } else {
                    newVal = oldValue - parseInt(self.parent().find('.js-item-quantity-input').attr('step'));
                }

                self.parent().find(".js-item-quantity-input").val(newVal).trigger("change");
            });
        });
    }
}

function updateCartPage() {
    var $cartForm = $("#cart-items-form");
    var url = util.appendParamToURL($cartForm.attr("action"), "format", "ajax");
    var formData = $cartForm.serialize();

    if (this) {
        formData += "&" + this.attr("name") + "=" + this.attr("value");
    }

    debounce.debounce(updatePage(url, formData), 100); //NOSONAR

    $(".js-save-cart").dialog("destroy").remove();
}

function updatePage(url, formData) {
    var cartContent =  $(".cl-cart [role=\"main\"]");
    progress.show(cartContent);
    $(".js-loading-cart").addClass("loading");

    $.post(url, formData, function (response) {
        cartContent.html(response);
        progress.hide();
        $(".js-loading-cart").removeClass("loading");
        cart.init();
        minicart.refresh();
        window.affirm.ui.refresh();
        protectionPlan.loadProtectionPlanProducts();
    });
}

function saveCartTabs() {
    var tabContent = $(".save-cart-tab-content");
    var tabItems = $("ul.save-cart-tabs li");

    tabContent.hide();
    tabContent.first().show();

    /* if in tab mode */
    tabItems.click(function () {
        tabContent.hide();
        var activeTab = $(this).attr("rel");
        $("#" + activeTab).fadeIn();

        tabItems.removeClass("active");
        $(this).addClass("active");
    });
}

// Initialize Product Edition functions
function initializeEdition() {
    initializeUpdateQuantity();
    initializeUpdatePrice();
    initializeApplyDiscount();
}

/**
 * Update Quantity
 */
function initializeUpdateQuantity() {
    $(".updateQty").off("change").on("change", function () {
        productActions(this, "updateQty", "qty");
    });
}

/**
 * Update Price
 */
function initializeUpdatePrice() {
    $(".updatePrice").off("change").on("change", function () {
        $(this).parent().parent().parent().find(".save-percent-code").addClass("ms-hide");
        productActions(this, "updatePrice", "price");
    });
}

/**
 * Apply Discount
 */
function initializeApplyDiscount() {
    $(".discountInput").off("change").on("change", function () {
        $(this).parent().parent().parent().find(".save-percent-code").addClass("ms-hide");
        productActions(this, "discount", "value");
    });
}

// Call the Product Actions function on Cart Controller
function productActions(el, action, parameter) {
    var that = $(el),
        data = {
            action   : action,
            pid      : that.attr("name").substring(4)
        };

    if (parameter) {
        data[parameter] = that.val();
    }

    $(".js-loading-cart").addClass("loading");

    jQuery.ajax({
        type : "POST",
        url  : Urls.productActionsCart,
        data : data
    }).done(function (response) {
        var cartContent = $(".cl-cart [role=\"main\"]");
        progress.show(cartContent);
        cartContent.html(response);
        progress.hide();
        $(".js-loading-cart").removeClass("loading");
        minicart.refresh();
        cart.init();
        window.affirm.ui.refresh();
    });
}

function sendDeviceType() {
    var jres = window.modulesSystem.jRes;
    var deviceType;
    if (jres.isPhone || jres.isPhoneLandscape) deviceType = "Mobile";
    else if (jres.isTablet) deviceType = "Tablet";
    else deviceType = "Desktop";
     
    liveagent.setCustomVariable('deviceType', deviceType);
}

var cart = {
    init: function () {
        activeQuote();
        initializeEvents();
        initializeEdition();
        initPaypalFunctionality();
        initializeEmailCart();
        maxlength();
        if (SitePreferences.STORE_PICKUP) {
            cartStoreInventory.init();
        }
        account.initCartLogin();
        /* -- Start Amazon Pay Integration changes -- */
        amazonPayments.init();
        /* -- End Amazon Pay Integration changes -- */
        savecart.init();
        protectionPlan.cart.init();
        
        orderSummaryStickyBar();
        itemQuantityHandler();
        saveCartTabs();
        sendDeviceType();
    }
};

module.exports=cart;
