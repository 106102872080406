'use strict';

/**
 * Determines the type of product page
 * 
 * @returns {string} - either 'configurable' or 'simple'
 */

function getPDPType() {
    var configurable = $('.product-variations').data('has-variation-gtm');
    return configurable ? 'configurable' : 'simple';
}

function getProductName() {
    return $('#pdpMain h1.product-name').text().trim();
}

/**
 * Creates and pushes a GTM event to the dataLayer
 *
 * @param {string} elementAction - the type of user action that triggered the event
 * @param {string} elementOrdinal - Primary || Product
 * @param {string} elementPrimary - Type of event
 * @param {string} elementDescription - a brief description of the event
 */
function createEvent(elementAction, elementOrdinal, elementPrimary, elementDescription) {
    dataLayer.push({
        event: "gtm_event",
		non_interaction_event: false,
        event_name: 'pdp_interaction',
        element: 'pdp',
        element_action: elementAction.toLowerCase() || null,
        element_ordinal: elementOrdinal.toLowerCase() || null,
        element_primary: elementPrimary.toLowerCase() || null,
        element_description: elementDescription.toLowerCase() || null,
		pdp_type: getPDPType()
    });
}

/**
 * Initializes event listeners for various user actions on the product page
 */
module.exports = function () {
    if (pageContext.type === 'product') {
        $('body').on('mouseover', '#pdpMain', function(e) {
            if ($(e.target).is('.primary-image')) {
                var alt = $(e.target).attr('alt') || "";
                createEvent('hover', 'primary', 'zoom', alt);
            }
        });
    
        $('body').on('click', '#pdpMain', function(e) {
            var $target = $(e.target);
    
            if ($(e.target).is('.primary-image')) {
                var alt = $(e.target).attr('alt') || "";
                createEvent('click', 'primary', 'zoom', alt);
                return;
            }
            
            if ($target.is('.add-to-wishlist-heart *')) {
                createEvent('click', 'primary', 'add to wishlist', getProductName());
            }
            
            if ($target.is('.socialsharing .share-icon *')) {
                var shareMethod = $target.closest('.share-icon').data('share');
                createEvent('click', 'primary', 'share', shareMethod);
            }
            
            if ($target.is('a#readReviews')) {
                createEvent('click', 'primary', 'reviews', 'anchor');
            }
            
            if ($target.is('button.write-review-btn')) {
                createEvent('click', 'primary', 'reviews', 'add your review');
            }   
            
            if($target.is('.pdp__document-link')) {
                var docType = $target.text().trim();
                createEvent('click', 'primary', 'documentation', docType);
            }
       
        });

        $('body').on('product:afterAddToCart', function () {
            createEvent('click', 'primary', 'add to cart', getProductName());
        });
    
        $('#pdpMain .carousel-navigation').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            var alt = $('.pdp-image-box .slick-current img').attr('alt');
            var position = nextSlide + 1;
            createEvent('click', 'primary', 'alternate image', 'position: ' + position + ' | ' + 'image: ' + alt);
            return;
        });
        
        $('body').on('change', '#pdpMain .product-variations select.js-variation-select', function () {
            var elementDescription = $(this).find('option:selected').data('gtm-attribute-type');
            createEvent('click', 'primary', 'configuration', elementDescription);
        });
        
        $('body').on('change', '#pdpMain .quantity input.quantity-text', function () {
            var selectedQuantity = $(this).val();
            createEvent('click', 'Primary', 'Quantity', selectedQuantity);
        });
        
        $('body').on('click', '.product-breadcrumbs .breadcrumb-element', function (e) {
            var breadcurmb = $(e.target).text().trim();
            createEvent('click', 'Primary', 'Breadcrumbs', breadcurmb);
        });
    
        $('body').on('click', '#TTsubmitReview', function() {
            createEvent('click', 'Primary', 'Reviews', 'Submit Review');
        });
        
        $(window).on('load', function() {
            if (typeof tangiblee !== 'undefined') {
                createEvent('click', 'Primary', 'View in Context', 'Load');
            }
        });
        
        $('body').on('click', '.tangiblee-cta', function (e) {
            createEvent('click', 'Primary', 'View in Context', 'Click');
        });
        
        $('body').on('click', '#product-info', function () {
            createEvent('click', 'Primary', 'product details', 'show details');
        });

        $('body').on('click', '.contact__call > a', function () {
            createEvent('click', 'Primary', 'Tap to Call', 'Click');
        });

        $('body').on('click', '.contact__chat > a', function () {
            createEvent('click', 'Primary', 'Click to Chat', 'Click');
        });
        
        $(document).ready(function () {
            if (pageContext.type === 'product') {
                createEvent('', '', '', getPDPType());
            }
        });
    }
};

