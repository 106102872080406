var navStaticAttributes = {
    'logoHeader' : {
        'element_action': 'Click',
        'element_ordinal': 'Primary',
        'element_primary': 'Header Logo',
    },
    'myAccountHeader' : {
        'element_action': 'Click',
        'element_ordinal': 'Primary',
        'element_primary': 'My Account',
    },
    'tradeProgramHeader' : {
        'element_action': 'Click',
        'element_ordinal': 'Primary',
        'element_primary': 'Trade Program',
    },
    'storeLocationsHeader' : {
        'element_action': 'Click',
        'element_ordinal': 'Primary',
        'element_primary': 'Store Locations',
    },
    'chatButtonHeader' : {
        'element_action': 'Click',
        'element_ordinal': 'Primary',
        'element_primary': 'Chat Button',
    },
    'LoginRegister' : {
        'element_action': 'Click',
        'element_ordinal': 'Secondary',
        'element_primary': 'My Account',
        'element_secondary': 'Login/Register'
    },
    'MyAccount' : {
        'element_action': 'Click',
        'element_ordinal': 'Secondary',
        'element_primary': 'My Account',
        'element_secondary': 'My Account'
    },
    'WishList' : {
        'element_action': 'Click',
        'element_ordinal': 'Secondary',
        'element_primary': 'My Account',
        'element_secondary': 'Wish List'
    },
    'MyOrders' : {
        'element_action': 'Click',
        'element_ordinal': 'Secondary',
        'element_primary': 'My Account',
        'element_secondary': 'My Orders'
    },
    'Logout' : {
        'element_action': 'Click',
        'element_ordinal': 'Secondary',
        'element_primary': 'My Account',
        'element_secondary': 'Logout'
    },
    'cartIconHeader' : {
        'element_action': 'Click',
        'element_ordinal': 'Primary',
        'element_primary': 'Cart'
    },
    'searchFieldClickHeader' : {
        'element_action': 'Click',
        'element_ordinal': 'Primary',
        'element_primary': 'Search Field Input Click'
    },
    'searchEngagementAutocompleteHeader' : {
        'element_action': 'Click',
        'element_ordinal': 'Secondary',
        'element_primary': 'Search Engagement',
        'element_secondary': 'Autocomplete'
    },
    'searchEngagementQuerySubmissionHeader' : {
        'element_action': 'Click',
        'element_ordinal': 'Secondary',
        'element_primary': 'Search Engagement',
        'element_secondary': 'Query Submission'
    },
    'promoHeader' : {
        'element_action': 'Click',
        'element_ordinal': 'Primary',
        'element_primary': 'Promo'
    }
};
var NavigationEvents = {
    elementEventType: '',
    logoHeader: function(element) {
        this.setNavigationEvent();
    },
    myAccountHeader: function(element) {
        this.setNavigationEvent();
    },
    tradeProgramHeader: function(element) {
        this.setNavigationEvent();
    },
    storeLocationsHeader: function(element) {
        this.setNavigationEvent();
    },
    chatButtonHeader: function(element) {
        this.setNavigationEvent();
    },
    LoginRegister: function(element) {
        this.setNavigationEvent();
    },
    MyAccount: function(element) {
        this.setNavigationEvent();
    },
    WishList: function(element) {
        this.setNavigationEvent();
    },
    MyOrders: function(element) {
        this.setNavigationEvent();
    },
    Logout: function(element) {
        this.setNavigationEvent();
    },
    cartIconHeader: function(element) {
        this.setNavigationEvent();
    },
    promoHeader: function(element) {
        this.setNavigationEvent();
    },
    searchFieldClickHeader: function(element) {
        this.setNavigationEvent();
    },
    searchEngagementAutocompleteHeader: function(element) {
        let elemStatic = navStaticAttributes[this.elementEventType];

        elemStatic['element_description'] = element.data('gtm-description');

        // push event to datalayer
        this.setNavigationEvent();
    },
    searchEngagementQuerySubmissionHeader: function(element) {
        let elemStatic = navStaticAttributes[this.elementEventType];

        elemStatic['element_description'] = $("input[name=q]").val();

        // push event to datalayer
        this.setNavigationEvent();
    },
    setNavigationEvent: function () {
        this.pushNavigationEvent(...Object.values(navStaticAttributes[this.elementEventType]));
    },
    pushNavigationEvent: function(element_action = null, elementOrdinal = null, elementPrimary = null, elementSecondary = null, elementDescription = null) {
        var eventObject = {
            event: "gtm_event",
            non_interaction_hit: false,
            event_name: 'element_interaction',
            element: 'header',
            element_action: element_action,
            element_ordinal: elementOrdinal,
            element_primary: elementPrimary,
            element_secondary: elementSecondary,
            element_description: elementDescription
        }
    
        // lowercase the values
        eventObject = this.lowercaseObjectValues(eventObject);

        dataLayer.push(eventObject);
    },
    lowercaseObjectValues: function(obj) {
        if (Array.isArray(obj)) {
            return obj.map(this.lowercaseObjectValues);
        } else if (typeof obj === 'object' && obj !== null) {
            var result = {};
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    result[key] = this.lowercaseObjectValues(obj[key]);
                }
            }
            return result;
        } else if (typeof obj === 'string') {
            return obj.toLowerCase();
        } else {
            return obj;
        }
    }
};
function init() {
    $('body').on('click', '.js-cl-header', function(e) {
        let $elem = $(e.target);

        // check if is clicked from anywhere on myaccount
        if($elem.is('.user-account') || $elem.is('.cl-user-utility__username') || $elem.is('.cl-user-utility__username-span') || $elem.is('.account-icon')) {
            $elem = $elem.parents('.js-user-info');
            
            // set the action as 'click'
            navStaticAttributes[$elem.data('gtm-event')]['element_action'] = "Click";
        }

        // check if is clicked from topbar and forward to correct parent elem
        if($elem.is('.cl-top-band__link') || $elem.is('.cl-top-band__svg-icon')) {
            $elem = $elem.parents('.cl-top-band__list-item');
        }
        
        // check if is clicked from topbar on cart
        if($elem.is('.cl-minicart__quantity') || $elem.is('.cl-header__svg-icon--cart') || $elem.is('.cart-icon-span')) {
            $elem = $elem.parents('.mini-cart-link');
        }

        // perfrom normal operations on all elements
        if($elem.data('gtm-event') && NavigationEvents[$elem.data('gtm-event')]){
            NavigationEvents.elementEventType = $elem.data('gtm-event');
            if (NavigationEvents.elementEventType !== 'searchEngagementQuerySubmissionHeader') {
                NavigationEvents[NavigationEvents.elementEventType]($elem);
            }
        }
    });

    // specific event for hover
    $('body').on('mouseenter', '.user-account', function(e) {
        let $elem = $(e.target);

        $elem = $elem.parents('.js-user-info');
        if($elem.data('gtm-event') && NavigationEvents[$elem.data('gtm-event')]){
            NavigationEvents.elementEventType = $elem.data('gtm-event');
            
            // set the action as 'hover'
            navStaticAttributes[$elem.data('gtm-event')]['element_action'] = "Hover";

            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });

    // specific event for search click/autocomplete/submission
    $('body').on('custom:searchFieldClickOrEngagement', function(e, $elem) {
        if($elem.data('gtm-event') && NavigationEvents[$elem.data('gtm-event')]){
            NavigationEvents.elementEventType = $elem.data('gtm-event');
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });

    // specific event for promo click
    $('body').on('click', '.cl-top-band__bouncex-callout', function(e) {
        let $elem = $(e.target);
        let eventName = 'promoHeader';

        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
};

module.exports = {
    init: init
}

