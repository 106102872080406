var util = require("../../util");


function initAddressFieldValidation() {
    $('body').on('keyup', 'form input[name$=_address1], form input[name$=_address2]', function () {
        var $inputLabel = $(this).closest('.form-row ').find('label .cl-form-label');
        var isAddress1 = $(this).is('input[name$=_address1]');
        if($(this).val().length >= 35){
            $(this).addClass('error');
            $inputLabel.addClass('length-error');
            $inputLabel.text(isAddress1 ? Resources.ADDRESS1_LIMITED : Resources.ADDRESS2_LIMITED);
        } else {
            $(this).removeClass('error');
            $inputLabel.removeClass('length-error');
            $inputLabel.text(isAddress1 ? Resources.ADDRESS1_DEFAULT : Resources.ADDRESS2_DEFAULT);
        }
    });
}

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
    var $form = $(".address");
    // select address from list
    $(document).on("click", ".js-select-shipping-address, .js-select-billing-address", function () {
        var addressSection;
        if ($(this).hasClass("js-select-shipping-address")) {
            addressSection = "shippingAddress";
        } else if ($(this).hasClass("js-select-billing-address")) {
            addressSection = "billingAddress";
        }

        var selectedAddress = $(this).data("address");
        if (!selectedAddress) { return; }
        util.fillAddressFields(selectedAddress, $form, addressSection);
        // re-validate the form
        $form.validate().form();
    });

    initAddressFieldValidation();
};
