'use strict';

/**
 * @private
 * @function
 * @description Function to initialize slick slider for the store categories.
 */
function initStoreCategoriesCarousel() {
    if ($('.js-store-categories').length > 0) {

        let $storeCategoriesCarousel = $('.js-store-categories');
        let storeCategoriesCarouselConfigs = {
            arrows: true,
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            variableWidth: false,
            slidesToScroll: 1,
            mobileFirst: true,
            accessibility: false,
            responsive: [
                {
                    breakpoint: 1365,
                    settings: {
                        slidesToShow: 6
                    }
                },
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };

        $storeCategoriesCarousel.slick(storeCategoriesCarouselConfigs);
    }
}

exports.init = function () {
    initStoreCategoriesCarousel();
};