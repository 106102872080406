
var listHalpers = require('../helper/listCustomTagHalpers');

var navStaticAttributes = {
    'pageLoadList' : {
        non_interaction_hit: true,
        list_type: null,
        element_action: 'Load',
        element_ordinal: null,
        element_primary: null,
        element_description: null,
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'imageHeroList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Hero Image',
        element_description: null,
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'breadcrumbList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Breadcrumbs',
        element_description: null,
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'filterByList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Filter by',
        element_description: null,
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'openFilterList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Filter',
        element_description: 'Open',
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'closeFilterList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Filter',
        element_description: 'Close',
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'removeFilterList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Remove Filter',
        element_description: null,
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'sortByList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Sort By',
        element_description: null,
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'paginationList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Pagination',
        element_description: null,
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'showPerPageList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Show Per Page',
        element_description: null,
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'productClickList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Product click',
        element_description: null,
        list_parent_page_type: 'plp',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'quickViewList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Quick View',
        element_description: null,
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
    'addtoWisList' : {
        non_interaction_hit: false,
        list_type: null,
        element_action: 'Click',
        element_ordinal: 'Primary',
        element_primary: 'Add to Wishlist',
        element_description: null,
        list_parent_page_type: 'Self',
        list_name: null,
        list_results_count: null,
        list_page_index: null,
        list_results_per_page: null,
        list_page_sort_by: null,
        list_filters_active: null,
    },
};
var NavigationEvents = {
    elementEventType: '',
    pageLoadList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];

        // set default attributes
        this.setDefaultAttributes(element, elemStatic);

        this.setNavigationEvent();
    },
    imageHeroList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];
        elemStatic['element_description'] = element.attr('alt');

        // set default attributes
        this.setDefaultAttributes(element, elemStatic);

        this.setNavigationEvent();
    },
    breadcrumbList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];
        elemStatic['element_description'] = $('a.breadcrumb-element').index(element);

        // set default attributes
        this.setDefaultAttributes(element, elemStatic);

        this.setNavigationEvent();    
    },
    filterByList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];
        elemStatic['element_description'] = element.parents('.js-refinement').find('.cl-search-aside-nav__refinement-header').text().trim() 
                                            + ': ' + 
                                            element.find('.ms-font--ellipsis').text().trim();
        // set default attributes
        this.setDefaultAttributes(element, elemStatic);

        this.setNavigationEvent();    
    },
    filterBySliderList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];
        let referenceElement = element.parents('.js-refinement');
        elemStatic['element_description'] = referenceElement.find('.cl-search-aside-nav__refinement-header').text().trim() 
                                            + ': ' + 
                                            $('.js-slider-range-min').val().trim()
                                            + ' - ' +
                                            referenceElement.find('.js-slider-range-max').val().trim();

        // set default attributes
        this.setDefaultAttributes(element, elemStatic);

        this.setNavigationEvent();    
    },
    openFilterList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];

        // set default attributes
        this.setDefaultAttributes(element, elemStatic);

        this.setNavigationEvent();    
    },
    closeFilterList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];

        // set default attributes
        this.setDefaultAttributes(element, elemStatic);

        this.setNavigationEvent();    
    },
    removeFilterList: function(element) {
        var elemDescription = '';
        var elemStatic = navStaticAttributes[this.elementEventType];
        if (element.length > 1) {
            element.each(function (i, item) {
                elemDescription = elemDescription ? elemDescription  + ' | ' : elemDescription;
                elemDescription += $(item).find('.breadcrumb-refinement-name').text().trim() + ': ' + $(item).find('.breadcrumb-refinement-value').text().trim().replace(/(\r\n|\n|\r)/gm,"");
            });
            elemStatic['element_description'] = elemDescription;
        } else {
            elemStatic['element_description'] = element.find('.breadcrumb-refinement-name').text().trim() + ': ' + element.find('.breadcrumb-refinement-value').text().trim().replace(/(\r\n|\n|\r)/gm,"");
        }

        // set default attributes
        this.setDefaultAttributes(element, elemStatic);

        this.setNavigationEvent();    
    },
    sortByList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];
        elemStatic['element_description'] = element.text().trim();
        
        // set default attributes
        this.setDefaultAttributes(element, elemStatic);

        this.setNavigationEvent();    
    },
    paginationList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];
        elemStatic['element_description'] = element.attr('title');

        // set default attributes
        this.setDefaultAttributes(element, elemStatic);

        this.setNavigationEvent();    
    },
    showPerPageList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];
        elemStatic['element_description'] = element.text().trim();

        // set default attributes
        this.setDefaultAttributes(element, elemStatic)

        this.setNavigationEvent();    
    },
    productClickList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];
        elemStatic['element_description'] = listHalpers.setNameIndexOfProductTile(element);

        if(element.parents('.js-einstein-recommender')){
            element = element.parents('.js-einstein-recommender');
        }

        // set default attributes
        this.setDefaultAttributes(element, elemStatic)

        this.setNavigationEvent();    
    },
    quickViewList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];
        elemStatic['element_description'] = listHalpers.setNameIndexOfProductTile(element);

        // set default attributes
        this.setDefaultAttributes(element, elemStatic)

        this.setNavigationEvent();      
    },
    addtoWisList: function(element) {
        var elemStatic = navStaticAttributes[this.elementEventType];
        elemStatic['element_description'] = listHalpers.setNameIndexOfProductTile(element);
        
        if(element.parents('.js-einstein-recommender')){
            element = element.parents('.js-einstein-recommender');
        }

        // set default attributes
        this.setDefaultAttributes(element, elemStatic)

        this.setNavigationEvent();    
    },
    setDefaultAttributes: function(element, elemStatic){
        listHalpers.parentPageType(element, elemStatic);
        listHalpers.listType(element, elemStatic);
        listHalpers.listName(element, elemStatic);
        listHalpers.listResultsCount(element, elemStatic);
        listHalpers.listPageIndex(element, elemStatic);
        listHalpers.listResultsPerPage(element, elemStatic);
        listHalpers.listPageSortBy(element, elemStatic);
        listHalpers.listFiltersActive(element, elemStatic);
    },
    setNavigationEvent: function () {
        this.pushNavigationEvent(...Object.values(navStaticAttributes[this.elementEventType]));
    },
    pushNavigationEvent: function(non_interaction_hit, list_type, element_action, element_ordinal = null,
        element_primary = null, element_description = null, list_parent_page_type = null,
        list_name = null, list_results_count = null, list_page_index = null,
        list_results_per_page = null, list_page_sort_by = null, list_filters_active = null
        ){
        
        var eventObject = {
            event: "gtm_event",
            non_interaction_hit: non_interaction_hit,
            event_name: 'list_interaction',
            element: 'list',
            list_type: list_type,
            element_action: element_action,
            element_ordinal: element_ordinal,
            element_primary: element_primary,
            element_description: element_description,
            list_parent_page_type: list_parent_page_type,
            list_name: list_name,
            list_results_count: list_results_count,
            list_page_index: list_page_index,
            list_results_per_page: list_results_per_page,
            list_page_sort_by: list_page_sort_by,
            list_filters_active: list_filters_active
        }
    
        // lowercase the values
        eventObject = this.lowercaseObjectValues(eventObject);

        if (typeof window.gtmLoaded !== 'undefined') {
            dataLayer.push(eventObject);
        } else {
            window.eventQueue.push(eventObject);
        }
    },
    lowercaseObjectValues: function(obj) {
        if (Array.isArray(obj)) {
            return obj.map(this.lowercaseObjectValues);
        } else if (typeof obj === 'object' && obj !== null) {
            var result = {};
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    result[key] = this.lowercaseObjectValues(obj[key]);
                }
            }
            return result;
        } else if (typeof obj === 'string') {
            return obj.toLowerCase();
        } else {
            return obj;
        }
    },
};
function init() {
    $('body').on('custom:listLoad', function(e, $elem) {
        let eventName = 'pageLoadList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });

    // observer to trigger load event for list or search
    if(window.pageContext.ns == 'search'){
        $('.pt_product-search-result').ready(function() {
            $('body').trigger('custom:listLoad', [$(this)]);
        });
    }
    // observer to trigger load event for carousel
    $(document).ready(function() {
        var observer = new MutationObserver(function(mutations, observer) {
            mutations.forEach(function(mutation) {
                if (mutation.type === 'childList') {
                    $(mutation.addedNodes).filter('.js-einstein-recommender').each(function() {
                        $('body').trigger('custom:listLoad', [$(this)]);
                    });
                }
            });
        });
        // Observe the body element for changes to its child nodes
        observer.observe(document.body, { childList: true, subtree: true });
    });

    $('body').on('click', '.clp-header-banner__image', function(e) {
        let $elem = $(e.target);
        let eventName = 'imageHeroList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });

    $('body').on('click', 'a.breadcrumb-element', function(e) {
        let $elem = $(e.target).parents('.breadcrumb-element');
        let eventName = 'breadcrumbList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });

    $('body').on('click', '.js-filter-category-link', function(e) {
        let $elem = $(e.target).parents('.js-filter-category-link');
        let eventName = 'filterByList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });

    $('body').on('click', '.refinement-element__clear', function(e) {
        let $elem = $(e.target).parents('.breadcrumb-refinement');
        let eventName = 'removeFilterList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });

    $('body').on('click', '.cl-clear-filters', function (e) {
        let $elem = $(e.target).parent().find('.breadcrumb-refinement');
        let eventName = 'removeFilterList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    })

    // Price slider events
    $('body').on('custom:priceSliderFilterList', function(e, $elem) {
        let eventName = 'filterBySliderList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = 'filterByList';
            NavigationEvents[eventName]($elem);
        }
    });
    $('body').on('input', '.js-slider-range-min, .js-slider-range-max', function(e) {
        $('body').trigger('custom:priceSliderFilterList', [$(this)]);
    });

    $('body').on('click', '.js-mobile-apply-filters', function(e) {
        let $elem = $(e.target);
        let eventName = 'openFilterList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
    $('body').on('click', '.js-refine-mobile-close', function(e) {
        let $elem = $(e.target);
        let eventName = 'closeFilterList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
    $('body').on('click', '.cl-sorting-bar__menu #grid-sort-header-menu li', function(e) {
        let $elem = $(e.target);
        let eventName = 'sortByList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
    $('body').on('click', '.cl-sorting-bar__menu #grid-paging-footer-menu li', function(e) {
        let $elem = $(e.target);
        let eventName = 'showPerPageList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
    $('body').on('click', 'a.cl-pagination__link', function(e) {
        let $elem = $(e.target);
        let eventName = 'paginationList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
    $('body').on('click', 'a.product-tile__thumbnail-link, a.product-tile__name', function(e) {
        let $elem = $(e.target);
        let eventName = 'productClickList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
    $('body').on('custom:quickViewList', function(e, $elem) {
        let eventName = 'quickViewList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
    $('body').on('click', 'a.product-tile__add-to-wish', function(e) {
        let $elem = $(this);
        let eventName = 'addtoWisList';
        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
};

module.exports = {
    init: init
}

