/** Property of OSF Global Services, Inc., (with its brand OSF Commerce). OSF remains the sole owner of all right,
 *   title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning,
 *   manufacture or operation therein.
 */
/**
* This file contains all WordPress Integration JavaScript functionality
*/

/* global document window */

"use strict";

var blog = {
    cache: {},

    // cache elements which will be selected multiple times
    initializeCache: function () {
        blog.cache = {
            blogArticles: $("#blog-articles"),
            blogCategoryId: $("#blog-category-id"),
            blogYear: $("#blog-year"),
            blogMonth: $("#blog-month")
        };
    },

    // initialize the events
    initializeEvents: function () {
        blog.loadArticles();
        blog.sortArticles();
    },

    articlesRequestConfigs: function () {
        var fdid = 0;
        var blogYear = null;
        var blogMonth = null;
        var lastPrintedPeriod = null;
        var isArchive = false;
        // add the category id to the ajax request if it is a category page
        if (blog.cache.blogCategoryId.length === 1) {
            fdid = blog.cache.blogCategoryId.val();
        }

        // add the year to the ajax request
        if (blog.cache.blogYear.length === 1) {
            blogYear = blog.cache.blogYear.val();
        }
        // add the month to the ajax request
        if (blog.cache.blogMonth.length === 1) {
            blogMonth = blog.cache.blogMonth.val();
        }

        // set the call url
        if ($("#blog-archive-title").length === 1) {
            lastPrintedPeriod = $(".blog-subtitle").last().html().trim();
            isArchive = true;
        }

        return {
            fdid: fdid,
            blogYear: blogYear,
            blogMonth: blogMonth,
            lastPrintedPeriod: lastPrintedPeriod,
            isArchive: isArchive
        };
    },
    // retrieves articles and adds them to the page
    loadArticles: function () {
        blog.cache.blogArticles.on("click", "#load-more", function (e) {
            e.preventDefault();
            $("body").prepend("<div class=\"spinner\" id=\"spinner\"></div>");
            $("#wrapper").css({ opacity: "0.4" });

            var {
                fdid, blogYear, blogMonth, lastPrintedPeriod, isArchive
            } = blog.articlesRequestConfigs();

            var callUrl = $(this).attr("href");

            var srule = $("#srule").val();
            var startFrom = blog.cache.blogArticles.find("> div > article").length;
            if (!isArchive) {
                startFrom++;
            }
            $.ajax({
                url: callUrl,
                type: "GET",
                data: {
                    start_from: startFrom,
                    fdid: fdid,
                    blog_year: blogYear,
                    blog_month: blogMonth,
                    last_printed_period: lastPrintedPeriod,
                    srule: srule,
                    archive: isArchive
                },
                success: function (response) {
                    $("#load-more").parent().remove();
                    blog.cache.blogArticles.append(response);
                    $("#spinner").remove();
                    $("#wrapper").css({ opacity: "1" });
                }
            });
        });
    },

    // handle sort option selection
    sortArticles: function () {
        blog.cache.blogArticles.on("change", ".js-article-sorting", function (e) {
            e.preventDefault();
            $("body").prepend("<div class=\"spinner\" id=\"spinner\"></div>");
            $("#wrapper").css({ opacity: "0.4" });

            var {
                fdid, blogYear, blogMonth, lastPrintedPeriod, isArchive
            } = blog.articlesRequestConfigs();

            var callUrl = $(this).data("link");

            var srule = $(this).val();
            $("#srule").val(srule);
            var sortSelectTag = $(this);

            $.ajax({
                url: callUrl,
                type: "GET",
                data: {
                    start_from: 0,
                    count: blog.cache.blogArticles.find("> article").length,
                    fdid: fdid,
                    blog_year: blogYear,
                    blog_month: blogMonth,
                    last_printed_period: lastPrintedPeriod,
                    srule: srule,
                    archive: isArchive
                },
                success: function (response) {
                    $("#load-more").parent().remove();
                    blog.cache.blogArticles.html(response);
                    blog.cache.blogArticles.prepend(sortSelectTag);
                    $("#spinner").remove();
                    $("#wrapper").css({ opacity: "1" });
                }
            });
        });
    },

    // handle active nav selection
    setupActiveNav: function () {
        var $blogMenu = $('.blog-menu'),
            $blogMenuItems = $blogMenu.find('.menu-item'),
            $blogCategoryName = $('.blog-category-name'),
            $blogCategoryNameText = $blogCategoryName.text().trim();

        if($blogCategoryName.text().length > 0) {
            $blogMenuItems.each(function() {
                if($(this).hasClass('selected')) {
                    $(this).removeClass('selected');
                }
            });
            $blogMenu.find('a[title="' + $blogCategoryNameText + '"]').parent().addClass('selected');
        }
    },

    // add drop caps to first letter of paragraph with content
    addDropCaps: function () {
        var containers = document.querySelectorAll('header + div');
        for (var i = 0; i < containers.length; i++) {
            var container = containers[i];
            var paragraphs = container.querySelectorAll('p');
            for (var j = 0; j < paragraphs.length; j++) {
                var paragraph = paragraphs[j];
                if (paragraph.textContent.trim().length > 0) {
                    paragraph.className += ' dropcap';
                    break;
                }
            }
        }
    },

    init: function () {
        blog.initializeCache();
        blog.initializeEvents();
        blog.setupActiveNav();
        blog.addDropCaps();
        
        $(".blog-content-asset").find("[style]").removeAttr("style");

    }
}

module.exports = blog;