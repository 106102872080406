

/**
 * @private
 * @function
 * @description Binds the events
 */
function initializeEvents() {
    $(".js-print-confirmation").on("click", function () {
        window.print();
    });

    if ($(".js-credit-card-block").length > 0) {
        var $ccExpiration = $(".js-credit-card-block .cc-exp");
        $ccExpiration.text($ccExpiration.text().replace(".", "/"));
    }

    // Account registration
    if ($("#summaryAccount").length) {
        $("#summaryAccount").on("click", function (e) {
            e.preventDefault();
            var link      = $(this).attr("data-link");
            var redirect  = $(this).attr("data-success");
            var firstName = $(this).attr("data-firstname");
            var lastName  = $(this).attr("data-lastname");
            var arr       = $(this).closest("form").serializeArray();
            var obj       = {};

            for (var i = 0; i < arr.length; i++) {
                switch (true) {
                    case arr[i].name.indexOf("email") >= 0:
                        obj.email = arr[i].value;
                        break;

                    case arr[i].name.indexOf("password_") >= 0:
                        obj.password = arr[i].value;
                        break;

                    case arr[i].name.indexOf("confirm") >= 0:
                        obj.confirm = arr[i].value;
                        break;

                    default:
                        break;
                }
            }

            if (firstName && lastName) {
                obj.firstName = firstName;
                obj.lastName  = lastName;
            }

            var options = {
                type : "POST",
                url  : link,
                data : {
                    form : JSON.stringify(obj)
                }
            };

            $.ajax(options).done(function (data) {
                if (data && data.success) {
                    $('body').trigger('success:createAccount');
                    window.location = redirect;
                }
            });
        });
    }
}

function optimizelyScript() {
    if (SitePreferences.OPTIMIZELY_ID != "" && SitePreferences.OPTIMIZELY_ID != null && SitePreferences.OPTIMIZELY_ID != undefined) {
        var valueInCents = parseInt($(".order-value").text().replace(/(,|\.|\s|\$)/g, ""), 10);
        window["optimizely"] = window["optimizely"] || []; //eslint-disable-line
        window["optimizely"].push({//eslint-disable-line
            "type": "event",
            "eventName": "trackRevenue",
            "tags": {"revenue": valueInCents}
        });
    }
}

function removeSessionCouponCode() {
    localStorage.removeItem('sessionCouponCode');
}

var orderconfirmation = {
    init: function () {
        initializeEvents();
        optimizelyScript();
        removeSessionCouponCode();
    }
};

module.exports = orderconfirmation;
