

/**
 * @function Update Zip Code format
 **/
module.exports = function () {
    $(document).off("change", ".postal").on("change", ".postal", function () {
        var rg = /^([ABCEGHJKLMNPRSTVXY]{1})(\d{1})([A-Z]{1}) *(\d{1})([A-Z]{1})(\d{1})$/,
            $self = $(this),
            postalCode = $self.val().toUpperCase(),
            validPostal = postalCode.replace(rg, "$1$2$3 $4$5$6");
        $self.val(validPostal);
    });

    // Triggers change if the Postal Code Field isn't empty
    var $postal = $(".postal");
    if ($postal.length) {
        if ($postal.val().length > 0) {
            $postal.trigger("change");
        }
    }
};
