

/**
 * @module quotes
 */

const dialogConfig = {
    autoOpen    : true,
    draggable   : false,
    modal       : true,
    resizable   : false,
    width       : "none",
    position    : {
        my : "center",
        at : "center",
        of : window
    },
    dialogClass : "cl-actions-dialog",
    classes     : {
        "ui-dialog"                : "cl-actions-dialog",
        "ui-dialog-title"          : "cl-actions-dialog__title",
        "ui-dialog-titlebar"       : "cl-actions-dialog__titlebar",
        "ui-dialog-titlebar-close" : "cl-actions-dialog__close",
        "ui-dialog-content"        : "cl-actions-dialog__content"
    },
    open        : function () {
        //will set dialog title
        var dialogTitle = $(this).closest(".ui-dialog")
            .find(".ui-dialog-name")
            .attr("data-name");

        $(this).closest(".ui-dialog")
            .find(".ui-dialog-title")
            .text(dialogTitle);

        // Add custom close button icon
        $(this).closest(".ui-dialog")
            .find(".ui-dialog-titlebar-close")
            .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

        $(this).find("button").blur();

        // Close Dialog When Clicked Outside
        $(document).find(".ui-widget-overlay").bind("click", function () {
            dialog.close(); //eslint-disable-line
        });
    },
    close: function () {
        $("#prepareCart").blur();
    }
};

/**
 * Quote Rename - Begin
 */
function initializeQuoteRename() {
    var $renameQuoteBlock = $("#renameQuoteBlock");
    var $quoteNameBlock   = $("#renameQuote");
    var $newQuoteName     = $("[name=newQuoteName]");
    var $quoteName        = $("#quoteName");

    $("#renameQuote").off("click").on("click", function () {
        $renameQuoteBlock.removeClass("hide");
        $quoteNameBlock.addClass("hide");
        $newQuoteName.val("");
    });

    $("#cancelRename").off("click").on("click", function () {
        $renameQuoteBlock.addClass("hide");
        $quoteNameBlock.removeClass("hide");
    });

    $("#saveRename").off("click").on("click", function () {
        if ($newQuoteName.val().length > 0) {
            $.ajax({
                type : "POST",
                url  : Urls.quoteRename,
                data : {
                    qid  : getURLParameter("qid"),
                    name : $newQuoteName.val(),
                }
            }).done(function (response) {
                if (response && response.success) {
                    $quoteName.text($newQuoteName.val());
                    $renameQuoteBlock.addClass("hide");
                    $quoteNameBlock.removeClass("hide");
                } else {
                    alert(Resources.SERVER_ERROR); //NOSONAR
                }
            }).fail(function () {
                alert(Resources.SERVER_ERROR); //NOSONAR
            });
        }
    });
}
/**
 * Quote Rename - End
 */

/**
 * Prepare Cart - Begin
 */
function initializePrepareCart() {
    $("#prepareCart").off("click").on("click", function () {
        $.ajax({
            type : "GET",
            url  : Urls.quotePrepareCart,
            data : {
                qid : getURLParameter("qid")
            }
        }).done(function (response) {
            if (response) {
                if (response.showMessage) {
                    var $container = createContainer("#loadQuoteContainer");
                    $container.load(response.url, function () {
                        $container.dialog(dialogConfig);

                        $("#btnCancelQuote").on("click", function () {
                            $container.dialog("close");
                            $("#prepareCart").blur();
                        });

                        $("#btnOkQuote").on("click", function () {
                            $.ajax({
                                type: "GET",
                                url  : Urls.quotePrepareCart,
                                data : {
                                    qid       : getURLParameter("qid"),
                                    fromModal : true
                                },
                                success: function (response) { //NOSONAR
                                    if (response && response.success) {
                                        window.location = Urls.cartShow;
                                    } else {
                                        alert(Resources.SERVER_ERROR); //NOSONAR
                                    }
                                }
                            });
                            $container.dialog("close");
                        });
                    });
                } else {
                    window.location = Urls.cartShow;
                }
            } else {
                alert(Resources.SERVER_ERROR); //NOSONAR
            }
        }).fail(function () {
            alert(Resources.SERVER_ERROR); //NOSONAR
        });
    });

    var placeOrder = getURLParameter("placeOrder");

    if (placeOrder == "true") {
        $("#prepareCart").trigger("click");
    }
}
/**
 * Prepare Cart - End
 */


/**
 * Prepare Remove Quote - Begin
 */
function initializeRemoveQuote() {
    $(".removeQuote").off("click").on("click", function (e) {
        e.preventDefault();
        var url        = $(this).attr("href");
        var $container = createContainer("#removeQuoteContainer");

        $container.load(url, function () {
            $container.dialog(dialogConfig);

            $("#btnCancelQuote").on("click", function () {
                $container.dialog("close");
            });

            $("#btnOkQuote").on("click", function () {
                var btnUrl = $(this).attr("data-link");
                $.ajax({
                    type: "GET",
                    url: btnUrl,
                    success: function (response) {
                        if (response && response.success) {
                            window.location = Urls.cartShow;
                        } else {
                            alert(Resources.SERVER_ERROR); //NOSONAR
                        }
                    }
                });
                $container.dialog("close");
            });
        });
    });
}
/**
 * Prepare Remove Quote - End
 */

/*
 * Helper functions
 */
// Create a Container
var createContainer = function (selector) {
    var $target, id;
    if (jQuery.type(selector) === "string") {
        if (selector.indexOf("#") === 0) {
            $target = $(selector);
        } else {
            $target = $("#" + selector);
        }
    } else if (selector instanceof jQuery) {
        $target = selector;
    }

    if ($target.length === 0) { //NOSONAR
        if ($target.selector && $target.selector.indexOf("#") === 0) {
            id = $target.selector.substr(1);
            $target = $("<div>").attr("id", id).appendTo("body");
        }
    }

    return $target;
};

// Get an URL Parameter
function getURLParameter(name) {
    return decodeURIComponent((new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.search) || [null, ""])[1].replace(/\+/g, "%20")) || null;
}

var quotes = {
    init: function () {
        initializeQuoteRename();
        initializePrepareCart();
        initializeRemoveQuote();
    }
};

module.exports = quotes;
