'use strict';

const dialog = require('../dialog');

function initializeEvents() {
    $('.js-return-methods-form').on('submit', function (event) {
        const $form = $(this);

        if (!$form.valid()) {
            event.preventDefault();
            return;
        }

        const selectedMethod = $form.find('.js-return-method-radio:checked').val();
        if (selectedMethod === 'returntostore') {
            event.preventDefault();

            // change some classes for this modal window
            dialog.settings.dialogClass = 'cl-modal-dialog cl-modal-dialog--returntostore';

            dialog.open({
                html: $('.js-store-method-modal-body').html(),
                options: {
                    buttons: [{
                        text: Resources.BUTTON_GO_BACK,
                        class: 'cl-button cl-button--return',
                        click: function () {
                            $(this).dialog('close');
                            $('button').blur(); // remove focus from all buttons
                        }
                    },
                    {
                        text: Resources.BUTTON_STORE_LOCATIONS,
                        class: 'cl-button cl-button--alternate',
                        click: function () {
                            $(this).dialog('close');
                            window.location = Urls.storeLocator;
                        }
                    }]
                }
            });

            // remove auto-focus function from jq ui
            $.ui.dialog.prototype._focusTabbable = function(){};

            $(document).one('click', '.ui-widget-overlay', function() {
                // closing the modal after clicking on the background
                $(".ui-dialog-buttonset .cl-button.cl-button--return").trigger('click');
            });

            $('button').blur(); // remove focus from all buttons
            $('.ui-dialog-buttonset .ui-button').removeClass('ui-button ui-corner-all ui-widget'); // remove default classes from buttons
        }
    });
}

function initSelecMenu() {
    const MENU_WIDTH_DIFFERENCE = 35;
    const select = $('.js-return-select');

    if (select.length === 0) {
        return;
    }

    select.selectmenu({
        classes: {
            'ui-selectmenu-button': 'cl-return-select__button',
            'ui-selectmenu-icon': 'cl-return-select__icon',
            'ui-selectmenu-menu': 'cl-return-select__menu',
            'ui-menu-item': 'cl-return-select__menu-item',
            'ui-selectmenu-text': 'cl-return-select__text'
        },

        create: function (event) {
            const select = $(event.target);

            select
                .attr('tabindex', -1)
                .addClass('ms-visually-hidden')
                .css('display', 'block');

            select
                .find('option[value=""]')
                .attr('disabled', 'disabled');
        },

        open: function (event) {
            if (!window.modulesSystem.jRes.isDesktopLarge) {
                return;
            }

            const select = $(event.target);
            const selectId = select.attr('id');
            const dropdownId = selectId && `${selectId}-menu`;
            const fieldWidth = select.closest('.field-wrapper').outerWidth();

            if (dropdownId && fieldWidth) {
                const correctMenuWidth = fieldWidth - MENU_WIDTH_DIFFERENCE;
                const dropdownMenu = $(`#${dropdownId}`);

                dropdownMenu.outerWidth(correctMenuWidth);
            }
        },

        select: function (event, ui) {
            const select = $(event.target);
            const formRow = select.closest('.form-row');
            const errorMessage = formRow && formRow.find('span.error');

            if (errorMessage.length && ui.item.value) {
                errorMessage.remove();
                select.removeClass('error');
            }
        }
    });
}

function onSelectNumberOfLabels() {
    const maxVal = 10;

    $(document).on('submit', 'form', function(e) {
        if (Number($('.js-return-labels').val()) > maxVal) {
            e.preventDefault();

            // change some classes for this modal window
            dialog.settings.dialogClass = 'cl-modal-dialog cl-modal-dialog--returntostore';

            dialog.open({
                html: $('.js-return-labels-modal-body').html(),
                options: {
                    buttons: [{
                        text: Resources.CLOSE,
                        class: 'cl-button cl-button--return  cl-button--margin-auto',
                        click: function () {
                            $(this).dialog('close');
                            $('button').blur(); // remove focus from all buttons
                        }
                    }]
                }
            });

            // remove auto-focus function from jq ui
            $.ui.dialog.prototype._focusTabbable = function(){};

            $(document).one('click', '.ui-widget-overlay', function() {
                // closing the modal after clicking on the background
                $(".ui-dialog-buttonset .cl-button.cl-button--return").trigger('click');
            });

            $('button').blur(); // remove focus from all buttons
            $('.ui-dialog-buttonset .ui-button').removeClass('ui-button ui-corner-all ui-widget'); // remove default classes from buttons

            return;
        }
    });
}

function init () {
    initializeEvents();
    initSelecMenu();
    onSelectNumberOfLabels();
}

module.exports = {
    init: init
};
