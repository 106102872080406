var dialog = require("../../dialog"),
    productStoreInventory = require("../../storeinventory/product"),
    tooltip = require("../../tooltip"),
    util = require("../../util"),
    addToCart = require("./addToCart"),
    availability = require("./availability"),
    image = require("./image"),
    productNav = require("./productNav"),
    productSet = require("./productSet"),
    recommendations = require("./recommendations"),
    tilesmatchheight = require("../../tilesmatchheight"),
    variant = require("./variant"),
    customReviews = require("./customReviews"),
    productSlider = require('./../../../../../int_acf_core/cartridge/js/modules/_product-recommendations-slider.js'),
    badges = require("../../badges"),
    accessories = require("../../pages/accessories"),
    product = require("../../pages/product"),
    populateHref = require("./populateHref"),
    clyde = require("../../pages/clyde");

var dialogConfig = function (customClass) {
    return {
        autoOpen    : false,
        draggable   : false,
        modal       : true,
        resizable   : false,
        width       : "none",
        position    : {
            my: "center",
            at: "center",
            of: window
        },
        dialogClass : "cl-actions-dialog cl-actions-dialog--large cl-showroom-stock-modal" + customClass ? customClass : "",
        classes: {
            "ui-dialog"                : "cl-actions-dialog cl-actions-dialog--large cl-showroom-stock-modal",
            "ui-dialog-title"          : "cl-actions-dialog__title",
            "ui-dialog-titlebar"       : "cl-actions-dialog__titlebar",
            "ui-dialog-titlebar-close" : "cl-actions-dialog__close",
            "ui-dialog-content"        : "cl-actions-dialog__content"
        },
        open: function () {
            //will set dialog title
            var dialogTitle = $(this).closest(".ui-dialog")
                .find(".ui-dialog-name")
                .attr("data-name");

            $(this).closest(".ui-dialog")
                .find(".ui-dialog-title")
                .text(dialogTitle);

            // Add custom close button icon
            $(this).closest(".ui-dialog")
                .find(".ui-dialog-titlebar-close")
                .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

            // Close Dialog When Clicked Outside
            $(document).find(".ui-widget-overlay").bind("click", function () {
                dialog.close();
            });
        },
        close: function () {
            $(this).detach();
        }
    };
};
/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    productNav();
    recommendations();
    tooltip.init();
    badges.init();
    customReviews();
    populateHref.init();
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $("#pdpMain");
    var $pdpQtyContainer = $(".js-pdp-quantity");
    var $pdpStickyQtyContainer = $(".js-pdp-sticky-quantity");

    addToCart();
    availability();
    variant.init();
    image();
    productSet();
    initializeMoreFromCollection();

    accessories.pdpItemQuantityHandler($pdpQtyContainer, false);
    accessories.pdpItemQuantityHandler($pdpStickyQtyContainer, false);

    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // $(document).on("click", ".js-buy-it-installed", function (event) {
    //     event.preventDefault();
    //     $(".add-to-cart").trigger("click", true);
    // });

    // $(document).on("click", ".js-close-bii-modal", function (event) {
    //     event.preventDefault();
    //     dialog.close();
    // });

    // $(document).on("click", ".product-bii-link", function (event) {
    //     event.preventDefault();
    //     dialog.open({
    //         url: Urls.assetBiiVideo,
    //         options : dialogConfig("modal-bii-caption")
    //     });
    // });

    $(document).on("click", ".js-check-showroom-stock", function (event) {
        event.preventDefault();
        dialog.open({
            url     : $(this).attr("href"),
            options : dialogConfig(),
            callback: function () {
                var $checkShowroomModal = $(".js-showroom-stock-modal");

                variant.init();

                $checkShowroomModal.off("change", ".variation-select").on("change", ".variation-select", function () {
                    var $this = $(this);
                    var $productContent = $("#js-product-content");
                    var fromPDP = false;

                    variant.updateContent($this.val(), $this.attr("name"), $productContent, fromPDP);
                });
            }
        });
    });

    $(document).find(".js-variation-select").selectmenu({
        classes: {
            "ui-selectmenu-button" : "cl-variation-select",
            "ui-selectmenu-icon"   : "cl-sorting-bar__icon",
            "ui-selectmenu-menu"   : "cl-variation-select__menu cl-variation-select__custom-menu cl-variation-select__menu--lg",
            "ui-menu-item"         : "cl-variation-select__menu-item",
            "ui-selectmenu-text"   : "cl-variation-select__text-icon"
        },
        create: function (event) {
            $(this).next().find(".ui-selectmenu-icon").html("<svg class=\"cl-svg-icon cl-sorting-bar__svg-icon\"><use xlink:href=\"#drop-down\"></use></svg>");

            if ($(event.target).hasClass("js-variation-finish")) {
                var activeData = $(event.target).find(":selected").attr("data-icon");

                if (activeData !== undefined) {
                    $("<img>", {
                        "src"   : activeData,
                        "alt"   : Resources.VENDOR_SKU,
                        "class" : "cl-sorting-bar__thumb"
                    }).prependTo($(this).next().find(".ui-selectmenu-text"));
                }
            }
        },
        change: function (event, ui) {
            $(this).trigger("change", ui);
        }
    });

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on("click", "[data-action=\"wishlist\"], [data-action=\"gift-registry\"]", function () {
        populateHref.populateAddToWishlistHrefCurrentElement($(this));
        var data = util.getQueryStringParams($(".pdpForm").serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute("href", url);
    });

    // product options
    $pdpMain.on("change", ".product-options select", function () {
        var salesPrice = $pdpMain.find(".product-add-to-cart .price-sales");
        var selectedItem = $(this).children().filter(":selected").first();
        salesPrice.text(selectedItem.data("combined"));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on("click", ".thumbnail-link, .unselectable a", function (e) {
        e.preventDefault();
    });

    $(".size-chart-link a").on("click", function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr("href")
        });
    });
}

function initializeMoreFromCollection() {
    var $collectioWrapper = $(".js-from-collection-wrapper:not(.loaded)");
    var pid = $collectioWrapper.data("pid");

    $collectioWrapper.addClass("loaded");

    if (!pid) {
        return;
    }

    $.ajax({
        type: "GET",
        data: {
            pid: pid
        },
        dataType: "html",
        url: Urls.renderCollectionItems,
        success: function (response) {
            if ($(response).filter(".product-recommendations-slider").find(".product-tile").length) {
                $collectioWrapper.html(response);
                productSlider.init();
                badges.init();
                tilesmatchheight.elMatchHeight();
                $(".js-from-collection, .js-product-recommendations").removeClass("ms-hide");
            }
        }
    });
}
//eslint-disable-next-line
var product = { //NOSONAR
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
        clyde.initPDP();
    }
};

module.exports = product;
