var addressSuggestions = {
    settings: {
        dialogSettings: {
            autoOpen    : false,
            draggable   : false,
            modal       : true,
            resizable   : false,
            width       : "none",
            position    : {
                my: "center",
                at: "center",
                of: window
            },
            dialogClass : "cl-actions-dialog cl-actions-dialog--large vertex-address-modal",
            classes: {
                "ui-dialog"                : "cl-actions-dialog cl-actions-dialog--large",
                "ui-dialog-title"          : "cl-actions-dialog__title",
                "ui-dialog-titlebar"       : "cl-actions-dialog__titlebar",
                "ui-dialog-titlebar-close" : "cl-actions-dialog__close",
                "ui-dialog-content"        : "cl-actions-dialog__content"
            },
            open: function () {
                var dialogTitle = Resources.ADDRESS_VALIDATION_MAIN_TITLE;
        
                $(this).closest(".ui-dialog")
                    .find(".ui-dialog-title")
                    .text(dialogTitle);
                
                // Add custom close button icon
                $(this).closest(".ui-dialog")
                    .find(".ui-dialog-titlebar-close")
                    .html("<svg class=\"cl-svg-icon cl-svg-icon--close-small\"><use xlink:href=\"#close\" /></svg>");

                // Close Dialog When Clicked Outside
                $(document).find(".ui-widget-overlay").bind("click", function () {
                    dialog.close();
                });

                // Scrolls to the top only on mobile devices
                if (window.innerWidth <= 768) {
                    $("html, body").animate({ scrollTop: 0 }, "fast");
                }

                addressSuggestions.methods.selectAddress();
            }
        }
    },
    cache: {},
    methods: {
        countryAddressValidateEnabled: function(selectedCountryCode){
            var disabledCountriesList = window.SitePreferences.VERTEX_ADDRESS_VALIDATE_DISABLED_COUNTRIES.split(','),
                isCountryValidationEnabled = true;

            if(selectedCountryCode && disabledCountriesList.length){
                isCountryValidationEnabled = disabledCountriesList.filter(function(countryCode){
                    return countryCode.toLowerCase() === selectedCountryCode.toLowerCase();
                }).length == 0;
            }

            return isCountryValidationEnabled;
        },
        getShippingFormData: function($shippingForm, addressType){
            var shippingFormData;
            if($shippingForm){
                addressType = addressType || 'checkout';
                if(addressType == 'checkout'){
                    shippingFormData = {
                        shipping_firstName       : $shippingForm.find("[name$=shippingAddress_addressFields_firstName]").val(),
                        shipping_lastName        : $shippingForm.find("[name$=shippingAddress_addressFields_lastName]").val(),
                        shipping_company         : $shippingForm.find("[name$=shippingAddress_addressFields_company]").val(),
                        shipping_address1        : $shippingForm.find("[name$=shippingAddress_addressFields_address1]").val(),
                        shipping_address2        : $shippingForm.find("[name$=shippingAddress_addressFields_address2]").val(),
                        shipping_shippingComment : $shippingForm.find("[name$=shippingAddress_addressFields_shippingComment]").val(),
                        shipping_city            : $shippingForm.find("[name$=shippingAddress_addressFields_city]").val(),
                        shipping_state           : $shippingForm.find("[name$=shippingAddress_addressFields_states_state]").val(),
                        shipping_country         : $shippingForm.find("[name$=shippingAddress_addressFields_country]").val(),
                        shipping_postal          : $shippingForm.find("[name$=shippingAddress_addressFields_postal]").val(),
                        shipping_email           : $shippingForm.find("[name$=billingAddress_email_emailAddress]").val(),
                        shipping_phone           : $shippingForm.find("[name$=shippingAddress_addressFields_phone]").val()
                    };
                }else{
                    shippingFormData = {
                        shipping_firstName       : $shippingForm.find("[name$=profile_address_firstname]").val(),
                        shipping_lastName        : $shippingForm.find("[name$=profile_address_lastname]").val(),
                        shipping_company         : $shippingForm.find("[name$=profile_address_company]").val(),
                        shipping_address1        : $shippingForm.find("[name$=profile_address_address1]").val(),
                        shipping_address2        : $shippingForm.find("[name$=profile_address_address2]").val(),
                        shipping_city            : $shippingForm.find("[name$=profile_address_city]").val(),
                        shipping_state           : $shippingForm.find("[name$=profile_address_states_state]").val(),
                        shipping_country         : $shippingForm.find("[name$=profile_address_country]").val(),
                        shipping_postal          : $shippingForm.find("[name$=profile_address_postal]").val(),
                        shipping_phone           : $shippingForm.find("[name$=profile_address_phone]").val(),
                        shipping_phone_alternate : $shippingForm.find("[name$=profile_address_phonealternate]").val()
                    };
                }
                
            }
            return shippingFormData;
        },
        setShippingFormData(selectedAddressData, $shippingForm, addressType){
            var address1 = selectedAddressData.address1,
                address2 = selectedAddressData.address2 ? selectedAddressData.address2 : '',
                city = selectedAddressData.city,
                countryCode = selectedAddressData.countryCode.toUpperCase(),
                postalCode = selectedAddressData.postalCode,
                stateCode = selectedAddressData.stateCode ? selectedAddressData.stateCode.toUpperCase() : '';

            var address1Selector, address2Selector, citySelector, stateSelector, postalSelector, countrySelector;
            addressType = addressType || 'checkout';
            if(addressType == 'checkout'){
                address1Selector = "[name$=shippingAddress_addressFields_address1]";
                address2Selector = "[name$=shippingAddress_addressFields_address2]";
                citySelector = "[name$=shippingAddress_addressFields_city]";
                stateSelector = "[name$=shippingAddress_addressFields_states_state]";
                postalSelector = "[name$=shippingAddress_addressFields_postal]";
                countrySelector = "[name$=shippingAddress_addressFields_country]";
            }else{
                address1Selector = "[name$=profile_address_address1]";
                address2Selector = "[name$=profile_address_address2]";
                citySelector = "[name$=profile_address_city]";
                stateSelector = "[name$=profile_address_states_state]";
                postalSelector = "[name$=profile_address_postal]";
                countrySelector = "[name$=profile_address_country]";
            }

            $shippingForm.find(address1Selector).val(address1).change();
            $shippingForm.find(address2Selector).val(address2).change();
            $shippingForm.find(citySelector).val(city).change();
            $shippingForm.find(countrySelector).val(countryCode).change();
            $shippingForm.find(postalSelector).val(postalCode).change();
            $shippingForm.find(stateSelector).val(stateCode).change();
        },
        getAddressSuggestions: function($shippingForm, addressType){
            var shippingAddress = addressSuggestions.methods.getShippingFormData($shippingForm, addressType);
            var addressSuggestDef;

            if(shippingAddress){
                validatedAddress = shippingAddress;
                addressSuggestDef = jQuery.Deferred();
                $.ajax({
                    url: Urls.getSuggest,
                    method: 'post',
                    data: {
                        address: JSON.stringify(shippingAddress)
                    }
                }).done(function(data){
                    if(data && data.success && data.text && data.text.length){
                        addressSuggestDef.resolve(data.text);
                    }else{
                        addressSuggestDef.reject();
                    }
                });
                return addressSuggestDef.promise();
            } else {
                return addressSuggestDef;
            }
        },
        deserializeForm: function(f, data, clearMismatchFormElements) {
            var map = {},
                find = function (selector) { return f.is("form") ? f.find(selector) : f.filter(selector); };
            //Get map of values
            jQuery.each(data.split("&"), function () {
                var nv = this.split("="),
                    n = decodeURIComponent(nv[0]),
                    v = nv.length > 1 ? decodeURIComponent(nv[1]) : null;
                if (!(n in map)) {
                    map[n] = [];
                }
                map[n].push(v);
            });
            //Set values for all form elements in the data
            jQuery.each(map, function (n, v) {
                v = v + '';
                if(v.length){
                    v = v.replace(/\+/g, ' ');
                    find("[name='" + n + "']").val(v).change();
                }
            });
            if(clearMismatchFormElements){
                //Clear all form elements not in form data
                find("input:text,select,textarea").each(function () {
                    if (!(jQuery(this).attr("name") in map)) {
                        jQuery(this).val("");
                    }
                });
                find("input:checkbox:checked,input:radio:checked").each(function () {
                    if (!(jQuery(this).attr("name") in map)) {
                        this.checked = false;
                    }
                });
            }
            
            return this;
        },
        selectAddress: function() {
            var addressSuggestion = $('#vertex-suggestions .inner-wrapper'),
                suggestionInput = $(addressSuggestion).find('input');

            $(addressSuggestion).on('click', function() {
                if (!$(this).hasClass('checked')) {
                    $(addressSuggestion).removeClass('checked');
                    $(suggestionInput).prop('checked', false);
                    $(this).addClass('checked');
                    $(this).find('input').prop('checked', true);
                }
            });
        }
    }
};

module.exports = addressSuggestions;