module.exports = function () {
    var $main = $("#pdpMain");

    /**
     * @private
     * @function
     * @description Generate 5 review stars
     * @returns generated markup
     */
    function generateReviewStars() {
        var starsMarkup = "";

        for (var i = 0; i < 5; i++) {
            starsMarkup += `
                <svg class="product-tile__star-icon">
                    <use xlink:href="#star-icon"></use>
                </svg>
                `;
        }

        return starsMarkup;
    }

    /**
     * @private
     * @function
     * @description Appens labels to TornTo user and location
     */
    function appendLabels(item, label) {
        if ($(item).find(".pdp-review-title").length || $(item).text().trim().length < 1) {
            return;
        } else {
            $(item).prepend("<span class=\"pdp-review-title\">" + label + "</span>");
        }
    }

    /**
     * @private
     * @function
     * @description Check if TurnTo object is available in global scope, if no run recursive function
     * @param {Boolean} instant - run initialisation immediately
     * @returns true if TurnTo was initialized
     */
    function pdpTorntoLoadInterval(instant = false) {
        var initialized = false;

        if (instant) {
            pdpCustomTornToEvents();
            initialized = true;
        } else {
            setTimeout(function () {
                if (typeof window.TurnTo == "undefined") {
                    // Call recursive function
                    pdpTorntoLoadInterval();
                } else {
                    pdpCustomTornToEvents();
                    initialized = true;
                }
            }, 1000);
        }

        return initialized;
    }

    /**
     * @private
     * @function
     * @description Open jQuery UI sorting selectmenu for mobile devices
     * @param {Object} e event
     */
    function openMobileSortMenu(e) {
        e.preventDefault();
        $(".js-sort-mobile-header").show();
        $main.find("#TTreviewSort").selectmenu("open");
    }

    /**
     * @private
     * @function
     * @description Close jQuery UI sorting selectmenu for mobile devices
     * @param {Object} e event
     */
    function closeMobileSortMenu(e) {
        e.preventDefault();
        $(".js-sort-mobile-header").hide();
        $main.find("#TTreviewSort").selectmenu("close");
    }

    /**
     * @private
     * @function
     * @description Create custom event listners for TurnTo elements
     */
    function pdpCustomTornToEvents() {
        var $tornToSorting      = $(window.TurnTo.jQcontext3).find("#TTreviewSort"),
            $tornToReviewsStars = $(window.TurnTo.jQcontext3).find(".TTratingBox"),
            $torntoReviewUser   = $(window.TurnTo.jQcontext3).find("[itemprop=\"author\"]"),
            $tornToReviewFrom   = $(window.TurnTo.jQcontext3).find(".TTrevCol3 div:last-child");

        var starsMarkup = `
            <span class="product-tile__reviews-stars--back">
                ${generateReviewStars()}
            </span>
            <span class="cl-reviews product-tile__reviews-stars--front">
                ${generateReviewStars()}
            </span>
            `;

        $tornToReviewsStars.each(function (i, item) {
            $(item).addClass("pdp-star-review").html(starsMarkup);
        });

        $torntoReviewUser.each(function (i, item) {
            appendLabels(item, Resources.USER);
        });

        $tornToReviewFrom.each(function (i, item) {
            appendLabels(item, Resources.FROM);
        });

        $tornToSorting.selectmenu({
            classes: {
                "ui-selectmenu-button": "cl-sorting-bar__button",
                "ui-selectmenu-icon": "cl-sorting-bar__icon",
                "ui-selectmenu-menu": "cl-sorting-bar__menu",
                "ui-menu-item": "cl-sorting-bar__menu-item"
            },
            create: function () {
                $(this).next().find(".ui-selectmenu-icon").html("<svg class=\"cl-svg-icon cl-sorting-bar__svg-icon\"><use xlink:href=\"#drop-down\"></use></svg>");
            },
            change: function (event, ui) {
                $(this).trigger("change", ui);
            },
            close: function () {
                $(".js-sort-mobile-header").hide();
            }
        });
    }

    window.modulesSystem.jRes.addFunc([
        {
            breakpoint: ["phone", "phoneLandscape", "tablet"],
            enter: function () {

                $(document).on("click", ".js-show-reviews", function (e) {
                    e.preventDefault();
                    pdpTorntoLoadInterval(true);
                });

                // Toggle functionality for mobile sorting popup
                $main.on("click", ".js-mobile-sort-by", openMobileSortMenu);
                $main.on("click", ".js-sort-mobile-close", closeMobileSortMenu);
            }
        },
        {
            breakpoint: ["desktop", "desktopLarge"],
            enter: function () {
                var turnToInit = pdpTorntoLoadInterval();

                // Run TurnTo initialisation in case if there was some issue during loading the page.
                $(window).on("scroll", function () {
                    if (!turnToInit) {
                        turnToInit = pdpTorntoLoadInterval(true);
                    }
                });
            }
        }
    ]);
};
