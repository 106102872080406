

var ajax    = require("../../ajax"),
    tooltip = require("../../tooltip"),
    util    = require("../../util");

module.exports = function () {
    var $addToCart = $("#add-to-cart, #add-to-cart-sticky"),
        $addAllToCart = $("#add-all-to-cart"),
        $productSetList = $("#product-set-list");

    var updateAddToCartButtons = function () {
        if ($productSetList.find(".add-to-cart[disabled]").length > 0) {
            $addAllToCart.attr("disabled", "disabled");
            // product set does not have an add-to-cart button, but product bundle does
            $addToCart.attr("disabled", "disabled");
        } else {
            $addAllToCart.removeAttr("disabled");
            $addToCart.removeAttr("disabled");
        }
    };

    if ($productSetList.length > 0) {
        updateAddToCartButtons();
    }
    // click on swatch for product set
    $productSetList.on("click", ".product-set-item .swatchanchor", function (e) {
        e.preventDefault();
        if ($(this).parents("li").hasClass("unselectable")) { return; }
        var url = Urls.getSetItem + this.search;
        var $container = $(this).closest(".product-set-item");
        var qty = $container.find("form input[name=\"Quantity\"]").first().val();

        ajax.load({
            url: util.appendParamToURL(url, "Quantity", isNaN(qty) ? "1" : qty),
            target: $container,
            callback: function () {
                updateAddToCartButtons();
                tooltip.init();
            }
        });
    });

    $("#add-to-cart-sticky").on("click", function (event) {
        event.preventDefault();
        $("#add-to-cart").trigger("click");
    });

    $(document).on("input, change", ".update-quantity-text", function () {
        var $this = $(this);

        if ($this.closest('.atc-tile-qty').length === 0) {
            $(".update-quantity-text").not($this).val($this.val());
            
            if($this.val() < $this.data('suggested') || isNaN($this.val())) {
                $(".update-quantity-text").not($this).val($this.data('suggested'));
                $(".update-quantity-text").val($this.data('suggested'));
            }
        } else {
            if($this.val() < $this.data('suggested') || isNaN($this.val())) {
                $this.val($this.data('suggested'));
                $this.closest('.product-atc-tile').find('.min-order').addClass('alert');

                window.setTimeout(function(){
                    $this.closest('.product-atc-tile').find('.min-order').removeClass('alert');
                }, 3000);
            }
        }

        

        // Update dynamically cases label
        if ($(".js-cases-label").length) {
            if ($this.val() > 1) {
                $(".js-cases-label").text(Resources.PDP_CASES_LABEL);
            } else {
                $(".js-cases-label").text(Resources.PDP_CASE_LABEL);
            }
        }
    });

    /**
     * @function
     * @description functionality for sticky add to cart section
     */
    // function stickyAddToCart() {
    //     var $addToCartPdp = $(".pt_product-details #add-to-cart"),
    //         addToCartTop = $addToCartPdp.offset().top;

    //     if (window.pageYOffset > addToCartTop) {
    //         $(".sticy-add-to-card").removeClass("ms-hide");
    //     } else if (util.elementInViewport($addToCartPdp.get(0))) {
    //         $(".sticy-add-to-card").addClass("ms-hide");
    //     }
    // }

    function stickyAddToCart() {
        
        var $addToCartPdp = $(".pt_product-details #add-to-cart"),
            addToCartTop = $addToCartPdp.offset().top;
            var chatBtn = $(".helpButton");
    
        if (window.pageYOffset > addToCartTop) {
            $(".sticy-add-to-card").removeClass("ms-hide");
            chatBtn.addClass("ms-hide");
        } else if (util.elementInViewport($addToCartPdp.get(0))) {
            $(".sticy-add-to-card").addClass("ms-hide");
            chatBtn.removeClass("ms-hide");
        } else {
            $(".sticy-add-to-card").addClass("ms-hide");
            chatBtn.removeClass("ms-hide");
        }
    }
    

    if ($(".pt_product-details #add-to-cart").length) {
        stickyAddToCart();

        $(window).on("scroll", stickyAddToCart);
    }
};
