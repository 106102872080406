"use strict";

/**
 * @function
 * @description Ellipses text, full content is shown on click button
 */

function ellipsesText() {
    if ($(".js-title-text-cta").length > 0) {
        let $ellipsisModule = $(".js-title-text-cta"),
            $ellipsisFullHiddenContent = $(".js-title-text-cta-hidden-content"),
            $ellipsisContent = $ellipsisModule.find(".js-title-text-cta-ellipsed-content"),
            $ellipsisLink = $ellipsisModule.find(".js-title-text-cta-expand"),
            ellipsisChars = 500;

        if ($ellipsisContent.length && $ellipsisLink.length) {
            $ellipsisContent.each(function() {
                let content = $(this).html();
                if (content.length > ellipsisChars) {
                    let showContent = content.substr(0, ellipsisChars) + "...";
                    $(this).html(showContent);
                    $ellipsisFullHiddenContent.html(content).hide();
                } else {
                    $ellipsisLink.off("click").hide();
                }
            });

            $ellipsisLink.on("click", function(e) {
                e.preventDefault();
                $(this).hide();
                $ellipsisContent.remove();
                $ellipsisFullHiddenContent.show();
            });
        }
    }
}

exports.init = ellipsesText;