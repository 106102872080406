var navStaticAttributes = {
    'topNavigation' : {
        'element_ordinal': 'Primary',
    },
    'midNavigation' : {
        'element_ordinal': 'Secondary',
    },
    'bottomNavigation' : {
        'element_ordinal': 'Tertiary',
    },
    'hamNavigation' : {
        'element_ordinal': 'Primary',
        'element_primary': 'Hamburger Menu',
    },
};
var NavigationEvents = {
    elementEventType: '',
    topNavigation: function(element) {
        let elemStatic = navStaticAttributes[this.elementEventType];

        elemStatic['element_primary'] = element.data('gtm-primary');

        // push event to datalayer
        this.setNavigationEvent();
    },
    midNavigation: function(element) {
        let elemStatic = navStaticAttributes[this.elementEventType];

        elemStatic['element_primary'] = element.closest("[data-gtm-event='topNavigation']").data('gtm-primary');
        elemStatic['element_secondary'] = element.data('gtm-secondary');

        // push event to datalayer
        this.setNavigationEvent();
    },
    bottomNavigation: function(element) {
        let elemStatic = navStaticAttributes[this.elementEventType];

        elemStatic['element_primary'] = element.closest("[data-gtm-event='topNavigation']").data('gtm-primary');
        elemStatic['element_secondary'] = element.data('gtm-secondary');
        elemStatic['element_tertiary'] = element.data('gtm-tertiary');

        // push event to datalayer
        this.setNavigationEvent();
    },
    hamNavigation: function(element) {
        let elemStatic = navStaticAttributes[this.elementEventType];
        if(element.hasClass("active"))
            elemStatic['element_secondary'] = 'open';
        else 
            elemStatic['element_secondary'] = 'close';

        // push event to datalayer
        this.setNavigationEvent();
    },
    setNavigationEvent: function () {
        this.pushNavigationEvent(...Object.values(navStaticAttributes[this.elementEventType]));
    },
    pushNavigationEvent: function(elementOrdinal = null, elementPrimary = null, elementSecondary = null, elementTrtiary = null) {
        var eventObject = {
            event: "gtm_event",
            non_interaction_event: false,
            event_name: 'element_interaction',
            element: 'navigation',
            element_action: 'click',
            element_ordinal: elementOrdinal,
            element_primary: elementPrimary,
            element_secondary: elementSecondary,
            element_tertiary: elementTrtiary,
            element_description: null
        }
    
        // lowercase the values
        eventObject = this.lowercaseObjectValues(eventObject);

        dataLayer.push(eventObject);
    },
    lowercaseObjectValues: function(obj) {
        if (Array.isArray(obj)) {
            return obj.map(this.lowercaseObjectValues);
        } else if (typeof obj === 'object' && obj !== null) {
            var result = {};
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    result[key] = this.lowercaseObjectValues(obj[key]);
                }
            }
            return result;
        } else if (typeof obj === 'string') {
            return obj.toLowerCase();
        } else {
            return obj;
        }
    }
};
function init() {
    $('body').on('click touchstart', '.cl-header-content-wrapper', function(e) {
        let $elem = $(e.target);
        // as hamburger has already event written and things are bit complicated, so doing this
        if($elem.is('.cl-navigation__open') || $elem.is('.cl-navigation__close')) {
            $elem = $elem.parents('.js-menu-toggle');
        }
        // perfrom normal operations on all elements
        if($elem.data('gtm-event') && NavigationEvents[$elem.data('gtm-event')]){
            NavigationEvents.elementEventType = $elem.data('gtm-event');
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
};

module.exports = {
    init: init
}

