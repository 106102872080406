

var util              = require("./util"),
    bonusProductsView = require("./bonus-products-view"),
    protectionPlan = require("./protectionPlan");

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var minicart = {
    init: function () {
        let self = this;
        this.$el = $("#mini-cart");
        this.$content = this.$el.find(".js-mini-cart-content");
        this.$close = this.$el.find(".js-minicart-close");
        this.$productContainer = this.$el.find(".js-minicart-products");

        $(document).off('refreshMinicart');
        $(document).on('refreshMinicart', function(action, data){
            minicart.refresh(data.isSlide);
        });

        protectionPlan.minicart.init();

        window.modulesSystem.jRes.addFunc([
            {
                breakpoint: ["phone", "phoneLandscape", "tablet"],
                enter: function () {
                    self.$el.find(".js-mini-cart-total").off("mouseenter");
                    self.$content.hide();
                }
            }, {
                breakpoint: ["desktop", "desktopLarge"],
                enter: function () {
                    // events
                    self.$el.find(".js-mini-cart-total").on("mouseenter", function () {
                        if (self.$content.not(":visible") && self.$content.hasClass("has-products")) {
                            timer.clear();
                            timer.start(30, self.slide.bind(self));
                        }
                    }.bind(self));

                    if (("ontouchstart" in window) || navigator.msMaxTouchPoints > 0) {
                        self.$el.find(".js-mini-cart-total").on("touchstart", function () {
                            if (self.$content.not(":visible") && self.$content.hasClass("has-products")) {
                                timer.clear();
                                timer.start(30, self.slide.bind(self));
                            }
                        }.bind(self));
                    }

                    self.$close.on("click", function (e) {
                        e.preventDefault();
                        timer.clear();
                        timer.start(30, self.close.bind(self));
                    }.bind(self));

                    self.$el.find(".js-mini-cart-total").on("mouseleave", function (e) {
                        e.preventDefault();
                        timer.clear();
                        timer.start(30, self.close.bind(self));
                    }.bind(self));

                    $(document).on("click", function (e) {
                        // prevent minicart closing when click within minicart area, except buttons
                        if (
                            $(e.target).hasClass('js-stop-event-action') ||
                            (!$(e.target).hasClass('cl-button') &&
                                !$(e.target).closest('.cl-button').length &&
                                $(e.target).closest('.js-mini-cart-content').length)
                        ) {
                            e.stopPropagation(); // Stop event propagation to prevent closing the minicart
                            return;
                        }
                    
                        // Allow default behavior for anchor tags (product URLs)
                        if ($(e.target).is('a.mini-cart-link')) {
                            return;
                        }
                    
                        if (!this.$content.is(e.target)) {
                            timer.clear();
                            timer.start(30, self.close.bind(self));
                        }
                    }.bind(self));
                }
            }
        ]);

    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        let self = this;
        this.update(html);

        // Slide updated minicart
        util.scrollBrowser(0);
        self.slide();
    },
    /**
     * @function
     * @description Updates the mini cart with the given content
     * @param {String} A HTML string with the content which will be shown
     */
    update: function (html, isSlide) {
        this.$el.html(html);
        bonusProductsView.loadBonusOption();
        protectionPlan.loadProtectionPlanProducts();
        if(isSlide){
            minicart.slide();
        }
        this.init();
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart and if product count > 3 set fixed height to the parent container
     */
    slide: function () {
        let self = this,
            productContainerHeight = 0;

        // If the current page is Cart page, then don't slide minicart
        if (window.pageContext.ns == "cart") {
            return false;
        }

        timer.clear();
        // show the item
        this.$content.addClass("active", function () {
            // set fixed product container height to be visible only first 3 products
            if (self.$productContainer.length > 0) {
                self.$productContainer.find(".mini-cart-product:lt(3)").each(function () {
                    productContainerHeight += $(this).outerHeight();
                });

                self.$productContainer.css({
                    "height": productContainerHeight + "px",
                    "max-height": productContainerHeight + "px",
                });
            }
        });

        this.$el.find(".js-mini-cart-total").addClass("active");
        this.$el.find('.cl-minicart__content').addClass("active");

        // Add blurred overlay to the body
        $("body").addClass("cl-minicart-overlay");
    },
    /**
     * @function
     * @description Displays the current items in the cart in the minicart panel.
     */
    refresh: function (isSlide) {
        $.get(util.ajaxUrl(Urls.minicartRefresh), null, function (response) {
            // handle error in the response
            if (response.error) {
                throw new Error(response.error);
            } else {
                minicart.update(response, isSlide);
            }
        });
    },
    /**
     * @function
     * @description Closes the mini cart with given delay and after delayToClose
     * @param {Number} delay The delay in milliseconds
     * @param {Number} delayToClose The delay to close in milliseconds
     */
    close: function (delay, delayToClose) {
        let self = this;
        delayToClose = delayToClose || 0;
        timer.clear();
        timer.start(delayToClose, function () {
            self.$content.removeClass("active", delay);
            self.$el.find(".js-mini-cart-total").removeClass("active");

            // Remove blurred overlay from the body
            $("body").removeClass("cl-minicart-overlay");

            if (self.$el.find(".js-mini-cart-total").hasClass("active")) {
                $("body").removeClass("cl-modal-overlay");
            }

            if ($(".js-menu-main-category").length) {
                $(".js-menu-main-category").removeClass("active");
            }
            
            // in case of minicart in digital catalog iframe
            $(parent.document).find('.cl-minicart__content').removeClass('active'); 
            $(parent.document).find("body").removeClass("cl-minicart-overlay");
        });
    }
};

module.exports = minicart;
