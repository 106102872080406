'use strict';

function createEvent(elementAction, elementOrdinal, elementPrimary, elementSecondary, elementDescription) {
    dataLayer.push({
        event: "gtm_event",
		non_interaction_event: false,
        event_name: 'element_interaction',
        element: 'home',
        element_action: elementAction.toLowerCase() || null,
        element_ordinal: elementOrdinal || null,
        element_primary: elementPrimary.toLowerCase() || null,
        element_secondary: typeof elementSecondary === 'number' ? elementSecondary : elementSecondary.toLowerCase() || null,
        element_description: elementDescription.toLowerCase() || null
    });
}

module.exports = function() {
    $('.up-3-cta__tile').each(function (index, item) {
        $(item).attr('data-position', index + 1);
    });

    $('body').on('click', '.hero-banner__button', function () {
        var imageDescription = $(this).closest('.hero-banner').find('img').attr('alt').toLowerCase();
        if (imageDescription) {
            createEvent('click', 'primary', 'hero image', '', imageDescription);
        }
    });

    $('body').on('click', '.cl-homepage .hero-banner img', function (e) {
        var imageDescription = $(e.target).attr('alt')
        createEvent('click', 'primary', 'hero image', '', imageDescription);
    }).on('click', '.category-recommendations .category-recommendations-category__wrapper', function (e) {
        var categoryName = $(this).data('gtm-category-name');
        createEvent('click', 'primary', 'shop by category', '', categoryName);
    }).on('click', '.up-3-cta__tile', function (e) {
        var position = $(this).data('position');
        var description = $(this).find('img').attr('alt');
        createEvent('click', 'secondary', 'content block', position || '', description || '');
    });
    
};

