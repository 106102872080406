

/**
 * @description Gets data-order attribute and load the order detail page with attribute in line.
 */
function getOrderDetails() {
    $(".order-details-button").click(function (e) {
        e.preventDefault();
        var orderData = $(this).attr("data-order");
        var orderObj  = JSON.parse(orderData);
        var orderType = orderObj.type;
        var orderID = orderObj.oid;
        var orderSummaryId = orderObj.osid;
        var orderToken = orderObj.token;
        var url       = $(document).find(".orders-form").attr("action");
        url = require("../util").appendParamToURL(url, "orderID", orderID);
        url = require("../util").appendParamToURL(url, "orderSummaryId", orderSummaryId);
        url = require("../util").appendParamToURL(url, "type", orderType);
        url = require("../util").appendParamToURL(url, "orderToken", orderToken);
        window.location = url;
    });
}

/**
 * @description Trim the cc number on order details page to only be last four digits
 */
function trimCcNum() {
    var cardNum = $(".cl-order-details .cl-order-details__item .cc-account-container .cc-type-box__cc-number");

    if (cardNum && cardNum.text().length > 4) {
        var newCardNum;

        newCardNum = "••••" + cardNum.text().slice(-4);
        cardNum.replaceWith(newCardNum);
    }
}

var orderdetail = {
    init: function () {
        getOrderDetails();
        trimCcNum();
    }
};

module.exports = orderdetail;
