/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var debounce = require('./debounce'),
    jRes = require('./jRes');

function initUserAgentDetecet() {
    if ($.isFunction($.fn.deviceDetector)) {
        window.userAgent = $.fn.deviceDetector;
    }
}

function initCache() {
    window.modulesSystem.$cache = window.modulesSystem.$cache || {};
    window.modulesSystem.$cache.body = $('body');
    window.modulesSystem.$cache.html = $('html');

    // Load all the sizes in global scope for lazy images plugin
    window.lazySizesConfig = window.lazySizesConfig || {};
    window.lazySizesConfig.init = false;
    window.lazySizesConfig.customMedia = {
        '--x-large': '(min-width: 1920px)',
        '--large': '(min-width: 1024px)',
        '--medium': '(min-width: 768px)',
        '--small': '(min-width: 320px)'
    }

    window.lazySizes.init();
}

var modules = {
    forgotPasswordPopup: require('./modules/_forgot-password-popup.js'),
    tradeProfessionalsForm: require('./modules/_trade-professionals-form.js'),
    becomeMember: require('./modules/_become-member.js'),
    storeCategories: require('./modules/_store-categories.js'),
    storeHeroBanner: require('./modules/_store-hero-banner.js'),
    textHtmlDump: require('./modules/_text-html-dump.js'),
    productRecommendationsSlider: require('./modules/_product-recommendations-slider.js'),
    productRecommendationsCarousel: require('./modules/_product-recommendations-carousel.js'),
    heroCarousel: require('./modules/_hero-carousel.js'),
    titleTextCta: require('./modules/_title-text-cta.js'),
    filterCategoryRecommendations: require('./modules/_filter-category-recommendations.js'),
    productTile: require('./modules/_product-tile.js'),
    clpHeaderBanner: require('./modules/_clp-header-banner.js')
};

var app = {
    init: function () {
        window.modulesSystem = window.modulesSystem || {};
        window.modulesSystem.jRes = jRes.init();

        initCache();
        initUserAgentDetecet();

        // Capitol Lighting Modules
        modules.forgotPasswordPopup.init();
        modules.tradeProfessionalsForm.init();
        modules.becomeMember.init();
        modules.storeCategories.init();
        modules.storeHeroBanner.init();
        modules.textHtmlDump.init();
        modules.productRecommendationsSlider.init();
        modules.productRecommendationsCarousel.init();
        modules.heroCarousel.init();
        modules.titleTextCta.init();
        modules.filterCategoryRecommendations.init();
        modules.productTile.init();
        modules.clpHeaderBanner.init();
    }
};

// initialize app
module.exports = app;
