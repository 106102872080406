

var page = require("./page");

/**
 * @private
 * @function
 * @description Binds the events of the footer's content.
 */
function initializeEvents() {
    $(".newsletter-submit").on("click", function (e) {
        e.preventDefault();
        var emailRegX = /^[\w.%+\-]+@[\w.\-]+\.[\w]{2,6}$/; //eslint-disable-line
        var email = $(".newsletter-email").val().toLowerCase();

        if (email == "") {
            page.redirect(Urls.myAccountEmail);
        } else {
            jQuery.ajax({
                url      : Urls.newsletterSubscription,
                type     : "POST",
                data     : {
                    email : email,
                }
            }).done(function (response) {

                if (response !== "" && !response.errorMsg) {
                    $('body').trigger('custom:emailsignup', [{type: 'standalone'}]);
                    $(".search-box__email-bar").addClass("ms-hide");
                    $(".newsletter-email-message").removeClass("ms-hide");
                    $(".newsletter-email-message1").removeClass("ms-hide");
                    $(".newsletter-emailused-message").addClass("ms-hide");
                    $(".newsletter-error-message").hide();
                    $(".newsletter-error-message").removeClass("error");
                } else {
                    $(".newsletter-error-message").text(response.errorMsg).show();
                    $(".newsletter-error-message").addClass("error");
                }
            });
        }

        if (email != 0 && !emailRegX.test(email)) {
            $(".newsletter-validemail-message").removeClass("ms-hide");
            $(".newsletter-emailused-message").addClass("ms-hide");
            return false;
        }

    });
}

/**
 * @private
 * @function
 * @description Binds the events of the footer's content.
 */
function EmailSubcriptions() {
    $(".js-email-subscription-button").on("click", function (e) {
        e.preventDefault();
        window.scrollTo(0, 0);

        var emailSubscription     = $("[name$='emailpreferences_email']").val(),
            postalSubscription    = $("[name$='emailpreferences_postal']").val(),
            firstnameSubscription = $("[name$='emailpreferences_firstname']").val(),
            lastnameSubscription  = $("[name$='emailpreferences_lastname']").val(),
            offersandPromotions   = $("[name$='emailpreferences_offersandpromotions']").is(":checked"),
            designNews            = $("[name$='emailpreferences_designnews']").is(":checked"),
            newandBestProducts    = $("[name$='emailpreferences_newandbestproducts']").is(":checked"),
            wishlist              = $("[name$='emailpreferences_wishlist']").is(":checked");

        var emailSubscriptionInput     = $("[name$='emailpreferences_email']").attr("name"),
            postalSubscriptionInput    = $("[name$='emailpreferences_postal']").attr("name"),
            firstnameSubscriptionInput = $("[name$='emailpreferences_firstname']").attr("name"),
            lastnameSubscriptionInput  = $("[name$='emailpreferences_lastname']").attr("name"),
            offersandPromotionsInput   = $("[name$='emailpreferences_offersandpromotions']").attr("name"),
            designNewsInput            = $("[name$='emailpreferences_designnews']").attr("name"),
            newandBestProductsInput    = $("[name$='emailpreferences_newandbestproducts']").attr("name"),
            wishlistInput              = $("[name$='emailpreferences_wishlist']").attr("name");

        var data = {};

        data[emailSubscriptionInput]     = emailSubscription;
        data[postalSubscriptionInput]    = postalSubscription;
        data[firstnameSubscriptionInput] = firstnameSubscription;
        data[lastnameSubscriptionInput]  = lastnameSubscription;
        data[offersandPromotionsInput]   = offersandPromotions;
        data[designNewsInput]            = designNews;
        data[newandBestProductsInput]    = newandBestProducts;
        data[wishlistInput]              = wishlist;

        if (emailSubscription == "") {
            $(".newsletter-error-message").text(Resources.NEWSLETTER_EMPTY_EMAIL).show();
        } else {
            jQuery.ajax({
                url      : Urls.emailPreferencesHandleForm,
                type     : "POST",
                data     : data
            }).done(function (response) {
                if (response != "" && response.success) {
                    $('body').trigger('custom:emailsignup', [{type: 'form', formOfSignup: 'email preference center'}]);
                    $(".js-form-email-subscriptions").addClass("ms-hide");
                    $(".email-subscribed-message").removeClass("ms-hide");
                    $(".newsletter-emailused-message").addClass("ms-hide");
                } else {
                    $(".newsletter-emailused-message").text(response.errorMsg).removeClass("ms-hide");
                }
            });
        }
    });

    $(".js-email-unsubscribe-button").on("click", function (e) {
        e.preventDefault();

        jQuery.ajax({
            url      : Urls.emailPreferencesUnsubscribe,
            type     : "GET"
        }).done(function (response) {
            if (response != "" && response.success) {
                $(".js-form-email-subscriptions").addClass("ms-hide");
                $(".newsletter-emailused-message").removeClass("ms-hide");
                $(".newsletter-unsubscribe1-message").removeClass("ms-hide");
                $(".newsletter-emailused-message").addClass("ms-hide");
            } else {
                $(".newsletter-emailused-message").text(response.errorMsg).removeClass("ms-hide");
            }
        });
    });

}

var newsletter = {
    init: function newsletter() {
        initializeEvents();
        EmailSubcriptions();
    }
};

module.exports = newsletter;
