

var productpopup = {
    /**
     * @public
     * @function
     * @description initialize dialog events
     */
    events: function () {
        var self = this;

        // Close dialog by click on close button or doalog backdrop
        $(".js-product-notification-popup").add(".js-product-notification-close").on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            self.hide();
        });

        // Prevent event bubbling to dialog backdrop
        $(".js-product-notification-container").on("click", function (e) {
            e.stopPropagation();
        });

        // Hide the dialog if it was shown and switched breakpoint
        window.modulesSystem.jRes.addFunc([{
            breakpoint: ["phone", "phoneLandscape", "tablet"],
            exit: function () {
                self.hide();
            }
        }]);
    },
    /**
     * @public
     * @function
     * @param {String} html - the markup of minicart
     * @description dynamically append dialog template to the body, insert into the container the last added product to the cart. Adjusted price and labels. Show the dialog and trigger events initialization.
     */
    show: function (html) {
        var self        = this,
            productId   = $(document).find("input#capitolID").val(),
            productQty  = $(document).find("input#Quantity").val(),
            lastProduct = $(html).find(".js-line-product[data-id=\"" + productId + "\"]"),
            $responseEachLabel;

        $.ajax({
            url: Urls.getProductPopupTemplate,
            success: function (response) {
                // Append dialog container to the body
                window.modulesSystem.$cache.body.append(response);

                // Insert last added item into cart into dialog
                var $responseContainer = $(".js-product-notification-response");
                $responseContainer.html(lastProduct);

                // Adjusted quantity and price label
                $responseContainer.find(".mini-cart-quantity .value").text(productQty);
                $responseEachLabel = $responseContainer.find(".mini-cart-price .each-label");

                if (productQty > 1) {
                    $responseEachLabel.addClass("show-label");
                }

                // Show the dialog and initialize events
                $(".js-product-notification-popup").addClass("shown");
                window.modulesSystem.$cache.body.addClass("ms-overflow-hidden");
                self.events();
            }
        });
    },
    /**
     * @public
     * @function
     * @description close dialog, remove body additional classes and remove dialog from the DOM
     */
    hide: function () {
        $(".js-product-notification-popup").removeClass("shown").remove();
        window.modulesSystem.$cache.body.removeClass("ms-overflow-hidden");
    }
};

module.exports = productpopup;
