'use strict';

var quickview = require("./quickview");
var dialog = require("./dialog");

exports.init = function () {

    window.addEventListener('message', function(message) {
        if (message.origin !== "https://view.publitas.com") {
            return;
        }
        
        var event = JSON.parse(message.data);
        var product = event[0];
        
        if(product && product.webshopIdentifier) {
            quickview.show({
                url: $("#product_url").val() + '?pid=' + product.webshopIdentifier,
                source: "quickview"
            });
        }
    });

    $('body').on('product:afterAddToCart', function () {
        if ($( "div[id^='publitas-embed']" ).length) {
            dialog.close();
        }
    })
};
