

var $loader;

/**
 * @function
 * @description Shows an AJAX-loader on top of a given container
 * @param {Element} container The Element on top of which the AJAX-Loader will be shown
 */
var show = function (container) {
    $loader = $loader || $(".loader");

    if ($loader.length === 0) {
        $loader = $("<div/>").addClass("loader")
            .append($("<div/>").addClass("loader-indicator"), $("<div/>").addClass("loader-bg"));
    }

    // if (container != "bii") {
    //     $(".js-loading-hide-span").addClass("loading");
    //     $(".js-loader").removeClass("ms-hide");
    //     return $loader.appendTo(".js-loading-spinner").show();
    // }
};
/**
 * @function
 * @description Hides an AJAX-loader
 */
var hide = function () {
    if ($loader) {
        $(".js-loading-hide-span").removeClass("loading");
        $(".js-loader").addClass("ms-hide");
        $loader.hide();
    }
};

exports.show = show;
exports.hide = hide;
