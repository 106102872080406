var navStaticAttributes = {
    'categoryNameFooter' : {
        'element_ordinal': 'Secondary',
    },
    'contactBoxFooter' : {
        'element_ordinal': 'Primary',
    },
    'socialFooter' : {
        'element_ordinal': 'Secondary',
        'element_primary': 'Social',
    },
};
var NavigationEvents = {
    elementEventType: '',
    categoryNameFooter: function(element) {
        let elemStatic = navStaticAttributes[this.elementEventType];

        elemStatic['element_primary'] = element.closest('div').find('.golden-title').text();
        elemStatic['element_secondary'] = element.text();

        this.setNavigationEvent();
    },
    contactBoxFooter: function(element) {
        let elemStatic = navStaticAttributes[this.elementEventType];

        elemStatic['element_primary'] = element.data('gtm-primary');

        this.setNavigationEvent();
    },
    socialFooter: function(element) {
        let elemStatic = navStaticAttributes[this.elementEventType];

        elemStatic['element_secondary'] = element.closest('[title]').attr('title');

        this.setNavigationEvent();
    },
    setNavigationEvent: function () {
        this.pushNavigationEvent(...Object.values(navStaticAttributes[this.elementEventType]));
    },
    pushNavigationEvent: function(elementOrdinal, elementPrimary = null, elementSecondary = null) {
        var eventObject = {
            event: "gtm_event",
            non_interaction_event: false,
            event_name: 'element_interaction',
            element: 'footer',
            element_action: "click",
            element_ordinal: elementOrdinal,
            element_primary: elementPrimary,
            element_secondary: elementSecondary
        }
    
        // lowercase the values
        eventObject = this.lowercaseObjectValues(eventObject);

        dataLayer.push(eventObject);
    },
    lowercaseObjectValues: function(obj) {
        if (Array.isArray(obj)) {
            return obj.map(this.lowercaseObjectValues);
        } else if (typeof obj === 'object' && obj !== null) {
            var result = {};
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    result[key] = this.lowercaseObjectValues(obj[key]);
                }
            }
            return result;
        } else if (typeof obj === 'string') {
            return obj.toLowerCase();
        } else {
            return obj;
        }
    }
};
function init() {
    $('body').on('click', '.details-box-item__link', function(e) {
        let $elem = $(e.target);
        let eventName = 'categoryNameFooter';

        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
    $('body').on('click', '.contact-box__link', function(e) {
        let $elem = $(e.target);
        if($elem.data('gtm-event') && NavigationEvents[$elem.data('gtm-event')]){
            NavigationEvents.elementEventType = $elem.data('gtm-event');
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
    $('body').on('click', '.gtm-social-links', function(e) {
        let $elem = $(e.target).closest('.gtm-social-links');        
        let eventName = 'socialFooter';

        if(NavigationEvents[eventName]){
            NavigationEvents.elementEventType = eventName;
            NavigationEvents[NavigationEvents.elementEventType]($elem);
        }
    });
};

module.exports = {
    init: init
}

