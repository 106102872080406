/**
 * @private
 * @function
 * @description Initialize jRespond
 */
function jResInit() {
    var jRes = jRespond([
        {
            label: 'phone',
            enter: 0,
            exit: 479
        },
        {
            label: 'phoneLandscape',
            enter: 480,
            exit: 767
        },
        {
            label: 'tablet',
            enter: 768,
            exit: 1023
        },
        {
            label: 'desktop',
            enter: 1024,
            exit: 1365
        },
        {
            label: 'desktopLarge',
            enter: 1366,
            exit: 20000
        }
    ]),
    $document = $(document);

    //set desktop as default
    jRes.isPhone   = false;
    jRes.isPhoneLandscape  = false;
    jRes.isTablet           = false;
    jRes.isDesktop          = false;

    // Run only once
    if (window.jResInitFlag === undefined) {
        window.jResInitFlag = true;
    }

    if (window.jResInitFlag) {
        jRes.addFunc([{
            breakpoint: 'phone',
            enter: function() {
                jRes.isPhone = true;
                $document.trigger('viewportchange', ['phone']);
            },
            exit: function() {
                jRes.isPhone = false;
            }
        },{
            breakpoint: 'phoneLandscape',
            enter: function() {
                jRes.isPhoneLandscape = true;
                $document.trigger('viewportchange', ['phoneLandscape']);
            },
            exit: function() {
                jRes.isPhoneLandscape = false;
            }
        },{
            breakpoint: 'tablet',
            enter: function() {
                jRes.isTablet = true;
                $document.trigger('viewportchange', ['tablet']);
            },
            exit: function() {
                jRes.isTablet = false;
            }
        },{
            breakpoint: 'desktop',
            enter: function() {
                jRes.isDesktop = true;
                $document.trigger('viewportchange', ['desktop']);
            },
            exit: function() {
                jRes.isDesktop = false;
            }
        },{
            breakpoint: 'desktopLarge',
            enter: function() {
                jRes.isDesktopLarge = true;
                $document.trigger('viewportchange', ['desktopLarge']);
            },
            exit: function() {
                jRes.isDesktopLarge = false;
            }
        }]);

        window.jResInitFlag = false;
    }

    return jRes;
}

exports.init = jResInit;